import { CustomNav, CustomNavLink } from "components/Common"
import { getUrlParamByKey } from "helpers/utilities"
import { useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { Row, TabContent, TabPane } from "reactstrap"
import CausalActionQC from "./CausalActionQC"
import { CAUSAL_ACTION } from "constant"

const CausalActionQCTab = ({ t }) => {
    const [currentTab, setCurrentTab] = useState(getUrlParamByKey("tab") || "1")

    return (
        <Row style={{ backgroundColor: 'white' }}>
            <CustomNav
                onToggle={e => {
                    setCurrentTab(e)
                }}
                defaultTab={currentTab}
                tabs
                className="nav-tabs-custom pt-3"
                tabContents={customActiveTab => (
                    <TabContent
                        activeTab={customActiveTab}
                    >
                        {currentTab == '1' && <TabPane tabId="1">
                            <div className='mt-1'>
                                <CausalActionQC
                                    causalActionType={CAUSAL_ACTION.NN}
                                    subtitle={t('Causal')}
                                />
                            </div>
                        </TabPane>}
                        {currentTab == '2' && <TabPane tabId="2">
                            <div className='mt-1'>
                                <CausalActionQC
                                    causalActionType={CAUSAL_ACTION.HDKP}
                                    subtitle={t('Corrective Action')}
                                />
                            </div>
                        </TabPane>}
                    </TabContent>
                )}
            >
                <CustomNavLink tabId="1">
                    <span className="">{t("Causal")}</span>
                </CustomNavLink>
                <CustomNavLink tabId="2">
                    <span className="">{t("Corrective Action")}</span>
                </CustomNavLink>
            </CustomNav>
        </Row>
    )
}


export default connect(
    null,
    null
)(withRouter(withTranslation(["causalActionQCPage", "message"])(CausalActionQCTab)))
