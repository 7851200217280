import { CustomBootstrapTable, CustomSID, LinkCustom } from "components/Common";
import BadgeLabel from "components/Common/BadgeLabel";
import * as constant from "constant";
import { RESULT_STATE } from "constant";
import { convertDateFormat, convertDateFormat_VN, indexCalculator } from "helpers/utilities";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import FilterForm from "./FilterForm";
import { ModuleIds } from "constant/utility"

const RESOURCE = constant.ModuleIds.WorkList;

const WorkListTable = ({
    workLists,
    onSearch,
    onSort,
    onRefresh,
    onPageChange,
    paging,
    onSizePerPageChange,
    onSubmitFilter,
    loading,
    t,
    updatedTime,
    onChangeModel,
    buttons,
    onSelect,
    onSelectAll,
    onExport,
    loadingDataExport,
    onExportWorkList,
}) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        setData(workLists)
    }, [workLists])

    const columns = [
        {
            dataField: "idx",
            text: "#",
            hidden: true,
            formatter: (cellContent, test, index) => {
                return cellContent
            },
        },
        {
            dataField: "id",
            text: t("#"),
            style: { width: 50 },
            formatter: (cellContent, test, index) => {
                return indexCalculator(paging.page, paging.size, index + 1)
            },
        },
        {
            dataField: "sid",
            text: t("SID"),
            formatter: (cellContent, item, index) => {
                return (
                    <CustomSID
                        key={`sampleType-${index}`}
                        keyToolTip={`sampleType-${index}`}
                        sid={item.subSID > 0 ? `${cellContent}-${item.subSID}` : cellContent}
                    />
                )
            }
        },
        {
            dataField: "fullName",
            text: t("Patient Name"),
            formatter: (cellContent, row, index) => {
                return row.fullName ? <LinkCustom resource={ModuleIds.Patient} link={`/Patient/${row.patientId}/view?tab=1`} content={`${row.fullName}`} /> : <></>
            },
        },
        {
            dataField: "dob",
            text: t("DOB"),
            formatter: (cellContent, row, index) => {
                return convertDateFormat_VN(cellContent)
            },
        },
        {
            dataField: "patientId",
            text: t("PID"),
        },
        {
            dataField: "testName",
            text: t("Test Name"),
        },
        {
            dataField: "result",
            text: t("Result"),
            headerStyle: { whiteSpace: 'nowrap' },
        },
        {
            dataField: "resultText",
            text: t("Result Text"),
            headerStyle: { whiteSpace: 'nowrap' },
        },
        {
            dataField: "normalRange",
            text: t("Normal Range"),
        },
        {
            dataField: "state",
            text: t("testResultPage:State"),
            align: 'center',
            headerAlign: 'center',
            formatter: (cellContent, row, index) => {
                return <BadgeLabel
                    value={t(`${RESULT_STATE[cellContent]}`)}
                    type={RESULT_STATE[cellContent]}
                    className="badge-width"
                />
            },
        },
        {
            dataField: "confirmName",
            text: t("workListPage:Confirm Name"),
            headerStyle: { whiteSpace: 'nowrap' },
        },
        {
            dataField: "confirmedDate",
            text: t("workListPage:Confirm Time"),
            headerStyle: { whiteSpace: 'nowrap' },
            formatter: (cellContent, row, index) => {
                return row.confirmedDate ? convertDateFormat(row.confirmedDate, "HH:mm:ss DD-MM-YYYY ") : '';
            }
        },
        {
            dataField: "validateName",
            text: t("workListPage:Validate Name"),
            headerStyle: { whiteSpace: 'nowrap' },
        },
        {
            dataField: "validatedDate",
            text: t("workListPage:Validate Time"),
            formatter: (cellContent, row, index) => {
                return row.validatedDate ? convertDateFormat(row.validatedDate, "HH:mm:ss DD-MM-YYYY ") : '';
            }
        },
    ]

    return (
        <React.Fragment>
            <div className="work-list">
                <FilterForm onChangeModel={(model) => {
                    onChangeModel(model)
                }}
                    loadingDataExport={loadingDataExport}
                    onSearch={onSearch}
                    onExport={onExportWorkList}
                />
            </div>
            <CustomBootstrapTable
                bodertable={true}
                columns={columns}
                data={data}
                paging={paging}
                onSort={onSort}
                onRefresh={onRefresh}
                onPageChange={onPageChange}
                onSizePerPageChange={onSizePerPageChange}
                onSubmitFilter={onSubmitFilter}
                loading={loading}
                updatedTime={updatedTime}
                resource={RESOURCE}
                keyField="id"
                isEnableRefresh={false}
                isEnableExport={true}
                customButtonTable={() => <React.Fragment>
                    {buttons && buttons()}
                </React.Fragment>}
                showSelectRow={true}
                onSelect={onSelect}
                isSelected
                onSelectAll={onSelectAll}
            />

        </React.Fragment>
    )
}

export default withTranslation(["workListPage", "common"])(WorkListTable)