import { CustomBreadcrumb, PageContent } from "components/Common";
import { ModuleIds } from "constant";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import WestgardSigmaIQCTab from "./MachineTestQCTab";

const RESOURCE = ModuleIds.MachineTestQC

const WestgardSigmaIQCContainer = ({ t }) => {
    const titleTag = `MachineTestQC`
    return (
        <PageContent title={t(titleTag)}>
            < CustomBreadcrumb
                breadcrumbItem={t("WestgardSigmaQCList")}
                resource={RESOURCE}
            />
            <WestgardSigmaIQCTab />
        </PageContent >
    )
}

WestgardSigmaIQCContainer.propTypes = {
    t: PropTypes.any,
}

export default withTranslation(["westgardSigmaQCPage"])(WestgardSigmaIQCContainer)