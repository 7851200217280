import { CustomBreadcrumb, PageContent } from "components/Common";
import { ModuleIds } from "constant";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import CaculationQCTab from "./CalculationQCTab";

const RESOURCE = ModuleIds.CalculationiQC

const CaculationQCContainer = ({ t }) => {
    const titleTag = `CalculationQC`
    return (
        <PageContent title={t(titleTag)}>
            <CustomBreadcrumb
                breadcrumbItem={t("CaculationQCList")}
                resource={RESOURCE}
            />
            <CaculationQCTab />
        </PageContent >
    )
}

CaculationQCContainer.propTypes = {
    t: PropTypes.any,
}

export default withTranslation(["calculationQCPage"])(CaculationQCContainer)