import { Check, CustomButton } from "components/Common"
import { permissionType } from "constant"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
const HeaderButtons = ({ resource, t, config,
    onClickAddResult,
    onClickSave,
    disableSave,
    disableValid,
    disableInValid,
    disableCancelResult,
    disableDelResult,
    onClickValid,
    onClickCancelValid,
    onClickCancelResult,
    onClickDeleteResult,
    onClickView,
    loading
}) => {
    return (
        <>
            <Check permission={permissionType.ViewResult} resource={resource}>
                <CustomButton color="primary"
                    loading={loading}
                    isEdit
                    onClick={() => {
                        onClickView()
                    }} outline>
                    {t("View Result")}
                </CustomButton>
            </Check>
            <Check permission={permissionType.AddResult} resource={resource}>
                <CustomButton color="primary" disabled={config.isAddManual != true}
                    onClick={() => {
                        onClickAddResult()
                    }} outline>
                    {t("Add Result")}
                </CustomButton>
            </Check>
            <Check permission={permissionType.U} resource={resource}>
                <CustomButton color="primary" onClick={() => {
                    onClickSave()
                }} outline
                    disabled={disableSave}
                >
                    {t("common:Save")}
                </CustomButton>
            </Check>
            <Check permission={permissionType.ValidResult} resource={resource}>
                <CustomButton color="success" onClick={() => {
                    onClickValid()
                }} outline
                    disabled={disableValid}
                >
                    {t("Valid Result")}
                </CustomButton>
            </Check>
            <Check permission={permissionType.CancelValid} resource={resource}>
                <CustomButton color="warning" onClick={() => {
                    onClickCancelValid()
                }} outline
                    disabled={disableInValid}
                >
                    {t("Cancel Valid")}
                </CustomButton>
            </Check>
            <Check permission={permissionType.CancelResult} resource={resource}>
                <CustomButton color="danger" onClick={() => {
                    onClickCancelResult()
                }} outline
                    disabled={disableCancelResult}
                >
                    {t("Cancel Result")}
                </CustomButton>
            </Check>
            <Check permission={permissionType.DeleteResult} resource={resource}>
                <CustomButton color="danger" onClick={() => {
                    onClickDeleteResult()
                }} outline
                    disabled={disableDelResult}>
                    {t("Del Result")}
                </CustomButton>
            </Check>
        </>
    )
}

HeaderButtons.propTypes = {
    resource: PropTypes.string.isRequired,
    onReloadClick: PropTypes.func.isRequired,
    onSuspendClick: PropTypes.func.isRequired,
}

export default withTranslation(["resultIQCPage", "common"])(HeaderButtons)
