import { parameterCode } from "constant"
import { AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { CustomDatePicker, CustomSelect, CustomSelectAsync } from "components/Common"
import { convertDateFormat, getI18nextLng } from "helpers/utilities"
let isRender = false;
const HeaderFilter = ({
    t,
    model,
    onChange,
    onSubmitFilter
}) => {
    const [machine, setMachine] = useState(model.machine || '')
    const [level, setLevel] = useState(model.level || 0)
    const [test, setTest] = useState(model.test || '')
    const [dates, setDates] = useState([
        convertDateFormat(new Date(), "YYYY-MM-DD"),
        convertDateFormat(new Date(), "YYYY-MM-DD")
    ]);
    useEffect(() => {
        if (isRender == true) {
            onSubmitFilter()
        }
    }, [machine, level, test, dates])

    return (
        <React.Fragment>
            <AvForm>
                <div className="row w-100 header-management-qc" style={{ paddingTop: '10px' }}>
                <div className="col-lg-6 mb-2">
                <CustomDatePicker
                            name="requestDate"
                            label={''}
                            format={"DD/MM/YYYY"}
                            dateFormat={"d-m-Y"}
                            value={[
                                convertDateFormat(dates[0], "DD-MM-YYYY"),
                                convertDateFormat(dates[1], "DD-MM-YYYY")
                            ]}
                            onChangeHandler={(date) => {
                                if (date.time.length === 2) {
                                    const newDates = [
                                        convertDateFormat(date.time[0], "YYYY-MM-DD"),
                                        convertDateFormat(date.time[1], "YYYY-MM-DD")
                                    ];
                                    setDates(newDates);
                                    onChange({
                                        start: newDates[0],
                                        end: newDates[1]
                                    });
                                }
                            }}
                            closeOnSelect={true}
                            mode='range'
                            maxDate={new Date(new Date().setHours(0, 0, 0, 0))}
                            conjunction={'  '}
                        />
                </div>
                <div className="col-lg-6 mb-2">
                    <CustomSelect
                        name="machineId"
                        label={""}
                        onChange={(e, vals) => {
                            isRender = true
                            onChange({ machine: vals[0] || '' })
                            setMachine(vals[0] || '')
                        }}
                        placeholder={t("Machine")}
                        value={model.machine || ''}
                        code={parameterCode.INSTRUMENT}
                    />
                </div>
                    <div className="col-lg-6 mb-2">
                    <CustomSelectAsync
                            name="testId"
                            label={""}
                            onChange={(e, vals) => {
                                isRender = true
                                onChange({ test: vals[0] || '' })
                                setTest(vals[0] || '')
                            }}
                            placeholder={t("TestCodeName")}
                            value={model.test || ''}
                            code={parameterCode.TEST_CODE}
                        />
                    </div>
                    
                    <div className="col-lg-6 mb-2">
                        <CustomSelect
                            name="level"
                            code={parameterCode.LEVEL_QC}
                            label={""}
                            onChange={(e, vals) => {
                                isRender = true
                                onChange({ levels: vals[0] || '' })
                                setLevel(vals[0] || '')
                            }}
                            placeholder={t("Level")}
                            value={model.level}
                        />
                    </div>
                </div>
            </AvForm>
        </React.Fragment>
    )
}

const mapStateToProps = ({ }) => ({
})

const mapDispatchToProps = dispatch => ({
})
HeaderFilter.displayName = 'HeaderFilter';
export default withTranslation(["chartiQCPage", "common"], { withRef: true })(connect(
    mapStateToProps,
    mapDispatchToProps, null, { forwardRef: true }
)(HeaderFilter))