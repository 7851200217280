import { call, put, takeEvery } from "redux-saga/effects"

//setting redux states
import {
  ADD_MACHINE_QC,
  DELETE_MACHINE_QC
} from "./actionTypes"

import {
  addMachineQCFail,
  addMachineQCSuccess,
  deleteMachineQCFail,
  deleteMachineQCSuccess
} from "./actions"
import {
  createMachineQC,
  deleteMachineQC
} from "helpers/app-backend/machinesqc_backend_helper"

function* onAddMachineQC({ payload, callback }) {
  try {
    const response = yield call(createMachineQC, payload)
    const id = response?.id
    yield put(addMachineQCSuccess(id))
    if (callback) {
      callback()
    }
  } catch (error) {
    console.log(error)
    yield put(addMachineQCFail(error))
  }
}

function* onDeleteMachineQC({ payload, callback }) {
  try {
    const response = yield call(deleteMachineQC, payload)
    yield put(deleteMachineQCSuccess(payload))
    if (callback) callback()
  } catch (error) {
    console.log(error)
    yield put(deleteMachineQCFail(error))
  }
}

function* machineSaga() {
  yield takeEvery(ADD_MACHINE_QC, onAddMachineQC)
  yield takeEvery(DELETE_MACHINE_QC, onDeleteMachineQC)
}
export default machineSaga
