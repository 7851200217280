import { CustomBootstrapTable, CustomButton } from "components/Common"
import BadgeLabel from "components/Common/BadgeLabel"
import { CustomTooltipButton } from "components/Common/Button"
import { ModuleIds } from "constant"
import { convertDateFormat, indexCalculator } from "helpers/utilities"
import { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect, useSelector } from "react-redux"
import ConfigLotQCButtons from "./ConfigLotQCButtons"
import FilterForm from "./FilterForm"

const RESOURCE = ModuleIds.ManagementQC

const ConfigLotQCTable = ({
  onSelect,
  onSelectAll,
  lotQCs,
  onSearch,
  onSort,
  onRefresh,
  onPageChange,
  paging,
  onSizePerPageChange,
  onSubmitFilter,
  loading,
  onEdit,
  onDelete,
  t,
  updatedTime,
  isSelected,
  onAnalysisProcessExport,
  onChangeModel,
}) => {
  const [data, setData] = useState([])
  const [model, setModel] = useState({
    search: "",
    fromDate: convertDateFormat(new Date(), "YYYY-MM-DD 00:00"),
    end: convertDateFormat(new Date(), "YYYY-MM-DD HH:mm"),
  })
  const searchQuery = useSelector(state => state.lotQCs.searchQuery)
  const onResetHandler = () => {
    const initModel = {
      search: "",
      fromDate: convertDateFormat(new Date(), "YYYY-MM-DD 00:00"),
      end: convertDateFormat(new Date(), "YYYY-MM-DD HH:mm"),
    }
    setModel(initModel)
  }

  useEffect(() => {
    onChangeModel && onChangeModel(model)
  }, [model])

  useEffect(() => {
    setModel(searchQuery)
  }, [searchQuery])

  useEffect(() => {
    setData(lotQCs)
  }, [lotQCs])

  const columns = [
    {
      dataField: "id",
      text: "",
      hidden: true,
      formatter: (cellContent, item, index) => {
        return cellContent
      },
    },
    {
      dataField: "SEQ",
      text: "#",
      headerStyle: { width: "30px" },
      formatter: (cellContent, item, index) => {
        return indexCalculator(paging.page, paging.size, index + 1)
      },
    },
    {
      dataField: "expireDate",
      text: t("Expire Date"),
      style: { minWidth: 110 },
      formatter: (cellContent, item, index) => {
        return <span>{convertDateFormat(cellContent, "DD-MM-YYYY")}</span>
      },
    },
    {
      dataField: "lotId",
      text: t("Lot ID"),
      style: {
        minWidth: 80,
      },
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "lotName",
      text: t("Lot Name"),
      style: {
        minWidth: 210,
        color: "#3C9DEC",
        fontWeight: "bold",
        fontSize: 14,
      },
      align: "center",
      headerAlign: "center",
      formatter: (cellContent, item, index) => {
        return (
          <label
            key={index}
            data-tip
            data-for={`lotName-${index}`}
            style={{
              color: "#5F5F5F",
              backgroundColor: "#fff",
              padding: "1px 5px",
              marginRight: "5px",
              borderRadius: "5px",
              border: `1px solid #808080`,
              minWidth: 40,
              textAlign: "center",
            }}
          >
            {cellContent}
          </label>
        )
      },
    },
    {
      dataField: "machineName",
      text: t("Machines"),
      style: {
        minWidth: 150,
      },
      align: "center",
      headerAlign: "center",
      formatter: cellContent => {
        const machineNames = cellContent?.split(",") || []

        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {machineNames.map((name, index) => (
              <div
                key={`machine-name-${index}`}
                style={{ marginBottom: 4, fontSize: 15, color: "#333" }}
              >
                {name.trim()}
              </div>
            ))}
          </div>
        )
      },
    },
    {
      style: { width: "80px" },
      headerStyle: { width: "80px" },
      dataField: "testCategory",
      text: t("Test Category"),
    },
    {
      dataField: "levels",
      text: t("Levels"),
      style: { width: "60px" },
      headerStyle: { width: "60px" },
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "noOfPointPI",
      style: { width: "120px" },
      headerStyle: { width: "120px" },
      text: t("Number of PI Points"),
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "noOfPointCal",
      style: { width: "140px" },
      headerStyle: { width: "140px" },
      text: t("Number of Calibration Points"),
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "manufactor",
      text: t("Manufactor"),
      style: {
        width: "140px",
        color: "white",
      },
      headerStyle: { width: "140px" },
      formatter: (cellContent, item, index) => {
        return (
          <>
            <BadgeLabel
              value={cellContent}
              type={"rev"}
              className="badge-width"
              dataFor={cellContent}
            />
          </>
        )
      },
    },
    {
      dataField: "noOfRun",
      text: t("Number of Runs"),
      style: { width: "120px" },
      headerStyle: { width: "120px" },
      style: { fontWeight: "bold" },
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "maxDayOpen",
      text: t("Max Days Open"),
      style: { minWidth: "80px" },
      headerStyle: { minWidth: "120px" },
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "maxOpenDate",
      text: t("Open Date"),
      style: { minWidth: "120px" },
      headerStyle: { minWidth: "120px" },
      formatter: (cellContent, item, index) => {
        return <span>{convertDateFormat(cellContent, "DD-MM-YYYY")}</span>
      },
    },
    {
      dataField: "action",
      text: "",
      isDummyField: true,
      style: { width: 50 },
      formatter: (cellContent, param, index) => (
        <CustomTooltipButton
          index={index}
          component={param}
          onEdit={onEdit}
          onDelete={onDelete}
          t={t}
          resource={RESOURCE}
        />
      ),
    },
  ]

  return (
    <div className="test-request-table-toolbar">
      <CustomBootstrapTable
        columns={columns}
        search
        data={data}
        paging={paging}
        onSelect={onSelect}
        onSelectAll={onSelectAll}
        onSearch={onSearch}
        onSort={onSort}
        onRefresh={onRefresh}
        filterForm={() => <FilterForm model={model} />}
        isSelected={isSelected}
        onReset={onResetHandler}
        onPageChange={onPageChange}
        onSizePerPageChange={onSizePerPageChange}
        onSubmitFilter={onSubmitFilter}
        searchText={model.search}
        loading={loading}
        updatedTime={updatedTime}
        resource={RESOURCE}
        keyField="id"
        isImportExportButton={true}
        importExportForm={() => (
          <div className="tooltip-custom">
            <CustomButton
              isEdit
              color="secondary"
              outline
              onClick={onAnalysisProcessExport}
            >
              <i className={"fas fa-download"}></i>
            </CustomButton>
            <span className="tooltiptext-custom-button-icon-left">
              {t("Export")}
            </span>
          </div>
        )}
        customButtonTable={() => (
          <ConfigLotQCButtons
            t={t}
            model={model}
            onChange={val => {
              setModel({ ...model, ...val })
            }}
            onSubmit={val => {
              onSubmitFilter(val)
            }}
          />
        )}
      />
    </div>
  )
}

const mapStateToProps = ({}) => ({})

const mapDispatchToProps = dispatch => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["managementQCPage", "common"])(ConfigLotQCTable))
