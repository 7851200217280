import {
    ADD_NEW_WESTGARD_SIGMA_QC,
    ADD_WESTGARD_SIGMA_QC_FAIL,
    ADD_WESTGARD_SIGMA_QC_SUCCESS,
    DELETE_WESTGARD_SIGMA_QC,
    DELETE_WESTGARD_SIGMA_QC_FAIL,
    DELETE_WESTGARD_SIGMA_QC_SUCCESS,
    GET_FULL_WESTGARD_SIGMA_QC,
    GET_FULL_WESTGARD_SIGMA_QC_FAIL,
    GET_FULL_WESTGARD_SIGMA_QC_SUCCESS,
    GET_WESTGARD_SIGMA_QC_DETAIL,
    GET_WESTGARD_SIGMA_QC_DETAIL_FAIL,
    GET_WESTGARD_SIGMA_QC_DETAIL_SUCCESS,
    RESET_WESTGARD_SIGMA_QC_SEARCH_QUERY,
    SET_WESTGARD_SIGMA_QC_SEARCH_QUERY,
    UPDATE_WESTGARD_SIGMA_QC,
    UPDATE_WESTGARD_SIGMA_QC_FAIL,
    UPDATE_WESTGARD_SIGMA_QC_SUCCESS
} from "./actionTypes"

/* WestgardSigmaQC search query */
export const resetSearchQuery = () => ({
    type: RESET_WESTGARD_SIGMA_QC_SEARCH_QUERY
})

export const setWestgardSigmaQCSearchQuery = searchQuery => ({
    type: SET_WESTGARD_SIGMA_QC_SEARCH_QUERY,
    payload: searchQuery,
})

/* Get westgardSigmaQCs */
export const getWestgardSigmaQCDetail = payload => {
    return {
        type: GET_WESTGARD_SIGMA_QC_DETAIL,
        payload: payload || {},
    }
}

export const getWestgardSigmaQCDetailSuccess = westgardSigmaQCs => ({
    type: GET_WESTGARD_SIGMA_QC_DETAIL_SUCCESS,
    payload: westgardSigmaQCs,
})

export const getWestgardSigmaQCDetailFail = error => ({
    type: GET_WESTGARD_SIGMA_QC_DETAIL_FAIL,
    payload: error,
})


/* Add westgardSigmaQC */
export const addNewWestgardSigmaQC = (westgardSigmaQC, callback) => ({
    type: ADD_NEW_WESTGARD_SIGMA_QC,
    payload: westgardSigmaQC,
    callback
})

export const addWestgardSigmaQCSuccess = westgardSigmaQC => ({
    type: ADD_WESTGARD_SIGMA_QC_SUCCESS,
    payload: westgardSigmaQC
})

export const addWestgardSigmaQCFail = error => ({
    type: ADD_WESTGARD_SIGMA_QC_FAIL,
    payload: error
})

/* Update a westgardSigmaQC */
export const updateWestgardSigmaQC = (westgardSigmaQC, callback) => ({
    type: UPDATE_WESTGARD_SIGMA_QC,
    payload: westgardSigmaQC,
    callback
})

export const updateWestgardSigmaQCSuccess = westgardSigmaQC => ({
    type: UPDATE_WESTGARD_SIGMA_QC_SUCCESS,
    payload: westgardSigmaQC
})

export const updateWestgardSigmaQCFail = error => ({
    type: UPDATE_WESTGARD_SIGMA_QC_FAIL,
    payload: error
})

/* Delete westgardSigmaQCs */
export const deleteWestgardSigmaQCs = westgardSigmaQCs => ({
    type: DELETE_WESTGARD_SIGMA_QC,
    payload: westgardSigmaQCs
})

export const deleteWestgardSigmaQCsSuccess = westgardSigmaQCs => ({
    type: DELETE_WESTGARD_SIGMA_QC_SUCCESS,
    payload: westgardSigmaQCs
})

export const deleteWestgardSigmaQCsFail = error => ({
    type: DELETE_WESTGARD_SIGMA_QC_FAIL,
    payload: error
})

export const getFullWestgardSigmaQC = payload => ({
    type: GET_FULL_WESTGARD_SIGMA_QC,
    payload: payload || {},
})

export const getFullWestgardSigmaQCSuccess = westgardSigmaQCs => ({
    type: GET_FULL_WESTGARD_SIGMA_QC_SUCCESS,
    payload: westgardSigmaQCs,
})

export const getFullWestgardSigmaQCFail = error => ({
    type: GET_FULL_WESTGARD_SIGMA_QC_FAIL,
    payload: error,
})