import {
  CustomNav,
  CustomNavLink,
  showToast
} from "components/Common"
import { RESULT_STATE_Text, TestRequest_State, TestRequest_Test_Type } from "constant"
import { getUrlParamByKey } from "helpers/utilities"
import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect, useSelector } from "react-redux"
import { Button, TabContent, TabPane } from "reactstrap"
import {
  clearDataRequestDetailInPatientVisit,
  clearDetailInfoPatientVisit,
  createNewRequest,
  deleteRequests,
  emptyTestRequestDetail,
  getIndividualDetail,
  getTestrequestGeneralDetail,
  getTestrequestSamplesDetail,
  getTestrequestSamplesDetailSuccess,
  getTestrequestTestsDetail,
  getTestrequestTestsDetailSuccess,
  updateRequestGeneral,
  updateRequestSample,
  updateRequestTests,
} from "store/actions"
import SampleTab from "././sample/SampleTab"
import TestTab from "././test/TestsTab"
import { getGeneralSetting } from "store/setting/generalSetting/actions"

const init = {
  requestDate: new Date(),
  requestid: "",
  alternateId: "",
  patientId: "",
  medicalId: "",
  fullName: "",
  serviceType: 3,
  dob: new Date(),
  physicianId: 0,
  gender: "",
  departmentId: "",
  phone: "",
  diagnosis: "",
  address: "",
  resultTime: "",
  email: "",
  remark: "",
}

let resultChange = {}
let testRequestSamplesGlobal = [];
const ServiceFrame = forwardRef(
  ({
    t,
    patientVisit,
    testRequestTests,
    patientDetail,
    testRequestSamples,
    onUpdateRequestSample,
    onUpdateRequestTests,
    fetchGetTestrequestSamplesDetail,
    fetchGetTestrequestTestsDetail,
    onEmptyTestRequestDetail,
    onDeleteRequests,
    tabActive,
    loadingRequestTestDetail,
    onRefresh,
    onRefreshInfo,
    patientVisitTab,
    onRequestChange,
    onClearDetailInfoPatientVisit,
    onGetGeneralSetting,
    generalSetting,
    onClearDataRequestDetailInPatientVisit,
    clearPatientDetail
  },
    ref
  ) => {
    const [tests, setTests] = useState([])
    const [tab, setTab] = useState(1)
    const [data, setData] = useState(init)
    const [isCollected, setIsCollected] = useState(false)
    const [colorSample, setColorSample] = useState([])
    const [countTest, setCountTest] = useState(0)
    const [changeTest, setChangeTest] = useState(false)
    const [changeSample, setChangeSample] = useState(false)
    const [showSpin, setShowSpin] = useState(false)
    const [currentTab, setCurrentTab] = useState(getUrlParamByKey('tab') || '1')
    const selectedArea = localStorage.getItem("selectedArea")
    const defaultCollector = localStorage.getItem("defaultCollector")
    const [isSpin, setIsSpin] = useState(false)
    const [userCollectArea, setUserCollectArea] = useState({});
    const [area, setArea] = useState({})
    const [collector, setCollector] = useState({})

    useEffect(() => {
      onGetGeneralSetting(userInfo?.company)
    }, [])

    useEffect(() => {
      testRequestSamplesGlobal = testRequestSamples
    }, [testRequestSamples])


    const userInfo = JSON.parse(localStorage.getItem("userInfo"))
    useEffect(() => {
      onGetGeneralSetting(userInfo?.company)
    }, [])

    useEffect(() => {
      testRequestSamplesGlobal = testRequestSamples
    }, [testRequestSamples])

    useEffect(() => {
      if (tabActive)
        setCurrentTab(tabActive)
    }, [tabActive])

    const getUserCollectArea = () => {
      if (selectedArea) {
        try {
          setArea(JSON.parse(selectedArea))
        } catch (error) {

        }
      }
    }

    const getDefaultCollector = () => {
      if (defaultCollector) {
        try {
          setCollector(JSON.parse(defaultCollector))
        } catch (error) {

        }
      }
    }

    useEffect(() => {
      getUserCollectArea();
      getDefaultCollector();
      window.addEventListener('storage', getUserCollectArea)
      return () => {
        window.removeEventListener('storage', getUserCollectArea)
      }
    }, [selectedArea, defaultCollector])

    useEffect(() => {
      if (selectedArea?.code != selectedArea?.code) {
        getUserCollectArea()
      }
      if (defaultCollector?.collectorId) {
        getDefaultCollector()
      }
    }, [selectedArea, defaultCollector])

    useEffect(() => {
      setIsSpin(loadingRequestTestDetail)
    }, [loadingRequestTestDetail])

    const onGetRequestTesst = () => {
      patientDetail.id && fetchGetTestrequestTestsDetail(patientDetail.id)
    }
    useEffect(() => {
      onGetRequestTesst()
    }, [patientDetail.id])

    useEffect(() => {
      setTests(testRequestTests)
    }, [testRequestTests])

    useImperativeHandle(ref, () => ({
      ResetData: () => {
        setData({ requestDate: new Date(), dob: new Date() })
        setTab(1)
        onEmptyTestRequestDetail()
      },
      submit: () => {
        formRef.current.submit()
      },
      onDelete: () => {
        handleSubmitDelete()
      },
      onSaveRequest: () => {
        onSaveSample()
      }
    }))

    const handleUserKeyPress = useCallback(event => {
      const { key, keyCode } = event
      if (
        document.activeElement &&
        document.activeElement.tagName !== "INPUT" &&
        document.activeElement.tagName !== "TEXTAREA"
      ) {
        if (keyCode == 84) {
          setTab(1)
        } else if (keyCode == 83) {
          setTab(2)
        }
      }
    }, [])

    useEffect(() => {
      window.addEventListener("keydown", handleUserKeyPress)
      return () => {
        window.removeEventListener("keydown", handleUserKeyPress)
      }
    }, [handleUserKeyPress])

    useEffect(() => {
      CountTests(testRequestTests)
      if (testRequestSamples.length > 0) {
        let arrColor = []
        testRequestSamples.forEach(element => {
          if (arrColor.findIndex(x => x == element.sampleColor) < 0)
            arrColor.push(element.sampleColor)
        })
        setColorSample(arrColor)
      } else {
        setColorSample([])
      }
    }, [testRequestTests, testRequestSamples])

    useEffect(() => {
      if (
        data.requestid &&
        data.requestid != "" &&
        patientVisit.sid != undefined
      ) {
        let sid = testRequestSamples || []
        let sidText =
          sid.length > 0 ? ` (${sid.map(x => x.sid).join(", ")})` : ""
        setData({
          ...data,
          requestLabel: patientVisit.sid + sidText,
        })
      }
    }, [testRequestTests])

    useEffect(() => {
      if (
        data.requestid &&
        data.requestid != "" &&
        patientVisit.sid != undefined
      ) {
        let sid = testRequestSamples || []
        let sidText =
          sid.length > 0 ? ` (${sid.map(x => x.sid).join(", ")})` : ""
        setData({
          // ...data,
          ...patientVisit,
          requestLabel: patientVisit.sid + sidText,
        })
      }
    }, [patientVisit])

    const CountTests = tests => {
      if (tests.length > 0) {
        let count = 0
        tests.forEach(element => {
          if (element.type == TestRequest_Test_Type.TEST) {
            count++
          }
          if (element.children && element.children.length > 0) {
            element.children.forEach(parent => {
              if (parent.type == TestRequest_Test_Type.TEST) {
                count++
              }
              if (parent.children && parent.children.length > 0) {
                parent.children.forEach(child => {
                  if (child.type == TestRequest_Test_Type.TEST) {
                    count++
                  }
                })
              }
            })
          }
        })
        setCountTest(count)
      } else {
        setCountTest(0)
      }
    }

    const handleSaveRequestSampleSubmit = (id, samplesNew) => {
      setShowSpin(true)
      let values = {}
      values.id = id
      let samples = []
      let newData = samplesNew ? samplesNew : testRequestSamples
      newData.forEach(item => {
        samples.push({
          sampleType: item.sampleType,
          sampleColor: item.sampleColor || "",
          numberOfLabels: item.numberOfLabels ? `${item.numberOfLabels}` : 0,
          collectionTime: (item.isClear === false || item.isCollected === false) ? null : (item.collectionTime ? `${item.collectionTime}` : null),
          quality: item.quality ? `${item.quality}` : "",
          collectorUserId: (item.isClear === false || item.isCollected === false) ? null : (item.collectorUserId ? `${item.collectorUserId}` : null),
          receivedTime: (item.isClear === false || item.isReceived === false) ? null : (item.receivedTime ? `${item.receivedTime}` : null),
          receiverUserId: (item.isClear === false || item.isReceived === false) ? null : (item.receiverUserId ? `${item.receiverUserId}` : null),
          sID: item.sid ? `${item.sid}` : 0,
          subSID: item.subSID ? item.subSID : null
        })
      })
      if (testRequestSamples.length > 0) {
        values.isCollected = testRequestSamples[0].isCollected || false
        values.isReceived = testRequestSamples[0].isReceived || false
      }
      values.samples = samples
      onUpdateRequestSample({
        request: values,
        showMSG: false,
        callback: (val) => {
          if (val != false) {
            showToast(
              `${t("message:UpdatedMessage", {
                field: `${t("testRequestPage:Sample Info")}`,
              })}`
            )
            fetchGetTestrequestTestsDetail(values.id, () => {
              fetchGetTestrequestSamplesDetail(values.id, () => {
                // let count = testRequestSamplesGlobal.filter(x => x.state == RESULT_STATE_Text.Submitted
                //   || x.state == RESULT_STATE_Text.RDS || x.state == RESULT_STATE_Text.OnHold)
                // if (count == 0) {//không còn dịch vụ nào
                //   ClearData()
                //   onRefreshInfo(null, { id: 0 })
                // }
              })
            })
            onRefresh()
            onRefreshInfo(null, patientVisit)
            if (changeTest == true) {
              setChangeTest(false)
              handleSaveRequestTestSubmit(patientVisit.id)
            }
          }
        },
      })
    }

    const handleSaveRequestTestSubmit = (id, result = {}) => {
      setShowSpin(true)
      let values = {}
      values.id = id
      let tests = []
      let profiles = []
      let newData = result.tests ? result.tests : testRequestTests
      newData.forEach(item => {
        //push test đơn
        if (item.type === TestRequest_Test_Type.TEST) {
          tests.push({
            testCode: item.code,
            testCategory: item.category,
            sampleType: item.sampleType,
            profileCode: item.parentCode,
            sID: item?.sid || 0,
            subID: item?.subID || '',
            subSID: item.subSID,
            state: item.state,
            reason: item.reason
          })
        } else if (item.type === TestRequest_Test_Type.PROFILE_GROUP) {
          //push profile group vào list
          profiles.push({
            profileCode: item.code,
            profileCategory: item.category,
          })

          if (item.children) {
            //push tất cả child trực tiếp là test vào list
            item.children
              .filter(x => x.type === TestRequest_Test_Type.TEST)
              .map(test => {
                tests.push({
                  testCode: test.code,
                  testCategory: test.category,
                  sampleType: test.sampleType,
                  profileCode: test.parentCode,
                  sID: test?.sid || 0,
                  subID: test?.subID || '',
                  subSID: test.subSID,
                  state: test.state,
                  reason: test.reason
                })
              })

            //push tất cả child trực tiếp là profile vào list
            item.children
              .filter(x => x.type === TestRequest_Test_Type.PROFILE)
              .map(profile => {
                profiles.push({
                  profileCode: profile.code,
                  profileCategory: profile.category,
                })
                if (profile.children) {
                  //push tất cả test cháu vào list
                  profile.children.map(test => {
                    tests.push({
                      testCode: test.code,
                      testCategory: test.category,
                      sampleType: test.sampleType,
                      profileCode: test.parentCode,
                      sID: test?.sid || 0,
                      subID: test?.subID || '',
                      subSID: test.subSID,
                      state: test.state,
                      reason: test.reason
                    })
                  })
                }
              })
          }
        } else if (item.type === TestRequest_Test_Type.PROFILE) {
          //push profile bên ngoài
          profiles.push({
            profileCode: item.code,
            profileCategory: item.category,
          })
          if (item.children) {
            item.children.map(test => {
              tests.push({
                testCode: test.code,
                testCategory: test.category,
                sampleType: test.sampleType,
                profileCode: test.parentCode,
                sID: test?.sid || 0,
                subID: test?.subID || '',
                subSID: test.subSID,
                state: test.state,
                reason: test.reason
              })
            })
          }
          //push profile bên trong
        }
      })
      values.tests = tests
      values.profiles = profiles

      onUpdateRequestTests({
        request: values,
        showMSG: false,
        callback: (val) => {
          if (val != false) {
            fetchGetTestrequestTestsDetail(values.id, () => {
              fetchGetTestrequestSamplesDetail(values.id, () => {
                // let count = testRequestSamplesGlobal.filter(x => x.state == RESULT_STATE_Text.Submitted
                //   || x.state == RESULT_STATE_Text.RDS || x.state == RESULT_STATE_Text.OnHold)
                // if (count == 0) {//không còn dịch vụ nào
                //   ClearData()
                //   onRefreshInfo(null, { id: 0 })
                // }
              })
            })
            showToast(
              `${t("message:UpdatedMessage", {
                field: `${t("testRequestPage:Profile/Test")}`,
              })}`
            )
            onRefresh()
            const isExsitTest = newData.find(item => item.state != 2)
            if (!isExsitTest) {
              onClearDetailInfoPatientVisit()
            }
          }
        },
      })
    }

    const handleSubmitDelete = () => {
      if (patientVisit.id != undefined && patientVisit.id != "") {
        onDeleteRequests({
          requests: [patientVisit],
          callback: () => {
            setData({ requestDate: new Date(), dob: new Date() })
            onEmptyTestRequestDetail()
          },
        })
      }
    }

    let patientVisitConfig = localStorage.getItem("patientVisitConfig")

    const [config, setConfig] = useState({})

    useEffect(() => {
      setConfig(patientVisitConfig ? JSON.parse(patientVisitConfig) : {})
    }, [localStorage.getItem("patientVisitConfig")])

    useEffect(() => {
      if (showSpin == true) {
        setTimeout(() => {
          setShowSpin(false)
        }, 500)
      }
    }, [showSpin])

    const onSaveSample = () => {
      if (changeTest) {
        handleSaveRequestTestSubmit(patientVisit.id, resultChange)
      } else if (changeSample) {
        handleSaveRequestSampleSubmit(patientVisit.id)
      } else {
        showToast(
          `${t("message:UpdatedMessage", {
            field: `${t("testRequestPage:Sample Info")}`,
          })}`
        )
      }
    }

    const ClearData = () => {
      onClearDataRequestDetailInPatientVisit()
      clearPatientDetail && clearPatientDetail()
    }

    let sampleCount = 0
    let testCount = 0
    let testList = testRequestTests?.filter(item => item.state != 2 && (item.children && item.children.length > 0 ? item.children[0].state != 2 : true))
    if (patientVisitTab == 2) {
      sampleCount = testRequestSamples?.filter(item => item.state != RESULT_STATE_Text.Canceled && (item.state < RESULT_STATE_Text.Collected || item.state == RESULT_STATE_Text.RDS))?.length || 0
      testCount = testList?.filter(item => item.sampleState == RESULT_STATE_Text.RDS
        || item.sampleState < RESULT_STATE_Text.Collected
        || item.state == RESULT_STATE_Text.RDS
        || item.sampleState == RESULT_STATE_Text.RDS
        || item?.children?.some(x => x.sampleState < RESULT_STATE_Text.Collected || x.sampleState == RESULT_STATE_Text.RDS || x.state == RESULT_STATE_Text.RDS))?.length || 0

    } else if (patientVisitTab == 3) {
      sampleCount = testRequestSamples?.filter(item => item.state == RESULT_STATE_Text.Collected)?.length || 0
      testCount = testList?.filter(item => ((item.sampleState != RESULT_STATE_Text.RDS
        && item.sampleState == RESULT_STATE_Text.Collected)
        || item?.children?.some(x => x.sampleState == RESULT_STATE_Text.Collected)))?.length || 0

    }

    return (
      <div>
        {isSpin &&
          <div className="h-50 w-100" style={{
            position: 'absolute', zIndex: 1,
            background: 'rgba(256,256,256,0.5)'
          }}>
            <div className="w-100 h-100">
              <div className="text-center" style={{ marginTop: '150px' }}>
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          </div>
        }
        <CustomNav
          onToggle={e => {
            setCurrentTab(e)
          }}
          defaultTab={currentTab}
          tabs
          className="nav-tabs-custom"
          tabContents={customActiveTab => (
            <TabContent activeTab={customActiveTab} className="p-1 text-muted">
              <TabPane tabId="1">
                <TestTab
                  onChange={result => {
                    setChangeTest(true)
                    onRequestChange(true, result, 'test')
                    resultChange = result
                    // if (
                    //   (result.id && result.id != "") ||
                    //   (data.id && data.id != "")
                    // ) {
                    //   if (config.autoSave == true)
                    //     handleSaveRequestTestSubmit(
                    //       patientVisit.id || result.id || data.id,
                    //       result
                    //     )
                    // }
                  }}
                  patientVisitTab={patientVisitTab}
                  isCollected={isCollected}
                  testRequestTests={testRequestTests}
                  testRequest={patientDetail}
                  testRequestSamples={testRequestSamples}
                />
              </TabPane>
              <TabPane tabId="2">
                <SampleTab
                  config={config}
                  patientVisitTab={patientVisitTab}
                  onChange={(isChange = true, samples) => {
                    setChangeSample(true)
                    onRequestChange(isChange, samples, 'sample')
                    // let idSubmit = patientVisit?.id
                    // if (
                    //   patientVisit.samples &&
                    //   patientVisit.samples.length > 0
                    // ) {
                    //   const tmp = patientVisit.samples.find(
                    //     x => x.requestId && x.requestId != ""
                    //   )
                    //   if (tmp) idSubmit = tmp.requestId
                    // }
                    // if (idSubmit && idSubmit != "") {
                    //   if (config.autoSave == true) {
                    //     handleSaveRequestSampleSubmit(idSubmit)
                    //   }
                    // }
                  }}
                  onChangeCollect={val => {
                    setIsCollected(val)
                  }}
                  testRequest={patientDetail}
                  onSaveSample={onSaveSample}
                />
              </TabPane>
            </TabContent>
          )}
        >
          <CustomNavLink key={1} tabId={"1"}>
            <div className="d-flex gap-2">
              <span>{t("Request Services")}</span>
              <span
                className="badge-gender"
                style={{
                  width: "29px",
                  backgroundColor: "#39a8fd",
                  color: "white",
                  textAlign: "-webkit-center",
                  fontSize: "14px",
                  alignItems: "center",
                }}
              >
                {/* {testRequestTests?.filter(item => item.state != 2 && (item.children && item.children.length > 0 ? item.children[0].state != 2 : true))?.length || 0} */}
                {testCount}
              </span>
            </div>
          </CustomNavLink>
          <CustomNavLink key={2} tabId={"2"}>
            <div className="d-flex gap-2">
              <span>{t("Sample Info")}</span>
              <span
                className="badge-gender"
                style={{
                  width: "29px",
                  backgroundColor: "rgb(202 232 255)",
                  color: "blue",
                  textAlign: "-webkit-center",
                  fontSize: "14px",
                  borderWidth: "1.5px",
                  borderStyle: "solid",
                  borderColor: "#6fb6ff",
                  alignItems: "center",
                }}
              >
                {/* {testRequestSamples?.filter(item => item.state != 2)?.length || 0} */}
                {sampleCount}
              </span>
            </div>
          </CustomNavLink>
          {generalSetting.find(x => x.fieldName == "AreaCollected")?.fieldValue == "True" &&
            <div
              className="d-flex gap-1"
              style={{ alignItems: "center", fontSize: "14px", marginLeft: 'auto', fontWeight: "bold" }}
            >
              <span>{t("Area")}: </span>
              <span style={{ color: "#556ee6" }}>{area?.name ? area?.name : "--"} </span>/
              <span>{t("common:Collector")}: </span>
              <span style={{ color: "#556ee6" }}>{collector?.collectorName ? collector?.collectorName : "--"}</span>
            </div>
          }
        </CustomNav>
      </div>
    )
  }
)
const mapStateToProps = ({ testRequest, generalSetting }) => ({
  testRequestTests: testRequest.testRequest.tests || [],
  testRequestSamples: testRequest.testRequest.samples || [],
  patientVisit: testRequest.patientVisit,
  testRequest: testRequest.testRequest || {},
  loadingRequestTestDetail: testRequest.loadingRequestTestDetail,
  generalSetting: generalSetting.generalSetting
})
const mapDispatchToProps = dispatch => ({
  onGetIndividualDetail: individualId =>
    dispatch(getIndividualDetail(individualId)),
  onCreateNewRequest: ({ request, history, callback, reset }) =>
    dispatch(createNewRequest({ request, history, callback, reset })),
  onUpdateRequestGeneral: ({ request, callback, showMSG }) =>
    dispatch(updateRequestGeneral({ request, callback, showMSG })),
  onUpdateRequestSample: ({ request, callback, showMSG }) =>
    dispatch(updateRequestSample({ request, callback, showMSG })),
  onUpdateRequestTests: ({ request, callback, showMSG }) =>
    dispatch(updateRequestTests({ request, callback, showMSG })),
  fetchGetTestrequestGeneralDetail: requestId =>
    dispatch(getTestrequestGeneralDetail(requestId)),
  fetchGetTestrequestTestsDetail: (requestId, callback) =>
    dispatch(getTestrequestTestsDetail(requestId, callback)),
  fetchGetTestrequestSamplesDetail: (requestId, callback) =>
    dispatch(getTestrequestSamplesDetail(requestId, callback)),
  onDeleteRequests: ({ requests, callback }) =>
    dispatch(deleteRequests({ requests, callback })),
  onEmptyTestRequestDetail: payload =>
    dispatch(emptyTestRequestDetail(payload)),
  onGetTestRequestTestsDetailSuccess: payload =>
    dispatch(getTestrequestTestsDetailSuccess(payload)),
  onGetTestRequestSamplesDetailSuccess: payload =>
    dispatch(getTestrequestSamplesDetailSuccess(payload)),
  onClearDetailInfoPatientVisit: () =>
    dispatch(clearDetailInfoPatientVisit()),
  onGetGeneralSetting: payload => dispatch(getGeneralSetting(payload)),
  onClearDataRequestDetailInPatientVisit: payload => dispatch(clearDataRequestDetailInPatientVisit(payload))
})

ServiceFrame.displayName = "ServiceFrame"
export default withTranslation(["partyPage", "message", "common"], { withRef: true })(connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ServiceFrame))
