import { getUrlParamByKey, getUrlParams, insertUrlParam, trimObjectValues } from "helpers/utilities"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import {
    Check,
    ConfirmModal2,
    CustomButton,
    TitleAndTable,
    WarningModal
} from "components/Common"
import {
    onDelete,
    onDeleteToggle,
    selectCheckboxHandler,
} from "helpers/utilities"

import {
    addNewCausalActionQC,
    deleteCausalActionQCs,
    getCausalActionQCDetail,
    getFullCausalActionQC,
    resetSearchQuery,
    updateCausalActionQC
} from "store/laboratoryIQC/CausalActionQC/actions"

import { ModuleIds, permissionType } from "constant"
import { withTranslation } from "react-i18next"
import CausalActionQCTable from "./CausalActionQCTable"
import CausalActionQCModal from "./Modal/CausalActionQCModal"

const RESOURCE = ModuleIds.CausalActioniQC

const CausalActionQC = ({
    causalActionQCs,
    paging,
    onGetCausalActionQC,
    onAddNewCausalActionQC,
    onUpdateCausalActionQC,
    onDeleteCausalActionQC,
    onResetCausalActionQCSearchQuery,
    causalActionQC,
    loadingCausalActionQC,
    updatedCausalActionQCTime,
    t,
    userPermissions,
    onGetCausalActionQCDetail,
    causalActionType,
    subtitle
}) => {

    const [confirmModal, setConfirmModal] = useState(false)
    const [modal, setModal] = useState(false)
    const [isEdit, setIsEdit] = useState(true)
    const [isClone, setIsClone] = useState(false)
    const [row, setRow] = useState(false)
    const [rowDelete, setRowDelete] = useState({})
    const [rows, setRows] = useState([])
    const [warningModal, setWarningModal] = useState(false)

    const formEl = useRef(null)

    const popStateChange = (event) => {
        let params = getUrlParams()
        let paramPage = getUrlParamByKey("page")
        if (paramPage) {
            onGetCausalActionQCList(params)
        }
        else {
            fetchCausalActionQC()
        }
    }

    const afterUpdate = () => {
        toggle()
        popStateChange()
    }

    const toggle = () => {
        setModal(prev => !prev)
    }

    const addCausalActionQCClicks = () => {
        setIsEdit(false)
        setIsClone(false)
        toggle()
    }

    const handleValidCausalActionQCSubmit = (e, values) => {
        values.category = causalActionType
        values.rules = values?.rules?.join(',')
        if (isEdit) {
            onUpdateCausalActionQC({ causalActionQC: trimObjectValues(values), callback: afterUpdate })
        } else {
            const newCausalActionQC = {
                ...values,
            }
            onAddNewCausalActionQC({
                causalActionQC: trimObjectValues(newCausalActionQC), callback: afterUpdate
            })
        }
    }

    const onCloneHandler = (param) => {
        const id = param?.id || row?.id
        if (id) {
            setIsEdit(false)
            setIsClone(true)
            toggle()
            onGetCausalActionQCDetail(id)
        } else {
            setWarningModal(true)
        }

    }

    const onEditHandler = (e, causalActionQCId, param) => {
        const id = causalActionQCId || row?.id;
        if (id) {
            setIsEdit(true)
            toggle()
            onGetCausalActionQCDetail(id)
        } else {
            setWarningModal(true)
        }
    }

    const resetState = () => {
        setRow({})
        setRows([])
    }

    const onDeleteToggleHandler = (e, causalActionQC) => {
        onDeleteToggle({
            rows,
            row: causalActionQC || rowDelete,
            setConfirmModal,
            setWarningModal,
            setRowDelete
        })
        if (confirmModal) {
            setRowDelete({})
        }
    }

    const onDeleteMultipleRows = rowsState => {
        onDeleteCausalActionQC({ causalActionQCs: rowsState, callback: resetState })
    }

    const onDeleteSingleRow = rowState => {
        onDeleteCausalActionQC({
            causalActionQCs: rowState,
            callback: () => {
                setRows(prev.filter(x => x.id != rowDelete.id))
            }
        })
        setRowDelete({})
        setRow({})
    }

    const onDeleteCausalActionQCHandler = () => {
        onDelete({
            rowDelete,
            rows,
            onDeleteSingleRow,
            onDeleteMultipleRows,
        })
        setConfirmModal(false)
    }

    const onSelectCheckbox = (row, isSelected) => {
        const { rowsState, currentRow } = selectCheckboxHandler(
            rows,
            row,
            isSelected
        )
        setRows(rowsState)
        setRow(currentRow)
    }

    const onSelectAllCheckbox = rows => {
        setRows(rows)
        if (rows.length < 1) setRow({})
        else setRow(rows[rows.length - 1])
    }

    const onGetCausalActionQCList = (payload) => {
        // insertUrlParam(payload)
        payload.category = causalActionType
        onGetCausalActionQC(payload)
    }

    const fetchCausalActionQC = () => {
        onGetCausalActionQCList({ page: 1 })
    }

    const onRefreshHandler = () => {
        resetState()
        popStateChange()
    }

    const onSearch = searchText => {
        onGetCausalActionQCList({ page: 1, search: searchText })
    }

    const onSizePerPageChange = size => {
        onGetCausalActionQCList({ page: 1, size })
    }

    const onPageChange = page => {
        onGetCausalActionQCList({ page })
    }

    const onSubmitFilter = values => {
        onGetCausalActionQCList({ page: 1, ...values })
    }

    const onSortHandler = (field, order) => {
        const sortString = `${field}:${order}`
        onGetCausalActionQCList({ page: 1, sort: sortString })
    }

    useEffect(() => {
        onResetCausalActionQCSearchQuery()
    }, [])

    useEffect(() => {
        let params = getUrlParams()
        let paramPage = getUrlParamByKey("page")
        if (paramPage) {
            onGetCausalActionQCList(params)
        } else {
            fetchCausalActionQC()
        }
    }, [])

    return (
        <React.Fragment>
            <TitleAndTable
                table={() => (
                    <CausalActionQCTable
                        userPermissions={userPermissions}
                        causalActionQCs={causalActionQCs}
                        onSelect={onSelectCheckbox}
                        onSelectAll={onSelectAllCheckbox}
                        onSearch={onSearch}
                        onSort={onSortHandler}
                        onRefresh={onRefreshHandler}
                        onPageChange={onPageChange}
                        paging={paging}
                        onSizePerPageChange={onSizePerPageChange}
                        onSubmitFilter={onSubmitFilter}
                        onEdit={onEditHandler}
                        onDelete={onDeleteToggleHandler}
                        onClone={onCloneHandler}
                        loading={loadingCausalActionQC}
                        updatedTime={updatedCausalActionQCTime}
                        onUpdateCausalActionQC={onUpdateCausalActionQC}
                    />
                )}
                resource={RESOURCE}
                buttons={() => (
                    <>
                        <Check permission={permissionType.C} resource={RESOURCE}>
                            <CustomButton color="primary" onClick={addCausalActionQCClicks} outline>
                                {t("common:Add")}
                            </CustomButton>
                        </Check>
                        <Check permission={permissionType.D} resource={RESOURCE}>
                            <CustomButton
                                color="primary"
                                onClick={e => {
                                    onDeleteToggleHandler(e, rows.id)
                                }}
                                outline
                            >
                                {t("common:Delete")}
                            </CustomButton>
                        </Check>
                    </>
                )}
                onEdit={onEditHandler}
                onDelete={onDeleteToggleHandler}
                isDisableClone
                isHideEdit
                isDropDown
                title={t("CausalActionQC")}
                subtitle={subtitle}
            />
            <WarningModal
                modal={warningModal}
                onToggle={() => setWarningModal(prev => !prev)}
                message={t("message:SelectRowWarning")}
            />
            <ConfirmModal2
                modal={confirmModal}
                title={`${t("common:Delete")} ${t("CausalActionQC")}`}
                message={t("message:DeleteConfirm")}
                onToggle={onDeleteToggleHandler}
                onConfirm={onDeleteCausalActionQCHandler}
                isEdit
            />
            <CausalActionQCModal
                message={subtitle}
                formEl={formEl}
                modal={modal}
                isEdit={isEdit}
                onValidSubmit={handleValidCausalActionQCSubmit}
                toggle={toggle}
                data={!isEdit ? (isClone ? causalActionQC : {}) : causalActionQC}
            />
        </React.Fragment>
    )
}

CausalActionQC.propTypes = {
    causalActionQCs: PropTypes.array,
    paging: PropTypes.object,
    onGetCausalActionQC: PropTypes.func,
    onAddNewCausalActionQC: PropTypes.func,
    onUpdateCausalActionQC: PropTypes.func,
    onDeleteCausalActionQC: PropTypes.func,
    onResetCausalActionQCSearchQuery: PropTypes.func,
    onGetCausalActionQCDetail: PropTypes.func,
    causalActionQC: PropTypes.object,
    loadingCausalActionQC: PropTypes.bool,
    updatedCausalActionQCTime: PropTypes.any,
    t: PropTypes.any,
}

const mapStateToProps = ({ causalActionQC, Authorization }) => ({
    causalActionQCs: causalActionQC.causalActionQCs,
    causalActionQC: causalActionQC.causalActionQC,
    paging: causalActionQC.paging,
    loadingCausalActionQC: causalActionQC.loadingCausalActionQC,
    updatedCausalActionQCTime: causalActionQC.updatedTime,
    userPermissions: Authorization.permissions || [],
})

const mapDispatchToProps = dispatch => ({
    onGetCausalActionQC: payload => dispatch(getFullCausalActionQC(payload)),
    onAddNewCausalActionQC: ({ causalActionQC, callback }) => dispatch(addNewCausalActionQC(causalActionQC, callback)),
    onUpdateCausalActionQC: ({ causalActionQC, callback }) => dispatch(updateCausalActionQC(causalActionQC, callback)),
    onDeleteCausalActionQC: causalActionQC => dispatch(deleteCausalActionQCs(causalActionQC)),
    onGetCausalActionQCDetail: causalActionQCId => dispatch(getCausalActionQCDetail(causalActionQCId)),
    onResetCausalActionQCSearchQuery: () => dispatch(resetSearchQuery())
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation(["causalActionQCPage", "message"])(CausalActionQC)))