import { useEffect, useMemo, useState } from "react"
import BarcodeSampleFrame from "./BarcodeAndSampleFrame/BarcodeSampleFrame"
import PatientListFrame from "./PatientListFrame"
const RightFrame = ({
  onRowClick,
  patientDetail,
  toggleBarcodeSetting,
  onChangeGroup,
  requestDate,
  onChangeRow,
  onMassCreateReport
}) => {
  const [rows, setRows] = useState([])
  const [resetRow, setResetRow] = useState([])

  const handleClearSelected = () => {
    setResetRow([...[]])
  }

  useEffect(() => {
    onChangeRow && onChangeRow(rows)
  }, [rows])

  return (
    <div style={{ overflowX: 'hidden', height:'100%' }}>
      <PatientListFrame
        onMassCreateReport={onMassCreateReport}
        requestDate={requestDate}
        onRowClick={onRowClick}
        onChangeGroup={onChangeGroup}
        onChangeRow={values => {
          setRows(values)
        }}
        resetRow={resetRow}
      />
      <BarcodeSampleFrame selectedRows={rows} patientDetail={patientDetail} toggleBarcodeSetting={toggleBarcodeSetting} handleClearSelected={handleClearSelected} />
    </div>
  )
}

export default RightFrame
