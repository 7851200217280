import { AvForm } from "availity-reactstrap-validation"
import { CustomButton, CustomSelect } from "components/Common"
import CustomBootstrapTableAsync from "components/Common/CustomTable/CustomBootstrapTableAsync"
import { ModuleIds, parameterCode } from "constant"
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react"
import Editable from "react-bootstrap-editable"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { getPositionScroll, updateNormalRangeSub } from "store/actions"

const RESOURCE = ModuleIds.ChartiQC

let itemActiveGlobal = {}
let pageGlobal = 0
const ChartDetailTable = forwardRef(
    ({
        data,
        onSort,
        onSelect,
        rows,
        onSelectAll,
        // onGetTestsSuccess,
        onSearch,
        onRefresh,
        paging,
        itemActive,
        updatedTime,
        loadingTests,
        onGetPositionScroll,
        t,
    }, ref) => {
        const [model, setModel] = useState({
            search: "",
        })
        const size = 15;
        const [dataSource, setDataSource] = useState([])
        const [page, setPage] = useState(0)

        useImperativeHandle(ref, () => ({
            onGetData: () => {
                return dataSource || []
            },
        }))

        useEffect(() => {
            setDataSource(data)
        }, [data])

        const checkAndTab = (e, idHTML, key) => {
            if (e.key === 'Enter' || e.key === "Tab") {
                const fields =
                    Array.from(document.getElementsByClassName(key)) ||
                    []
                const position = fields.findIndex(x => x.id == `${idHTML}`)
                if (position >= 0) {
                    setTimeout(() => {
                        let el = fields[position + 1]
                        if (el) {
                            const tmpClick = $(`#${el.id} form a`)
                            if (tmpClick && tmpClick.length > 0)
                                tmpClick[0].click()
                        }
                    }, 0);

                }
            }
        }

        const arrNum = ['mean', 'sd', 'deCOdd', 'meanPI', 'sdpi', 'meanPeer', 'tea', '_6Sigma']
        const renderEditField = (item, key) => {
            const idHTML = `qcLotTestBlurItemFocus${item.id}-${key}`
            return <React.Fragment>
                <div
                    className={"qcLotTestBlurItemFocus"}
                    id={idHTML}
                    onKeyDown={e => {
                        checkAndTab(e, idHTML, 'qcLotTestBlurItemFocus')
                    }}
                    style={{ width: '100%', margin: "0 auto" }}
                    onClick={e => {
                        e.stopPropagation()
                    }}
                    onBlur={() => {
                        $(`#${item.id}-${key}`).click()
                    }}
                >
                    <Editable
                        alwaysEditing={false}
                        disabled={false}
                        editText={item[key] != null ? `${item[key]}` : "---"}
                        initialValue={item[key] != null ? `${item[key]}` : ""}
                        id={item[key]}
                        isValueClickable={false}
                        mode="inline"
                        placement="top"
                        showText={false}
                        type="textfield"
                        renderConfirmElement={confirmElement(`${item.id}-${key}`)}
                        renderCancelElement={cancelElement}
                        onSubmit={(value) => {
                            if (arrNum.includes(key)) { //xử lý số
                                if (Number(value) || value == '' || value == '0') {
                                    if (item[key] != value && !(item[key] == null && value == ''))
                                        onSubmitRule(item, value, [key])
                                }
                            }
                            else {
                                if (item[key] != value && !(item[key] == null && value == ''))
                                    onSubmitRule(item, null, [key])
                            }
                        }}
                        validate={(value) => {
                            if (arrNum.includes(key))
                                if (!Number(value) && value != '' && value != '0') {
                                    return t("Numbers only");
                                }
                        }}
                    />
                </div>
            </React.Fragment>
        }

        const onDelete = (id) => {
            let tmp = dataSource;
            tmp = tmp.filter(x => x.id !== id)
            setDataSource([...tmp])
        }

        
        let columns = [
            {
                dataField: "",
                text: "#",
                classes: 'stt-col-sticky',
                style: { borderLeft: '1px solid #F0F2F7', minWidth: 60, maxWidth: 60, with: 60 },
                headerStyle: { borderLeft: '1px solid #F0F2F7', minWidth: 60, maxWidth: 60, with: 60 },
                headerClasses: 'stt-col-header-sticky',
                formatter: (cellContent, item, index) => {
                    return index + 1
                }
            },
            {
                dataField: "testCode",
                text: t("Test Code"),
                classes: 'testCode-col-sticky',
                headerClasses: 'testCode-col-header-sticky',
                style: { minWidth: 100, maxWidth: 100, with: 100 },
                headerStyle: { minWidth: 100, maxWidth: 100, with: 100 },
                formatter: (cellContent, item, index) => {
                    if (item.id == -1)
                        return <span id={`watch_end_of_document`}>End of document</span>
                    else
                        return <span>{cellContent}</span>
                }
            },
            {
                dataField: "testName",
                text: t("Test Name"),
                classes: 'testName-col-sticky',
                headerClasses: 'testName-col-header-sticky',
                style: { minWidth: 250, maxWidth: 250, with: 250 },
                headerStyle: { minWidth: 250, maxWidth: 250, with: 250 },
            },
            {
                dataField: "unit",
                text: t("Unit"),
                style: { minWidth: 80, maxWidth: 80 },
            },
            {
                dataField: "levels",
                text: t("Levels"),
                style: { minWidth: 80, maxWidth: 80 },
            },
            {
                dataField: "mean",
                text: t("TB (Mean)"),
                headerStyle: { whiteSpace: 'nowrap' },
                style: { minWidth: 100, maxWidth: 100 },
                // events: {
                //     onClick: () => { },
                // },
                formatter: (cellContent, item, index) => {
                    return renderEditField(item, 'mean')
                },
            },
            {
                dataField: "sd",
                text: t("Độ lệch chuẩn (SD)"),
                headerStyle: { whiteSpace: 'nowrap' },
                style: { minWidth: 100, maxWidth: 100 },
                // events: {
                //     onClick: () => { },
                // },
                formatter: (cellContent, item, index) => {
                    return renderEditField(item, 'sd')
                },
            },
            {
                dataField: "deCOdd",
                text: t("Odd"),
                headerStyle: { whiteSpace: 'nowrap' },
                style: { minWidth: 80, maxWidth: 80 },
                // events: {
                //     onClick: () => { },
                // },
                formatter: (cellContent, item, index) => {
                    return renderEditField(item, 'deCOdd')
                },
            },
            {
                dataField: "meanPI",
                text: t("Mean NSX"),
                headerStyle: { whiteSpace: 'nowrap' },
                style: { minWidth: 80, maxWidth: 80 },
                // events: {
                //     onClick: () => { },
                // },
                formatter: (cellContent, item, index) => {
                    return renderEditField(item, 'meanPI')
                },
            },
            {
                dataField: "sdpi",
                text: t("SD NSX"),
                headerStyle: { whiteSpace: 'nowrap' },
                style: { minWidth: 80, maxWidth: 80 },
                // events: {
                //     onClick: () => { },
                // },
                formatter: (cellContent, item, index) => {
                    return renderEditField(item, 'sdpi')
                },
            },
            {
                dataField: "meanPeer",
                text: t("Mean Peer"),
                headerStyle: { whiteSpace: 'nowrap' },
                style: { minWidth: 80, maxWidth: 80 },
                // events: {
                //     onClick: () => { },
                // },
                formatter: (cellContent, item, index) => {
                    return renderEditField(item, 'meanPeer')
                },
            },
            {
                dataField: "tea",
                text: t("TEA (%)"),
                headerStyle: { whiteSpace: 'nowrap' },
                style: { minWidth: 80, maxWidth: 80 },
                // events: {
                //     onClick: () => { },
                // },
                formatter: (cellContent, item, index) => {
                    return renderEditField(item, 'tea')
                },
            },
            {
                dataField: "_6Sigma",
                text: t("6 Sigma"),
                headerStyle: { whiteSpace: 'nowrap' },
                style: { minWidth: 80, maxWidth: 80 },
                // events: {
                //     onClick: () => { },
                // },
                formatter: (cellContent, item, index) => {
                    return renderEditField(item, '_6Sigma')
                },
            },
            {
                dataField: "lowl",
                text: t("Lowl"),
                headerStyle: { whiteSpace: 'nowrap' },
                style: { minWidth: 80, maxWidth: 80 },
                // events: {
                //     onClick: () => { },
                // },
                formatter: (cellContent, item, index) => {
                    return renderEditField(item, 'lowl')
                },
            },
            {
                dataField: "hightl",
                text: t("Hightl"),
                headerStyle: { whiteSpace: 'nowrap' },
                style: { minWidth: 80, maxWidth: 80 },
                // events: {
                //     onClick: () => { },
                // },
                formatter: (cellContent, item, index) => {
                    return renderEditField(item, 'hightl')
                },
            },
            {
                dataField: "temperature",
                text: t("Temperature"),
                headerStyle: { whiteSpace: 'nowrap' },
                style: { minWidth: 80, maxWidth: 80 },
                // events: {
                //     onClick: () => { },
                // },
                formatter: (cellContent, item, index) => {
                    return renderEditField(item, 'temperature')
                },
            },
            {
                dataField: "eval",
                text: t("Eval"),
                headerStyle: { whiteSpace: 'nowrap' },
                style: { minWidth: 150, maxWidth: 150 },
                // events: {
                //     onClick: () => { },
                // },
                formatter: (cellContent, item, index) => {
                    return renderEditField(item, 'eval')
                },
            },
            {
                dataField: "isPosNeg",
                text: t("IsPosNeg"),
                headerStyle: { whiteSpace: 'nowrap', textAlign: 'center' },
                style: { minWidth: 80, maxWidth: 80, textAlign: 'center' },
                formatter: (cellContent, item, index) => {
                    return <span>{item.isPosNeg == true && <i className="fa fa-check-circle text-success"></i>}</span>
                },
            },
            {
                dataField: "action",
                text: "",
                // isDummyField: true,
                style: { width: "50px", margin: 0, paddingLeft: "0px", paddingRight: "0px", maxWidth: 50, minWidth: 50, textAlign: 'center' },
                headerStyle: { width: "50px", margin: 0, paddingLeft: "0px", paddingRight: "0px", maxWidth: 50, minWidth: 50, textAlign: 'center' },
                formatter: (cellContent, item, index) => (
                    <span onClick={() => {
                        onDelete(item.id)
                    }}>
                        <i className="mdi mdi-trash-can font-size-16 text-danger me-1" />
                    </span>
                ),
            },
        ]

        const confirmElement = id => (
            <button
                id={id}
                type="submit"
                className="btn btn-outline-primary editable-submit btn-sm ms-2 me-1 d-none"
            >
                <i className="mdi mdi-check"></i>
            </button>
        )

        const cancelElement = (
            <button
                type="button"
                className="btn btn-outline-primary editable-cancel btn-sm me-3 d-none"
            >
                <i className="mdi mdi-close"></i>
            </button>
        )

        const onSubmitRule = (item, value, name) => {
            item[name] = `${value}`
            const dataReplace = data.find(obj => obj.id === item.id)
            Object.assign(dataReplace, item)
            // onGetTestsSuccess(data)
            setDataSource([...dataSource])
        }


        useEffect(() => {
            itemActiveGlobal = itemActive
            //reset table
            let el = document.getElementById("resizeMeAsync")
            if (el) {
                el.scrollIntoView(true)
                setTimeout(() => {
                    setPage(0)
                    pageGlobal = 0
                }, 100);
            }
        }, [itemActive])

        const io = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (!entry.isIntersecting) {
                    return;
                }
                pageGlobal++;
                setPage(pageGlobal)
            })
        })

        let elEnd = document.getElementById(`watch_end_of_document`)
        useEffect(() => {
            if (elEnd) {
                io.observe(elEnd);
            }
        }, [elEnd])

        const getDataDisplay = () => {
            if (dataSource?.length > 0) {
                let res = [...dataSource].slice(0, size * (page + 1))
                res.push({ id: -1, testCode: '' })
                return res
            }
        }

        return (
            <div style={{ backgroundColor: 'white' }} className={`table-expand-custom ${dataSource.length === 0 ? '' : 'table-rule-management'}`}>
                {loadingTests &&
                    <div className="position-absolute" style={{ width: '98%', height: '93%', zIndex: 10, backgroundColor: 'rgba(255, 255, 255, 0.5)' }}>
                        <div className="w-100 h-100"><div className="text-center">
                            <div className="spinner-border text-primary" role="status" style={{ marginTop: 150 }}>
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                        </div>
                    </div>
                }
                <CustomBootstrapTableAsync
                    updatedTime={updatedTime}
                    columns={columns}
                    search
                    data={getDataDisplay()}
                    onSort={onSort}
                    keyField="id"
                    onSelect={onSelect}
                    onSelectAll={onSelectAll}
                    selectedRows={rows?.map(r => r.id)}
                    searchText={model.search}
                    onSearch={onSearch}
                    resource={RESOURCE}
                    onRefresh={onRefresh}
                    striped={true}
                    isScrollable={true}
                    showSelectRow={true}
                    isHover={false}
                    isEnableExport={false}
                    onScroll={(e) => {
                        onGetPositionScroll({
                            left: e.target.scrollLeft,
                            top: e.target.scrollTop
                        })
                    }}
                    // paging={paging}
                    onRowClick={(e, row, rowIndex) => {

                    }}
                    customButtonRight={() => (
                        <>
                            <CustomButton
                                color="secondary"
                                outline
                                onClick={() => { }}
                            >
                                <i style={{ fontSize: 16 }} className={`bx bx-cog`}></i>
                            </CustomButton>
                        </>
                    )}
                    customButtonTable={() =>
                        <AvForm>
                            <div style={{ width: '200px', marginLeft: '4px' }}>
                                <CustomSelect
                                    label={t("")}
                                    portal
                                    name="groupLot"
                                    value={''}
                                    code={parameterCode.TESTRESULT_STATE_KEY}
                                    onChange={(name, value, label, item) => {

                                    }}
                                />
                            </div>
                        </AvForm>
                    }
                />
            </div>
        )
    })

const mapStateToProps = ({ RuleManagement }) => ({
    loadingTests: RuleManagement.loadingTests
})

const mapDispatchToProps = dispatch => ({
    // onGetTestsSuccess: payload => dispatch(getFullTestSuccess(payload)),
    onGetPositionScroll: payload => dispatch(getPositionScroll(payload)),
    onUpdateNormalRangeSub: (payload, callback) => dispatch(updateNormalRangeSub(payload, callback)),
})

ChartDetailTable.displayName = 'ChartDetailTable';
export default withTranslation(["chartiQCPage", "common", "message"], { withRef: true })(connect(
    mapStateToProps,
    mapDispatchToProps
)(ChartDetailTable))
