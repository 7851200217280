import { AvForm } from "availity-reactstrap-validation";
import {
  Accordion,
  AccordionWrapper,
  CustomAvField,
  CustomButton,
  CustomDatePicker,
  CustomModal,
  CustomSelect,
  CustomSelectGroup,
} from "components/Common";
import { parameterCode } from "constant/utility";
import { getInvalidMessageI18n } from "helpers/utilities";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Col, ModalBody, ModalFooter, Row } from "reactstrap";

const ConfigLotQCModal = ({
  modal,
  toggle,
  isEdit,
  onValidSubmit,
  data,
  formEl,
  t,
}) => {
  const title =
    (!!isEdit ? t("common:Edit") : t("common:Add")) + " " + t("Lot")

  const [maxOpenDate, setMaxOpenDate] = useState(data.maxOpenDate || new Date());
  const [expireDate, setExpireDate] = useState(data.expireDate || new Date());
  const [instrumentIdsArray, setInstrumentIdsArray] = useState([]
  );

  useEffect(() => {
    if (data?.instrumentIds && instrumentIdsArray.length === 0) {
      setInstrumentIdsArray(data?.instrumentIds.split(',').map(id => parseInt(id, 10)));
    }
  }, [data?.instrumentIds, instrumentIdsArray.length]);

  const handleValidSubmit = (event, values) => {
    const updatedData = {
      ...values,
      maxOpenDate: maxOpenDate,
      expireDate: expireDate,
      instrumentIds: instrumentIdsArray.join(','),
    };
    onValidSubmit(event, updatedData);
  };

  if (isEdit && isEmpty(data)) return null;

  return (
    <CustomModal modal={modal} title={title} onToggle={toggle}>
      <ModalBody>
        <AvForm
          ref={formEl}
          id="testForm"
          onValidSubmit={handleValidSubmit}
          model={data}
        >
          <AccordionWrapper defaultTab="1">
            <Accordion tabId={"1"} title={t("LOT Information")}>
              <CustomAvField name="id" type="hidden" value={data?.id || ""} />
              <Row className="px-2">
                <Col className="col-4">
                  <div className="mb-3">
                    <CustomAvField
                      name="lotId"
                      type="text"
                      required
                      errorMessage={getInvalidMessageI18n(t, "Lot ID")}
                      validate={{
                        required: { value: true },
                        maxLength: { value: 100 },
                      }}
                      value={data?.lotId || ""}
                      disabled={isEdit}
                      label={t("Lot ID")}
                      detected={isEdit}
                    />
                  </div>
                </Col>

                <Col className="col-4">
                  <div className="mb-3">
                    <CustomAvField
                      name="lotName"
                      type="text"
                      required
                      disabled={isEdit}
                      errorMessage={getInvalidMessageI18n(t, "Lot Name")}
                      validate={{
                        required: { value: true },
                        maxLength: { value: 150 },
                      }}
                      value={data?.lotName || ""}
                      label={t("Lot Name")}
                      detected={isEdit}
                    />
                  </div>
                </Col>
                <Col className="col-4">
                  <div className="mb-3">
                    <CustomDatePicker
                      name="expireDate"
                      label={t("Expiration Date")}
                      placeholder={"DD/MM/YYYY"}
                      format={"DD/MM/YYYY"}
                      value={data?.expireDate || expireDate}
                      detected={isEdit}
                      required
                      onChangeHandler={(e) => {
                        setExpireDate(e.time[0]);
                      }}
                    />
                  </div>
                </Col>

                <Col className="col-4">
                  <div className="mb-3">
                    <CustomSelectGroup
                      required
                      errorMessage={getInvalidMessageI18n(t, "Test Category")}
                      name="testCategory"
                      value={data?.testCategory || ""}
                      code={parameterCode.TESTPROFILE_SUB_CATEGORY_CODE}
                      label={t("Test Category")}
                      detected={isEdit}
                    />
                  </div>
                </Col>

                <Col className="col-4">
                  <div className="mb-3">
                    <CustomAvField
                      name="manufactor"
                      type="text"
                      required
                      errorMessage={getInvalidMessageI18n(t, "Manufactor")}
                      validate={{
                        required: { value: true },
                        maxLength: { value: 100 },
                      }}
                      value={data?.manufactor || ""}
                      label={t("Manufactor")}
                      detected={isEdit}
                    />
                  </div>
                </Col>

                <Col className="col-4">
                  <div className="mb-3">
                    <CustomSelect
                      label={t("Lot Group")}
                      name="lotGroup"
                      portal
                      value={data?.lotGroup || ""}
                      code={parameterCode.LOT_GROUP_QC}
                    />
                  </div>
                </Col>
                <Col className="col-12">
                  <div className="mb-3">
                    <CustomSelect
                      label={t("Machines")}
                      name="instrumentId"
                      portal
                      isMulti
                      value={instrumentIdsArray}
                      code={parameterCode.INSTRUMENT}
                      onChange={(name, value, label, selectedOptions) => {
                        const selectedIds = selectedOptions.map(option => option.value);
                        setInstrumentIdsArray(selectedIds);
                      }}
                    />
                  </div>
                </Col>
                <Col className="col-4">
                  <div className="mb-3">
                    <CustomSelect
                      label={t("Levels")}
                      name="levels"
                      portal
                      value={data?.levels || 0}
                      code={parameterCode.LEVEL_QC}
                    />
                  </div>
                </Col>
                <Col className="col-4">
                  <div className="mb-3">
                    <CustomAvField
                      name="noOfPointPI"
                      type="number"
                      errorMessage={getInvalidMessageI18n(t, "Number of PI Points")}
                      value={data?.noOfPointPI || ""}
                      label={t("Number of PI Points")}
                      detected={isEdit}
                    />
                  </div>
                </Col>
                <Col className="col-4">
                  <div className="mb-3">
                    <CustomAvField
                      name="noOfRun"
                      type="number"
                      errorMessage={getInvalidMessageI18n(t, "Number of Runs")}
                      value={data?.noOfRun || ""}
                      label={t("Number of Runs")}
                      detected={isEdit}
                    />
                  </div>
                </Col>
                <Col className="col-4">
                  <div className="mb-3">
                    <CustomAvField
                      name="noOfPointCal"
                      type="number"
                      errorMessage={getInvalidMessageI18n(t, "Number of Cal Points")}
                      value={data?.noOfPointCal || ""}
                      label={t("Number of Calibration Points")}
                      detected={isEdit}
                    />
                  </div>
                </Col>
                <Col className="col-4">
                  <div className="mb-3">
                    <CustomAvField
                      name="maxDayOpen"
                      type="number"
                      errorMessage={getInvalidMessageI18n(t, "Max Day Open")}
                      value={data?.maxDayOpen || ""}
                      label={t("Max Days Open")}
                      detected={isEdit}
                    />
                  </div>
                </Col>
                <Col className="col-4">
                  <Row className="d-flex">
                    <div className="mb-3">
                      <CustomDatePicker
                        name="maxOpenDate"
                        label={t("Open Date")}
                        placeholder={"DD-MM-YYYY"}
                        format={"DD-MM-YYYY"}
                        value={data?.maxOpenDate || maxOpenDate}
                        detected={isEdit}
                          onChangeHandler={e => {
                            setMaxOpenDate(e.time[0])
                        }}
                      />
                    </div>
                  </Row>
                </Col>
              </Row>
            </Accordion>
          </AccordionWrapper>
        </AvForm>
      </ModalBody>
      <ModalFooter>
        <CustomButton
          text={t("common:Cancel")}
          type="button"
          onClick={toggle}
          data-dismiss="modal"
          className="button-width"
        />
        <CustomButton
          text={t("common:Save")}
          type="submit"
          color="primary"
          onClick={() => formEl?.current?.submit()}
          className="save-user button-width"
          isEdit
        />
      </ModalFooter>
    </CustomModal>
  );
};

export default withTranslation(["managementQCPage", "common"])(ConfigLotQCModal);
