export const GET_LIST_RESULT_QC = "GET_LIST_RESULT_QC"
export const GET_LIST_RESULT_QC_SUCCESS = "GET_LIST_RESULT_QC_SUCCESS"
export const GET_LIST_RESULT_QC_FAIL = "GET_LIST_RESULT_QC_FAIL"

export const CLICK_ADD_NEW_RESULT_IQC = "CLICK_ADD_NEW_RESULT_IQC"

export const UPDATE_LIST_RESULT_QC = "UPDATE_LIST_RESULT_QC"
export const UPDATE_LIST_RESULT_QC_SUCCESS = "UPDATE_LIST_RESULT_QC_SUCCESS"
export const UPDATE_LIST_RESULT_QC_FAIL = "UPDATE_LIST_RESULT_QC_FAIL"

export const VALID_RESULT_QC = "VALID_RESULT_QC"
export const VALID_RESULT_QC_SUCCESS = "VALID_RESULT_QC_SUCCESS"
export const VALID_RESULT_QC_FAIL = "VALID_RESULT_QC_FAIL"

export const CANCEL_VALID_RESULT_QC = "CANCEL_VALID_RESULT_QC"
export const CANCEL_VALID_RESULT_QC_SUCCESS = "CANCEL_VALID_RESULT_QC_SUCCESS"
export const CANCEL_VALID_RESULT_QC_FAIL = "CANCEL_VALID_RESULT_QC_FAIL"

export const CANCEL_RESULT_QC = "CANCEL_RESULT_QC"
export const CANCEL_RESULT_QC_SUCCESS = "CANCEL_RESULT_QC_SUCCESS"
export const CANCEL_RESULT_QC_FAIL = "CANCEL_RESULT_QC_FAIL"

export const DELETE_RESULT_QC = "DELETE_RESULT_QC"
export const DELETE_RESULT_QC_SUCCESS = "DELETE_RESULT_QC_SUCCESS"
export const DELETE_RESULT_QC_FAIL = "DELETE_RESULT_QC_FAIL"