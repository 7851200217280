import { AvForm } from "availity-reactstrap-validation";
import { CustomBootstrapTable } from "components/Common";
import FormSwitchForm from "components/Common/FormSwitchForm";
import IconStatus from "components/Common/IconStatus";
import { ModuleIds } from "constant";
import { indexCalculator } from "helpers/utilities";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import FilterForm from "./FilterForm";
import ImportExportButton from "./ImportExportForm";

const RESOURCE = ModuleIds.MachineTestQC

const TestTable = ({
    tests,
    onSearch,
    onSort,
    onRefresh,
    onPageChange,
    paging,
    onSizePerPageChange,
    onSubmitFilter,
    loading,
    t,
    updatedTime,
    updateTestQC
}) => {
    const [data, setData] = useState([])
    const [model, setModel] = useState({
        search: "",
        type: "",
        category: "",
        sampleType: "",
    });
    const searchQuery = useSelector(state => state.test.searchQuery);

    const onResetHandler = () => {
        const initModel = {
            search: "",
            type: "",
            category: "",
            sampleType: "",
        }
        let x = document.getElementsByName("type")
        for (let i = 0; i < x.length; i++) {
            x[i].checked = false
        }
        setModel(initModel)
    }

    useEffect(() => {
        setModel(searchQuery)
    }, [searchQuery])

    useEffect(() => {
        setData(tests)
    }, [tests])

    const columns = [
        {
            dataField: "id",
            text: "#",
            hidden: true,
            formatter: (cellContent, test, index) => {
                return cellContent
            },
        },
        {
            dataField: "id",
            text: "#",
            style: { width: 50 },
            formatter: (cellContent, test, index) => {
                return indexCalculator(paging.page, paging.size, index + 1)
            },
        },
        {
            dataField: "testCode",
            text: t("Code"),
            sort: true,
            formatter: (cellContent) => {
                return <span style={{ fontWeight: 'bold' }}>{cellContent}</span>
            }
        },
        {
            dataField: "testName",
            text: t("Name"),
            style: { maxWidth: "300px" },
            sort: true,
            formatter: (cellContent, test, index) => {
                const testName = test?.customName && test.customName !== '' ? `${test.testName} [${test.customName}]` : test.testName
                return <Link to={`/Test/${test.id}/view?tab=1`}>{testName}</Link>
            },
        },
        {
            dataField: "categoryName",
            text: t("Category"),
            sort: true,
        },
        {
            dataField: "unit",
            text: t("Unit"),
            sort: true,
            style: { maxWidth: "70px" },
            align: '-webkit-center',
            headerAlign: '-webkit-center',
        },
        {
            dataField: "isQC",
            text: t("QC"),
            formatter: (cellContent, test, index) => {
                return <AvForm>
                    <FormSwitchForm
                        name="qc"
                        label=''
                        value={!!cellContent}
                        onChange={(value) => { updateTestQC(test, value) }}
                    />
                </AvForm>
            },
        },
        {
            dataField: "inUse",
            text: t("Status"),
            style: { minWidth: "85px" },
            align: 'center',
            headerAlign: 'center',
            formatter: (cellContent, test, index) => {
                return <IconStatus
                    isRound={true}
                    type={cellContent ? "Active" : "Inactive"}
                />
            },
        }
    ]

    return (
        <div>
            <CustomBootstrapTable
                columns={columns}
                search
                data={data}
                paging={paging}
                onSearch={onSearch}
                onSort={onSort}
                isSelected
                onRefresh={onRefresh}
                filterForm={() => <FilterForm model={model} />}
                onReset={onResetHandler}
                onPageChange={onPageChange}
                onSizePerPageChange={onSizePerPageChange}
                onSubmitFilter={onSubmitFilter}
                searchText={model.search}
                loading={loading}
                updatedTime={updatedTime}
                resource={RESOURCE}
                keyField="id"
                isImportExportButton={true}
                importExportForm={() => <ImportExportButton model={model} />}
            />

        </div>
    )
}

export default withTranslation(["testPage"])(TestTable)