import { call, put, takeEvery, select, all } from "redux-saga/effects";
import i18n from "i18next"
import { TestResultStatus } from "constant"
//setting redux states
import {
    GET_VALIDRESULTS_LIST,
    DELETE_VALIDRESULTS,
    UPDATE_VALID_VAL_RESULTS,
    UPDATE_INVALID_REV_RESULTS,
    UPDATE_VALID_RESULTS_SAMPLEID,
    UPDATE_VALID_RESULTS_SID_QC,
    GET_VALID_RESULT_SAMPLES_LIST
} from "./actionTypes"

import {
    resetValidResultSearchQuery, setValidResultSearchQuery,
    getValidResultsListSuccess, getValidResultsListFail,
    deleteValidResultsSuccess, deleteValidResultsFail,

    updateValidResultsVALSuccess, updateValidResultsVALFail,
    updateInValidResultsREVSuccess, updateInValidResultsREVFail,

    getValidResultSamplesListSuccess, getValidResultSamplesListFail,
    updateValidResultsSampleIdSuccess, updateValidResultsSampleIdFail,

    updateValidResultSIDQCSuccess, updateValidResultSIDQCFail
} from "./actions"

import {
    deleteValidResult,
    getAllValidResult,
    updateValidResultSid,
    updateValidResultSIDQC,
    updateValidResultStatus,
} from "helpers/app-backend/valid_results_backend_helper"

import { showToast } from "components/Common";
const t = (msg, param) => i18n.t(msg, param)

function* fetchValidResults({ payload }) {
    try {
        const searchQuery = yield select(state => {
            return state.validResult.searchQuery
        })
        payload = { ...searchQuery, ...payload }
        const response = yield call(getAllValidResult, payload)
        yield put(getValidResultsListSuccess(response))
        yield put(setValidResultSearchQuery(payload))

    } catch (error) {
        console.log(error);
        yield put(getValidResultsListFail(error))
    }
}

function* onDeleteValidResults({ payload: { results, callback } }) {
    try {
        const response = yield call(deleteValidResult, results)
        yield put(deleteValidResultsSuccess(results))
        showToast(
            `${t("message:DeletedMessage", {
                field: `${t("resultPage:Result")}`,
            })}`
        )
        if (callback) callback()
    } catch (error) {
        console.log(error);
        yield put(deleteValidResultsFail(error))
    }
}

function* onUpdateValidResultSid({ payload }) {
    try {
        let { samples, callback } = payload
        const response = yield call(updateValidResultSid, samples)
        yield put(updateValidResultsSampleIdSuccess(response))

        showToast(
            `${t("message:UpdatedMessage", {
                field: `${t(
                    "resultPage:Result"
                )} <span class='text-decoration-underline fw-bold'>
                    </span>`,
            })}`
        )
        if (callback) callback()
    } catch (error) {
        console.log(error);
        yield put(updateValidResultsSampleIdFail(error))
    }
}

function* updateValidResultSID_QC({ payload }) {
    try {
        let { data, callback } = payload
        console.log('data123', data)
        const response = yield call(updateValidResultSIDQC, data)
        yield put(updateValidResultSIDQCSuccess(response))

        showToast(
            `${t("message:UpdatedMessage", {
                field: `${t(
                    "resultPage:Result"
                )} <span class='text-decoration-underline fw-bold'>
                    </span>`,
            })}`
        )
        if (callback) callback()
    } catch (error) {
        console.log(error);
        yield put(updateValidResultSIDQCFail(error))
    }
}

function* onFetchValidResultSamples({ payload }) {
    try {
        const response = yield call(getAllValidResult, payload)

        yield put(getValidResultSamplesListSuccess(response))

    } catch (error) {
        console.log(error);
        yield put(getValidResultSamplesListFail(error))
    }
}

function* onUpdateValidVALResults({ payload }) {
    try {
        let { results, callback } = payload
        if (results.length > 0) {
            let values = {};
            values.results = results.map(item => {
                return { id: item.id, status: TestResultStatus.VAL }
            });
            const response = yield call(updateValidResultStatus, values)
            yield put(updateValidResultsVALSuccess(values))

            showToast(
                `${t("message:UpdatedMessage", {
                    field: `${t(
                        "resultPage:Result"
                    )} <span class='text-decoration-underline fw-bold'>
                        </span>`,
                })}`
            )
            if (callback) callback()
        }

    } catch (error) {
        console.log(error);
        yield put(updateValidResultsVALFail(error))
    }
}

function* onUpdateInValidREVResults({ payload }) {
    try {
        let { results, callback } = payload
        if (results.length > 0) {
            let values = {};
            values.results = results.map(item => {
                return { id: item.id, status: TestResultStatus.REV }
            });
            const response = yield call(updateValidResultStatus, values)
            yield put(updateInValidResultsREVSuccess(values))

            showToast(
                `${t("message:UpdatedMessage", {
                    field: `${t(
                        "resultPage:Result"
                    )} <span class='text-decoration-underline fw-bold'>
                    </span>`,
                })}`
            )
            if (callback) callback()
        }

    } catch (error) {
        console.log(error);
        yield put(updateInValidResultsREVFail(error))
    }
}

function* validResultSaga() {
    yield takeEvery(GET_VALIDRESULTS_LIST, fetchValidResults)
    yield takeEvery(DELETE_VALIDRESULTS, onDeleteValidResults)

    yield takeEvery(UPDATE_VALID_RESULTS_SAMPLEID, onUpdateValidResultSid)

    yield takeEvery(UPDATE_VALID_RESULTS_SID_QC, updateValidResultSID_QC)

    yield takeEvery(GET_VALID_RESULT_SAMPLES_LIST, onFetchValidResultSamples)

    yield takeEvery(UPDATE_VALID_VAL_RESULTS, onUpdateValidVALResults)
    yield takeEvery(UPDATE_INVALID_REV_RESULTS, onUpdateInValidREVResults)
}
export default validResultSaga