import { CustomBootstrapTable, CustomButton } from "components/Common"
import { ModuleIds } from "constant"
import ConfigCalculateModal from "pages/LaboratoryIQC/CalculationQC/CalculationIndex/Modal/ConfigCalculateModal"
import { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import ReactTooltip from "react-tooltip"
import CalculationIndexButtons from "./CalculationIndexTableButtons"

const RESOURCE = ModuleIds.CalculationiQC

const CalculationIndexTable = ({
  onSelect,
  lottests,
  config,
  onSearch,
  onRefresh,
  onSubmitFilter,
  loading,
  t,
  updatedTime,
  onChangeModel,
  submitConfig,
  onLottestExport,
  onLottestChange
}) => {
  const [data, setData] = useState([])
  const [model, setModel] = useState({})

  useEffect(() => {
    onLottestChange(lottests)
  }), [data]

  useEffect(() => {
    onChangeModel && onChangeModel(model)
  }, [model])

  useEffect(() => {
    setData([...lottests])
    onLottestChange(lottests)
  }, [lottests])

  const columns = [
    {
      dataField: "id",
      text: "",
      hidden: true,
      formatter: (cellContent, item, index) => {
        return cellContent
      },
    },
    {
      dataField: "",
      text: t(""),
      headerStyle: { width: "30px" },
      formatter: (cellContent, item, index) => {
        return <div className="d-flex flex-column gap-3">
          {item?.lottests?.map((x, position) => (
            <div key={position}>
              <input
                type="checkbox"
                className="form-check-input"
                onChange={(e) => { onSelect(item, e.target.checked) }}
              />
            </div>
          ))}
        </div>
      },
    },
    {
      dataField: "SEQ",
      text: "#",
      headerStyle: { width: "30px" },
      formatter: (cellContent, item, index) => {
        return index + 1
      },
    },

    {
      dataField: "testCode",
      text: t("Test Code"),
      style: { width: "70px" },
    },
    {
      dataField: "testName",
      text: t("Test Name"),
      style: { width: "350px" },
    },
    {
      dataField: "unit",
      text: t("Unit"),
      style: { width: "30px" }
    },
    {
      dataField: "levels",
      text: t("Level"),
      style: { width: "30px" },
      formatter: (cellContent, item, index) => {
        return <div className="d-flex flex-column gap-3">
          {item?.lottests?.map((x, position) => (
            <div key={position}>
              {x.levels}
              {/* <hr className="my-2" /> */}
            </div>
          ))}
        </div>
      },
    },
    {
      dataField: "noOfRun",
      text: t("Number of Runs"),
      headerStyle: { width: "100px" },
      style: { width: "100px" },
      formatter: (cellContent, item, index) => {
        return <div className="d-flex flex-column gap-3">
          {item?.lottests?.map((x, position) => (
            <div key={position}>
              {x?.noOfRun || '1'}
              {/* <hr className="my-2" /> */}
            </div>
          ))}
        </div>
      },
    },
    {
      dataField: "mean",
      text: t("Mean Old"),
      style: { width: "100px" },
      formatter: (cellContent, item, index) => {
        return <div className="d-flex flex-column gap-3">
          {item?.lottests?.map((x, position) => (
            <div key={position}>
              {x?.mean}
              {/* <hr className="my-2" /> */}
            </div>
          ))}
        </div>
      },
    },
    {
      dataField: "sd",
      text: t("SD Old"),
      style: { width: "100px" },
      formatter: (cellContent, item, index) => {
        return <div className="d-flex flex-column gap-3">
          {item?.lottests?.map((x, position) => (
            <div key={position}>
              {x?.sd}
              {/* <hr className="my-2" /> */}
            </div>
          ))}
        </div>
      },
    },
    {
      dataField: "cv",
      text: t("%CV Old"),
      style: { width: "100px" },
      formatter: (cellContent, item, index) => {
        return <div className="d-flex flex-column gap-3">
          {item?.lottests?.map((x, position) => (
            <div key={position}>
              {x?.cv}
            </div>
          ))}
        </div>
      },
    }
  ]

  return (
    <div className="calculation-table">
      <CustomBootstrapTable
        columns={columns}
        data={data}
        onRefresh={onRefresh}
        showSelectRow={false}
        isEnableRefresh={false}
        onSubmitFilter={onSubmitFilter}
        loading={loading}
        paging={{
          dataSize: data.length
        }}
        updatedTime={updatedTime}
        resource={RESOURCE}
        keyField="id"
        isImportExportButton={true}
        importExportForm={() => (
          <div className="tooltip-custom">
            <CustomButton
              isEdit
              color="secondary"
              outline
              onClick={onLottestExport}
            >
              <i className={"fas fa-download"}></i>
            </CustomButton>
            <span className="tooltiptext-custom-button-icon-left">
              {t("Export")}
            </span>
          </div>
        )}
        customButtonRight={() => (
          <ConfigCalculateModal
            submitConfig={submitConfig}
            model={model}
            data={data}
          />
        )}
        customButtonTable={() => (
          <CalculationIndexButtons
            t={t}
            model={model}
            searchText={model.search}
            onSearch={onSearch}
            loading={loading}
            onChange={val => {
              setModel({ ...model, ...val })
            }}
            onSubmit={val => {
              onSubmitFilter(val)
            }}
          />
        )}
        infoExtension={
          <div className="d-flex gap-2 mt-1 font-size-15 justify-content-end">
            <div className="tooltip-custom">
              <i
                data-tip
                data-for={`tooltip-calculate`}
                className="fas fa-exclamation-circle text-primary"
              ></i>
              <ReactTooltip
                id={`tooltip-calculate`}
                place="top"
                effect="solid"
              >
                <div>
                  {t("Calculate %CV", {
                    date: `${config.isCV ? config?.cvDate?.join(' - ') : 'N/A'}`
                  })}
                </div>
                <div>
                  {t("Calculate Mean", {
                    date: `${config.isMean ? config?.meanDate?.join(' - ') : 'N/A'}`
                  })}
                </div>
              </ReactTooltip>
            </div>
            <span>
              <b>{t("%CV Calculate")}:</b> <b className="text-primary">{config.cvCalculateName || ''}</b>
            </span>
            -
            <span>
              <b>{t("Resoure Mean")}:</b> <b className="text-primary">{config.meanCalculateName || ''}</b>
            </span>
            -
            <span>
              <b>{t("Point Valid Calculate")}: </b>
              {config.isValid ?
                <i className="font-size-15 fas fa-check-circle text-success" /> :
                <i className="font-size-15 fas fa-times-circle text-secondary" />}
            </span>
          </div>
        }
      />
    </div>
  )
}

export default withTranslation(["calculationQCPage", "message"])(CalculationIndexTable)