import { showToast } from "components/Common"
import { createQCLot, deleteQCLotById, getQCLotById, updateQCLotById } from "helpers/app-backend"
import { GetLotQCListFromLotTest } from "helpers/app-backend/IQC/QCLotTest_backend_helper"
import { getAllMachine } from "helpers/app-backend/machines_backend_helper"
import { convertDateFormat } from "helpers/utilities"
import i18n from "i18next"
import { call, put, takeEvery } from "redux-saga/effects"
import {
    ADD_NEW_EXPRESSION_QC,
    DELETE_EXPRESSION_QC,
    GET_EXPRESSION_QC_DETAIL,
    GET_LIST_EXPRESSION_QC,
    UPDATE_EXPRESSION_QC
} from "./actionTypes"
import {
    addNewExpressionQCFail,
    addNewExpressionQCSuccess,
    addNewLotQCFail,
    addNewLotQCSuccess,
    deleteExpessionQCFail,
    deleteExpessionQCSuccess,
    deleteLotQCFail,
    deleteLotQCSuccess,
    getListExpressionQCFail,
    getListExpressionQCSuccess,
    getLotExpressionDetailFail,
    getLotExpressionDetailSuccess,
    getLotQCDetailFail,
    getLotQCDetailSuccess,
    getLotQCListFromLotTestFail,
    getLotQCListFromLotTestSuccess,
    updateExpressionQCFail,
    updateExpressionQCSuccess,
    updateLotQCFail,
    updateLotQCSuccess
} from "./actions"
import { createQCExpression, deleteQCExpression, getQCExpressionById, getQCExpressionList, updateQCExpression } from "helpers/app-backend/IQC/QCExpression_backend_helper"
const t = (msg, param) => i18n.t(msg, param)

function* fetchGetListExpressionQC({ payload }) {
    try {
        let response = yield call(getQCExpressionList, payload)
        yield put(getListExpressionQCSuccess(response))
    } catch (error) {
        console.log(error)
        yield put(getListExpressionQCFail(error))
    }
}

function* fetchExpressionDetail({ id, callback }) {
    try {
        const response = yield call(getQCExpressionById, id)
        yield put(getLotExpressionDetailSuccess(response))
        callback && callback(response)
    } catch (error) {
        yield put(getLotExpressionDetailFail(error))
    }
}
function* onUpdateExpressionQC({ payload: { expression, callback } }) {
    try {
        yield call(updateQCExpression, expression)
        yield put(updateExpressionQCSuccess(expression))
        showToast(
            `${t("message:UpdatedMessage", {
                field: `${t(
                    ""
                )} <span class='text-decoration-underline fw-bold'>${expression["expressionCode"]}</span>`,
            })}`
        )
        callback && callback()
    } catch (error) {
        yield put(updateExpressionQCFail(error))
    }
}
function* onDeleteQCExpression({ payload: { expression, callback } }) {
    try {
        yield call(deleteQCExpression, expression)
        yield put(deleteExpessionQCSuccess())
        showToast(
            `${t("message:DeletedMessage", {
                field: `${t("")}`,
            })}`
        )
        callback && callback()
    } catch (error) {
        yield put(deleteExpessionQCFail(error))
    }
}
function* onAddNewQCExpression({ payload: { expression, callback } }) {
    try {
        const response = yield call(createQCExpression, expression)
        yield put(addNewExpressionQCSuccess(expression))
        showToast(
            `${t("message:CreatedMessage", {
                field: `${t(
                    ""
                )} <span class='text-decoration-underline fw-bold'>${expression["expressionCode"]
                    }</span>`,
            })}`
        )
        callback && callback()
    } catch (error) {
        console.log(error)
        yield put(addNewExpressionQCFail(error))
    }
}

function* ExpressionQCsSaga() {
    yield takeEvery(GET_LIST_EXPRESSION_QC, fetchGetListExpressionQC)
    yield takeEvery(GET_EXPRESSION_QC_DETAIL, fetchExpressionDetail)
    yield takeEvery(ADD_NEW_EXPRESSION_QC, onAddNewQCExpression)
    yield takeEvery(UPDATE_EXPRESSION_QC, onUpdateExpressionQC)
    yield takeEvery(DELETE_EXPRESSION_QC, onDeleteQCExpression)
}
export default ExpressionQCsSaga