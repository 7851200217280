import { Check, ConfirmModal2, CustomNav, CustomNavLink, showToast } from "components/Common"
import CustomTestAuditLog from "components/Common/CustomTestAuditLog"
import { AuditLogIdentifier, ModuleIds, PartyProfile, permissionType, TestRequest_Test_Type } from "constant"
import { checkAllowRole, convertDateFormat, getUrlParamByKey, insertUrlParam } from "helpers/utilities"
import { isEmpty } from "lodash"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { Card, CardBody, Col, Row, TabContent, TabPane } from "reactstrap"
import {
    cancelTestRequest,
    createNewRequest,
    deleteRequests,
    emptyTestRequestDetail,
    getBillingTypes,
    getRequests,
    getTestRequestDetail,
    getTestRequestSample,
    getTestrequestGeneralDetail,
    getTestrequestSamplesDetail,
    getTestrequestTestsDetail,
    updateRequestGeneral,
    updateRequestTests,
    updateTestRequest,
} from "store/laboratory/testRequest/actions"
import RequestModal from "../Modal/RequestModal"
import DetailPatientTab from "./DetailPatientTab"
import DetailSampleTab from "./DetailSampleTab"
import DetailTestsTab from "./DetailTestsTab"
import TabDetails from "./TabDetails/TabDetails"
import TitleAndInfo from "./TitleAndInfo"
import { useLocation } from "react-router-dom/cjs/react-router-dom"
import moment from "moment"

const RESOURCE = ModuleIds.TestRequest
let listTabName = [
    {
        id: 1,
        name: 'Request'
    },
    {
        id: 2,
        name: 'Patient details'
    },
    {
        id: 3,
        name: 'Request of tests'
    },
    {
        id: 4,
        name: 'Sample info'
    }
]
const CardDetail = ({
    isEdit,
    history,
    match,
    testRequest,
    sticky,
    t,

    onGetTestRequestDetail,
    billingTypes,

    onCreateNewRequest,
    onUpdateTestRequest,
    onGetBillingTypes,

    testRequestSamples,
    testRequestTests,
    onUpdateRequestGeneral,
    onUpdateRequestTests,
    onDeleteRequests,

    fetchGetTestrequestGeneralDetail,
    fetchGetTestrequestTestsDetail,
    fetchGetTestrequestSamplesDetail,
    onEmptyTestRequestDetail,
    onCancelTestRequest,
    userPermissions
}) => {
    const [isClone, setIsClone] = useState(false)
    const [modal, setModal] = useState(false)
    const formEl = useRef(null)
    const [rowEdit, setRowEdit] = useState({})
    const { params } = match
    const [confirmModal, setConfirmModal] = useState(false);
    const [currentTab, setCurrentTab] = useState(getUrlParamByKey('tab') || '1')
    const resource = RESOURCE ? true : false
    const userInSesstion = JSON.parse(localStorage.getItem("userInSesstion"));
    const location = useLocation()
    const [listTab, setListTab] = useState(listTabName)
    useEffect(() => {
        let check = checkAllowRole({
            permission: permissionType.R,
            userPermissions,
            permissions: [],
            resource: 'Logs',
            exclusivePermissions: []
        })
        if (check) {
            let tmp = listTabName
            if (tmp.findIndex(x => x.id == 5) < 0) {
                tmp.push({
                    id: 5,
                    name: t("testRequestPage:History")
                })
                setListTab(tmp)
            }
        }
    }, [userPermissions])

    useEffect(() => {
        return () => {
            onEmptyTestRequestDetail()
        }
    }, [])

    useEffect(() => {
        if (getUrlParamByKey('tab') != currentTab) {
            const url = location.pathname.split('/')
            insertUrlParam({ tab: currentTab })
            const tabVal = getUrlParamByKey('tab');
            let tabUrl = ""
            if (tabVal != null && tabVal != '') {
                tabUrl = "?tab=" + tabVal
            }
            history.push(url[url.length - 1] + tabUrl)
        }
    }, [currentTab])
    useEffect(() => {
        setCurrentTab(getUrlParamByKey('tab'))
    }, [window.location.search])
    const toggleModal = () => {
        setModal(prev => !prev)
    }

    const onEditHandler = () => {
        setIsClone(false)
        toggleModal()
    }

    const onCloneHandler = () => {
        setIsClone(true)
        toggleModal()
    }

    const afterUpdate = () => {
        onFetchDetail()
        toggleParamModal()
    }

    const toggleParamModal = () => {
        setModal(prev => !prev)
    }

    const onCancel = () => {
        const url = location.pathname.split('/')
        const tabVal = getUrlParamByKey('tab');
        let tabUrl = ""
        if (tabVal != null && tabVal != '') {
            tabUrl = "?tab=" + tabVal
        }
        history.push('view' + tabUrl)
        // history.push("view")
    }
    const onCancelToggleHandler = () => {
        setConfirmModal(true)
    }
    const onCancelToggle = () => {
        setConfirmModal(false);
    }
    const onCancelTestRequestHandler = () => {
        onCancelTestRequest({ request: [testRequest], callback: callback() })
        setConfirmModal(false)
    }
    const onFetchDetail = () => {
        if (params && params.id) {
            fetchGetTestrequestTestsDetail(params.id,()=>{
                fetchGetTestrequestSamplesDetail(params.id)
                fetchGetTestrequestGeneralDetail(params.id)
            })
        }
    }

    useEffect(() => {
        onFetchDetail()
    }, [params.id])

    useEffect(() => {
        setModal(false)
    }, [params.id])

    useEffect(() => {
        setRowEdit(testRequest)
    }, [testRequest])

    const onDelete = () => {
        setConfirmModal(true)
    }

    const onDeleteToggleHandler = (e, param) => {
        setConfirmModal(false)
    }

    const onDeleteRequestsHandler = () => {
        onDeleteRequests({ requests: [testRequest], callback: callback() })
    }

    const callback = () => {
        const url = `/TestRequest`
        setConfirmModal(false)
        history.replace(url)
    }

    const handleValidRequestSubmit = (values) => {
        //values.companyId = 1;
        values.patientId = `${values.individualValues?.PatientId || ''}`
        values.patientGroupType = 'S'
        //update later
        if (values.individualValues) {
            values.individualValues.insuranceNumber = values.insuranceNumber;
            values.individualValues.inPatient = values.inPatient;
            delete values.individualValues.patientId
            if (!values.individualValues?.ProfileId) {
                values.individualValues.ProfileId = PartyProfile.DEFAULT_PATIENT;
            }
        }
        let tests = [];
        let profiles = [];
        let samples = [];
        let sidParam = {
            FullDate: moment(new Date(values?.requestDate)).format("DDMMYY"),
            Year: convertDateFormat(values?.requestDate, "YYYY")
        }

        testRequestTests.forEach(item => {
            //push test đơn
            if (item.type === TestRequest_Test_Type.TEST) {
                tests.push({
                    testCode: item.code,
                    testCategory: item.category,
                    sampleType: item.sampleType,
                    profileCode: item.parentCode,
                    sID: item?.sid || 0,
                });
            } else if (item.type === TestRequest_Test_Type.PROFILE_GROUP) {
                //push profile group vào list
                profiles.push({
                    profileCode: item.code,
                    profileCategory: item.category
                });

                if (item.children) {
                    //push tất cả child trực tiếp là test vào list
                    item.children.filter(x => x.type === TestRequest_Test_Type.TEST).map(test => {
                        tests.push({
                            testCode: test.code,
                            testCategory: test.category,
                            sampleType: test.sampleType,
                            profileCode: test.parentCode,
                            sID: test?.sid || 0,
                        });
                    })

                    //push tất cả child trực tiếp là profile vào list
                    item.children.filter(x => x.type === TestRequest_Test_Type.PROFILE).map(profile => {
                        profiles.push({
                            profileCode: profile.code,
                            profileCategory: profile.category
                        });
                        if (profile.children) {
                            //push tất cả test cháu vào list
                            profile.children.map(test => {
                                tests.push({
                                    testCode: test.code,
                                    testCategory: test.category,
                                    sampleType: test.sampleType,
                                    profileCode: test.parentCode,
                                    sID: test?.sid || 0,
                                });
                            })
                        }
                    })
                }
            } else if (item.type === TestRequest_Test_Type.PROFILE) {
                //push profile bên ngoài
                profiles.push({
                    profileCode: item.code,
                    profileCategory: item.category
                });
                if (item.children) {
                    item.children.map(test => {
                        tests.push({
                            testCode: test.code,
                            testCategory: test.category,
                            sampleType: test.sampleType,
                            profileCode: test.parentCode,
                            sID: test?.sid || 0,
                        });
                    })
                }
                //push profile bên trong
            }
        })

        testRequestSamples.forEach(item => {
            samples.push({
                sampleType: item.sampleType,
                sampleColor: item.sampleColor,
                numberOfLabels: `${item.numberOfLabels}`,
                collectionTime: item.collectionTime,
                quality: item.quality ? `${item.quality}` : "",
                collectorUserId: item.collectorUserId ? `${item.collectorUserId}` : null,
                sID: item?.sid || 0,
            });
        });

        values.tests = tests;
        values.profiles = profiles;
        values.samples = samples;
        values.sidParam = sidParam
        if (userInSesstion?.area) {
            values.area = userInSesstion?.area
        }
        if (isClone) {
            onCreateNewRequest({ request: values, history: history, callback: () => { } })
        } else {
            onUpdateTestRequest({ request: values, callback: afterUpdate })
        }
    }

    const onValidSubmitGeneralTab = (e, values) => {
        //values.companyId = 1;
        values.patientId = testRequest.patientId ? testRequest.patientId : ''
        values.resultTime = isNaN(values.resultTime) ? null : (values.resultTime == "" ? null : values.resultTime)
        onUpdateRequestGeneral({
            request: values,
            callback: (id) => {
                showToast(
                    `${t("message:UpdatedMessage", {
                        field: `${t(
                            "testRequestPage:Request"
                        )} <span class='text-decoration-underline fw-bold'>${convertDateFormat(values["requestDate"], "YYYY")}${convertDateFormat(values["requestDate"], "MMDD")}-R${id.toString().padStart(5, '0')}</span>`,
                    })}`
                )
                isEdit ? onCancel() : afterUpdate()
            }
        })
    }

    const onValidSubmitTestsTab = (e, values) => {
        values.patientId = `${values.patientId}`;
        let tests = [];
        let profiles = [];
        let samples = [];

        testRequestTests.forEach(item => {
            //push test đơn
            if (item.type === TestRequest_Test_Type.TEST) {
                tests.push({
                    testCode: item.code,
                    testCategory: item.category,
                    sampleType: item.sampleType,
                    profileCode: item.parentCode,
                    sID: item?.sid || 0,
                });
            } else if (item.type === TestRequest_Test_Type.PROFILE_GROUP) {
                //push profile group vào list
                profiles.push({
                    profileCode: item.code,
                    profileCategory: item.category
                });

                if (item.children) {
                    //push tất cả child trực tiếp là test vào list
                    item.children.filter(x => x.type === TestRequest_Test_Type.TEST).map(test => {
                        tests.push({
                            testCode: test.code,
                            testCategory: test.category,
                            sampleType: test.sampleType,
                            profileCode: test.parentCode,
                            sID: test?.sid || 0,
                        });
                    })

                    //push tất cả child trực tiếp là profile vào list
                    item.children.filter(x => x.type === TestRequest_Test_Type.PROFILE).map(profile => {
                        profiles.push({
                            profileCode: profile.code,
                            profileCategory: profile.category
                        });
                        if (profile.children) {
                            //push tất cả test cháu vào list
                            profile.children.map(test => {
                                tests.push({
                                    testCode: test.code,
                                    testCategory: test.category,
                                    sampleType: test.sampleType,
                                    profileCode: test.parentCode,
                                    sID: test?.sid || 0,
                                });
                            })
                        }
                    })
                }
            } else if (item.type === TestRequest_Test_Type.PROFILE) {
                //push profile bên ngoài
                profiles.push({
                    profileCode: item.code,
                    profileCategory: item.category
                });
                if (item.children) {
                    item.children.map(test => {
                        tests.push({
                            testCode: test.code,
                            testCategory: test.category,
                            sampleType: test.sampleType,
                            profileCode: test.parentCode,
                            sID: test?.sid || 0,
                        });
                    })
                }
                //push profile bên trong
            }
        })

        testRequestSamples.forEach(item => {
            samples.push({
                sampleType: item.sampleType,
                sampleColor: item.sampleColor,
                numberOfLabels: `${item.numberOfLabels}`,
                collectionTime: item.collectionTime,
                quality: item.quality ? `${item.quality}` : "",
                collectorUserId: item.collectorUserId ? `${item.collectorUserId}` : null,
                sID: item.sid ? `${item.sid}` : 0,
            });
        });

        values.tests = tests;
        values.profiles = profiles;
        values.samples = samples;
        if (userInSesstion?.area) {
            values.area = userInSesstion?.area
        }
        if (isEdit) {
            onUpdateTestRequest({ request: values, callback: afterUpdate })
        }
    }


    if (isEmpty(testRequest)) return null;

    return (
        <React.Fragment>
            <div className="sticky-header-detail">
                <Row>
                    <Col lg="12">
                        {testRequest &&
                            <div className={sticky ? '' : 'sticky-header-detail-expand'}>
                                <TitleAndInfo
                                    isHideEdit={true}
                                    onEdit={onEditHandler}
                                    onClone={onCloneHandler}
                                    data={testRequest}
                                    title={t("Test Request")}
                                    subtitle={<Link to={`/TestResult/${testRequest.id}?tab=1`} target="_blank">{`${testRequest.sid || ''}`}</Link>}
                                    resource={RESOURCE}
                                    // onDelete={onDelete}
                                    onCancel={onCancelToggleHandler}
                                    isHideCancel={resource}
                                    isHideDelete={resource}
                                    isDisableClone={resource}
                                    isDropDown={true}
                                />
                            </div>
                        }
                    </Col>
                </Row>
            </div>
            {modal &&
                <RequestModal
                    formEl={formEl}
                    modal={modal}
                    isEdit={!isClone}
                    isClone={isClone}
                    onValidSubmit={handleValidRequestSubmit}
                    toggle={toggleModal}
                    data={testRequest}
                />
            }
            <ConfirmModal2
                modal={confirmModal}
                title={`${t("common:Cancel")} ${t("Test Request")}`}
                message={t("message:CancelConfirm")}
                onToggle={onCancelToggle}
                onConfirm={onCancelTestRequestHandler}
            />
            <Row>
                <Col lg="12">
                    <Card>
                        <CardBody>
                            <Row>
                                <CustomNav
                                    onToggle={e => {
                                        setCurrentTab(e);
                                    }}
                                    defaultTab={currentTab}
                                    tabs
                                    className="nav-tabs-custom"
                                    tabContents={customActiveTab => (
                                        <TabContent
                                            activeTab={customActiveTab}
                                            className="p-3 text-muted"
                                        >
                                            <TabPane tabId="1">
                                                <TabDetails
                                                    onValidSubmit={onValidSubmitGeneralTab}
                                                    data={rowEdit}
                                                    billingTypes={billingTypes}
                                                    isEdit={isEdit}
                                                    onCancel={onCancel}
                                                    formEl={formEl}
                                                />
                                            </TabPane>
                                            <TabPane tabId="2">
                                                <DetailPatientTab
                                                    isEdit={true}
                                                    data={{}}
                                                    patientId={testRequest.partyId || 0}
                                                    testRequest={testRequest}
                                                    id={params.id}
                                                    isEditForm={isEdit}
                                                    onCancel={onCancel}
                                                    onRefreshDetail={onFetchDetail}
                                                />
                                            </TabPane>
                                            <TabPane tabId="3">
                                                <DetailTestsTab className="testsTab"
                                                    isEdit={!isClone}
                                                    onValidSubmit={onValidSubmitTestsTab}
                                                    data={testRequestTests}
                                                    id={params.id}
                                                    onRefreshDetail={onFetchDetail}
                                                />
                                            </TabPane>

                                            <TabPane tabId="4">
                                                <DetailSampleTab
                                                    showConfiguration={false}
                                                    resource={ModuleIds.Test}
                                                    id={params.id}
                                                    activeTab={customActiveTab}
                                                    onRefreshDetail={onFetchDetail}
                                                    requestDate={testRequest.requestDate}
                                                />
                                            </TabPane>
                                            {/* <TabPane tabId="5">
                                                <CustomTestAuditLog
                                                    resource={ModuleIds.TestRequest}
                                                    testRequest={[
                                                        AuditLogIdentifier.LA_TestRequest + params.id,
                                                        AuditLogIdentifier.LA_RequestPatient + testRequest.requestPatientId,
                                                    ]}
                                                    type={AuditLogIdentifier.LA_TestRequest}
                                                    title={""}
                                                />
                                            </TabPane> */}
                                            <Check permission={permissionType.R} resource={'Logs'}>
                                                <TabPane tabId="5">
                                                    <CustomTestAuditLog
                                                        resource={ModuleIds.TestRequest}
                                                        testRequest={[
                                                            AuditLogIdentifier.LA_TestRequest + params.id,
                                                            AuditLogIdentifier.LA_RequestPatient + testRequest.requestPatientId,
                                                        ]}
                                                        type={AuditLogIdentifier.LA_TestRequest}
                                                        title={t("testRequestPage:History")}
                                                    />
                                                </TabPane>
                                            </Check>
                                        </TabContent>
                                    )}
                                >
                                    {listTab.map(row => {
                                        return <CustomNavLink key={row.id} tabId={row.id.toString()}>
                                            <span className="">{t(`${row.name}`)}</span>
                                        </CustomNavLink>
                                    })
                                    }
                                </CustomNav>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}

CardDetail.propTypes = {
    testRequest: PropTypes.object,
    billingTypes: PropTypes.array,
    onGetTestRequestDetail: PropTypes.func,
    onUpdateRequestGeneral: PropTypes.func,
    onUpdateRequestTests: PropTypes.func,
    match: PropTypes.object,
    t: PropTypes.any,
    onDeleteRequests: PropTypes.func,
    fetchGetTestrequestGeneralDetail: PropTypes.func,
    fetchGetTestrequestTestsDetail: PropTypes.func,
    fetchGetTestrequestSamplesDetail: PropTypes.func,
    onGetTestRequestSample: PropTypes.func,
    onCancelTestRequest: PropTypes.func
}

const mapStateToProps = ({ testRequest, Authorization }) => {
    return ({
        testRequest: testRequest.testRequest,
        testRequestSamples: testRequest.testRequest.samples || [],
        testRequestTests: testRequest.testRequest.tests || [],
        billingTypes: testRequest.billingTypes,
        userPermissions: Authorization.permissions,
    })
}


const mapDispatchToProps = dispatch => ({
    onUpdateRequestGeneral: request => dispatch(updateRequestGeneral(request)),
    onUpdateRequestTests: request => dispatch(updateRequestTests(request)),

    onGetRequests: payload => dispatch(getRequests(payload)),
    onDeleteRequests: ({ requests, callback }) => dispatch(deleteRequests({ requests, callback })),

    onResetTestSearchQuery: () => dispatch(resetSearchQuery()),

    onCreateNewRequest: ({ request, history, callback }) =>
        dispatch(createNewRequest({ request, history, callback })),

    onUpdateTestRequest: ({ request, callback }) =>
        dispatch(updateTestRequest({ request, callback })),

    onGetTestRequestDetail: requestId => dispatch(getTestRequestDetail(requestId)),

    fetchGetTestrequestGeneralDetail: requestId => dispatch(getTestrequestGeneralDetail(requestId)),
    fetchGetTestrequestTestsDetail: (requestId, callback) => dispatch(getTestrequestTestsDetail(requestId, callback)),
    fetchGetTestrequestSamplesDetail: (requestId, callback) => dispatch(getTestrequestSamplesDetail(requestId, callback)),

    onGetBillingTypes: () =>
        dispatch(getBillingTypes()),

    onGetTestRequestSample: payload => dispatch(getTestRequestSample(payload)),
    onEmptyTestRequestDetail: payload => dispatch(emptyTestRequestDetail(payload)),
    onCancelTestRequest: ({ request, callback }) => dispatch(cancelTestRequest({ request, callback })),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation(["testRequestPage", "common"])(CardDetail)))