import {
    GET_LIST_CHANGE_QC,
    GET_LIST_CHANGE_QC_FAIL,
    GET_LIST_CHANGE_QC_SUCCESS,
    GET_CHANGE_QC_DETAIL,
    GET_CHANGE_QC_DETAIL_FAIL,
    GET_CHANGE_QC_DETAIL_SUCCESS,
    ADD_NEW_CHANGE_QC,
    ADD_NEW_CHANGE_QC_FAIL,
    ADD_NEW_CHANGE_QC_SUCCESS,
    UPDATE_CHANGE_QC,
    UPDATE_CHANGE_QC_FAIL,
    UPDATE_CHANGE_QC_SUCCESS,
    DELETE_CHANGE_QC,
    DELETE_CHANGE_QC_FAIL,
    DELETE_CHANGE_QC_SUCCESS
} from "./actionTypes"

export const getListChangeQC = payload => {
    return {
        type: GET_LIST_CHANGE_QC,
        payload: payload || {},
    }
}
export const getListChangeQCSuccess = payload => ({
    type: GET_LIST_CHANGE_QC_SUCCESS,
    payload: payload,
})
export const getListChangeQCFail = error => ({
    type: GET_LIST_CHANGE_QC_FAIL,
    payload: error,
})

export const getListChangeQCDetail = (id, callback) => ({
    type: GET_CHANGE_QC_DETAIL,
    id,
    callback,
})
export const getListChangeQCDetailSuccess = payload => ({
    type: GET_CHANGE_QC_DETAIL_SUCCESS,
    payload: payload,
})
export const getListChangeQCDetailFail = error => ({
    type: GET_CHANGE_QC_DETAIL_FAIL,
    payload: error,
})

export const addNewChangeSIDQC = payload => ({
    type: ADD_NEW_CHANGE_QC,
    payload: payload,
})
export const addNewChangeSIDQCSuccess = payload => ({
    type: ADD_NEW_CHANGE_QC_SUCCESS,
    payload: payload,
})
export const addNewChangeSIDQCFail = error => ({
    type: ADD_NEW_CHANGE_QC_FAIL,
    payload: error,
})

export const updateChangeQC = payload => ({
    type: UPDATE_CHANGE_QC,
    payload: payload,
})
export const updateChangeQCSuccess = payload => ({
    type: UPDATE_CHANGE_QC_SUCCESS,
    payload: payload,
})
export const updateChangeQCFail = error => ({
    type: UPDATE_CHANGE_QC_FAIL,
    payload: error,
})

export const deleteChangeQC = payload => ({
    type: DELETE_CHANGE_QC,
    payload: payload,
})
export const deleteChangeQCSuccess = payload => ({
    type: DELETE_CHANGE_QC_SUCCESS,
    payload: payload,
})
export const deleteChangeQCFail = error => ({
    type: DELETE_CHANGE_QC_FAIL,
    payload: error,
})
