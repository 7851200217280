import {
  ADD_MACHINE_QC,
  ADD_MACHINE_QC_FAIL,
  ADD_MACHINE_QC_SUCCESS,
  DELETE_MACHINE_QC,
  DELETE_MACHINE_QC_FAIL,
  DELETE_MACHINE_QC_SUCCESS
} from "./actionTypes"

// Add MachineQC
export const addMachineQC = (machine, callback) => ({
  type: ADD_MACHINE_QC,
  payload: machine,
  callback
})

export const addMachineQCSuccess = machine => ({
  type: ADD_MACHINE_QC_SUCCESS,
  payload: machine,
})

export const addMachineQCFail = error => ({
  type: ADD_MACHINE_QC_FAIL,
  payload: error,
})

export const deleteMachineQC = (machines, callback) => ({
  type: DELETE_MACHINE_QC,
  payload: machines,
  callback
})

export const deleteMachineQCSuccess = machines => ({
  type: DELETE_MACHINE_QC_SUCCESS,
  payload: machines,
})

export const deleteMachineQCFail = error => ({
  type: DELETE_MACHINE_QC_FAIL,
  payload: error,
})
