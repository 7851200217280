import { ButtonDropdownGroup, Check, CustomButton } from "components/Common"
import { permissionType } from "constant"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
const HeaderButtons = ({ resource, onChangeSIDQCClick, onReloadClick, t }) => {
    const isSIDQC = true;
    return (
        <>
            <ButtonDropdownGroup onDeleteClick={() => { }} onCloneClick={() => { }}>
                {/* <Check permission={permissionType.U} resource={resource}>
                    <CustomButton color="primary" onClick={onReloadClick} outline>
                        {t("qcResultPage:Reload")}
                    </CustomButton>
                </Check> */}
                <Check permission={permissionType.C} resource={resource}>
                    <CustomButton
                        color="primary"
                        onClick={() => onChangeSIDQCClick(isSIDQC)}
                        // disabled={isStatusUpdating}
                        outline
                    >
                        {t("resultPage:Change SID QC")}
                    </CustomButton>
                </Check>
            </ButtonDropdownGroup>
        </>
    )
}

HeaderButtons.propTypes = {
    resource: PropTypes.string.isRequired,
    onReloadClick: PropTypes.func.isRequired,
}

export default withTranslation(["common", "qcResultPage"])(HeaderButtons)
