import { call, put, takeEvery } from "redux-saga/effects"
import {
  GET_USER_INFO,
  LOGIN_USER,
  LOGOUT_USER
} from "./actionTypes"
import { apiError, getUserInfoSuccess, loginSuccess } from "./actions"
import {
  getUserInfo,
  postLogin
} from "helpers/auth-backend/auth_backend_helper"

function* loginUser({ payload: { user, history, location, callback } }) {
  try {
    const response = yield call(postLogin, {
      username: user.email,
      password: user.password,
    })

    yield put(loginSuccess(response))

    if (callback) {
      callback(response)
      let HISInfo = JSON.parse(localStorage.getItem('patientVisitConfig'));
      if (HISInfo) {
        HISInfo.autoSID = true;
        HISInfo.connector = "HL7_HIS";
        localStorage.setItem('patientVisitConfig', JSON.stringify(HISInfo));
      }
    }

    else {
      localStorage.setItem("authUser", JSON.stringify(response))
      const { from } = location?.state || {
        from: { pathname: "/" },
      }

      history.push(from)
    }
  } catch (error) {
    console.log(error);
    const status = error?.response?.status
    if (status === 400) yield put(apiError("Tên đăng nhập hoặc mật khẩu không đúng."))
    else if (status === 502) yield put(apiError("Hệ thống gián đoạn. Vui lòng thử lại sau"))
    // else yield put(apiError(error.message))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser")
    localStorage.removeItem("userInfo")
    localStorage.removeItem("images")
    // localStorage.removeItem("defaultCollector")
    // localStorage.removeItem("selectedCollector")
    // localStorage.removeItem("size")
    // localStorage.removeItem("sizeName")
    // const patientVisitConfig = JSON.parse(localStorage.getItem("patientVisitConfig")) || {};
    // delete patientVisitConfig?.requestDate;
    // localStorage.setItem("patientVisitConfig", JSON.stringify(patientVisitConfig));
    window.location.href = "/login"
    // if (history) history.push("/login")
    // else window.location.reload()
  } catch (error) {
    // yield put(apiError(error))
  }
}


function* getUserInformation({ payload: { token, callback } }) {
  try {
    const response = yield call(getUserInfo, token)

    yield put(getUserInfoSuccess(response))

    if (callback) callback(response)
  } catch (error) {

    yield put(apiError(error.message))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
  yield takeEvery(GET_USER_INFO, getUserInformation)
}

export default authSaga
