import {
    UPDATE_TEST_QC, UPDATE_TEST_QC_FAIL,
    UPDATE_TEST_QC_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
    tests: [],
    loadingTest: false,
    updatingTest: false,
    updateTestTime: new Date(),
    error: {},
    test: {},
}

const TestQC = (state = INIT_STATE, action) => {
    switch (action.type) {
        case UPDATE_TEST_QC:
            return {
                ...state,
            }
        case UPDATE_TEST_QC_SUCCESS:
            return {
                ...state,
            }
        case UPDATE_TEST_QC_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        default:
            return state
    }
}

export default TestQC