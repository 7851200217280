import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import ChangeSIDQCTable from "./ChangeSIDQCTable"

import {
  Check,
  ConfirmModal,
  CustomButton,
  TitleAndTable,
  WarningModal,
} from "components/Common"
import {
  onDelete,
  onDeleteToggle,
  selectCheckboxHandler,
} from "helpers/utilities"

import { ModuleIds, permissionType } from "constant"
import { withTranslation } from "react-i18next"
import {
  addNewChangeSIDQC,
  deleteChangeQC,
  getListChangeQC,
  getListChangeQCDetail,
  updateChangeQC,
} from "store/laboratoryIQC/ChangeSIDQC/actions"
import ChangeSIDQCModal from "./Modal/ChangeSIDQCModal"

const RESOURCE = ModuleIds.ChangeSIDQC

const ChangeSIDQC = ({
  onGetListChangeQC,
  changeQC,
  paging,
  updating,
  updatedTime,
  changeQCs,
  loading,
  onGetListChangeQCDetail,
  onCreateChangeQC,
  onUpdateChangeQC,
  onDeleteChangeQC,
  t,
}) => {
  const [confirmModal, setConfirmModal] = useState(false)
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(true)
  const [row, setRow] = useState({})
  const [rowDelete, setRowDelete] = useState({})
  const [rows, setRows] = useState([])
  const [warningModal, setWarningModal] = useState(false)
  const [keySearch, setKeySearch] = useState("")
  const [isClone, setIsClone] = useState(false)

  const toggle = () => {
    setModal(prev => !prev)
  }

  const onCloneHandler = param => {
    const id = param.id || row?.id
    if (param) setRow(param)
    if (id) {
      setIsEdit(false)
      setIsClone(true)
      toggle()
    } else {
      setWarningModal(true)
    }
  }
  const onEditHandler = (e, changeQc, param) => {
    const id = changeQc || row?.id
    if (id) {
      onGetListChangeQCDetail(id)
      setRow(param)
      setIsEdit(true)
      toggle()
    } else {
      setWarningModal(true)
    }
  }

  const resetState = () => {
    setRow({})
    setRows([])
  }

  const onDeleteToggleHandler = (e, test) => {
    onDeleteToggle({
      rows,
      row: test || rowDelete,
      setConfirmModal,
      setWarningModal,
      setRowDelete,
    })
    if (confirmModal) {
      setRowDelete({})
    }
  }

  const onDeleteMultipleRows = rowsState => {
    onDeleteChangeQC({
      changeQC: rowsState,
      callback: () => {
        afterSubmit()
        resetState()
      },
    })
  }

  const onDeleteSingleRow = rowState => {
    onDeleteChangeQC({
      changeQC: rowState,
      callback: () => {
        afterSubmit()
        resetState()
      },
    })

    setRowDelete({})
    setRow({})
  }

  const onDeleteTestHandler = () => {
    onDelete({
      rowDelete,
      rows,
      onDeleteSingleRow,
      onDeleteMultipleRows,
    })
    setConfirmModal(false)
  }
  /**Get selected row and set to state
   *
   */
  const onSelectCheckbox = (row, isSelected) => {
    const { rowsState, currentRow } = selectCheckboxHandler(
      rows,
      row,
      isSelected
    )
    setRows(rowsState)
    setRow(currentRow)
  }

  const onSelectAllCheckbox = rows => {
    setRows(rows)
    if (rows.length < 1) setRow({})
    else setRow(rows[rows.length - 1])
  }

  const onRefreshHandler = () => {
    onGetListChangeQC({ page: 1, search: keySearch })
  }

  const onSearch = searchText => {
    setKeySearch(searchText)
    onGetListChangeQC({ page: 1, search: searchText })
  }

  const onSizePerPageChange = size => {
    onGetListChangeQC({ page: 1, size })
  }

  const onPageChange = page => {
    onGetListChangeQC({ page })
  }

  const onSubmitFilter = values => {
    onGetListChangeQC({ ...values, page: 1 })
  }

  const afterSubmit = () => {
    setModal(false)
    onRefreshHandler()
  }
  const onSubmit = (e, val) => {
    if (isEdit) {
      val.id = changeQC.id
      onUpdateChangeQC({ changeQC: val, callback: afterSubmit })
    } else {
      onCreateChangeQC({ changeQC: val, callback: afterSubmit })
    }
  }
  useEffect(() => {
    onRefreshHandler()
  }, [])

  return (
    <React.Fragment>
      <TitleAndTable
        table={() => (
          <ChangeSIDQCTable
            dataConfigs={changeQCs}
            onSelect={onSelectCheckbox}
            onSelectAll={onSelectAllCheckbox}
            onSearch={onSearch}
            onRefresh={onRefreshHandler}
            onPageChange={onPageChange}
            paging={paging}
            onSizePerPageChange={onSizePerPageChange}
            onSubmitFilter={onSubmitFilter}
            onEdit={onEditHandler}
            onDelete={onDeleteToggleHandler}
            onClone={onCloneHandler}
            loading={loading}
            updatedTime={updatedTime}
          />
        )}
        resource={RESOURCE}
        onEdit={onEditHandler}
        onDelete={onDeleteToggleHandler}
        isDisableClone
        isHideEdit
        isDropDown
        title={t("QC Configuration")}
        subtitle={t("SID QC Change Configuration")}
        buttons={() => (
          <Check permission={permissionType.C} resource={RESOURCE}>
            <CustomButton
              color="primary"
              onClick={() => {
                setIsEdit(false)
                setIsClone(false)
                toggle()
              }}
              outline
            >
              {t("common:Add")}
            </CustomButton>
          </Check>
        )}
      />
      <WarningModal
        modal={warningModal}
        onToggle={() => setWarningModal(prev => !prev)}
        message={t("message:SelectRowWarning")}
      />
      <ConfirmModal
        modal={confirmModal}
        title={`${t("common:Delete")}`}
        message={t("message:DeleteConfirm")}
        onToggle={onDeleteToggleHandler}
        onDelete={onDeleteTestHandler}
      />
      <ChangeSIDQCModal
        modal={modal}
        isEdit={isEdit}
        onValidSubmit={(e, val) => {
          onSubmit(e, val)
        }}
        toggle={toggle}
        data={!isEdit ? (isClone ? row : {}) : changeQC}
      />
    </React.Fragment>
  )
}

ChangeSIDQC.propTypes = {
  t: PropTypes.any,
}

const mapStateToProps = ({ changeQCs }) => ({
  changeQC: changeQCs.changeQC,
  paging: changeQCs.paging,
  loading: changeQCs.loading,
  updating: changeQCs.updating,
  updatedTime: changeQCs.updatedTime,
  changeQCs: changeQCs.changeQCs,
})

const mapDispatchToProps = dispatch => ({
  onGetListChangeQC: payload => dispatch(getListChangeQC(payload)),
  onGetListChangeQCDetail: payload => dispatch(getListChangeQCDetail(payload)),
  onCreateChangeQC: payload => dispatch(addNewChangeSIDQC(payload)),
  onUpdateChangeQC: payload => dispatch(updateChangeQC(payload)),
  onDeleteChangeQC: payload => dispatch(deleteChangeQC(payload)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation(["changeSIDQCPage", "message"])(ChangeSIDQC)))
