import { ConfirmModal2, CustomBootstrapInlineTable, FormSwitch, WarningModal } from "components/Common"
import CustomBootstrapTableAsync from "components/Common/CustomTable/CustomBootstrapTableAsync"
import { ModuleIds, TestRequest_Test_Type, parameterCode } from "constant"
import { getCodesByParameterId } from "helpers/app-backend"
import { CheckEmptyAllValueObject, CheckExistObjectInArray, getI18nextLng, selectCheckboxHandler } from "helpers/utilities"
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react"
import Editable from "react-bootstrap-editable"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { getFullTestInsuranceSettings, getFullTestTATSettings, getInsuranceSettings, getTATSettings, setScrollLeftInsuranceSettings, setScrollLeftTATSettings } from "store/actions"
import InsurancePopover from "../InsurancePopover"
import InsuranceSettingsTableButtons from "./InsuranceSettingsTableButtons"

const RESOURCE = ModuleIds.Insurance

let expandGlobal = []
let itemActiveGlobal = {}
let pageGlobal = 0;
let dataGlobal = []
let initPaging = false
let rowSelected = []
const InsuranceSettingsTable = forwardRef(
  ({
    onSort,
    onGetFullTestInsuranceSettings,
    itemActive,
    updatedTime,
    onGetInsuranceSettings,
    rules,
    onSetScrollLeftInsuranceSettings,
    onSubmit,
    t,
  }, ref) => {
    let lang = getI18nextLng()
    const [searchText, setSearchText] = useState('')
    const [warningModal, setWarningModal] = useState(false)
    const [confirmModal, setConfirmModal] = useState(false)
    const size = 15;
    const [dataSource, setDataSource] = useState([])
    const [columnExpand, setColumnExpand] = useState([]);
    const [isExpand, setIsExpand] = useState(false)
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false)
    const [row, setRow] = useState(false)
    const [rows, setRows] = useState([])
    const [insuranceCodes, setInsuranceCodes] = useState([])
    const [insuranceTypes, setInsuranceTypes] = useState([])
    useImperativeHandle(ref, () => ({
      updateSuccess: () => {
        setConfirmModal(false)
        setRows([])
        setRow({})
      },
    }));
    useEffect(() => {
      onSetScrollLeftInsuranceSettings(0)

      return () => {
        onSetScrollLeftInsuranceSettings(0)
      }
    }, [])

    const onSelectCheckbox = (row, isSelected) => {
      const { rowsState, currentRow } = selectCheckboxHandler(
        rows,
        row,
        isSelected
      )
      setRows(rowsState)
      setRow(currentRow)
    }

    const onSelectAllCheckbox = rows => {
      setRows(rows)
      if (rows.length < 1) setRow({})
      else setRow(rows[rows.length - 1])
    }

    const checkAndTab = (e, idHTML, key, isnumber = false) => {
      if (e.key === 'Enter' || e.key === "Tab") {
        const fields =
          Array.from(document.getElementsByClassName(key)) ||
          []
        const position = fields.findIndex(x => x.id == `${idHTML}`)
        if (position >= 0) {
          setTimeout(() => {
            let el = fields[position + 1]
            if (el) {
              let tmpClick = $(`#${el.id} > form a`)
              if (tmpClick && tmpClick.length > 0)
                tmpClick[0].click()
              else {
                tmpClick = $(`#${el.id} > .inline-field`)
                if (tmpClick && tmpClick.length > 0)
                  tmpClick[0].click()
              }
            }
          }, 0);

        }
      } else if (isnumber) {
        if (e.key == '.' && e.target.value.includes('.')) {
          e.preventDefault()
        }
        else if (isNaN(e.key) && !(e.key == 'Backspace'))
          e.preventDefault()
      }

    }

    const getInsuranceTypes = async () => {
      const query = { lang }
      const res = await getCodesByParameterId(parameterCode.INSURANCE_TYPE, query)
      res?.map(_item => {
        _item.value = _item.code
        _item.label = _item.message
        return _item
      })
      setInsuranceTypes(res)
    }

    useEffect(() => {
      getInsuranceTypes()
    }, [])

    const arrFileld = ['insuranceCode', 'insuranceType', 'priceService', 'priceInsurance', 'payPercent', 'discount']
    const openEditField = (id) => {
      for (let index = 0; index < arrFileld.length; index++) {
        const element = arrFileld[index];
        let item = document.querySelector(`#resultBlurItemFocus${id}${element}>.inline-field`)
        if (item)
          item.click()
      }
    }

    const getEditText = (value) => {
      if (value && value?.trim() != "")
        return Number(value)?.toLocaleString('en-US')
      return "---"
    }
    let columns = [
      {
        dataField: "stt",
        text: t("Service Name"),
        style: { minWidth: 350, maxWidth: 350, width: 350 },
        headerStyle: { minWidth: 350, maxWidth: 350, width: 350 },
        classes: 'selection-cell-tat',
        headerClasses: 'selection-cell-header-tat',
        formatter: (cellContent, item, index) => {
          if (item.id == -1)
            return <span id={`watch_end_of_document`}>End of document</span>
          else
            return <div className="d-flex">
              <span style={{whiteSpace:'nowrap'}}>{index + 1} - </span>
              {item.type === TestRequest_Test_Type.PROFILE && (
                <div className="px-2 d-flex badge-profile" style={{ marginRight: 5 }}>
                  <span>{t('Profile')}</span>
                </div>
              )}
              {item.type === TestRequest_Test_Type.PROFILE_GROUP && (
                <div className="px-2 d-flex badge-group" style={{ marginRight: 5 }}>
                  <span>{t('Group')}</span>
                </div>
              )}

              <span id={`has-children${item.id}`} className={(item.children && item.children.length > 0) ? "has-children" : ""} style={{}} >{item?.testCode} - {item?.testName}</span>
            </div>
        }
      },
      {
        dataField: "insuranceCode",
        text: t("Insurance Code"),
        style: { minWidth: 210, maxWidth: 210, width: 210 },
        formatter: (cellContent, item, index) => {
          const idHTML = `resultBlurItemFocus${item.id + '-' + index}insuranceCode`
          return <React.Fragment>
            <div
              className={"resultBlurItemFocus"}
              onClick={e => {
                e.stopPropagation()
              }}
              id={idHTML}
              onKeyDown={e => {
                checkAndTab(e, idHTML, 'resultBlurItemFocus')
              }}
              onBlur={() => {
                $(`#child-${item.id}-insuranceCode`).click()
              }}
            >
              <Editable
                // alwaysEditing={true}
                disabled={false}
                editText={item.insuranceCode || "---"}
                initialValue={item.insuranceCode || ""}
                id={item.id}
                isValueClickable={false}
                mode="inline"
                placement="top"
                showText={false}
                type="textfield"
                renderConfirmElement={confirmElement(`child-${item.id}-insuranceCode`)}
                renderCancelElement={cancelElement}
                onSubmit={value => {
                  onSubmitRule(item, value, "insuranceCode")
                }}
              />
            </div>
          </React.Fragment>
        },
      },
      {
        dataField: "insuranceType",
        text: t("Insurance Type"),
        style: { minWidth: 210, maxWidth: 210, width: 210 },
        formatter: (cellContent, item, index) => {
          const idHTML = `resultBlurItemFocus${item.id + '-' + index}insuranceType`
          return <React.Fragment>
            <div
              className={"resultBlurItemFocus"}
              onClick={e => {
                e.stopPropagation()
              }}
              id={idHTML}
              onKeyDown={e => {
                checkAndTab(e, idHTML, 'resultBlurItemFocus')
              }}
              onBlur={() => {
                $(`#child-${item.id}-insuranceType`).click()
              }}
            >
              <InsurancePopover
                type={"select"}
                lineIndex={index}
                namepopover="namepopover-insuranceType"
                alwaysEditing={true}
                options={insuranceTypes || []}
                title={insuranceTypes.find(x => x.value == item.insuranceType)?.label}
                onChange={(values) => {
                  if (values)
                    onSubmitRule(item, values.value, "insuranceType")
                }}
              />
            </div>
          </React.Fragment>
        },
      },
      {
        dataField: "priceService",
        text: t("Price (Service)"),
        style: { minWidth: 150, maxWidth: 150, width: 150 },
        formatter: (cellContent, item, index) => {
          const idHTML = `resultBlurItemFocus${item.id + '-' + index}priceService`
          return <React.Fragment>
            <div
              className={"resultBlurItemFocus"}
              onClick={e => {
                e.stopPropagation()
              }}
              id={idHTML}
              onKeyDown={e => {
                checkAndTab(e, idHTML, 'resultBlurItemFocus')
              }}
              onBlur={() => {
                $(`#child-${item.id}-priceService`).click()
              }}
            >
              <Editable
                // alwaysEditing={true}
                disabled={false}
                editText={item.priceService ? Number(item.priceService)?.toLocaleString('en-US') : "---"}
                initialValue={item.priceService || ""}
                id={item.id}
                isValueClickable={false}
                mode="inline"
                placement="top"
                showText={false}
                type="textfield"
                renderConfirmElement={confirmElement(`child-${item.id}-priceService`)}
                renderCancelElement={cancelElement}
                onSubmit={value => {
                  onSubmitRule(item, value, "priceService")
                }}
              />
            </div>
          </React.Fragment>
        },
      },
      {
        dataField: "priceInsurance",
        text: t("Price (Ins)"),
        style: { minWidth: 120, maxWidth: 120, width: 120 },
        formatter: (cellContent, item, index) => {
          const idHTML = `resultBlurItemFocus${item.id + '-' + index}priceInsurance`
          return <React.Fragment>
            <div
              className={"resultBlurItemFocus"}
              onClick={e => {
                e.stopPropagation()
              }}
              id={idHTML}
              onKeyDown={e => {
                checkAndTab(e, idHTML, 'resultBlurItemFocus')
              }}
              onBlur={() => {
                $(`#child-${item.id}-priceInsurance`).click()
              }}
            >
              <Editable
                disabled={false}
                editText={item.priceInsurance ? Number(item.priceInsurance)?.toLocaleString('en-US') : "---"}
                initialValue={item.priceInsurance || ""}
                id={item.id}
                isValueClickable={false}
                mode="inline"
                placement="top"
                showText={false}
                type="textfield"
                renderConfirmElement={confirmElement(`child-${item.id}-priceInsurance`)}
                renderCancelElement={cancelElement}
                onSubmit={value => {
                  onSubmitRule(item, value, "priceInsurance")
                }}
              />
            </div>
          </React.Fragment>
        },
      },
      {
        dataField: "payPercent",
        text: t("Pay Percent"),
        style: { minWidth: 120, maxWidth: 120, width: 120 },
        formatter: (cellContent, item, index) => {
          const idHTML = `resultBlurItemFocus${item.id + '-' + index}payPercent`
          return <React.Fragment>
            <div
              className={"resultBlurItemFocus"}
              onClick={e => {
                e.stopPropagation()
              }}
              id={idHTML}
              onKeyDown={e => {
                checkAndTab(e, idHTML, 'resultBlurItemFocus')
              }}
              onBlur={() => {
                $(`#child-${item.id}-payPercent`).click()
              }}
            >
              <Editable
                disabled={false}
                editText={item.payPercent || "---"}
                initialValue={item.payPercent || ""}
                id={item.id}
                isValueClickable={false}
                mode="inline"
                placement="top"
                showText={false}
                type="textfield"
                renderConfirmElement={confirmElement(`child-${item.id}-payPercent`)}
                renderCancelElement={cancelElement}
                onSubmit={value => {
                  onSubmitRule(item, value, "payPercent")
                }}
              />
            </div>
          </React.Fragment>
        },
      },
      {
        dataField: "discount",
        text: t("Discount"),
        style: { minWidth: 100, maxWidth: 100, width: 100 },
        formatter: (cellContent, item, index) => {
          const idHTML = `resultBlurItemFocus${item.id + '-' + index}discount`
          return <React.Fragment>
            <div
              className={"resultBlurItemFocus"}
              onClick={e => {
                e.stopPropagation()
              }}
              id={idHTML}
              onKeyDown={e => {
                checkAndTab(e, idHTML, 'resultBlurItemFocus')
              }}
              onBlur={() => {
                $(`#child-${item.id}-discount`).click()
              }}
            >
              <Editable
                disabled={false}
                editText={item.discount ? Number(item.discount)?.toLocaleString('en-US') : "---"}
                initialValue={item.discount || ""}
                id={item.id}
                isValueClickable={false}
                mode="inline"
                placement="top"
                showText={false}
                type="textfield"
                renderConfirmElement={confirmElement(`child-${item.id}-discount`)}
                renderCancelElement={cancelElement}
                onSubmit={value => {
                  onSubmitRule(item, value, "discount")
                }}
              />
            </div>
          </React.Fragment>
        },
      },
      {
        dataField: "add",
        text: <div className="text-success text-center" style={{ width: 50 }}>
        </div>,
        isDummyField: true,
        classes: "add-rule-col",
        headerClasses: "add-rule-col-headder",
        style: { width: "50px", margin: 0, paddingLeft: "0px", paddingRight: "0px", maxWidth: 50, minWidth: 50 },
        headerStyle: { width: "50px", margin: 0, paddingLeft: "0px", paddingRight: "0px", maxWidth: 50, minWidth: 50 },
        formatter: (cellContent, item, index) => (
          <div className="text-center"
            onClick={(e) => {
              if (item.children && item.children.length > 0 && expandGlobal.findIndex(x => x == item.id) >= 0)
                e.stopPropagation()
              else {
                let el = document.getElementsByClassName('expand-cell')[index]
                setTimeout(() => {
                  if (el) {
                    let checkExpand = el.getElementsByClassName("fa-angle-up")
                    if (checkExpand.length == 0) {
                      el.click()
                    }
                  }
                }, 200);
              }
              let tmpData = dataGlobal
              if (!tmpData[index].children) {
                tmpData[index].children = [{
                  "id": new Date().getTime(),
                  "parentId": item.id,
                  "testCode": item.testCode,
                  "testName": item.testName,
                  "insuranceCode": item.insuranceCode,
                  "insuranceType": '',
                  "priceService": '',
                  "priceInsurance": '',
                  "payPercent": '',
                  "discount": '',
                }]
              }
              else {
                tmpData[index].children.push({
                  "id": new Date().getTime(),
                  "parentId": item.id,
                  "testCode": item.testCode,
                  "testName": item.testName,
                  "insuranceCode": item.insuranceCode,
                  "insuranceType": '',
                  "priceService": '',
                  "priceInsurance": '',
                  "payPercent": '',
                  "discount": '',
                })
              }
              setDataSource([...tmpData])
              // setTimeout(() => {
              //   document.getElementById("btn-blur-insurance").focus()
              // }, 100);
            }}
          ><i className="fas fa-plus text-success"></i></div>
        ),
      }
    ]

    const columnChild = [
      {
        dataField: "x",
        text: t("x"),
        style: { minWidth: 32, maxWidth: 32, width: 32 },
        headerStyle: { minWidth: 32, maxWidth: 32, width: 32 },
      },
      {
        dataField: "stt",
        text: t("STT"),
        style: { minWidth: 50, maxWidth: 50, width: 50 },
        headerStyle: { minWidth: 50, maxWidth: 50, width: 50 },
        align: 'center',
        formatter: (cellContent, item, index) => {
          return <React.Fragment>
            <div className="tooltip-custom">
              <span onClick={() => {
                let newChecks = [].push(item);
                rowSelected = newChecks;
                cloneData(item)
              }}>
                <i className="fas fa-copy" style={{ color: "#74788d" }}></i>
              </span>
              <span className="tooltiptext-custom-button-clone-tat">
                {t("common:Clone")}
              </span>
            </div>
          </React.Fragment>
        }
      },
      {
        dataField: "testName",
        text: t("Test Name"),
        style: { minWidth: 350, maxWidth: 350, width: 350 },
      },
      {
        dataField: "insuranceCode",
        text: t("Insurance Code"),
        style: { minWidth: 210, maxWidth: 210, width: 210 },
        formatter: (cellContent, item, index) => {
          return <React.Fragment>
            <span>{item.insuranceCode}</span>
          </React.Fragment>
        },
      },
      {
        dataField: "insuranceType",
        text: t("Insurance Type"),
        style: { minWidth: 210, maxWidth: 210, width: 210 },
        formatter: (cellContent, item, index) => {
          const idHTML = `resultBlurItemFocus${item.id + '-' + index}insuranceType`
          return <React.Fragment>
            <div
              className={"resultBlurItemFocus"}
              onClick={e => {
                e.stopPropagation()
              }}
              id={idHTML}
              onKeyDown={e => {
                checkAndTab(e, idHTML, 'resultBlurItemFocus')
              }}
              onBlur={() => {
                $(`#child-${item.id}-insuranceType`).click()
              }}
            >
              <InsurancePopover
                type={"select-search"}
                lineIndex={index}
                namepopover="namepopover-insuranceType"
                // alwaysEditing={true}
                options={insuranceTypes || []}
                title={insuranceTypes.find(x => x.value == item.insuranceType)?.label}
                onChange={(values) => {
                  if (values)
                    onSubmitRuleChild(item, values.value, "insuranceType")
                }}
              />
            </div>
          </React.Fragment>
        },
      },
      {
        dataField: "priceService",
        text: t("Price (Service)"),
        style: { minWidth: 150, maxWidth: 150, width: 150 },
        formatter: (cellContent, item, index) => {
          const idHTML = `resultBlurItemFocus${item.id + '-' + index}priceService`
          return <React.Fragment>
            <div
              className={"resultBlurItemFocus"}
              onClick={e => {
                e.stopPropagation()
              }}
              id={idHTML}
              onKeyDown={e => {
                checkAndTab(e, idHTML, 'resultBlurItemFocus', true)
              }}
              onBlur={() => {
                $(`#child-${item.id}-priceService`).click()
              }}
            >
              <Editable
                //alwaysEditing={true}
                disabled={false}
                editText={getEditText(item.priceService)}
                initialValue={item.priceService || ""}
                id={item.id}
                isValueClickable={false}
                mode="inline"
                placement="top"
                showText={false}
                type="textfield"
                renderConfirmElement={confirmElement(`child-${item.id}-priceService`)}
                renderCancelElement={cancelElement}
                onSubmit={value => {
                  onSubmitRuleChild(item, value, "priceService")
                }}
              />
            </div>
          </React.Fragment>
        },
      },
      {
        dataField: "priceInsurance",
        text: t("Price (Ins)"),
        style: { minWidth: 120, maxWidth: 120, width: 120 },
        formatter: (cellContent, item, index) => {
          const idHTML = `resultBlurItemFocus${item.id + '-' + index}priceInsurance`
          return <React.Fragment>
            <div
              className={"resultBlurItemFocus"}
              onClick={e => {
                e.stopPropagation()
              }}
              id={idHTML}
              onKeyDown={e => {
                checkAndTab(e, idHTML, 'resultBlurItemFocus')
              }}
              onBlur={() => {
                $(`#child-${item.id}-priceInsurance`).click()
              }}
            >
              <Editable
                // alwaysEditing={true}
                disabled={false}
                editText={item.priceInsurance ? Number(item.priceInsurance)?.toLocaleString('en-US') : "---"}
                initialValue={item.priceInsurance || ""}
                id={item.id}
                isValueClickable={false}
                mode="inline"
                placement="top"
                showText={false}
                type="textfield"
                renderConfirmElement={confirmElement(`child-${item.id}-priceInsurance`)}
                renderCancelElement={cancelElement}
                onSubmit={value => {
                  onSubmitRuleChild(item, value, "priceInsurance")
                }}
              />
            </div>
          </React.Fragment>
        },
      },
      {
        dataField: "payPercent",
        text: t("Pay Percent"),
        style: { minWidth: 120, maxWidth: 120, width: 120 },
        formatter: (cellContent, item, index) => {
          const idHTML = `resultBlurItemFocus${item.id + '-' + index}payPercent`
          return <React.Fragment>
            <div
              className={"resultBlurItemFocus"}
              onClick={e => {
                e.stopPropagation()
              }}
              id={idHTML}
              onKeyDown={e => {
                checkAndTab(e, idHTML, 'resultBlurItemFocus')
              }}
              onBlur={() => {
                $(`#child-${item.id}-payPercent`).click()
              }}
            >
              <Editable
                // alwaysEditing={true}
                disabled={false}
                editText={item.payPercent || "---"}
                initialValue={item.payPercent || ""}
                id={item.id}
                isValueClickable={false}
                mode="inline"
                placement="top"
                showText={false}
                type="textfield"
                renderConfirmElement={confirmElement(`child-${item.id}-payPercent`)}
                renderCancelElement={cancelElement}
                onSubmit={value => {
                  onSubmitRuleChild(item, value, "payPercent")
                }}
              />
            </div>
          </React.Fragment>
        },
      },
      {
        dataField: "discount",
        text: t("Discount"),
        style: { minWidth: 100, maxWidth: 100, width: 100 },
        formatter: (cellContent, item, index) => {
          const idHTML = `resultBlurItemFocus${item.id + '-' + index}discount`
          return <React.Fragment>
            <div
              className={"resultBlurItemFocus"}
              onClick={e => {
                e.stopPropagation()
              }}
              id={idHTML}
              onKeyDown={e => {
                checkAndTab(e, idHTML, 'resultBlurItemFocus')
              }}
              onBlur={() => {
                $(`#child-${item.id}-discount`).click()
              }}
            >
              <Editable
                // alwaysEditing={true}
                disabled={false}
                editText={item.discount ? Number(item.discount)?.toLocaleString('en-US') : "---"}
                initialValue={item.discount || ""}
                id={item.id}
                isValueClickable={false}
                mode="inline"
                placement="top"
                showText={false}
                type="textfield"
                renderConfirmElement={confirmElement(`child-${item.id}-discount`)}
                renderCancelElement={cancelElement}
                onSubmit={value => {
                  onSubmitRuleChild(item, value, "discount")
                }}
              />
            </div>
          </React.Fragment>
        },
      },
      {
        dataField: "action",
        text: "",
        isDummyField: true,
        style: { width: "50px", margin: 0, paddingLeft: "0px", paddingRight: "0px", maxWidth: 50, minWidth: 50, textAlign: 'center' },
        headerStyle: { width: "50px", margin: 0, paddingLeft: "0px", paddingRight: "0px", maxWidth: 50, minWidth: 50, textAlign: 'center' },
        formatter: (cellContent, item, index) => (
          <span onClick={() => {
            onDeleteRuleChild(item)
          }}>
            <i className="mdi mdi-trash-can font-size-16 text-danger me-1" />
          </span>
        ),
      },
    ]

    const confirmElement = id => (
      <button
        id={id}
        type="submit"
        className="btn btn-outline-primary editable-submit btn-sm ms-2 me-1 d-none"
      >
        <i className="mdi mdi-check"></i>
      </button>
    )

    const cancelElement = (
      <button
        type="button"
        className="btn btn-outline-primary editable-cancel btn-sm me-3 d-none"
      >
        <i className="mdi mdi-close"></i>
      </button>
    )


    useEffect(() => {
      dataGlobal = [...dataSource]
    }, [dataSource])

    const onSubmitRule = (item, value, name) => {
      item[name] = value
      const dataReplace = dataGlobal.find(obj => obj.id === item.id)
      Object.assign(dataReplace, item)
      setDataSource([...dataGlobal])
    }

    const onSubmitRuleChild = (item, value, name, key = 'children') => {
      let children = dataGlobal.find(x => x.id == item.parentId)
      if (children) {
        const dataReplace = children[key].find(obj => obj.id === item.id)
        dataReplace[name] = value
        setDataSource([...dataGlobal])

      }
      setTimeout(() => {
        // document.getElementById("btn-blur-insurance").focus()
      }, 100);

    }

    const onDeleteRuleChild = (item, key = 'children') => {
      let children = dataSource.find(x => x.id == item.parentId)
      if (children) {
        if (children[key].length == 1) {
          setColumnExpand(expandGlobal.filter(x => x != item.parentId))
        }
        children[key] = children[key].filter(obj => obj.id !== item.id)
        setDataSource([...dataSource])
      }
    }

    useEffect(() => {
      refresh()
      setRows([])

    }, [itemActive])

    const refresh = (searchText = '') => {
      rowSelected = []
      initPaging = false
      if (!itemActive.value)
        return
      setLoading(true)
      onGetFullTestInsuranceSettings({ inUse: true, search: searchText }, () => {
        onGetInsuranceSettings('', () => {
          setLoading(false)
        })
      })
      itemActiveGlobal = itemActive
      //reset table
      let el = document.getElementById("resizeMeAsync")
      if (el) {
        el.scrollIntoView(true)
        setTimeout(() => {
          setPage(0)
          pageGlobal = 0
        }, 100);
      }
    }
    useEffect(() => {
      setDataSource(ConvertDataToChild(rules))
    }, [rules])

    const onSearch = () => {
      refresh(searchText)
    }

    const onSearchTextChange = (e) => {
      setSearchText(e.target.value)
    }

    const onClearSearchTextChange = () => {
      setSearchText('')
      refresh('')
    }

    const ConvertDataToChild = (vals) => {
      let res = []
      for (let index = 0; index < vals.length; index++) {
        const row = vals[index];
        const ind = res.findIndex(x => x.testCode == row.testCode)
        if (ind >= 0) {
          res[ind].children.push({
            "id": new Date().getTime(),
            "testCode": res[ind].parentId,
            "testName": res[ind].testName,
            "insuranceCode": row.insuranceCode,
            "insuranceType": row.insuranceType,
            "priceService": row.priceService,
            "priceInsurance": row.priceInsurance,
            "payPercent": row.payPercent,
            "discount": row.discount,
            "enable": !!row.enable,
            "type": res[ind].type,
          })
        }
        else {
          const child = {
            "insuranceCode": row.insuranceCode,
            "insuranceType": row.insuranceType,
            "priceService": row.priceService,
            "priceInsurance": row.priceInsurance,
            "payPercent": row.payPercent,
            "discount": row.discount,
          }
          let check = CheckEmptyAllValueObject(child)
          let val = {
            "id": row.id,
            "testCode": row.testCode,
            "testName": row.testName,
            "selected": row.selected,
            "insuranceCode": row.insuranceCode,
            "insuranceType": row.insuranceType || '',
            "priceService": row.priceService,
            "priceInsurance": row.priceInsurance,
            "payPercent": row.payPercent,
            "discount": row.discount,
            "enable": !!row.enable,
            "children": row.children || [],
            "type": row.type,
          }
          // if (check == false)
          //   val.children.push({
          //     "id": new Date().getTime(),
          //     "parentId": row.id,
          //     "insuranceCode": row.insuranceCode,
          //     "insuranceType": row.insuranceType,
          //     "priceService": row.priceService,
          //     "priceInsurance": row.priceInsurance,
          //     "payPercent": row.payPercent,
          //     "discount": row.discount,
          //     "enable": !!row.enable,
          //   })
          res.push(val)
        }
      }
      return res;
    }

    const UpdateExpandColumn = (code, row) => {
      let children = row['children'] || []
      if (children.length == 0)
        return;
      let result = columnExpand
      let ind = result.findIndex(x => x == code)
      if (ind < 0) {
        result.push(code)
      }
      else {
        result = result.filter(x => x != code)
      }
      setColumnExpand([...result])
    }

    const getNonExpand = () => {
      let res = []
      res = dataSource.filter(x => !x.children || x.children.length == 0).map(x => x.id)
      res.push(-1)
      return res
    }

    const expandRow = {
      renderer: (row, rowIndex) => {
        return (<React.Fragment>
          {row.children ?
            <div className="table-expand-custom-header tableinline-scrollable table-expand-custom-child">
              <CustomBootstrapInlineTable
                columns={columnChild}
                data={[...row.children] || []}
                isScrollable
                searchText={""}
                tableHeaderClass={"col-hidden"}
                keyField={"id"}
                isHover={false}
              />
            </div>
            : null
          }
        </React.Fragment>)
      },
      parentClassName: (isExpanded, row, rowIndex) => {
        return columnExpand.findIndex(x => x == row.id) >= 0 ? 'row-expanded' : 'row-noneexpanded'
      },
      // expanded: columnExpand,
      nonExpandable: getNonExpand(),
      showExpandColumn: !(itemActiveGlobal.Key == 1 && itemActiveGlobal.parent.ID_Group == "1"),
      expandHeaderColumnRenderer: ({ isAnyExpands }) => {
        if (isAnyExpands) {
          return <b style={{ color: '#000' }}><i className="fas fa-angle-up"></i></b>;
        }
        return <b style={{ color: '#000' }}><i className="fas fa-angle-down"></i></b>;
      },
      expandColumnRenderer: ({ expanded }) => {
        if (expanded) {
          return (
            <b style={{ color: '#000' }}><i className="fas fa-angle-up"></i></b>
          );
        }
        return (
          <b style={{ color: '#000' }}><i className="fas fa-angle-down"></i></b>
        );
      }
    };

    const io = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        setTimeout(() => {
          checkHasChildren()
        }, 100);
        if (!entry.isIntersecting) {
          return;
        }
        pageGlobal++;
        setPage(pageGlobal)
      });
    });

    useEffect(() => {
      if (!initPaging && dataSource.length > 0) {
        initPaging = true
        setTimeout(() => {
          let elEnd = document.getElementById(`watch_end_of_document`)
          if (elEnd) {
            io.observe(elEnd);
          }
        }, 100);
      }
      checkHasChildren()
    }, [dataSource])

    const checkHasChildren = () => {
      if (dataSource.length > 0) {
        const arr = dataSource.filter(x => x.children && x.children.length > 0)
        for (let index = 0; index < arr.length; index++) {
          try {
            let e = document.getElementById(`has-children${arr[index].id}`)
            if (e) {
              e.parentElement.parentElement.children[0].children[0].style.color = 'blue'
            }
          } catch (error) {

          }
        }
      }
    }
    const getDataDisplay = () => {
      if (dataSource?.length > 0) {
        let res = [...dataSource].slice(0, size * (page + 1))
        res.push({ id: -1, testCode: '' })
        return res
      }
    }

    const cloneData = (itemClone) => {
      let tmpData = dataGlobal
      const index = tmpData.findIndex(x => x.id == itemClone.parentId)
      if (index >= 0) {
        const item = tmpData[index].children.find(x => x.id == itemClone.id)
        if (item) {
          tmpData[index].children.push({
            "id": new Date().getTime(),
            "parentId": item.parentId,
            "testCode": item.testCode,
            "testName": item.testName,
            "insuranceCode": item.insuranceCode,
            "insuranceType": item.insuranceType || '',
            "priceService": item.priceService,
            "priceInsurance": item.priceInsurance,
            "payPercent": item.payPercent,
            "discount": item.discount,
            "type": res[ind].type,
          })
          setDataSource([...tmpData])
        }
      }
    }

    const getDataSunmit = () => {
      let tmp = dataSource
      tmp = tmp.filter(x => rows.findIndex(a => a.id == x.id) >= 0)
      let res = []
      for (let index = 0; index < tmp.length; index++) {
        const element = tmp[index];
        const parentVal = {
          "expression": element.expression || '1==1',
          "enable": !!element.enable,
          "testCode": element.testCode,
          "insuranceCode": element.insuranceCode || '',
          "insuranceType": element.insuranceType || '',
          "priceService": element.priceService || '',
          "priceInsurance": element.priceInsurance || '',
          "payPercent": element.payPercent || '',
          "discount": element.discount || '',
        }
        res.push(parentVal)
        if (element.children && element.children.length > 0) {
          for (let indexChild = 0; indexChild < element.children.length; indexChild++) {
            const x = element.children[indexChild];
            const newVal = {
              "expression": x.expression || '1==1',
              "enable": !!x.enable,
              "testCode": element.testCode,
              "insuranceCode": x.insuranceCode || '',
              "insuranceType": x.insuranceType || '',
              "priceService": x.priceService || '',
              "priceInsurance": x.priceInsurance || '',
              "payPercent": x.payPercent || '',
              "discount": x.discount || '',
            }
            if (!CheckEmptyAllValueObject(newVal)) {
              if (!CheckExistObjectInArray(newVal, res)) {
                res.push(newVal)
              }
            }
          }
        }
      }
      return { rules: res }
    }
    return (
      <>
        <button id="btn-blur-insurance">&nbsp;</button>
        <div style={{ backgroundColor: 'white' }} className={`table-insurance-settings table-expand-custom ${dataSource.length === 0 ? '' : 'table-rule-management'}`}>
          <CustomBootstrapTableAsync
            updatedTime={updatedTime}
            columns={columns}
            data={getDataDisplay()}
            onSort={onSort}
            keyField="id"
            onSelect={onSelectCheckbox}
            onSelectAll={onSelectAllCheckbox}
            isEnableRefresh={false}
            resource={RESOURCE}
            striped={true}
            isScrollable={true}
            showSelectRow={true}
            isHover={false}
            rows={rows}
            row={row}
            selectedRows={rows.map(r => r.id)}
            expandRow={expandRow}
            paging={{ dataSize: dataSource.length, size: size * pageGlobal }}
            onSizePerPageChange={() => { }}
            customButtonTable={() =>
              <InsuranceSettingsTableButtons
                onSetRows={(data) => {
                  setRows(data)
                }}
                onSubmit={() => {
                  if (rows.length == 0) {
                    setWarningModal(true)
                  }
                  else {
                    setConfirmModal(true)
                  }
                }}
                data={dataSource}
                onRefresh={() => refresh()}
                onSearch={(e) => {
                  onSearch()
                }}
                t={t}
                loading={loading}
                searchText={searchText}
                onChangeSearchText={onSearchTextChange}
                onClearSearchText={onClearSearchTextChange}
              />
            }
            onScroll={(e) => {
              onSetScrollLeftInsuranceSettings({
                left: e.target.scrollLeft,
                top: e.target.scrollTop
              })
            }}
            onRowClick={(e, row, rowIndex) => {
              if (isExpand == false)
                setColumnExpand([])
              else
                UpdateExpandColumn(row.id, row);
            }}
          />
        </div>
        <WarningModal
          modal={warningModal}
          onToggle={() => setWarningModal(prev => !prev)}
          message={t("message:SelectRowWarning")}
        />
        <ConfirmModal2
          modal={confirmModal}
          title={t("Insurance Setting")}
          message={t("Save Insurance Settings?")}
          onToggle={() => { setConfirmModal(prev => !prev) }}
          onConfirm={() => onSubmit(getDataSunmit())}
          btnConfirmText={t("common:Save")}
          isEdit
        />
      </>
    )
  })

const mapStateToProps = ({ insurance }) => ({
  itemActive: insurance.itemActive,
  rules: insurance.rules,
  updatedTime: insurance.updatedTime
})

const mapDispatchToProps = dispatch => ({
  onGetFullTestInsuranceSettings: (payload, callback) => dispatch(getFullTestInsuranceSettings(payload, callback)),
  onGetInsuranceSettings: (payload, callback) => dispatch(getInsuranceSettings(payload, callback)),
  onSetScrollLeftInsuranceSettings: (payload) => dispatch(setScrollLeftInsuranceSettings(payload)),
})

InsuranceSettingsTable.displayName = 'InsuranceSettingsTable';
export default withTranslation(["insurancePage", "common"], { withRef: true })(connect(
  mapStateToProps,
  mapDispatchToProps, null, { forwardRef: true }
)(InsuranceSettingsTable))
