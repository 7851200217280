


//i18n
import {
    Accordion,
    AccordionBody, AccordionWrapper, Check, CustomButton
} from "components/Common"
import { ModuleIds, parameterCode, permissionType, RULE_SIGMA_VALUES, WESTGARD_TYPE } from "constant"
import { getCodesByParameterId } from "helpers/app-backend"
import { getwestgardSigmaQCById, getWestgardSigmaQCList } from "helpers/app-backend/IQC/QC_Westgard_Sigma_backend_helper"
import { getRuleSigmaByLotTestId } from "helpers/app-backend/IQC/QCLotTest_backend_helper"
import { getI18nextLng } from "helpers/utilities"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"

const ConfigSigmaModal = ({
    data,
    t,
    onSubmitSigma,
}) => {
    let lang = getI18nextLng()
    const [height, setHeight] = useState(window.innerHeight)
    const [isRender, setIsRender] = useState(false)
    const [modal, setModal] = useState(false)
    const [sigmaRules, setSigmaRules] = useState([])
    const [isSpin, setIsSpin] = useState(false)

    useEffect(() => {
        if (data && data.id) {
            getRuleByLotTestId(data.id)
        }
        else {
            resetRule()
        }
    }, [data])

    useEffect(() => {
        setTimeout(() => {
            getHeight()
        }, 500);
        fetchSigmaRules()
    }, [])

    const getHeight = () => {
        let top = document.getElementById("rule-sigma-config-button").getBoundingClientRect().top;
        setHeight((window.innerHeight - top - 65) * window.devicePixelRatio)
    }

    const resetRule = () => {
        let vals = sigmaRules;
        vals = vals.map(x => ({ ...x, radioValue: RULE_SIGMA_VALUES.OFF }))
        setSigmaRules(vals);
    }

    const getRuleByLotTestId = async (id) => {
        try {
            setIsSpin(true)
            let res = await getRuleSigmaByLotTestId(id)
            let vals = sigmaRules;
            if (res && res.length > 0) {
                vals = vals.map(x => ({ ...x, radioValue: res.find(z => z.code == x.code)?.ruleValue || RULE_SIGMA_VALUES.OFF }))
            } else {
                vals = vals.map(x => ({ ...x, radioValue: RULE_SIGMA_VALUES.OFF }))
                // if (data && data.id && data._6Sigma != '' && data._6Sigma != null) {
                //     const ruleDefaults = await getwestgardSigmaQCById(data._6Sigma)
                //     if (ruleDefaults && ruleDefaults.id) {
                //         let rejectRules = `,${ruleDefaults.rejectRules},`
                //         let warningRules = `,${ruleDefaults.warningRules},`
                //         vals = vals.map(x => ({ ...x, radioValue: checkRule(x.code, rejectRules, warningRules) }))
                //     }
                //     else {
                //         vals = vals.map(x => ({ ...x, radioValue: RULE_SIGMA_VALUES.OFF }))
                //     }
                // }
                // else {
                //     vals = vals.map(x => ({ ...x, radioValue: RULE_SIGMA_VALUES.OFF }))
                // }
            }
            setSigmaRules(vals);
            setIsSpin(false)
        } catch (error) {
            setIsSpin(false)
        }
    }

    useEffect(() => {
        if (modal == false) {
            setTimeout(() => {
                setIsRender(false)
            }, 400);
        }
        else {
            setIsRender(true)
        }
    }, [modal])

    const fetchSigmaRules = async () => {
        const query = { size: 0, inUse: true, type: WESTGARD_TYPE.WESTGARD_RULES }
        const res = await getWestgardSigmaQCList(query)
        if (res && res.data) {
            let dt = res.data.map(x => ({ code: x.code, message: x.name }))
            checkSelected(dt || [])
        }
    }

    const checkSelected = (vals) => {
        vals = vals.map(x => ({ ...x, radioValue: RULE_SIGMA_VALUES.OFF }))
        setSigmaRules(vals);
    }

    const onClickRule = (e) => {
        try {
            let tmp = e.target.children[0]
            tmp && tmp.children[0]?.click()
        } catch (error) {
            console.log(error);

        }
    }

    const onSelectedSigma = (code, val) => {
        let tmp = sigmaRules
        let oldVal = sigmaRules.find(x => x.code == code)
        if (oldVal) {
            oldVal.radioValue = val;
            setSigmaRules([...tmp])
        }
    }
    const getChecked = (code, val) => {
        return sigmaRules.findIndex(x => x.code == code && x.radioValue == val) >= 0
    }

    const addRuleFromSigma = async () => {
        if (data && data.id && data._6Sigma != '' && data._6Sigma != null) {
            const res = await getwestgardSigmaQCById(data._6Sigma)
            let vals = sigmaRules;
            if (res && res.id) {
                let rejectRules = `,${res.rejectRules},`
                let warningRules = `,${res.warningRules},`
                vals = vals.map(x => ({ ...x, radioValue: checkRule(x.code, rejectRules, warningRules) }))
            }
            else {
                vals = vals.map(x => ({ ...x, radioValue: RULE_SIGMA_VALUES.OFF }))
            }
            setSigmaRules(vals);
        }
    }

    const checkRule = (code, rejectRules, warningRules) => {
        if (rejectRules.includes(`,${code},`)) {
            return RULE_SIGMA_VALUES.R
        }
        if (warningRules.includes(`,${code},`)) {
            return RULE_SIGMA_VALUES.W
        }
        return RULE_SIGMA_VALUES.OFF
    }

    const submitSigma = () => {
        onSubmitSigma(sigmaRules.map(x => ({ code: x.code, value: x.radioValue })))
    }
    return (
        <React.Fragment>
            <div className="rule-sigma-config">
                <CustomButton
                    color="secondary"
                    outline
                    id="rule-sigma-config-button"
                    onClick={() => { setModal(prev => !prev) }}
                >
                    <i style={{ fontSize: 16 }} className={`bx bx-cog`}></i>
                </CustomButton>
                {isRender == true &&
                    <div className={`rule-sigma-config-modal ${modal ? 'rule-sigma-config-modal-active' : 'rule-sigma-config-modal-inactive'}`} style={{ height: 'auto', maxHeight: `${height}px` }}>
                        <div className="row rule-sigma-config-modal-loading">
                            {isSpin &&
                                <div className="w-100" style={{
                                    height: '98%',
                                    position: 'absolute', zIndex: 9,
                                    background: 'rgba(256,256,256,0.5)'
                                }}>
                                    <div className="w-100 " style={{ height: '98%' }}>
                                        <div className="text-center" style={{ paddingTop: '100px' }}>
                                            <div className="spinner-border text-primary" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="col-12">
                                <AccordionWrapper defaultTab={["1"]}>
                                    <Accordion tabId={"1"} title={t("Config Rules")}>
                                        <AccordionBody>
                                            <table className="table table-borderless">
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th>OFF</th>
                                                        <th>W</th>
                                                        <th>R</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {sigmaRules.map((row, index) => <tr key={index}>
                                                        <td className="sigma-name">{row.message}</td>
                                                        <td onClick={(e) => {
                                                            onClickRule(e)
                                                        }}>
                                                            <div className="form-check">
                                                                <label className="form-check-label">
                                                                    <input type="radio" className="form-check-input" name={row.code}
                                                                        onChange={(e) => { onSelectedSigma(row.code, RULE_SIGMA_VALUES.OFF) }}
                                                                        checked={getChecked(row.code, RULE_SIGMA_VALUES.OFF)}
                                                                    />
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td onClick={(e) => {
                                                            onClickRule(e)
                                                        }}>
                                                            <div className="form-check">
                                                                <label className="form-check-label">
                                                                    <input type="radio" className="form-check-input" name={row.code}
                                                                        onChange={(e) => { onSelectedSigma(row.code, RULE_SIGMA_VALUES.W) }}
                                                                        checked={getChecked(row.code, RULE_SIGMA_VALUES.W)}
                                                                    />
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td onClick={(e) => {
                                                            onClickRule(e)
                                                        }}>
                                                            <div className="form-check">
                                                                <label className="form-check-label">
                                                                    <input type="radio" className="form-check-input" name={row.code}
                                                                        onChange={(e) => { onSelectedSigma(row.code, RULE_SIGMA_VALUES.R) }}
                                                                        checked={getChecked(row.code, RULE_SIGMA_VALUES.R)}
                                                                    />
                                                                </label>
                                                            </div>
                                                        </td>
                                                    </tr>)}
                                                </tbody>
                                            </table>
                                            <div className="sigma-rules-button text-center mt-3">
                                                <>
                                                    <Check permission={permissionType.AddRule} resource={ModuleIds.LotTestQC}>

                                                        <CustomButton
                                                            text={t("Add Rule From Sigma")}
                                                            type="button"
                                                            onClick={() => {
                                                                addRuleFromSigma()
                                                            }}
                                                            color="success"
                                                            className="mb-0"
                                                            style={{ whiteSpace: 'nowrap' }}
                                                        />

                                                    </Check>
                                                    <Check permission={permissionType.ApplyRule} resource={ModuleIds.LotTestQC}>
                                                        <CustomButton
                                                            text={t("common:Save")}
                                                            type="button"
                                                            onClick={() => {
                                                                submitSigma()
                                                            }}
                                                            color="primary"
                                                            className="mb-0"
                                                            style={{ whiteSpace: 'nowrap' }}
                                                            isEdit
                                                        />
                                                    </Check>
                                                </>
                                            </div>
                                        </AccordionBody>
                                    </Accordion>
                                </AccordionWrapper>
                            </div>
                            <div className="col-12">
                                <AccordionWrapper defaultTab={["1"]}>
                                    <Accordion tabId={"1"} title={t("Note")}>
                                        <AccordionBody>
                                            <div className="sigma-note">
                                                <div className="sigma-note-content">
                                                    <div className="icon">
                                                        <i className="fas fa-exclamation-circle text-primary align-self-center"></i>
                                                    </div>
                                                    <div className="content">
                                                        <div>OFF: Tắt</div>
                                                        <div><span className="text-warning">W: </span> Warning - Cảnh báo</div>
                                                        <div><span className="text-danger">R: </span> Reject - Loại bỏ</div>
                                                    </div>
                                                </div>
                                                <div className="sigma-note-content">
                                                    <div className="icon">
                                                        <i className="fas fa-exclamation-circle text-primary align-self-center"></i>
                                                    </div>
                                                    <div className="content">
                                                        <div>Thêm luật từ Sigma:</div>
                                                        <div>Tự động áp dụng luật sau khi Sigma được tính.</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </AccordionBody>
                                    </Accordion>
                                </AccordionWrapper>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </React.Fragment>
    )
}

ConfigSigmaModal.defaultProps = {}

const mapStateToProps = ({ testResult }) => ({
})

const mapDispatchToProps = dispatch => ({
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation(["managementQCPage", "common"])(ConfigSigmaModal))
