import {
    TitleAndTable
} from "components/Common"
import { ModuleIds } from "constant"
import {
    getUrlParamByKey,
    getUrlParams
} from "helpers/utilities"
import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { addMachineQC, deleteMachineQC } from "store/connector/instrumentQC/actions"
import { getMachines, resetMachineSearchQuery } from "store/connector/instruments/actions"
import MachineTable from "./MachineTable"

const RESOURCE = ModuleIds.MachineTestQC

const Machines = ({
    machines,
    paging,
    onGetMachines,
    onCreateMachineQC,
    onDeleteMachineQC,
    onResetMachineSearchQuery,
    loadingMachines,
    updateMachineTime,
    t,
}) => {

    useEffect(() => {
        let params = getUrlParams();
        let paramPage = getUrlParamByKey("page");
        if (paramPage) {
            onGetMachineList(params)
        } else {
            onGetMachines()
        }
    }, [])

    const onGetMachineList = (payload) => {
        onGetMachines(payload)
    }

    const fetchMachines = () => {
        onGetMachineList({ page: 1 })
    }

    const onRefreshHandler = () => {
        fetchMachines()
    }

    const onSearch = searchText => {
        onGetMachineList({ page: 1, search: searchText })
    }

    const onSizePerPageChange = size => {
        onGetMachineList({ page: 1, size })
    }

    const onPageChange = page => {
        onGetMachineList({ page })
    }

    const onSortHandler = (field, order) => {
        const sortString = `${field}:${order}`
        onGetMachineList({ page: 1, sort: sortString })
    }

    const onQCChange = (data, isQc) => {
        data.instrumentID = data.insID
        if (isQc) {
            onCreateMachineQC(data, () => {
                onRefreshHandler()
            })
        } else {
            onDeleteMachineQC(data, () => {
                onRefreshHandler()
            })
        }
    }

    useEffect(() => {
        onResetMachineSearchQuery()
    }, [])

    return (
        <React.Fragment>
            <TitleAndTable
                table={() => (
                    <MachineTable
                        machines={machines}
                        onSearch={onSearch}
                        onSort={onSortHandler}
                        onRefresh={onRefreshHandler}
                        onPageChange={onPageChange}
                        paging={paging}
                        onSizePerPageChange={onSizePerPageChange}
                        loading={loadingMachines}
                        updatedTime={updateMachineTime}
                        onQCChange={onQCChange}
                    />
                )}
                resource={RESOURCE}
                isDisableClone
                isHideEdit
                isDropDown
                title={t("sidebar:MasterData Entry IQC")}
                subtitle={t("Instrument List")}
            />
        </React.Fragment>
    )
}

Machines.propTypes = {
    machines: PropTypes.array,
    onGetMachines: PropTypes.func,
    onUpdateMachine: PropTypes.func,
    onResetMachineSearchQuery: PropTypes.func,
    paging: PropTypes.object,
    loadingMachines: PropTypes.bool,
    updateMachineTime: PropTypes.any,
    t: PropTypes.any,
}

const mapStateToProps = ({ machine }) => {
    return ({
        machines: machine.machines,
        machine: machine.machine,
        paging: machine.paging,
        loadingMachines: machine.loadingMachine,
        updateMachineTime: machine.updateMachineTime,
    })
}

const mapDispatchToProps = dispatch => ({
    onGetMachines: payload => dispatch(getMachines(payload)),
    onCreateMachineQC: (machine, callback) => dispatch(addMachineQC(machine, callback)),
    onDeleteMachineQC: (machine, callback) => dispatch(deleteMachineQC(machine, callback)),
    onResetMachineSearchQuery: () => dispatch(resetMachineSearchQuery()),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation(["machinePage", "message", "common"])(Machines)))
