//i18n
import { AvForm } from "availity-reactstrap-validation"
import {
  Accordion,
  AccordionBody,
  AccordionWrapper,
  CustomAvField,
  CustomButton,
  CustomDatePicker,
  CustomSelect,
} from "components/Common"
import FormSwitchForm from "components/Common/FormSwitchForm"
import { parameterCode, RULE_SIGMA_VALUES } from "constant"
import { getCodesByParameterId } from "helpers/app-backend"
import { getRuleSigmaByLotTestId } from "helpers/app-backend/IQC/QCLotTest_backend_helper"
import { getI18nextLng } from "helpers/utilities"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"

const ConfigSigmaModal = ({ data, t, onSubmitCalculate }) => {
  let lang = getI18nextLng()
  const [height, setHeight] = useState(window.innerHeight)
  const [isRender, setIsRender] = useState(false)
  const [modal, setModal] = useState(false)
  const [sigmaRules, setSigmaRules] = useState([])
  const [isSpin, setIsSpin] = useState(false)

  useEffect(() => {
    if (data && data.id) {
      getRuleByLotTestId(data.id)
    } else {
      resetRule()
    }
  }, [data])

  useEffect(() => {
    setTimeout(() => {
      getHeight()
    }, 500)
    fetchSigmaRules()
  }, [])

  const getHeight = () => {
    let top = document
      .getElementById("rule-sigma-config-button")
      .getBoundingClientRect().top
    setHeight((window.innerHeight - top - 65) * window.devicePixelRatio)
  }

  const resetRule = () => {
    let vals = sigmaRules
    vals = vals.map(x => ({ ...x, radioValue: RULE_SIGMA_VALUES.OFF }))
    setSigmaRules(vals)
  }

  const getRuleByLotTestId = async id => {
    try {
      setIsSpin(true)
      let res = await getRuleSigmaByLotTestId(id)
      let vals = sigmaRules
      if (res && res.length > 0) {
        vals = vals.map(x => ({
          ...x,
          radioValue:
            res.find(z => z.code == x.code)?.ruleValue || RULE_SIGMA_VALUES.OFF,
        }))
      } else {
        vals = vals.map(x => ({ ...x, radioValue: RULE_SIGMA_VALUES.OFF }))
      }
      setSigmaRules(vals)
      setIsSpin(false)
    } catch (error) {
      setIsSpin(false)
    }
  }

  useEffect(() => {
    if (modal == false) {
      setTimeout(() => {
        setIsRender(false)
      }, 400)
    } else {
      setIsRender(true)
    }
  }, [modal])

  const fetchSigmaRules = async () => {
    const query = { lang, sort: "sequence:asc" }
    const res = await getCodesByParameterId(parameterCode.SIGMA_RULES, query)
    checkSelected(res || [])
  }

  const checkSelected = vals => {
    vals = vals.map(x => ({ ...x, radioValue: RULE_SIGMA_VALUES.OFF }))
    setSigmaRules(vals)
  }

  const onClickRule = e => {
    try {
      let tmp = e.target.children[0]
      tmp && tmp.children[0]?.click()
    } catch (error) {
      console.log(error)
    }
  }

  const addRuleFromSigma = () => {
    let vals = sigmaRules
    let array = [
      RULE_SIGMA_VALUES.OFF,
      RULE_SIGMA_VALUES.W,
      RULE_SIGMA_VALUES.R,
    ]
    vals = vals.map(x => ({
      ...x,
      radioValue: array[Math.floor(Math.random() * array.length)],
    }))
    setSigmaRules(vals)
  }

  const submitSigma = () => {
    onSubmitCalculate(
      sigmaRules.map(x => ({ code: x.code, value: x.radioValue }))
    )
  }
  return (
<React.Fragment>
  <div className="rule-sigma-config">
    <CustomButton
      color="secondary"
      outline
      id="rule-sigma-config-button"
      onClick={() => setModal(prev => !prev)}
    >
      <i style={{ fontSize: 16 }} className="bx bx-cog"></i>
    </CustomButton>
    
    {isRender && (
      <div
        className={`settings-config-modal ${modal ? 'active' : 'inactive'}`}
        style={{ height: "auto", maxHeight: `${height}px` }}
      >
        <div className="settings-config-modal-loading">
          {isSpin && (
            <div
              className="w-100"
              style={{
                height: "98%",
                position: "absolute",
                zIndex: 9,
                background: "rgba(256,256,256,0.5)",
              }}
            >
              <div className="w-100" style={{ height: "98%" }}>
                <div className="text-center" style={{ paddingTop: "100px" }}>
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="col-12">
            <AccordionWrapper defaultTab={["1"]}>
              <Accordion tabId={"1"} title={t("Thiết lập cách tính Sigma, TE")}>
                <AccordionBody>
                  <AvForm>
                    <div className="sigma-rules-button text-center mt-3">
                      <div className="row mb-2">
                        <div className="col-12">
                          <FormSwitchForm
                            name="tea"
                            value={data.tea || "2"}
                            label={t("Cách tính Bias")}
                          />
                        </div>
                      </div>

                      <div className="row mb-2">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <CustomSelect
                            name="tea"
                            type="number"
                            value={data.tea || "2"}
                          />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <CustomDatePicker
                            name="tea"
                            type="number"
                            value={data.tea || "2"}
                          />
                        </div>
                      </div>

                      <div className="row mb-2">
                        <div className="col-12">
                          <FormSwitchForm
                            name="tea"
                            value={data.tea || "2"}
                            label={t("Cách tính CV cộng dồn")}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <CustomSelect
                            name="tea"
                            type="number"
                            value={data.tea || "2"}
                          />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <CustomDatePicker
                            name="tea"
                            type="number"
                            value={data.tea || "2"}
                          />
                        </div>
                        <div className="row">
                        
                        </div>
                      </div>
                    </div>
                  </AvForm>

                  <div className="sigma-rules-button text-center mt-3">
                    <CustomButton
                      text={t("Add Rule From Sigma")}
                      type="button"
                      onClick={addRuleFromSigma}
                      color="success"
                      className="mb-0"
                      style={{ whiteSpace: "nowrap" }}
                    />
                    <CustomButton
                      text={t("common:Save")}
                      type="button"
                      onClick={submitSigma}
                      color="primary"
                      className="mb-0"
                      style={{ whiteSpace: "nowrap" }}
                      isEdit
                    />
                  </div>
                </AccordionBody>
              </Accordion>
            </AccordionWrapper>
          </div>
        </div>
      </div>
    )}
  </div>
</React.Fragment>

  )
}

ConfigSigmaModal.defaultProps = {}

const mapStateToProps = ({ testResult }) => ({})

const mapDispatchToProps = dispatch => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["managementQCPage", "common"])(ConfigSigmaModal))
