import {
    CANCEL_RESULT_QC,
    CANCEL_RESULT_QC_FAIL,
    CANCEL_RESULT_QC_SUCCESS,
    CANCEL_VALID_RESULT_QC,
    CANCEL_VALID_RESULT_QC_FAIL,
    CANCEL_VALID_RESULT_QC_SUCCESS,
    CLICK_ADD_NEW_RESULT_IQC,
    DELETE_RESULT_QC,
    DELETE_RESULT_QC_FAIL,
    DELETE_RESULT_QC_SUCCESS,
    GET_LIST_RESULT_QC,
    GET_LIST_RESULT_QC_FAIL,
    GET_LIST_RESULT_QC_SUCCESS,
    UPDATE_LIST_RESULT_QC,
    UPDATE_LIST_RESULT_QC_FAIL,
    UPDATE_LIST_RESULT_QC_SUCCESS,
    VALID_RESULT_QC,
    VALID_RESULT_QC_FAIL,
    VALID_RESULT_QC_SUCCESS
} from "./actionTypes"

export const getListResultQC = payload => {
    return {
        type: GET_LIST_RESULT_QC,
        payload: payload || {},
    }
}
export const getListResultQCSuccess = payload => ({
    type: GET_LIST_RESULT_QC_SUCCESS,
    payload: payload,
})
export const getListResultQCFail = error => ({
    type: GET_LIST_RESULT_QC_FAIL,
    payload: error,
})

export const clickAddNewResultIQC = payload => {
    return {
        type: CLICK_ADD_NEW_RESULT_IQC,
        payload: payload || false,
    }
}

//update results qc
export const updateResultsQC = payload => ({
    type: UPDATE_LIST_RESULT_QC,
    payload: payload,
})
export const updateResultsQCSuccess = payload => ({
    type: UPDATE_LIST_RESULT_QC_SUCCESS,
    payload: payload,
})
export const updateResultsQCFail = error => ({
    type: UPDATE_LIST_RESULT_QC_FAIL,
    payload: error,
})

export const validResultsQC = (payload, callback) => ({
    type: VALID_RESULT_QC,
    payload: payload,
    callback: callback
})
export const validResultsQCSuccess = payload => ({
    type: VALID_RESULT_QC_SUCCESS,
    payload: payload,
})
export const validResultsQCQCFail = error => ({
    type: VALID_RESULT_QC_FAIL,
    payload: error,
})

export const cancelValidResultsQC = (payload, callback) => ({
    type: CANCEL_VALID_RESULT_QC,
    payload: payload,
    callback: callback
})
export const cancelValidResultsQCSuccess = payload => ({
    type: CANCEL_VALID_RESULT_QC_SUCCESS,
    payload: payload,
})
export const cancelValidResultsQCQCFail = error => ({
    type: CANCEL_VALID_RESULT_QC_FAIL,
    payload: error,
})

export const cancelResultsQC = (payload, callback) => ({
    type: CANCEL_RESULT_QC,
    payload: payload,
    callback: callback
})
export const cancelResultsQCSuccess = payload => ({
    type: CANCEL_RESULT_QC_SUCCESS,
    payload: payload,
})
export const cancelResultsQCFail = error => ({
    type: CANCEL_RESULT_QC_FAIL,
    payload: error,
})

export const deleteResultsQC = (payload, callback) => ({
    type: DELETE_RESULT_QC,
    payload: payload,
    callback: callback
})
export const deleteResultsQCSuccess = payload => ({
    type: DELETE_RESULT_QC_SUCCESS,
    payload: payload,
})
export const deleteResultsQCFail = error => ({
    type: DELETE_RESULT_QC_FAIL,
    payload: error,
})