import { AvForm } from "availity-reactstrap-validation"
import { CustomDatePicker, CustomSelect } from "components/Common"
import { parameterCode } from "constant"
import { convertDateFormat } from "helpers/utilities"
import React from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"

const CalculationSigmaTEButtons = ({ model, onChange, onSubmit, t }) => {
  return (
    <React.Fragment>
      <AvForm>
        <div className="d-flex">
          <div style={{width : '200px', marginLeft: "5px" }}>
            <CustomSelect
              name="instrumentId"
              portal
              placeholder={t("Machines")}
              value={model.instrumentId || ""}
              code={parameterCode.INSTRUMENT}
              onChange={(name, value, label, item) => {
                onChange({
                  instrumentId: value[0],
                })
                setTimeout(() => {
                  onSubmit({ ...model, instrumentId: value[0] || "" })
                }, 100)
              }}
            />
          </div>
          <div style={{width : '200px', marginLeft: "5px" }}>
            <CustomSelect
              name="instrumentId"
              portal
              placeholder={t("Levels")}
              value={model.instrumentId || ""}
              code={parameterCode.LEVEL_QC}
              onChange={(name, value, label, item) => {
                onChange({
                  instrumentId: value[0],
                })
                setTimeout(() => {
                  onSubmit({ ...model, instrumentId: value[0] || "" })
                }, 100)
              }}
            />
          </div>
          <div style={{width : '200px', marginLeft: "5px" }}>
            <CustomSelect
              name="instrumentId"
              portal
              placeholder={t("Lot QC")}
              value={model.instrumentId || ""}
              code={parameterCode.LOT_QC}
              onChange={(name, value, label, item) => {
                onChange({
                  instrumentId: value[0],
                })
                setTimeout(() => {
                  onSubmit({ ...model, instrumentId: value[0] || "" })
                }, 100)
              }}
            />
          </div>
        </div>
      </AvForm>
    </React.Fragment>
  )
}

const mapStateToProps = ({ hisConnector }) => ({})
const mapDispatchToProps = dispatch => ({})

CalculationSigmaTEButtons.displayName = "CalculationSigmaTEButtons"
export default withTranslation(["managementQCPage", "common", "button"], {
  withRef: true,
})(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    CalculationSigmaTEButtons
  )
)
