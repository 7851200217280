import PropTypes from "prop-types";
import { CustomBreadcrumb, PageContent } from "components/Common";
import { ModuleIds } from "constant";
import { withTranslation } from "react-i18next";
import ExpressionIQC from "./ExpressionIQC";

const RESOURCE = ModuleIds.ExpressionIQC
const ExpressionIQCContainer = ({ t }) => {
    const titleTag = t(`Expression IQC`)
    return (
        <PageContent title={titleTag}>
            <CustomBreadcrumb
                breadcrumbItem={t("Expression IQC")}
                resource={RESOURCE}
            />
            <ExpressionIQC />
        </PageContent>
    );
}

ExpressionIQCContainer.propTypes = {
    t: PropTypes.any,
}

export default withTranslation(["expressionsIQCPage"])(ExpressionIQCContainer)