import PropTypes from "prop-types"
import { Link } from "react-router-dom"

const LabelAndValue = ({ label, value, className, linkTo }) => {
  return (
    <div>
      <p className="text-muted text-truncate mb-2 font-size-14">{label}</p>
      {!linkTo && <h6 className={`mb-0 font-size-16 ${className}`}>{value}</h6>}
      {linkTo && <h6 className={`mb-0 font-size-16 ${className}`}>
        <Link to={linkTo} target="_blank">{value}</Link>
      </h6>}
    </div>
  )
}

LabelAndValue.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
}

LabelAndValue.defaultProps = {
  label: "label",
  value: "",
  className: "",
  linkTo: ""
}

export default LabelAndValue
