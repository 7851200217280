import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import CustomSID from "../CustomSID";
import { RESULT_STATE } from "constant";
import i18n from "i18next"
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

const CustomSIDHover = ({
  data,
  indexParent,
  emergency = false,
  placement = "top",
  showTooltip = true,
  t,
  isShowState = false,
}) => {
  //--const t = (msg, param) => i18n.t(msg, param)
  const [sampleIds, setSampleIds] = useState([])
  const [sampleDistinct, setSampleDistinct] = useState([]);
  useEffect(() => {
    let sids = (data != '' && data != null) ? JSON.parse(data) : []
    setSampleIds(sids)
    const sid = sids.map(x => `${x.SID}${x.SubSID ? ` - ${x.SubSID}` : ''}`.trim())
    const sidDistinct = sid.filter((x, index) => !sid.includes(x, index + 1)) || []
    setSampleDistinct(sidDistinct)
  }, [data])

  return (
    <>
      <div className="floating-text-field">
        {
          sampleDistinct.map((item, index) => {
            // let children = sampleIds.filter(x => x.SID == item)
            return <React.Fragment key={index}>
              <CustomSID
                key={`${index}`}
                placeToolTip={placement}
                keyToolTip={`tooltipsid${indexParent}${index}`}
                isToolTip={true}
                toolTip={sampleIds.map((x, indexChild) => {
                  let stateStr = "";
                  if (x.State && isShowState) {
                    stateStr = " - " + t(`${RESULT_STATE[x.State]}`)
                  }
                  return <React.Fragment key={indexChild}>
                    <span key={indexChild} style={{ whiteSpace: 'nowrap' }}>
                      {`${x.SampleTypeName} - ${x.SampleType}${stateStr}`}
                    </span>
                    <br />
                  </React.Fragment>
                }
                )}
                sid={item}
                emergency={emergency}
              />
            </React.Fragment>
          })
        }
      </div>
    </>
  )
}

CustomSIDHover.propTypes = {
  data: PropTypes.string,
  indexParent: PropTypes.number
}

CustomSIDHover.defaultProps = {
  data: '',
  indexParent: 1
}

const mapStateToProps = ({ }) => ({

})

const mapDispatchToProps = dispatch => ({

})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["testResultPage", "common"])(CustomSIDHover))