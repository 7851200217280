import { AvForm, AvRadio, AvRadioGroup } from "availity-reactstrap-validation";
import {
  CustomAvField,
  CustomButton,
  CustomDatePicker,
  CustomModal
} from "components/Common";
import { convertDateFormat, getInvalidMessageI18n } from "helpers/utilities";
import { useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import { ModalBody, ModalFooter } from "reactstrap";

const ScopeApplyCalculationQCModal = ({
  modal,
  toggle,
  onValidSubmit,
  t,
}) => {

  const [scope, setScope] = useState('calNum')
  const [dateTime, setDateTime] = useState([convertDateFormat(new Date(), "DD-MM-YYYY"), convertDateFormat(new Date(), "DD-MM-YYYY")])
  const [point, setPoint] = useState(null)
  const formEl = useRef()
  const title = t("Apply QC")

  const handleValidSubmit = (event, values) => {
    onValidSubmit(values)
  }

  return (
    <CustomModal modal={modal} title={title} onToggle={toggle} size='md' >
      <ModalBody>
        <AvForm
          ref={formEl}
          id="testForm"
          onValidSubmit={handleValidSubmit}
        >
          <div>
            <div className="d-flex mb-3">
              <AvRadioGroup
                name='type'
                value='All'
                inline
              >
                <div className="d-flex gap-4">
                  <AvRadio
                    label={t('All')}
                    value='All'
                  />
                  <AvRadio
                    label={t('Mean New')}
                    value='Mean'
                  />
                  <AvRadio
                    label={t('SD New')}
                    value='SD'
                  />
                </div>

              </AvRadioGroup>
            </div>

            <div className="d-flex mb-3">
              <div className="col-sm-6">
                <AvRadioGroup
                  name='scope'
                  value={scope}
                  onChange={(e) => {
                    setScope(e.target.value)
                    setPoint('')
                    setDateTime([convertDateFormat(new Date(), "DD-MM-YYYY"), convertDateFormat(new Date(), "DD-MM-YYYY")])
                  }}
                >
                  <div className="d-flex flex-column gap-5">
                    <div >
                      <AvRadio
                        label={t('Point Apply')}
                        value='calNum'
                      />
                    </div>
                    <div >
                      <AvRadio
                        label={t('Date Time Apply')}
                        value='appliedRangeDate'
                      />
                    </div>
                    <div >
                      <AvRadio
                        label={t('LTD Apply')}
                        value='ltd'
                      />
                    </div>
                  </div>
                </AvRadioGroup >
              </div>
              <div className="col-sm-6">
                <div className="d-flex flex-column gap-4">
                  <div>
                    <CustomAvField
                      name="calNum"
                      value={point}
                      onChange={(name, value) => {
                        setPoint(value)
                      }}
                      errorMessage={getInvalidMessageI18n(t, "Point")}
                      disabled={scope != 'calNum'}
                      required={scope == 'calNum'}
                    />
                  </div>
                  <div className="mt-1">
                    <CustomDatePicker
                      name="appliedRangeDate"
                      label={""}
                      dateFormat={"d-m-Y"}
                      value={dateTime}
                      closeOnSelect={true}
                      disabled={scope != 'appliedRangeDate'}
                      required={scope == 'appliedRangeDate'}
                      onChangeHandler={date => {
                        if (date.time.length == 2) {
                          setDateTime([
                            convertDateFormat(date.time[0] || new Date(), "DD-MM-YYYY"),
                            convertDateFormat(date.time[1] || new Date(), "DD-MM-YYYY")
                          ])
                        }
                      }}
                      mode="range"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AvForm>
      </ModalBody>
      <ModalFooter>
        <CustomButton
          text={t("common:Cancel")}
          type="button"
          onClick={toggle}
          data-dismiss="modal"
          className="button-width"
        />
        <CustomButton
          text={t("common:Save")}
          type="submit"
          color="primary"
          onClick={() => formEl?.current?.submit()}
          className="save-user button-width"
          isEdit
        />
      </ModalFooter>
    </CustomModal>
  );
};

export default withTranslation(["calculationQCPage", "common"])(ScopeApplyCalculationQCModal);
