import { AvForm } from "availity-reactstrap-validation"

import { ModalBody, ModalFooter } from "reactstrap"

import {
    Accordion,
    AccordionBody,
    AccordionWrapper,
    CustomAvField,
    CustomButton,
    CustomModal,
    CustomSelect,
    CustomSelectAsync
} from "components/Common"

//i18n
import { parameterCode } from "constant"
import { convertDateFormat, getInvalidMessageI18n } from "helpers/utilities"
import React, { useEffect, useReducer, useRef, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import AddLotTestMachineTable from "./AddLotTestMachineTable"
import { copyQCLotTest, GetQCLotTestList } from "helpers/app-backend/IQC/QCLotTest_backend_helper"
import { showErrToast } from "components/Common/ErrToastr"
import { getAllMachine } from "helpers/app-backend/machines_backend_helper"

const AddLotTestMachine = ({
    modal,
    toggle,
    data,
    t,
    onValidSubmit,
}) => {
    const ref = useRef();
    const refData = useRef()
    const [config, setConfig] = useState(data || {})
    const [disaleSubmit, setDisableSubmit] = useState(true)
    const [model, setModel] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            insId: '',
            lotId: '',
            levels: '',
            testCode: '',
        }
    )
    useEffect(() => {
        if (modal == false) {
            setConfig(data)
            setModel({
                insId: '',
                lotId: '',
                levels: '',
                testCode: '',
            })
            ref?.current?.ClearData()
        }
    }, [modal])

    useEffect(() => {
        setConfig(data)
    }, [data])

    const onInSertData = async () => {
        let tmp = {
            insId: model.insId,
            lotId: model.lotId,
        }
        if (model.levels && model.levels != '') {
            tmp.levels = model.levels
        }

        if (model.testCode && model.testCode != '') {
            tmp.testCode = model.testCode
        }
        let res = await GetQCLotTestList(tmp)
        let newData = res || []
        if (newData.length > 0) {
            let ins = newData.filter(x => x.insId != null && x.insId != '').map(x => x.insId)
            if (ins.length > 0) {
                let resIns = await getAllMachine({ id: ins, size: 0 })
                if (resIns.data && resIns.data.length > 0) {
                    newData.forEach(element => {
                        element.machineName = resIns.data.find(x => x.id == element.insId)?.name || ''
                    });
                }
            }
        }
        res = newData
        if (config.levels && config.levels != '') {
            res = res.filter(x => x.levels == config.levels)
        }
        if (config.testCode && config.testCode != '') {
            res = res.filter(x => x.testCode == config.testCode)
        }
        if (res.length == 0) {
            showErrToast(
                `${t("No records found")}`
            )
        }
        else {
            ref.current?.UpdateData(res)
        }
    }

    const handleValidSubmit = async () => {
        let res = ref.current?.onGetData()
        res.data = res.data.map(x => ({
            ...x, insId: config.instrumentId, lotId: config.lotId
        }))
        await copyQCLotTest(res)
    }

    return (
        <React.Fragment>
            <CustomModal modal={modal} title={t("Add Lot Test Machine")} onToggle={toggle} size="xl" customClass={"modal-90vw management-qc-right management-qc-right-modal"} centered={false}>
                <ModalBody>
                    <div className="copy-lot-test-modal">
                        <div className="row">
                            <div className="col-auto mb-3 modal-add-lot-test-title align-self-center">
                                {t("Lot")}:
                                <span>{config.lotId} - {config.lotName} - {t("Level")}: {config.levels}</span>
                                <br />
                                {t("Machine")}: {config.machineName}
                            </div>
                            <div className="col">
                                <AvForm
                                    onValidSubmit={(e, v) => {
                                        onInSertData()
                                    }}
                                >
                                    <div className="d-flex justify-content-end align-items-center gap-1 mb-1">
                                        <div className="d-flex gap-1 text-center" style={{ width: '100px' }}>
                                            <i className="fas fa-exclamation-circle text-primary align-self-center"></i>
                                            <div>{t("Import form Lot")}</div>
                                        </div>
                                        <div style={{ width: '150px' }}>
                                            <CustomSelect
                                                name="insIdFrom"
                                                label={""}
                                                placeholder={t("Machine")}
                                                value={model.insId || ''}
                                                code={parameterCode.INSTRUMENT}
                                                onChange={(e, vals, a, items) => {
                                                    setModel({ insId: vals[0] || '' })
                                                }}
                                                required
                                                errorMessage={getInvalidMessageI18n(t, "Machine")}
                                                validate={{
                                                    required: { value: true },
                                                }}
                                            />
                                        </div>
                                        <div style={{ width: '250px' }}>
                                            <CustomSelectAsync
                                                name="lotIdFrom"
                                                label={""}
                                                placeholder={t("Lot")}
                                                value={model.lotId || ''}
                                                code={parameterCode.QC_LOT_LIST_FROM_LOT_TEST}
                                                onChange={(e, vals, a, items) => {
                                                    setModel({ lotId: vals[0] || '' })
                                                }}
                                                required={true}
                                                errorMessage={getInvalidMessageI18n(t, "Lot")}
                                            />
                                        </div>
                                        <div style={{ width: '100px' }}>
                                            <CustomSelectAsync
                                                name="levelsFrom"
                                                label={""}
                                                placeholder={t("Level")}
                                                value={model.levels || ''}
                                                code={parameterCode.LEVEL_QC}
                                                onChange={(e, vals, a, items) => {
                                                    setModel({ levels: vals[0] || '' })
                                                }}
                                            />
                                        </div>
                                        <div style={{ width: '250px' }}>
                                            <CustomSelectAsync
                                                name="testCodeFrom"
                                                placeholder={t("Search Test Code, Name")}
                                                value={""}
                                                valueName={""}
                                                code={parameterCode.TEST_CODE}
                                                label={""}
                                                onChange={(e, vals, a, items) => {
                                                    setModel({ testCode: vals[0] || '' })
                                                }}
                                            />
                                        </div>
                                        <CustomButton
                                            text={t("Import Data")}
                                            type="submit"
                                            // onClick={() => {
                                            //     onInSertData()
                                            // }}
                                            color="primary"
                                            outline
                                            style={{ width: '120px', whiteSpace: 'nowrap' }}
                                        />
                                    </div>
                                </AvForm>
                                <AvForm
                                    ref={refData}
                                    onValidSubmit={(e, v) => {
                                        handleValidSubmit()
                                    }}
                                >
                                    <div className="d-flex justify-content-end align-items-center gap-1">
                                        <div className="text-center" style={{ width: '100px' }}>
                                            <div>Tới Lô</div>
                                        </div>
                                        <div style={{ width: '150px' }}>
                                            <CustomSelect
                                                name="insIdTo"
                                                label={""}
                                                placeholder={t("Machine")}
                                                value={config.instrumentId || ''}
                                                code={parameterCode.INSTRUMENT}
                                                onChange={(e, vals, a, items) => {
                                                    setConfig({ ...config, instrumentId: vals[0] || '', machineName: items[0]?.name || '' })
                                                }}
                                                required
                                                errorMessage={getInvalidMessageI18n(t, "Machine")}
                                                validate={{
                                                    required: { value: true },
                                                }}
                                            />
                                        </div>
                                        <div style={{ width: '250px' }}>
                                            <CustomSelectAsync
                                                name="lot"
                                                placeholder={t("Lot")}
                                                label={""}
                                                value={config.lotId || ""}
                                                valueNameNotSearch={config.lotName || ''}
                                                code={parameterCode.QC_LOT_LIST_FROM_LOT_TEST}
                                                required={true}
                                                errorMessage={getInvalidMessageI18n(t, "Lot")}
                                                onChange={(e, vals, a, items) => {
                                                    setConfig({ ...config, lotId: vals[0] || '', lotName: items[0]?.label || '' })
                                                }}
                                            />
                                        </div>
                                        <div style={{ width: '100px' }}>
                                            <CustomSelectAsync
                                                name="levelsFrom"
                                                label={""}
                                                placeholder={t("Level")}
                                                value={config.levels || ''}
                                                code={parameterCode.LEVEL_QC}
                                                onChange={(e, vals) => {
                                                    setConfig({ ...config, levels: vals[0] || '' })
                                                }}
                                            />
                                        </div>
                                        <div style={{ width: '250px' }}>
                                            <CustomSelectAsync
                                                name="testCodeTo"
                                                placeholder={t("Search Test Code, Name")}
                                                value={""}
                                                valueName={""}
                                                code={parameterCode.TEST_CODE}
                                                label={""}
                                                onChange={(e, vals) => {
                                                    setConfig({ ...config, testCode: vals[0] || '' })
                                                }}
                                            />
                                        </div>
                                        <div style={{ width: '120px', textAlign: 'right' }}>
                                            <CustomButton
                                                color="secondary"
                                                outline
                                                onClick={() => {
                                                    ref?.current?.ResetData()
                                                }}

                                            >
                                                <i style={{ fontSize: 16 }} className={`fas fa-sync-alt `}></i>
                                            </CustomButton>
                                        </div>
                                    </div>
                                </AvForm>
                            </div>
                        </div>
                        <div className="w-100 copy-lot-test-modal-table">
                            <AddLotTestMachineTable
                                // updatedTime={updatedTimeLotTests}
                                // onSearch={(val) => {
                                //     setModel({ ...model, ...val })
                                //     getQCLotTest(val)
                                // }}
                                // onResetFilter={() => {
                                //     setModel(initModel)
                                //     getQCLotTest(initModel)
                                // }}
                                instrumentId={config.instrumentId}
                                model={{}}
                                data={[]}
                                ref={ref}
                                onChangeRow={(val) => {
                                    setDisableSubmit(val)
                                }}
                                onRefresh={() => { }}
                                onChangeData={(datas) => {
                                }}

                            />
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <CustomButton
                        text={t("common:Cancel")}
                        type="button"
                        onClick={toggle}
                        data-dismiss="modal"
                        className="button-width"
                    />

                    <CustomButton
                        text={t("common:Save")}
                        type="submit"
                        color="primary"
                        disabled={disaleSubmit}
                        onClick={() => { refData?.current?.submit() }}
                        className="save-user button-width"
                        isEdit
                    />
                </ModalFooter>
            </CustomModal>
        </React.Fragment >
    )
}

AddLotTestMachine.defaultProps = {}

const mapStateToProps = ({ testResult }) => ({
})

const mapDispatchToProps = dispatch => ({
    // onGetNotifications: payload => dispatch(getNotifications(payload)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation(["managementQCPage", "common"])(AddLotTestMachine))
