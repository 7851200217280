// ChartiQC.jsx
import { ModuleIds } from "constant";
import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import CustomSplitPane from "components/Common/CustomSplitPaneLeft"
import "react-splitter-layout/lib/index.css"; // Import default styles
import { withRouter } from "react-router-dom";
import BodyLeft from "./LeftFrame";
import BodyRight from "./RightFrame";
import BodyMid from "./MidFrame";
import moment from "moment"

const RESOURCE = ModuleIds.ChartiQC;

const ChartiQC = ({ t }) => {
  const [selected, setSelected] = useState({});
  const [model, setModel] = useState({
          start: moment().format("YYYY-MM-DD"),
          end: moment().format("YYYY-MM-DD HH:mm"),
          lotGroup: '',
          isExpired: false,
          category: '',
          machine: '',
          test: '',
          levels: '',
      });
  return (
        <CustomSplitPane
          LeftFrame={() => (
            <div style={{ padding: "10px", backgroundColor: "#f0f0f0", height: "100%" }}>
             <BodyLeft onChangeSelected={(val) => setSelected(val)} onChangeModel={
              (val) => setModel(val)}/>
            </div>
          )}
          RightFrame={() => (
            <CustomSplitPane
              LeftFrame={() => (
                <div style={{ padding: "10px", backgroundColor: "#f0f0f0", height: "100%" }}>
                  
                <BodyMid
                 payload={model} />
                </div>
              )}
              RightFrame={() => (
                <BodyRight config={selected}/>
              )}
              InitialSize={25} />
          )}
          InitialSize={80}
        />
  );
};

const mapStateToProps = ({ testRequest }) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation(["chartiQCPage", "message", "common"])(ChartiQC)));
