import {
    ADD_MACHINE_QC, ADD_MACHINE_QC_FAIL,
    ADD_MACHINE_QC_SUCCESS,
    DELETE_MACHINE_QC, DELETE_MACHINE_QC_FAIL,
    DELETE_MACHINE_QC_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
    machines: [],
    loadingMachine: false,
    updatingMachine: false,
    updateMachineTime: new Date(),
    error: {},
    machine: {},
}

const Machine = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ADD_MACHINE_QC:
            return {
                ...state,
            }
        case ADD_MACHINE_QC_SUCCESS:
            return {
                ...state,
            }
        case ADD_MACHINE_QC_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        case DELETE_MACHINE_QC:
            return {
                ...state,
            }
        case DELETE_MACHINE_QC_SUCCESS:
            return {
                ...state,
            }
        case DELETE_MACHINE_QC_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        default:
            return state
    }
}

export default Machine;