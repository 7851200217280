import {
  GET_SETTINGS,
  GET_SETTINGS_FAIL,
  GET_SETTINGS_IQC,
  GET_SETTINGS_IQC_FAIL,
  GET_SETTINGS_IQC_SUCCESS,
  GET_SETTINGS_SUCCESS,
  GET_TYPE_SETTINGS,
} from "./actionTypes"

export const getSettings = payload => ({
  type: GET_SETTINGS,
  payload: payload || {},
})

export const getSettingsSuccess = payload => ({
  type: GET_SETTINGS_SUCCESS,
  payload: payload,
})

export const getSettingsFail = error => ({
  type: GET_SETTINGS_FAIL,
  payload: error,
})

export const setTypeSettings = payload => ({
  type: GET_TYPE_SETTINGS,
  payload: payload,
})

export const getSettingsIQC = payload => ({
  type: GET_SETTINGS_IQC,
  payload: payload || {},
})

export const getSettingsIQCSuccess = payload => ({
  type: GET_SETTINGS_IQC_SUCCESS,
  payload: payload,
})

export const getSettingsIQCFail = error => ({
  type: GET_SETTINGS_IQC_FAIL,
  payload: error,
})