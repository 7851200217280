import { AvForm } from "availity-reactstrap-validation"

import { ModalBody, ModalFooter } from "reactstrap"

import {
    Accordion,
    AccordionBody,
    AccordionWrapper,
    CustomAvField,
    CustomButton,
    CustomModal,
    CustomNav,
    CustomNavLink,
    CustomSelectAsync
} from "components/Common"

//i18n
import { parameterCode } from "constant"
import { convertDateFormat, getInvalidMessageI18n } from "helpers/utilities"
import { isEmpty } from "lodash"
import React, { useReducer, useRef } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { TabContent, TabPane } from "reactstrap"

const AddLotTestModal = ({
    modal,
    toggle,
    data,
    t,
    onValidSubmit,
}) => {
    const formEl = useRef()
    const [model, setModel] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            meanPI: '',
            sdpi: '',
            deCOdd: '',
            meanPI2: '',
            sdpi2: '',
            deCOdd2: '',
            meanPI3: '',
            sdpi3: '',
            deCOdd3: '',
            highI: '',
            lowI: '',
            eval: '',
            highI2: '',
            lowI2: '',
            eval2: ''
        }
    )
    if (isEmpty(data)) return null;
    return (
        <React.Fragment>
            <CustomModal modal={modal} title={t("Add Lot Test")} onToggle={toggle} size="xl">
                <ModalBody style={{height:"100vh"}}>
                    <AvForm
                        ref={formEl}
                        id="quantitative"
                        onValidSubmit={(e, vals) => {
                            let tmp = {
                                testCode: vals.testCode,
                                temperature: vals.temperature,
                                tea: vals.tea,
                                method: vals.method || '',
                                machine: data.instrumentId || '0',
                                lotId: data.lotId,
                                qcInfo: [],
                                qcTextInfo: []
                            }
                            if (vals.meanPI && vals.meanPI != '' && vals.sdpi && vals.sdpi != '') {
                                tmp.qcInfo.push({
                                    level: 1, meanPI: vals.meanPI, sdpi: vals.sdpi, deCOdd: vals.deCOdd, mean: vals.meanPI
                                })
                            }
                            if (vals.meanPI2 && vals.meanPI2 != '' && vals.sdpi2 && vals.sdpi2 != '') {
                                tmp.qcInfo.push({
                                    level: 2, meanPI: vals.meanPI2, sdpi: vals.sdpi2, deCOdd: vals.deCOdd2, mean: vals.meanPI2
                                })
                            }
                            if (vals.meanPI3 && vals.meanPI3 != '' && vals.sdpi3 && vals.sdpi3 != '') {
                                tmp.qcInfo.push({
                                    level: 3, meanPI: vals.meanPI3, sdpi: vals.sdpi3, deCOdd: vals.deCOdd3, mean: vals.meanPI3
                                })
                            }
                            if (vals.highI && vals.highI != '' && vals.lowI && vals.lowI != '') {
                                tmp.qcTextInfo.push({
                                    level: 1, highI: vals.highI, lowI: vals.lowI, eval: vals.eval
                                })
                            }
                            if (vals.highI2 && vals.highI2 != '' && vals.lowI2 && vals.lowI2 != '') {
                                tmp.qcTextInfo.push({
                                    level: 2, highI: vals.highI2, lowI: vals.lowI2, eval: vals.eval2
                                })
                            }
                            onValidSubmit && onValidSubmit(tmp)
                        }}
                        model={data}
                    >
                        <div className="row">
                            <div className="col-12">
                                <AccordionWrapper defaultTab={["1"]}>
                                    <Accordion tabId={"1"} title={t("Lot Test Infomation")}>
                                        <AccordionBody className="accordion-body px-0 pb-0">
                                            <div className="row">
                                                <div className="col-12 mb-3 modal-add-lot-test-title">
                                                    {t("Lot")}:
                                                    <span>{data.lotId} - {data.lotName} - {t("Level")}: {data.levels}, {t("Expiration")}: {convertDateFormat(data.expireDate, "HH:mm DD-MM-YYYY")},</span>
                                                    {t("Machine")}: {data.machineName}
                                                </div>
                                                <div className="col-12 mb-3">
                                                    <CustomSelectAsync
                                                        name="testCode"
                                                        value={""}
                                                        valueName={""}
                                                        code={parameterCode.TEST_CODE}
                                                        label={`${t("Test")}`}
                                                        required={true}
                                                        errorMessage={getInvalidMessageI18n(t, "Test")}
                                                    />
                                                </div>
                                                <div className="col-lg-4">
                                                    <CustomAvField
                                                        name="temperature"
                                                        type="text"
                                                        value={data.temperature}
                                                        label={t("Temperature")}
                                                        maxLength={10}
                                                    />
                                                </div>
                                                <div className="col-lg-4">
                                                    <CustomAvField
                                                        name="method"
                                                        type="text"
                                                        value={data.method}
                                                        label={t("Method")}
                                                        maxLength={155}
                                                    />
                                                </div>
                                                <div className="col-lg-4">
                                                    <CustomAvField
                                                        name="tea"
                                                        type="number"
                                                        value={data.tea}
                                                        label={t("TEA %")}
                                                    />
                                                </div>
                                            </div>
                                        </AccordionBody>
                                    </Accordion>
                                </AccordionWrapper>
                            </div>
                        </div>
                        <div className="col-12">
                            <AccordionWrapper defaultTab={["1"]}>
                                <Accordion tabId={"1"} title={t("QC Levels Infomation")}>
                                    <AccordionBody>
                                        <CustomNav
                                            // onToggle={e => {
                                            //     setCurrentTab(e);
                                            // }}
                                            defaultTab={"1"}
                                            tabs
                                            className="nav-tabs-custom"
                                            tabContents={customActiveTab => (
                                                <TabContent
                                                    activeTab={customActiveTab}
                                                    className="p-0 text-muted nav-tabs-custom-management-qc"
                                                >
                                                    <TabPane tabId="1">
                                                        <div className="row mt-2">
                                                            <div className="col-12">
                                                                {t("Level")} 1
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <CustomAvField
                                                                    name="meanPI"
                                                                    type="number"
                                                                    value={data.meanPI}
                                                                    label={t("Mean - Trung bình")}
                                                                    onChange={val => {
                                                                        setModel({ meanPI: val || '' })
                                                                    }}
                                                                    errorMessage={getInvalidMessageI18n(t, "Mean - Trung bình")}
                                                                    validate={{
                                                                        required: { value: model.sdpi != '' },
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <CustomAvField
                                                                    name="sdpi"
                                                                    type="number"
                                                                    value={data.sdpi}
                                                                    label={t("SD - Độ lệch chuẩn")}
                                                                    onChange={val => {
                                                                        setModel({ sdpi: val || '' })
                                                                    }}
                                                                    errorMessage={getInvalidMessageI18n(t, "SD - Độ lệch chuẩn")}
                                                                    validate={{
                                                                        required: { value: model.meanPI != '' },
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <CustomAvField
                                                                    name="deCOdd"
                                                                    type="number"
                                                                    value={data.deCOdd || 0}
                                                                    label={t("Odd")}
                                                                    onChange={val => {
                                                                        setModel({ deCOdd: val || '' })
                                                                    }}
                                                                    errorMessage={getInvalidMessageI18n(t, "Odd")}
                                                                    validate={{
                                                                        required: { value: model.meanPI != '' },
                                                                    }}
                                                                />
                                                            </div>

                                                            <div className="col-12">
                                                                {t("Level")} 2
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <CustomAvField
                                                                    name="meanPI2"
                                                                    type="number"
                                                                    value={data.meanPI2}
                                                                    label={t("Mean - Trung bình")}
                                                                    onChange={val => {
                                                                        setModel({ meanPI2: val || '' })
                                                                    }}
                                                                    errorMessage={getInvalidMessageI18n(t, "Mean - Trung bình")}
                                                                    validate={{
                                                                        required: { value: model.sdpi2 != '' },
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <CustomAvField
                                                                    name="sdpi2"
                                                                    type="number"
                                                                    value={data.sdpi2}
                                                                    label={t("SD - Độ lệch chuẩn")}
                                                                    onChange={val => {
                                                                        setModel({ sdpi2: val || '' })
                                                                    }}
                                                                    errorMessage={getInvalidMessageI18n(t, "SD - Độ lệch chuẩn")}
                                                                    validate={{
                                                                        required: { value: model.meanPI2 != '' },
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <CustomAvField
                                                                    name="deCOdd2"
                                                                    type="number"
                                                                    value={data.deCOdd2 || 0}
                                                                    label={t("Odd")}
                                                                    onChange={val => {
                                                                        setModel({ deCOdd2: val || '' })
                                                                    }}
                                                                    errorMessage={getInvalidMessageI18n(t, "Odd")}
                                                                    validate={{
                                                                        required: { value: model.deCOdd2 != '' },
                                                                    }}
                                                                />
                                                            </div>

                                                            <div className="col-12">
                                                                {t("Level")} 3
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <CustomAvField
                                                                    name="meanPI3"
                                                                    type="number"
                                                                    value={data.meanPI3}
                                                                    label={t("Mean - Trung bình")}
                                                                    onChange={val => {
                                                                        setModel({ meanPI3: val || '' })
                                                                    }}
                                                                    errorMessage={getInvalidMessageI18n(t, "Mean - Trung bình")}
                                                                    validate={{
                                                                        required: { value: model.sdpi3 != '' },
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <CustomAvField
                                                                    name="sdpi3"
                                                                    type="number"
                                                                    value={data.sdpi3}
                                                                    label={t("SD - Độ lệch chuẩn")}
                                                                    onChange={val => {
                                                                        setModel({ sdpi3: val || '' })
                                                                    }}
                                                                    errorMessage={getInvalidMessageI18n(t, "SD - Độ lệch chuẩn")}
                                                                    validate={{
                                                                        required: { value: model.meanPI3 != '' },
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <CustomAvField
                                                                    name="deCOdd3"
                                                                    type="number"
                                                                    value={data.deCOdd3 || 0}
                                                                    label={t("Odd")}
                                                                    onChange={val => {
                                                                        setModel({ deCOdd3: val || '' })
                                                                    }}
                                                                    errorMessage={getInvalidMessageI18n(t, "Odd")}
                                                                    validate={{
                                                                        required: { value: model.meanPI3 != '' },
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </TabPane>
                                                    <TabPane tabId="2">
                                                        <div className="row mt-2">
                                                            <div className="col-12">
                                                                {t("Level")} 1
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <CustomAvField
                                                                    name="highI"
                                                                    type="text"
                                                                    value={data.highI}
                                                                    label={t("HighI")}
                                                                    onChange={val => {
                                                                        setModel({ highI: val || '' })
                                                                    }}
                                                                    errorMessage={getInvalidMessageI18n(t, "HighI")}
                                                                    validate={{
                                                                        required: { value: model.lowI != '' },
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <CustomAvField
                                                                    name="lowI"
                                                                    type="text"
                                                                    value={data.lowI}
                                                                    label={t("LowI")}
                                                                    onChange={val => {
                                                                        setModel({ lowI: val || '' })
                                                                    }}
                                                                    errorMessage={getInvalidMessageI18n(t, "LowI")}
                                                                    validate={{
                                                                        required: { value: model.highI != '' },
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <CustomAvField
                                                                    name="eval"
                                                                    type="text"
                                                                    value={data.eval}
                                                                    label={t("Eval")}
                                                                />
                                                            </div>
                                                            <div className="col-12">
                                                                {t("Level")} 2
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <CustomAvField
                                                                    name="highI2"
                                                                    type="text"
                                                                    value={data.highI2}
                                                                    label={t("HighI")}
                                                                    onChange={val => {
                                                                        setModel({ highI2: val || '' })
                                                                    }}
                                                                    errorMessage={getInvalidMessageI18n(t, "HighI")}
                                                                    validate={{
                                                                        required: { value: model.lowI2 != '' },
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <CustomAvField
                                                                    name="lowI2"
                                                                    type="text"
                                                                    value={data.lowI2}
                                                                    label={t("LowI")}
                                                                    onChange={val => {
                                                                        setModel({ lowI2: val || '' })
                                                                    }}
                                                                    errorMessage={getInvalidMessageI18n(t, "LowI")}
                                                                    validate={{
                                                                        required: { value: model.highI2 != '' },
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <CustomAvField
                                                                    name="eval2"
                                                                    type="text"
                                                                    value={data.eval2}
                                                                    label={t("Eval")}
                                                                />
                                                            </div>
                                                        </div>
                                                    </TabPane>
                                                </TabContent>
                                            )}
                                        >
                                            <CustomNavLink tabId="1">
                                                <span>{t("Quantitative")}</span>
                                            </CustomNavLink>
                                            <CustomNavLink tabId="2">
                                                <span>{t("Qualitative")}</span>
                                            </CustomNavLink>
                                        </CustomNav>
                                    </AccordionBody>
                                </Accordion>
                            </AccordionWrapper>
                        </div>
                    </AvForm>

                </ModalBody>
                <ModalFooter>
                    <CustomButton
                        text={t("common:Cancel")}
                        type="button"
                        onClick={toggle}
                        data-dismiss="modal"
                        className="button-width"
                    />

                    <CustomButton
                        text={t("common:Save")}
                        type="submit"
                        color="primary"
                        onClick={() => formEl?.current?.submit()}
                        className="save-user button-width"
                        isEdit
                    />
                </ModalFooter>
            </CustomModal>
        </React.Fragment>
    )
}

AddLotTestModal.defaultProps = {}

const mapStateToProps = ({ testResult }) => ({
})

const mapDispatchToProps = dispatch => ({
    // onGetNotifications: payload => dispatch(getNotifications(payload)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation(["managementQCPage", "common"])(AddLotTestModal))
