import PropTypes from "prop-types"
import { Label, Row } from "reactstrap"
//i18n
import { AvField } from "availity-reactstrap-validation"
import { CustomDatePicker, CustomSelect } from "components/Common"
import { parameterCode } from "constant"
import { convertDateFormat } from "helpers/utilities"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"

const FilterForm = ({ model, t, onChange }) => {
    return (
        <>
            <Row>
                <div className="col-12 mb-3">
                    <div className="custom-range-picker">
                        <div className="custom-range-picker-1">
                            <CustomDatePicker
                                name="start"
                                placeholder={"HH:mm YYYY-MM-DD"}
                                value={
                                    model.start ? convertDateFormat(model.start, "00:00 DD-MM-YYYY") : null
                                }
                                enableTime={true}
                                errorMessage={''}
                                required={false}
                                dateFormat={"H:i d-m-Y"}
                                showRemove={true}
                                onChangeHandler={e => {
                                    if (e.time.length > 0) {
                                        onChange({ start: e.time[0] })
                                    } else {
                                        onChange({ start: null })
                                    }
                                }
                                }
                            />
                        </div>
                        <div className="custom-range-picker-2">
                            <CustomDatePicker
                                name="toDate"
                                placeholder={"HH:mm YYYY-MM-DD"}
                                value={model.end ? convertDateFormat(model.end, "HH:mm DD-MM-YYYY") : null}
                                enableTime={true}
                                dateFormat={"H:i d-m-Y"}
                                onChangeHandler={e => {
                                    if (e.time.length > 0) {
                                        onChange({ end: e.time[0] })
                                    } else {
                                        onChange({ end: convertDateFormat(new Date(), "HH:mm DD-MM-YYYY") })
                                    }
                                }
                                }
                            />
                        </div>
                    </div>
                </div>
                <div className="col-12 mb-3">
                    <CustomSelect
                        label={t("Group Lot QC")}
                        portal
                        name="groupLot"
                        value={model.lotGroup || ''}
                        code={parameterCode.LOT_GROUP_QC}
                        onChange={(e, vals) => {
                            onChange({ lotGroup: vals[0] || '' })
                        }}
                    />
                </div>
                <div className="col-12 mb-3">
                    <Label for="isExpirated">{t("Expirated")}</Label>
                    <AvField
                        name="isExpirated"
                        type="checkbox"
                        className="form-input"
                        value={model.isExpired}
                        onChange={(e, vals) => {
                            onChange({ isExpired: e.target.checked || false })
                        }}
                    />
                </div>
            </Row>

        </>
    )
}

FilterForm.propTypes = {
    model: PropTypes.object,
    t: PropTypes.any
}

const mapDispatchToProps = dispatch => ({
})

export default withTranslation(["chartiQCPage", "common"])(connect(
    null,
    mapDispatchToProps
)(FilterForm))