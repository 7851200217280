export const GET_LIST_EXPRESSION_QC = "GET_LIST_EXPRESSION_QC"
export const GET_LIST_EXPRESSION_QC_SUCCESS = "GET_LIST_EXPRESSION_QC_SUCCESS"
export const GET_LIST_EXPRESSION_QC_FAIL = "GET_LIST_EXPRESSION_QC_FAIL"
/* DETAIL */
export const GET_EXPRESSION_QC_DETAIL = "GET_EXPRESSION_QC_DETAIL"
export const GET_EXPRESSION_QC_DETAIL_SUCCESS = "GET_EXPRESSION_QC_DETAIL_SUCCESS"
export const GET_EXPRESSION_QC_DETAIL_FAIL = "GET_EXPRESSION_QC_DETAIL_FAIL"
/* ADD */
export const ADD_NEW_EXPRESSION_QC = "ADD_NEW_EXPRESSION_QC"
export const ADD_NEW_EXPRESSION_QC_SUCCESS = "ADD_NEW_EXPRESSION_QC_SUCCESS"
export const ADD_NEW_EXPRESSION_QC_FAIL = "ADD_NEW_EXPRESSION_QC_FAIL"
/* EDIT */
export const UPDATE_EXPRESSION_QC = "UPDATE_EXPRESSION_QC"
export const UPDATE_EXPRESSION_QC_SUCCESS = "UPDATE_EXPRESSION_QC_SUCCESS"
export const UPDATE_EXPRESSION_QC_FAIL = "UPDATE_EXPRESSION_QC_FAIL"
/* DELETE */
export const DELETE_EXPRESSION_QC = "DELETE_EXPRESSION_QC"
export const DELETE_EXPRESSION_QC_SUCCESS = "DELETE_EXPRESSION_QC_SUCCESS"
export const DELETE_EXPRESSION_QC_FAIL = "DELETE_EXPRESSION_QC_FAIL"

