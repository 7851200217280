import { Check, CustomButton, Header } from "components/Common"
import CustomSplitPane from "components/Common/CustomSplitPaneLeft"
import { ModuleIds, permissionType } from "constant"
import React, { useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import ConfigLotTestQCLeft from "./LeftFrame"
import ConfigLotTestQCRight from "./RightFrame"

const RESOURCE = ModuleIds.ResultiQC


const ConfigLotTestQC = ({
  t,
}) => {
  const [selected, setSelected] = useState({})
  return (
    <React.Fragment>

      <div className="row position-relative h-100">
        <CustomSplitPane
          LeftFrame={() => (
            <ConfigLotTestQCLeft onChangeSelected={val => setSelected(val)} />
          )}
          RightFrame={() => (
            <ConfigLotTestQCRight config={selected} />
          )}
          InitialSize={66}
        />
      </div>
    </React.Fragment>
  )
}
const mapStateToProps = ({ testRequest }) => ({

})
const mapDispatchToProps = dispatch => ({

})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation(["managementQCPage", "message", "common"])(ConfigLotTestQC)))
