import {
    ADD_NEW_LOT_QC,
    ADD_NEW_LOT_QC_FAIL,
    ADD_NEW_LOT_QC_SUCCESS,
    ADD_NEW_LOT_TEST_QC,
    ADD_NEW_LOT_TEST_QC_FAIL,
    ADD_NEW_LOT_TEST_QC_SUCCESS,
    DELETE_LOT_QC,
    DELETE_LOT_QC_FAIL,
    DELETE_LOT_QC_SUCCESS,
    GET_LIST_LOT_LIST_FROM_LOT_TEST,
    GET_LIST_LOT_LIST_FROM_LOT_TEST_FAIL,
    GET_LIST_LOT_LIST_FROM_LOT_TEST_SUCCESS,
    GET_LIST_LOT_QC,
    GET_LIST_LOT_QC_FAIL,
    GET_LIST_LOT_QC_SUCCESS,
    GET_LOT_QC_DETAIL,
    GET_LOT_QC_DETAIL_FAIL,
    GET_LOT_QC_DETAIL_SUCCESS,
    GET_QC_LOT_TESTS,
    GET_QC_LOT_TESTS_FAIL,
    GET_QC_LOT_TESTS_SUCCESS,
    RESET_LOT_QC_SEARCH_QUERY,
    SET_LOT_QC_SEARCH_QUERY,
    UPDATE_LOT_QC,
    UPDATE_LOT_QC_FAIL,
    UPDATE_LOT_QC_SUCCESS,
    UPDATE_LOT_TEST_QC,
    UPDATE_LOT_TEST_QC_FAIL,
    UPDATE_LOT_TEST_QC_SUCCESS,
} from "./actionTypes"
export const resetLotQCSearchQuery = () => ({
    type: RESET_LOT_QC_SEARCH_QUERY,
})
export const setLotQCSearchQuery = searchQuery => ({
    type: SET_LOT_QC_SEARCH_QUERY,
    payload: searchQuery,
})
export const getListLotQC = payload => {
    return {
        type: GET_LIST_LOT_QC,
        payload: payload || {},
    }
}
export const getListLotQCSuccess = payload => ({
    type: GET_LIST_LOT_QC_SUCCESS,
    payload: payload,
})
export const getListLotQCFail = error => ({
    type: GET_LIST_LOT_QC_FAIL,
    payload: error,
})
export const getLotQCDetail = (id, callback) => ({
    type: GET_LOT_QC_DETAIL,
    id,
    callback,
})
export const getLotQCDetailSuccess = payload => ({
    type: GET_LOT_QC_DETAIL_SUCCESS,
    payload: payload,
})
export const getLotQCDetailFail = error => ({
    type: GET_LOT_QC_DETAIL_FAIL,
    payload: error,
})
export const addNewLotQC = payload => ({
    type: ADD_NEW_LOT_QC,
    payload: payload,
})
export const addNewLotQCSuccess = payload => ({
    type: ADD_NEW_LOT_QC_SUCCESS,
    payload: payload,
})
export const addNewLotQCFail = error => ({
    type: ADD_NEW_LOT_QC_FAIL,
    payload: error,
})
export const updateLotQC = payload => ({
    type: UPDATE_LOT_QC,
    payload: payload,
})
export const updateLotQCSuccess = payload => ({
    type: UPDATE_LOT_QC_SUCCESS,
    payload: payload,
})
export const updateLotQCFail = error => ({
    type: UPDATE_LOT_QC_FAIL,
    payload: error,
})
export const deleteLotQC = payload => ({
    type: DELETE_LOT_QC,
    payload: payload,
})
export const deleteLotQCSuccess = payload => ({
    type: DELETE_LOT_QC_SUCCESS,
    payload: payload,
})
export const deleteLotQCFail = error => ({
    type: DELETE_LOT_QC_FAIL,
    payload: error,
})
export const addNewLotTestQC = payload => ({
    type: ADD_NEW_LOT_TEST_QC,
    payload: payload,
})
export const addNewLotTestQCSuccess = payload => ({
    type: ADD_NEW_LOT_TEST_QC_SUCCESS,
    payload: payload,
})
export const addNewLotTestQCFail = error => ({
    type: ADD_NEW_LOT_TEST_QC_FAIL,
    payload: error,
})
export const getLotQCListFromLotTest = payload => {
    return {
        type: GET_LIST_LOT_LIST_FROM_LOT_TEST,
        payload: payload || {},
    }
}
export const getLotQCListFromLotTestSuccess = payload => ({
    type: GET_LIST_LOT_LIST_FROM_LOT_TEST_SUCCESS,
    payload: payload,
})
export const getLotQCListFromLotTestFail = error => ({
    type: GET_LIST_LOT_LIST_FROM_LOT_TEST_FAIL,
    payload: error,
})
export const getQCLotTest = payload => {
    return {
        type: GET_QC_LOT_TESTS,
        payload: payload || {},
    }
}
export const getQCLotTestSuccess = payload => ({
    type: GET_QC_LOT_TESTS_SUCCESS,
    payload: payload,
})
export const getQCLotTestFail = error => ({
    type: GET_QC_LOT_TESTS_FAIL,
    payload: error,
})
export const updateLotTestQC = payload => ({
    type: UPDATE_LOT_TEST_QC,
    payload: payload,
})
export const updateLotTestQCSuccess = payload => ({
    type: UPDATE_LOT_TEST_QC_SUCCESS,
    payload: payload,
})
export const updateLotTestQCFail = error => ({
    type: UPDATE_LOT_TEST_QC_FAIL,
    payload: error,
})