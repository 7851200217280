import { MS_Setting_Type } from "constant"
import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { Col, Container, Row } from "reactstrap"
import { getSettings, getSettingsIQC } from "store/actions"
import { getImageById } from "store/users/user-profiles/actions"

const Dashboard = ({ settings, onGetSettings, t, onGetImageByUserId, settingsIQC, onGetSettingsIQC }) => {
  useEffect(() => {
    onGetImageByUserId()
    onGetSettings({ type: MS_Setting_Type.DefaultDashboard })
    onGetSettingsIQC({ type: MS_Setting_Type.IQC })
  }, [])
  useEffect(() => {
    localStorage.setItem("DefaultDashboard", JSON.stringify(settings))
  }, [settings])
  return (
    <React.Fragment>
      <div>
        <div className="text-center p-3">
          <div className="h2" style={{ color: '#556ee6' }}>Welcome to IOLIS</div>
          <div>{t('Bạn sử dụng menu dưới đây để truy cập vào các chức năng.')}</div>
        </div>

        <Container fluid>
          <div style={{ marginBottom: '5px' }}>{t("Menu phân hệ Huyết học - Sinh hóa - GPB")}</div>
          <Row>
            {Array.from(new Set(settings.map(x => x.displayColumn))).map((displayColumn, dindex) => {
              return <Col xl={3} md={6} key={dindex}>
                <div className="card jobs-categories">
                  <div className="card-body">
                    {(settings.filter(x => x.displayColumn == displayColumn) || []).map((item, key) => (
                      <Link
                        key={key}
                        to={item.settingUri}
                        className="px-3 py-2 rounded d-block mb-2"
                        style={{ color: '#000', backgroundColor: '#F8F8FB', fontSize: '16px' }}
                      >
                        {t(item.setting)}
                        {/* <span className="badge float-end" style={{ minWidth: 20, backgroundColor: '#789EEC' }}>
                          {item.settingCount}
                        </span> */}
                        <span className="badge text-bg-info float-end bg-opacity-100">
                          <i className={item.displayIcon} style={{ fontSize: '18px', color: '#fff' }}></i>
                        </span>
                      </Link>
                    ))}
                  </div>
                </div>
              </Col>
            })
            }
          </Row>
        </Container>
        <Container fluid>
          <div style={{ marginBottom: '5px' }}>{t("Menu phân hệ IQC")}</div>
          <Row>
            {Array.from(new Set(settingsIQC.map(x => x.displayColumn))).map((displayColumn, dindex) => {
              return <Col xl={3} md={6} key={dindex}>
                <div className="card jobs-categories">
                  <div className="card-body">
                    {(settingsIQC.filter(x => x.displayColumn == displayColumn) || []).map((item, key) => (
                      <Link
                        key={key}
                        to={item.settingUri}
                        className="px-3 py-2 rounded d-block mb-2"
                        style={{ color: '#000', backgroundColor: '#F8F8FB', fontSize: '16px' }}
                      >
                        {t(item.setting)}
                        {/* <span className="badge float-end" style={{ minWidth: 20, backgroundColor: '#789EEC' }}>
                          {item.settingCount}
                        </span> */}
                        <span className="badge text-bg-info float-end bg-opacity-100">
                          <i className={item.displayIcon} style={{ fontSize: '18px', color: '#fff' }}></i>
                        </span>
                      </Link>
                    ))}
                  </div>
                </div>
              </Col>
            })
            }
          </Row>
        </Container>
      </div>
    </React.Fragment >
  )
}
const mapStateToProps = ({ MsDashboard }) => ({
  settings: MsDashboard.settings || [],
  settingsIQC: MsDashboard.settingsIQC || [],
  onGetUserDetail: PropTypes.func,
})
const mapDispatchToProps = dispatch => ({
  onGetSettings: payload => dispatch(getSettings(payload)),
  onGetSettingsIQC: payload => dispatch(getSettingsIQC(payload)),
  onGetImageByUserId: () => dispatch(getImageById()),
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["sidebar", "departmentPage"])(Dashboard))

