import {
  UPDATE_TEST_QC,
  UPDATE_TEST_QC_FAIL,
  UPDATE_TEST_QC_SUCCESS,
} from "./actionTypes"

export const updateTestQC = (test, callback) => ({
  type: UPDATE_TEST_QC,
  payload: test,
  callback
})

export const updateTestQCSuccess = test => ({
  type: UPDATE_TEST_QC_SUCCESS,
  payload: test,
})

export const updateTestQCFail = error => ({
  type: UPDATE_TEST_QC_FAIL,
  payload: error,
})