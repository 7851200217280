import React, { useState } from "react";

const Header = ({ showBothCharts, setShowBothCharts, levels, setLevels, showUnvalidatedPoint, setShowUnvalidatedPoint }) => {
  const [isExpanded, setIsExpanded] = useState(true); // State to manage header visibility

  return (
    <div style={headerWrapperStyle}>
      <div style={headerStyle}>
        <div style={contentStyle}>
          {isExpanded && (
            <>
              <div style={leftSectionStyle}>
                <div style={radioGroupStyle}>
                  <label style={labelStyle}>
                    <input type="radio" name="timeOption" defaultChecked />
                    Theo thời gian chạy
                  </label>
                  <label style={labelStyle}>
                    <input type="radio" name="timeOption" />
                    Theo lần chạy
                  </label>
                </div>
              </div>
              <div style={middleSectionStyle}>
                <div style={checkboxGroupStyle}>
                  <label style={labelStyle}>
                    <input
                      type="checkbox"
                      checked={showBothCharts}
                      onChange={(e) => setShowBothCharts(e.target.checked)}
                    />
                    Dùng chỉ số NXS
                  </label>
                  <label style={labelStyle}>
                    <input type="checkbox"
                    checked={showUnvalidatedPoint}
                    onChange={(e) => setShowUnvalidatedPoint(e.target.checked)} />
                    Hiển thị điểm chưa duyệt
                  </label>
                </div>
              </div>
              <div style={rightSectionStyle}>
                <select style={dropdownStyle}
                  value={levels === null ? "" : levels}
                  onChange={(e) => {
                    const value = e.target.value;
                    setLevels(value === "Mặc định" ? null : parseInt(value, 10));
                  }}>
                  <option value="Mặc định">Mặc định</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                </select>
                <div style={verificationStyle}>
                  <span style={verificationTextStyle}>✔ Levey Jennings</span>
                </div>
              </div>
            </>
          )}
        </div>
        <button onClick={() => setIsExpanded(!isExpanded)} style={toggleButtonStyle}>
          {isExpanded ? "^" : "v"}
        </button>
      </div>
    </div>
  );
};

const headerWrapperStyle = {
  marginBottom: "25px", // Add space below the header
};

const headerStyle = {
  display: "flex",
  justifyContent: "space-between", // Ensure the expand button stays on the right
  alignItems: "center",
  padding: "5px 10px",
  border: "1px solid #ccc",
  borderRadius: "5px",
  backgroundColor: "#f9f9f9", // Header background color
};

const contentStyle = {
  display: "flex",
  flex: 1, // Ensure content stretches fully
  justifyContent: "space-between",
};

const leftSectionStyle = {
  flex: 2, // Proportion for the left section
};

const middleSectionStyle = {
  flex: 2, // Proportion for the middle section
  display: "flex",
  flexDirection: "column",
};

const rightSectionStyle = {
  flex: 1, // Proportion for the right section
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
};

const radioGroupStyle = {
  display: "flex",
  flexDirection: "column", // Stack radio buttons
  gap: "5px",
};

const checkboxGroupStyle = {
  display: "flex",
  flexDirection: "column", // Stack checkboxes
  gap: "5px",
};

const labelStyle = {
  fontSize: "14px",
};

const dropdownLabelStyle = {
  fontSize: "14px",
  marginBottom: "5px",
};

const dropdownStyle = {
  padding: "5px",
  fontSize: "14px",
  borderRadius: "5px",
  border: "1px solid #ccc",
};

const verificationStyle = {
  marginTop: "5px",
};

const verificationTextStyle = {
  color: "green",
  fontSize: "14px",
  fontWeight: "bold",
};

const toggleButtonStyle = {
  padding: "5px 10px",
  border: "none", // Removed border
  borderRadius: "5px",
  backgroundColor: "#f9f9f9", // Match header background color
  cursor: "pointer",
};

export default Header;
