import { Fragment, useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import ReactTooltip from "react-tooltip"
import { Card, CardHeader, Col, Row } from "reactstrap"
import { getDepartmentDetail, getPhysicianDetail } from "store/actions"
import TimeLine from "./TimeLine"
import { isEmpty } from "lodash"
import { CustomSID } from "components/Common"
import { formatTime } from "helpers/utilities"

const RightTab = ({
  requestSampleTracking,
  loading,
  onGetPhysicianDetail,
  onGetDepartmentDetail,
  physician,
  department,
  t,
}) => {
  const [totalTime, setTotalTime] = useState(0)
  const [timeCollectionAndDelivery, setTimeCollectionAndDelivery] = useState(null);
  const [timeDeliveryAndReceived, setTimeDeliveryAndReceived] = useState(null);
  const [timeCorrectionAndReceived, setTimeCorrectionAndReceived] = useState(null);
  const [timeConfirmAndReceive, setTimeConfirmAndReceive] = useState(null);
  const [timeResultAndConfirm, setTimeResultAndConfirm] = useState(null);
  const [timeValidAndConfirm, setTimeValidAndConfirm] = useState(null);
  const [timeSignAndConfirm, setTimeSignAndConfirm] = useState(null);
  const getMinuteBetween = (fromDate, totDate) => {
    return Math.floor(
      Math.abs(new Date(fromDate) - new Date(totDate)) / 1000 / 60
    )
  }

  const caculateTAT = () => {
    let data = requestSampleTracking
    const minuteCollectionAndDelivery =
      !data.deliveryTime || !data.collectionTime
        ? data.collectionTime
          ? getMinuteBetween(new Date(), data.collectionTime)
          : null
        : getMinuteBetween(data.deliveryTime, data.collectionTime)

    const minuteDeliveryAndReceived =
      !data.receivedTime || !data.deliveryTime
        ? null
        : getMinuteBetween(data.receivedTime, data.deliveryTime)

    const minuteCorrectionAndReceived = 0

    //confirm and receive
    const minuteConfirmAndReceive =
      !data.resultTime || !data.receivedTime
        ? null
        : getMinuteBetween(data.resultTime, data.receivedTime)

        const minuteResultAndConfirm =
      !data.resultTime || !data.confirmTime
        ? null
        : getMinuteBetween(data.confirmTime, data.resultTime)

    const minuteValidAndCofirm =
      !data.validTime || !data.confirmTime
        ? null
        : getMinuteBetween(data.confirmTime, data.validTime)

    const minuteSignAndConfirm =
      !data.signTime || !data.confirmTime
        ? null
        : getMinuteBetween(data.signTime, data.confirmTime)

    const calTat =
      minuteCollectionAndDelivery +
      minuteDeliveryAndReceived +
      minuteCorrectionAndReceived +
      minuteConfirmAndReceive +
      minuteValidAndCofirm +
      minuteSignAndConfirm
      setTimeCollectionAndDelivery(formatTime(minuteCollectionAndDelivery));
      setTimeDeliveryAndReceived(formatTime(minuteDeliveryAndReceived));
      setTimeCorrectionAndReceived(formatTime(minuteCorrectionAndReceived));
      setTimeConfirmAndReceive(formatTime(minuteConfirmAndReceive));
      setTimeResultAndConfirm(formatTime(minuteResultAndConfirm));
      setTimeValidAndConfirm(formatTime(minuteValidAndCofirm));
      setTimeSignAndConfirm(formatTime(minuteSignAndConfirm));
    if (data.collectionTime) {
      if (data.validTime) {
        const calTime = getMinuteBetween(new Date(data.validTime), new Date(data.collectionTime))
        setTotalTime(calTime)
      } else {
        const calTime = getMinuteBetween(new Date(), new Date(data.collectionTime))
        setTotalTime(calTime)
      }
    }
  }
  useEffect(() => {
    caculateTAT()
    if (requestSampleTracking && requestSampleTracking.physicianId) {
      onGetPhysicianDetail({ id: requestSampleTracking.physicianId })
      onGetDepartmentDetail(requestSampleTracking.departmentId)
    }
  }, [requestSampleTracking])

  const dataProfiles = requestSampleTracking.profiles || []
  const dataTest = requestSampleTracking.tests || []
  const dataPendings = requestSampleTracking.pendingTests || []
  return (
    <Fragment>
      <Card style={{ height: "100%" }}>
        <CardHeader
          style={{
            background: "#fff",
          }}
        >
          <Row>
            <Col lg={6}>
              <p style={{ fontSize: 16, fontWeight: 600 }}>
                SID:  
                {requestSampleTracking.sid &&
                  <>
                    <CustomSID
                      textOnly={true}
                      sid={requestSampleTracking.sid}
                    />
                    {` - ${requestSampleTracking.sampleTypeName}`}
                  </>
                }
              </p>
              <p style={{ fontSize: 14 }}>
                PID:
                {requestSampleTracking.sid &&
                  ` ${requestSampleTracking.patientId
                  } - ${requestSampleTracking.patientName?.trim()} - ${requestSampleTracking.genderName
                  }`}
              </p>
            </Col>
            <Col
              lg={4}
              style={{
                width: 150,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <i
                data-tip
                data-for={"department"}
                className="mdi mdi-briefcase text-primary"
                style={{ fontSize: 20, height: "fit-content" }}
              />
              <ReactTooltip id={"department"} place="bottom" effect="solid">
              {<p>{t("Departments")}: {!isEmpty(requestSampleTracking) && department.name}</p>}
              </ReactTooltip>
              <i
                data-tip
                data-for={"information"}
                className="mdi mdi-information text-primary"
                style={{ fontSize: 20, height: "fit-content" }}
              />
              <ReactTooltip id={"information"} place="bottom" effect="solid">
                <p>{t("Physican")}: {physician.FullName}</p>
              </ReactTooltip>
              <i
                data-tip
                data-for={"account"}
                className="mdi mdi-account text-primary"
                style={{ fontSize: 20, height: "fit-content" }}
              />
              <ReactTooltip id={"account"} place="bottom" effect="solid">
                {t("Objects")}: {requestSampleTracking.serviceTypeName}
              </ReactTooltip>
              <i
                data-tip
                data-for={"request"}
                className="mdi mdi-calendar-plus text-primary"
                style={{ fontSize: 20, height: "fit-content" }}
              />
              <ReactTooltip id={"request"} place="bottom" effect="solid">
                <div>
                  {t("Services")}:
                  {dataProfiles
                    .filter(
                      x => dataTest.findIndex(z => z.profileCode == x.code) >= 0
                    )
                    .map(item => (
                      <div key={item.id}>
                        <div>
                          <>&nbsp;</>-<>&nbsp;</>
                          {item.profileName} ({item.code})
                        </div>
                        {dataTest
                          .filter(t => t.profileCode == item.code)
                          .map(row => (
                            <div key={row.id}>
                              <>&nbsp;</>
                              <>&nbsp;</>+<>&nbsp;</>
                              {row.testName} ({row.testCode})
                            </div>
                          ))}
                      </div>
                    ))}
                  {dataTest
                    .filter(
                      x =>
                        dataProfiles.findIndex(z => z.code == x.profileCode) < 0
                    )
                    .map(row => (
                      <div key={row.id}>
                        <div>
                          <>&nbsp;</>-<>&nbsp;</>
                          {row.testName} ({row.testCode})
                        </div>
                      </div>
                    ))}
                </div>
              </ReactTooltip>

              <i
                data-tip
                data-for={"pending"}
                className="mdi mdi-blood-bag text-primary"
                style={{ fontSize: 20, height: "fit-content" }}
              />

              <ReactTooltip id={"pending"} place="bottom" effect="solid">
                <div>
                  {t("Pending Test")}:
                  {dataProfiles
                    .filter(
                      x =>
                        dataPendings.findIndex(z => z.profileCode == x.code) >=
                        0
                    )
                    .map(item => (
                      <div key={item.id}>
                        <div>
                          <>&nbsp;</>-<>&nbsp;</>
                          {item.profileName}
                        </div>
                        {dataPendings
                          .filter(t => t.profileCode == item.code)
                          .map(row => (
                            <div key={row.id}>
                              <>&nbsp;</>
                              <>&nbsp;</>+<>&nbsp;</>
                              {row.testName}
                            </div>
                          ))}
                      </div>
                    ))}
                  {dataPendings
                    .filter(
                      x =>
                        dataProfiles.findIndex(z => z.code == x.profileCode) < 0
                    )
                    .map(row => (
                      <div key={row.id}>
                        <div>
                          <>&nbsp;</>-<>&nbsp;</>
                          {row.testName}
                        </div>
                      </div>
                    ))}
                </div>
              </ReactTooltip>
            </Col>
            {requestSampleTracking.sid && (
              <Col lg={2}>
                <ReactTooltip id={"tat"} place="bottom" effect="solid">
                  {t("Time Current")}: {totalTime} min
                </ReactTooltip>
                <div style={{ marginTop: 10 }}>
                  <div
                    className="progress-xl progress"
                    data-tip
                    data-for={"tat"}
                  >
                    <div
                      className={`progress-bar ${(totalTime / requestSampleTracking?.tat) * 100 > 100
                        ? "bg-danger"
                        : "bg-success"
                        }`}
                      role="progressbar"
                      aria-valuenow={
                        (totalTime / requestSampleTracking?.tat) * 100
                      }
                      aria-valuemin="0"
                      aria-valuemax="100"
                      style={{
                        width: `${(totalTime / requestSampleTracking?.tat) * 100
                          }%`,
                      }}
                    >
                      {requestSampleTracking.tat &&
                        ((totalTime / requestSampleTracking?.tat) * 100).toFixed(1)}%
                    </div>
                  </div>
                </div>
              </Col>
            )}
            <Col style={{ fontSize: 16, fontWeight: 600, textAlign: "right" }}>
              <div style={{ marginTop: 10 }}>
                TAT{" "}
                {requestSampleTracking.tat && requestSampleTracking.tat !== 0
                  ? `${requestSampleTracking?.tat} ${t("min")}`
                  : "N/A"}
              </div>
            </Col>
          </Row>
        </CardHeader>
        <TimeLine
          data={requestSampleTracking}
          timeCollectionAndDelivery={timeCollectionAndDelivery}
          timeDeliveryAndReceived={timeDeliveryAndReceived}
          timeCorrectionAndReceived={timeCorrectionAndReceived}
          timeConfirmAndReceive={timeConfirmAndReceive}
          timeResultAndConfirm={timeResultAndConfirm}
          timeValidAndConfirm={timeValidAndConfirm}
          timeSignAndConfirm={timeSignAndConfirm}
        />
      </Card>
    </Fragment>
  )
}

const mapStateToProps = ({ sampleTracking, physician, department }) => ({
  requestSampleTracking: sampleTracking.requestSampleTracking,
  loading: sampleTracking.loadingRequestSampleTrackingDetail,
  physician: physician.physician,
  department: department.department,
})
const mapDispatchToProps = dispatch => ({
  onGetPhysicianDetail: physicianId =>
    dispatch(getPhysicianDetail(physicianId)),
  onGetDepartmentDetail: departmentId =>
    dispatch(getDepartmentDetail(departmentId)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation(["testConfig"])(RightTab)))
