import { CustomBreadcrumb, PageContent } from "components/Common";
import { ModuleIds } from "constant";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import ConfigLotTestQC from "./ConfigLotTestQC";

const RESOURCE = ModuleIds.ManagementQC
const ConfigLotTestQCContainer = ({ t }) => {
    const titleTag = `Management QC`
    return (
        <PageContent title={titleTag}>
            <CustomBreadcrumb
                breadcrumbItem={t("Management QC")}
                resource={RESOURCE}
            />
            <ConfigLotTestQC />
        </PageContent>
    );
}

ConfigLotTestQCContainer.propTypes = {
    t: PropTypes.any,
}

export default withTranslation(["managementQCPage"])(ConfigLotTestQCContainer)