import { AvForm } from "availity-reactstrap-validation"
import { Check, CustomAvField, CustomButton } from "components/Common"
import { ModuleIds, permissionType } from "constant"
import React from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import ReactTooltip from "react-tooltip"
import { Label } from "reactstrap"

const RESOURCE = ModuleIds.ChartiQC


const Header = ({
    t,
    onClickAdd,
    config
}) => {

    return (
        <React.Fragment>
            <div className="row header-content">
                <div className="col-auto header-left">
                    <div className="lot">{t("Lot")}: <span style={{ color: '#556ee6' }}>{config.lotId} - {config.lotName} - {t("Range")}:{config.levelName}</span></div>
                    <div className="lot">{t("Machine")}: {config.machineName}</div>
                </div>
                <div className="col header-right">
                    <div>
                        <AvForm>
                            <div className="d-flex gap-3">
                                <Label className="form-check-label"
                                    style={{ paddingTop: '1px', fontSize: '16px' }} >
                                    {t("Result Text")}
                                </Label>
                                <div className="form-check form-switch">
                                    <CustomAvField
                                        type="checkbox"
                                        direction="down"
                                        name='isResultText'
                                        detected={false}
                                        onChange={(value) => {

                                        }}
                                    />
                                </div>
                            </div>
                        </AvForm>
                    </div>
                    <div>
                        <ReactTooltip
                            id={`tooltip-custom-management-qc`}
                            place="bottom"
                            effect="solid"
                            className="custom-tooltip"
                        >
                            <div className="tooltip-content">
                                <span>{t("Tích chọn để thêm mới Lot")}</span>
                                <span>{t("Test cho XN bán định lượng")}</span>
                            </div>
                        </ReactTooltip>
                        <span
                            className="tooltip-icon-wrapper"
                            data-tip
                            data-for={`tooltip-custom-management-qc`}
                        >
                            <i className="fas fa-exclamation-circle text-primary font-size-16"></i>
                        </span>
                    </div>
                    <div>
                        <Check permission={permissionType.U} resource={RESOURCE}>
                            <CustomButton
                                text={t("common:Add")}
                                type="button"
                                color="primary"
                                outline
                                onClick={() => {
                                    onClickAdd && onClickAdd()
                                }}
                            >
                                {t("common:Add")}
                            </CustomButton>
                        </Check>
                    </div>
                    <div>
                        <Check permission={permissionType.U} resource={RESOURCE}>
                            <CustomButton
                                text={t("Add/Copy")}
                                type="button"
                                color="primary"
                                outline
                                onClick={() => {
                                }}
                            >
                                {t("Add/Copy")}
                            </CustomButton>
                        </Check>
                    </div>
                    <div>
                        <Check permission={permissionType.U} resource={RESOURCE}>
                            <CustomButton
                                text={t("Import Excel")}
                                type="button"
                                color="success"
                                onClick={() => {
                                }}
                            >
                                {t("Import Excel")}
                            </CustomButton>
                        </Check>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
const mapStateToProps = ({ testRequest }) => ({

})
const mapDispatchToProps = dispatch => ({

})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation(["chartiQCPage", "message", "common"])(Header)))
