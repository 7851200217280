import { Check, CustomButton } from "components/Common"
import { ModuleIds, permissionType } from "constant"
import React, { useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { read, utils } from "xlsx"

const RESOURCE = ModuleIds.ManagementQC


const Header = ({
    t,
    onClickAdd,
    onClickAddText,
    config,
    onSaveClick,
    onClickCopy,
    disableSave
}) => {
    const [fileName, setFileName] = useState("");
    const [dataSource, setDataSource] = useState([]);

    const resetData = () => {
        setDataSource([]);
        setFileName("");
        document.getElementById("importExcelLotTest").value = null;
    }

    const handleImport = e => {
        const files = e.target.files;
        if (files.length) {
            let rowChoose = [];
            const file = files[0];
            const reader = new FileReader();
            reader.onload = event => {
                const wb = read(event.target.result);
                const sheets = wb.SheetNames;
                if (sheets.length) {
                    const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
                    rows.forEach((item, index) => {
                        console.log(item);

                    });
                    // setDataSource([...rowChoose]);
                }
            };
            reader.readAsArrayBuffer(file);
        } else {
            resetData();
        }
    };

    return (
        <React.Fragment>
            <input
                id="importExcelLotTest"
                name="importTestTemplate"
                type="file"
                className="d-none"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                onChange={e => {
                    setFileName(e.target.files[0]?.name || "")
                    handleImport(e)
                }}
            />
            <div className="row header-content">
                <div className="col-auto header-left">
                    <div className="lot">{t("Lot")}: <span style={{ color: '#556ee6' }}>{config.lotId} - {config.lotName} - {t("Range")}:{config.levelName}</span></div>
                    <div className="lot">{t("Machine")}: {config.machineName}</div>
                </div>
                <div className="col header-right">
                    <div>
                        <Check permission={permissionType.C} resource={RESOURCE}>
                            <CustomButton
                                text={t("common:Add")}
                                type="button"
                                color="primary"
                                disabled={!(config.id)}
                                outline
                                onClick={() => {
                                    onClickAdd && onClickAdd()
                                }}
                            >
                                {t("common:Add")}
                            </CustomButton>
                        </Check>
                    </div>
                    <div>
                        <Check permission={permissionType.C} resource={RESOURCE}>
                            <CustomButton
                                text={t("Add Semi-Quant")}
                                type="button"
                                color="primary"
                                disabled={!(config.id)}
                                outline
                                onClick={() => {
                                    onClickAddText && onClickAddText()
                                }}
                            >
                                {t("Add Semi-Quant")}
                            </CustomButton>
                        </Check>
                    </div>
                    <div>
                        <Check permission={permissionType.C} resource={RESOURCE}>
                            <CustomButton
                                text={t("Copy Lot Test")}
                                type="button"
                                color="warning"
                                outline
                                onClick={() => {
                                    onClickCopy && onClickCopy()
                                }}
                            >
                                {t("Copy Lot Test")}
                            </CustomButton>
                        </Check>
                    </div>
                    <div>
                        <Check permission={permissionType.C} resource={RESOURCE}>
                            <CustomButton
                                text={t("common:Save")}
                                type="button"
                                color="primary"
                                outline
                                disabled={disableSave}
                                onClick={() => {
                                    onSaveClick()
                                }}
                            >
                                {t("common:Save")}
                            </CustomButton>
                        </Check>
                    </div>
                    <div>
                        <Check permission={permissionType.Excel} resource={ModuleIds.LotTestQC}>
                            <CustomButton
                                text={t("Import Excel")}
                                type="button"
                                color="success"
                                onClick={() => {
                                    resetData();
                                    $(`#importExcelLotTest`).click()
                                }}
                            >
                                {t("Import Excel")}
                            </CustomButton>
                        </Check>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
const mapStateToProps = ({ testRequest }) => ({

})
const mapDispatchToProps = dispatch => ({

})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation(["managementQCPage", "message", "common"])(Header)))
