import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Alert, Button, Card, CardBody, Col, Container, Row } from "reactstrap"

import {
  CopyrightFooter,
  CustomButton,
  CustomSelect,
  LetterAvatar,
} from "components/Common"

//redux
import { useDispatch, useSelector } from "react-redux"

import { Link, withRouter } from "react-router-dom"

// availity-reactstrap-validation
import {
  AvField,
  AvForm,
  AvGroup
} from "availity-reactstrap-validation"

// actions
import { getAuthorizeMenus, getUserInfo, loginUser } from "store/actions"

// import images
// import logo from "assets/images/logo-sm.png"
import banner from "assets/images/panel-login.png"
import logo from "../../assets/images/Logo Isolis-11.png"

import { parameterCode } from "constant"
import { getAuthorizePageByResourceNameWithoutError, getCodesByParameterId, getCompanyById } from "helpers/app-backend"
import { getSidLengthByCompanyId } from "helpers/app-backend/accessionNumbers_backend_helper"
import { getI18nextLng, getInputChangedValue, isEmptyValues } from "helpers/utilities"
import { getImageById } from "store/users/user-profiles/actions"
import "../../assets/scss/custom/pages/_login.scss"

const Login = ({ history, location }) => {
  const [authUser, setAuthUser] = useState()
  const [userInfo, setUserInfo] = useState()
  const [isSingleRole, setIsSingleRole] = useState(false)
  const [roles, setRoles] = useState([])
  const [selectedRole, setSelectedRole] = useState()
  const userLocal = localStorage.getItem("authUser")
  const [isDemo, setIsDemo] = useState(false);
  const [sizes, setSizes] = useState([])
  const [loginModel, setLoginModel] = useState({ emailzz: "", passwordzz: "" })
  const dispatch = useDispatch()
  const [showPassword, setShowPassword] = useState(false)
  const [password, setPassword] = useState("")
  const [rememberUP, setRememberUP] = useState(false);
  const [username, setUsername] = useState('');

  //string to byte
  const stringToArrayBuffer = (str) => {
    return new TextEncoder().encode(str);
  };

  // byte to string
  const arrayBufferToString = (buffer) => {
    return new TextDecoder().decode(buffer);
  };

  const generateKey = async (secretKey) => {
    return crypto.subtle.digest("SHA-256", stringToArrayBuffer(secretKey));
  };

  //encode AES-GCM
  const encryptData = async (data, secretKey) => {
    const iv = window.crypto.getRandomValues(new Uint8Array(12));

    const key = await crypto.subtle?.importKey(
      "raw",
      await generateKey(secretKey),
      { name: "AES-GCM" },
      false,
      ["encrypt"]
    );
    const encrypted = await crypto.subtle?.encrypt(
      {
        name: "AES-GCM",
        iv: iv
      },
      key,
      stringToArrayBuffer(data)
    );

    return {
      iv: Array.from(iv),
      ciphertext: Array.from(new Uint8Array(encrypted))
    };
  };

  //decode AES-GCM
  const decryptData = async (encryptedData, secretKey) => {
    const { iv, ciphertext } = encryptedData;
    const key = await crypto.subtle?.importKey(
      "raw",
      await generateKey(secretKey),
      { name: "AES-GCM" },
      false,
      ["decrypt"]
    );
    const decrypted = await crypto.subtle?.decrypt(
      {
        name: "AES-GCM",
        iv: new Uint8Array(iv)
      },
      key,
      new Uint8Array(ciphertext)
    );
    return arrayBufferToString(decrypted);
  };

  const handleRememberMe = async (username, password, rememberUP) => {
    const secretKey = "Lis@@2023Lis@@2023";

    if (rememberUP) {
      const encryptedUsername = await encryptData(username, secretKey);
      const encryptedPassword = await encryptData(password, secretKey);
      localStorage.setItem('rememberUP', true);
      localStorage.setItem("rememberedUsername", JSON.stringify(encryptedUsername));
      localStorage.setItem("rememberedPassword", JSON.stringify(encryptedPassword));
    } else {
      localStorage.removeItem("rememberedUsername");
      localStorage.removeItem("rememberedPassword");
      localStorage.removeItem("rememberUP");
    }
  };

  const loadRememberedUser = async () => {
    const secretKey = "Lis@@2023Lis@@2023";

    const encryptedUsername = JSON.parse(localStorage.getItem("rememberedUsername"));
    const encryptedPassword = JSON.parse(localStorage.getItem("rememberedPassword"));

    if (encryptedUsername && encryptedPassword) {
      const username = await decryptData(encryptedUsername, secretKey);
      const password = await decryptData(encryptedPassword, secretKey);
      return { username, password };
    }

    return { username: "", password: "" };
  };

  useEffect(() => {
    const rememberMe = localStorage.getItem('rememberUP') === 'true';
    setRememberUP(rememberMe);
    const loadUser = async () => {
      const rememberedUser = await loadRememberedUser();
      setUsername(rememberedUser.username);
      setPassword(rememberedUser.password);
    };
    loadUser();
  }, []);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }
  const handlePasswordChange = e => {
    setPassword(e.target.value)
  }

  const handleUsernameChange = e => {
    setUsername(e.target.value)
  }

  const { error, loading } = useSelector(state => ({
    error: state.Login.error,
    loading: state.Login.loading,
  }))
  const handleValidSubmit = async (event, values) => {

    event.preventDefault();
    await handleRememberMe(username, password, rememberUP);

    values.email = values.emailzz
    values.password = values.passwordzz

    if (values.email.toLocaleLowerCase().includes("@demo.com")) {
      setIsDemo(true)
    }

    dispatch(loginUser(values, history, location, getUserInformation))
  }

  const getUserInformation = loginInfo => {
    const token = loginInfo.access_token
    setAuthUser(loginInfo)
    dispatch(getUserInfo({ token, callback: userInfoHandler }))
  }

  const userInfoHandler = info => {
    const isArray = Array.isArray(info.role)
    const roles = info.role,
      updateRoles = []

    setUserInfo(info)

    if (isArray && roles.length > 1) {
      roles.forEach(_role => {
        const value = _role.substring(0, _role.indexOf("-") - 1)
        const label = _role.substring(_role.indexOf("-") + 2)
        updateRoles.push({ label, value })
      })

      setRoles(updateRoles)
      setIsSingleRole(!isArray)
    } else {
      setSelectedRole(roles)
      if (isDemo) {
        if (!isArray) {
          setIsSingleRole(true)
        }
      }
      else {
        if (!isArray) {
          setIsSingleRole(true)
        }
      }

    }
  }
  useEffect(() => {
    if (userInfo && !isSingleRole) {
      dispatch(getImageById());
    }
  }, [userInfo, isSingleRole]);

  useEffect(() => {
    if (userInfo && userLocal) {
      getCompanyInfo()
    }
  }, [userInfo, userLocal])

  const getCompanyInfo = async () => {
    await getCompanyById(userInfo.company).then(res => {
      localStorage.setItem("OneLisCompany", JSON.stringify(res))
    })
    await getSidLengthByCompanyId(userInfo.company).then(res => {
      // dispatch(setSidLengthSuccess(res))
      localStorage.setItem("SidLength", res)
    })

  }

  const onSelectRoleSubmit = async (e, value) => {
    const userInfoStore = JSON.parse(JSON.stringify(userInfo))

    const role = value.roleId
    userInfoStore.selectedRole = role

    localStorage.setItem("authUser", JSON.stringify(authUser))
    localStorage.setItem("userInfo", JSON.stringify(userInfoStore))

    dispatch(getAuthorizeMenus({ role, callback: handleRedirect }))
  }

  const SelectRoleComponent = ({ onSubmit, name }) => {
    return (
      <AvForm className="form-horizontal" onValidSubmit={onSubmit}>
        <div className="text-center mb-4">
          <div className="avatar-md mx-auto mb-1">
            <LetterAvatar name={name} className="font-size-20" />
          </div>
          <h5 className="font-size-15 text-truncate">{name}</h5>
        </div>
        <label htmlFor="roleId" className="form-label label-input-login">
          {"Chọn vai trò"}
        </label>
        <CustomSelect
          name="roleId"
          value={roles[0]?.["value"]}
          options={[...roles]}
          label={""}
          required
          customStyle={{ fontSize: 16 }}
        />
        <div className="mt-3 d-grid">
          <CustomButton
            type="submit"
            color="success"
            text={"Tiếp tục"}
            block
            style={{ fontSize: 16 }}
            isEdit
          />
        </div>
      </AvForm>
    )
  }

  const onSubmitSize = (e, value) => {
    localStorage.setItem("size", value.roleId)
    localStorage.setItem("sizeName", sizes.find(x => `${x.value}` == `${value.roleId}`)?.label || '')
    setIsDemo(false);
    setIsSingleRole(roles.length <= 1)
  }

  let lang = getI18nextLng()

  const getSizes = async () => {
    let query = { lang, sort: 'code' }
    let res = await getCodesByParameterId(parameterCode.BUSSINESS_TYPE, query);
    res = res?.map(_item => {
      _item.value = _item.code
      _item.label = _item.message
      return _item
    })
    setSizes(res)
  }

  useEffect(() => {
    getSizes()

  }, [])

  const SelectSizeComponent = ({ name }) => {
    return (
      <AvForm className="form-horizontal" onValidSubmit={onSubmitSize}>
        <div className="text-center mb-4">
          <div className="avatar-md mx-auto mb-1">
            <LetterAvatar name={name} className="font-size-20" />
          </div>
          <h5 className="font-size-15 text-truncate">{name}</h5>
        </div>
        <CustomSelect
          code={parameterCode.BUSSINESS_TYPE}
          name="roleId"
          value={0}
          sort={"code"}
          label={"Size Select"}
          required
          options={sizes || []}
        />

        <div className="mt-3 d-grid">
          <CustomButton
            type="submit"
            color="success"
            text={"Continue"}
            block
            // loading={loading}
            isEdit
          />
        </div>
      </AvForm>
    )
  }

  const handleRedirect = async (menus, pageDefault) => {
    //const pathname = menus?.pageCode?.substring(1) || ""
    if (pageDefault.item) {
      try {
        let items = pageDefault.item.split(':')
        const res = await getAuthorizePageByResourceNameWithoutError("_" + items[2], items[1])
        if (res._Permissions[items[2]].length > 0) {
          history.push({ pathname: `/${items[2]}` })
          return;
        }
      } catch (error) {
        console.log(error);
      }

    }
    const pathname = ""
    const { from } =
      !isEmptyValues(location?.state) &&
        (location.state.from?.pathname !== "/" ||
          location.state.from?.pathname !== "/Page-starter")
        ? location.state
        : {
          from: { pathname: `/${pathname}` },
        }
    history.push(from)

  }

  const onInputChange = e => {
    const { name, value } = getInputChangedValue(e)

    setLoginModel(prev => ({ ...prev, [name]: value }))
  }


  useEffect(() => {
    if (isSingleRole) {
      onSelectRoleSubmit(null, {
        roleId: selectedRole?.substring(0, selectedRole.indexOf("-") - 1),
      })
    }
  }, [isSingleRole])

  const { family_name, given_name, name } = userInfo || {}
  const displayName =
    family_name && given_name ? `${family_name} ${given_name}` : name
  const checkRender = () => {
    if (userInfo && isDemo == true) {
      return 1
    }
    else if (userInfo && isDemo != true && !isSingleRole) {
      return 2
    }
    return 3
  }
  return (
    <React.Fragment>
      <MetaTags>
        <title>Login | OneLIS Solution</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Sign in to continue.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={banner} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0" style={{ paddingBottom: "115px" }}>
                  <div>
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            // className="rounded-circle"
                            height="45"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    {checkRender() == 1 &&
                      <SelectSizeComponent
                        name={displayName}
                      />
                    }
                    {checkRender() == 2 &&
                      <SelectRoleComponent
                        onSubmit={onSelectRoleSubmit}
                        name={displayName}
                      />
                    }
                    {checkRender() == 3 &&
                      <AvForm
                        className="form-horizontal login-content"
                        onValidSubmit={(e, v) => {
                          handleValidSubmit(e, v)
                        }}
                        onChange={onInputChange}
                        model={loginModel}
                      >
                        {error ? <Alert color="danger">{error}</Alert> : null}

                        <div className="mb-3">
                          <label htmlFor="emailzz" className="form-label label-input-login">
                            Tên đăng nhập
                          </label>
                          <AvField
                            name="emailzz"
                            label=""
                            className="form-control"
                            placeholder="Nhập email/tên đăng nhập"
                            value={username}
                            onChange={handleUsernameChange}
                            type="text"
                            required
                            autoComplete="on"
                            errorMessage="Tên đăng nhập không được để trống."
                          />
                        </div>
                        <AvGroup className="mb-3">
                          <label htmlFor="password" className="form-label label-input-login">
                            Mật khẩu
                          </label>
                          <div className="custom-input-group">
                            <AvField
                              name="passwordzz"
                              type={showPassword ? "text" : "password"}
                              className="form-control"
                              placeholder="Nhập mật khẩu"
                              value={password}
                              onChange={handlePasswordChange}
                              required
                              errorMessage="Mật khẩu không được để trống."
                            />
                            <Button
                              color="secondary"
                              style={{
                                background: "inherit",
                                border: "none",
                              }}
                              onClick={togglePasswordVisibility}
                            >
                              {
                                password && (
                                  <i
                                    className={
                                      showPassword
                                        ? "fa fa-eye"
                                        : "fa fa-eye-slash"
                                    }
                                    style={{
                                      color: "#939598",
                                      cursor: "pointer",
                                    }}
                                    aria-hidden="true"
                                  ></i>
                                )
                              }

                            </Button>
                          </div>
                        </AvGroup>
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customControlInline"
                            checked={rememberUP}
                            onChange={() => setRememberUP(!rememberUP)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customControlInline"
                          >
                            Ghi nhớ
                          </label>
                        </div>

                        <div className="mt-3 d-grid">
                          <CustomButton
                            style={{ fontSize: 16 }}
                            type="submit"
                            color="primary"
                            text={"Đăng nhập"}
                            block
                            loading={loading}
                            isEdit
                          />
                        </div>

                        {/* <div className="mt-4 text-center">
                          <Link to="/forgot-password" className="text-muted">
                            <i className="mdi mdi-lock me-1" />
                            Forgot your password?
                          </Link>
                        </div> */}
                      </AvForm>
                    }

                    {/* {userInfo && !isSingleRole ? (
                      <SelectRoleComponent
                        onSubmit={onSelectRoleSubmit}
                        name={displayName}
                      />
                    ) : (
                      <AvForm
                        className="form-horizontal"
                        onValidSubmit={(e, v) => {
                          handleValidSubmit(e, v)
                        }}
                        onChange={onInputChange}
                        model={loginModel}
                      >
                        {error ? <Alert color="danger">{error}</Alert> : null}

                        <div className="mb-3">
                          <AvField
                            name="emailzz"
                            label="Email"
                            className="form-control"
                            placeholder="Enter email"
                            type="text"
                            required
                            autoComplete="on" />
                        </div>
                        <AvGroup className="mb-3">
                          <label htmlFor="password" className="form-label">
                            Password
                          </label>
                          <div className="custom-input-group">
                            <AvField
                              name="passwordzz"
                              type={showPassword ? "text" : "password"}
                              className="form-control"
                              placeholder="Enter password"
                              value={password}
                              onChange={handlePasswordChange}
                              required
                            />
                            <Button
                              color="secondary"
                              style={{
                                background: "inherit",
                                border: "none",
                              }}
                              onClick={togglePasswordVisibility}
                            >
                              {
                                password && (
                                  <i
                                    className={
                                      showPassword
                                        ? "fa fa-eye"
                                        : "fa fa-eye-slash"
                                    }
                                    style={{
                                      color: "#939598",
                                      cursor: "pointer",
                                    }}
                                    aria-hidden="true"
                                  ></i>
                                )
                              }

                            </Button>
                          </div>
                        </AvGroup>
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customControlInline"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customControlInline"
                          >
                            Remember me
                          </label>
                        </div>

                        <div className="mt-3 d-grid">
                          <CustomButton
                            type="submit"
                            color="primary"
                            text={"Log In"}
                            block
                            loading={loading}
                            isEdit
                          />
                        </div>

                        <div className="mt-4 text-center">
                          <Link to="/forgot-password" className="text-muted">
                            <i className="mdi mdi-lock me-1" />
                            Forgot your password?
                          </Link>
                        </div>
                      </AvForm>
                    )} */}
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <CopyrightFooter />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
