


//i18n
import {
    Accordion,
    AccordionBody, AccordionWrapper, CustomButton,
    FormSwitch
} from "components/Common"
import { Compare2Object, getI18nextLng } from "helpers/utilities"
import React, { useEffect, useReducer, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"

const ConfigModal = ({
    data,
    t,
    onChangeConfig,
}) => {
    let lang = getI18nextLng()
    const [height, setHeight] = useState(window.innerHeight)
    const [isRender, setIsRender] = useState(false)
    const [modal, setModal] = useState(false)
    const [config, setConfig] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            isAddManual: false,
        }
    )

    useEffect(() => {
        setTimeout(() => {
            getHeight()
        }, 500);
    }, [])

    const getHeight = () => {
        let top = document.getElementById("result-iqc-config-button").getBoundingClientRect().top;
        setHeight((window.innerHeight - top - 65) * window.devicePixelRatio)
    }


    useEffect(() => {
        if (modal == false) {
            setTimeout(() => {
                setIsRender(false)
            }, 400);
        }
        else {
            setIsRender(true)
        }
    }, [modal])

    useEffect(() => {
        onChangeConfig && onChangeConfig(config)
    }, [config])

    useEffect(() => {
        if (data) {
            if (!Compare2Object(data, config))
                setConfig(data)
        }

    }, [data])


    return (
        <React.Fragment>
            <div className="result-iqc-config">
                <CustomButton
                    color="secondary"
                    outline
                    id="result-iqc-config-button"
                    onClick={() => { setModal(prev => !prev) }}
                >
                    <i style={{ fontSize: 16 }} className={`bx bx-cog`}></i>
                </CustomButton>
                {isRender == true &&
                    <div className={`result-iqc-config-modal ${modal ? 'result-iqc-config-modal-active' : 'result-iqc-config-modal-inactive'}`} style={{ height: 'auto', maxHeight: `${height}px` }}>
                        <div className="row result-iqc-config-modal-loading">
                            <div className="col-12">
                                <AccordionWrapper defaultTab={["1"]}>
                                    <Accordion tabId={"1"} title={t("Result QC Manual")}>
                                        <AccordionBody className="p-3">
                                            <div className="d-flex">
                                                <label htmlFor="result-iqc-add-manual" className="flex-1" style={{ textAlign: 'left' }}>{t("Add QC Manual")}
                                                    <span className="text-danger">*</span>
                                                </label>
                                                <FormSwitch label={t("")}
                                                    id="result-iqc-add-manual"
                                                    name="result-iqc-add-manual"
                                                    value={config.isAddManual}
                                                    onChangeSwitch={(e) => {
                                                        setConfig({ isAddManual: !config.isAddManual })
                                                    }}
                                                />
                                            </div>
                                        </AccordionBody>
                                    </Accordion>
                                </AccordionWrapper>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </React.Fragment>
    )
}

ConfigModal.defaultProps = {}

const mapStateToProps = ({ testResult }) => ({
})

const mapDispatchToProps = dispatch => ({
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation(["managementQCPage", "common"])(ConfigModal))
