import { Check, CustomBootstrapInlineTable } from "components/Common"
import CustomPopover from "components/Common/Input/CustomPopover"
import { ModuleIds, RESULT_STATE_Text, TestRequest_Test_Type, parameterCode, permissionType } from "constant"
import { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import ReactTooltip from "react-tooltip"

const RESOURCE = ModuleIds.ManualRequest

const TestsTabTable = ({
    onSelect,
    onSelectAll,
    data,
    onEdit,
    onDelete,
    t,
    loading,
    onEditSampleType,
    onGetTestRequestSample,
    disabled,
    collapse,
    isEdit,
    ...rest
}) => {
    const [subProfileData, setSubProfileData] = useState([])
    const isCancel = (code) => parseInt(code) === RESULT_STATE_Text.Canceled ? { textDecoration: 'line-through' } : {}
    const space = collapse ? 0 : 310
    useEffect(() => {
        setSubProfileData(data)
    }, [data])

    const columns = [
        {
            dataField: "id",
            text: "id",
            hidden: true,
            searchable: false,
        },
        {
            dataField: "idx",
            text: "#",
            searchable: false,
            headerStyle: { width: "50px" },
            formatter: (cellContent, user, index) => {
                return index + 1
            },
        },
        {
            dataField: "name",
            text: t("Profile/Test"),
            style: { paddingLeft: "0px", paddingRight: "0px", width: "300px" },
            headerStyle: { width: "300px" },
            formatter: (cellContent, code, index) => {
                return (
                    <>
                        {
                            (code.type == TestRequest_Test_Type.PROFILE_GROUP) &&
                            <div style={isCancel(code.state)}>
                                <i className="fas fa-arrow-right"
                                    style={code.children && code.children.length > 0 ? { color: "#556ee6" } : {}}></i>
                                <span className="px-2" style={{ fontWeight: 'bold' }}>{code.name}</span>
                            </div>
                        }
                        {
                            (code.type == TestRequest_Test_Type.PROFILE) &&
                            <div style={isCancel(code.children[0]?.state)}>
                                <i className="fas fa-arrow-right"
                                    style={code.children && code.children.length > 0 ? { color: "#556ee6" } : {}}></i>
                                <span className="px-2" style={{ fontWeight: 'bold' }}>{code.name}</span>
                            </div >
                        }
                        {
                            (code.type == TestRequest_Test_Type.TEST) &&
                            <div style={isCancel(code.state)}>
                                <span>-</span>
                                <span className="px-2">{code.name}</span>
                            </div>
                        }
                    </>
                )
            }
        },
        {
            dataField: "code",
            text: t("Code"),
            style: { paddingLeft: "3px", paddingRight: "0px" },
            headerStyle: { width: "120px", paddingLeft: "0px", paddingRight: "0px" },
        },
        {
            dataField: "subSID",
            text: t("SubSID"),
            style: { paddingLeft: "0px", paddingRight: "0px" },
            headerStyle: { width: "40px", paddingLeft: "0px", paddingRight: "0px" },
            formatter: (cellContent, item, index) => {
                return (
                    <span>
                        {cellContent != 0 && cellContent}
                    </span>
                )
            },
        },
        {
            dataField: "categoryName",
            text: t("Category"),
            style: { padding: "0px",paddingLeft: "3px" },
            headerStyle: { width: "120px", paddingLeft: "0px", paddingRight: "0px" },
            formatter: (cellContent, item, index) => {
                return (
                    <>
                        {(item.type != TestRequest_Test_Type.PROFILE_GROUP) &&
                            <div
                                className="px-2 d-flex badge-gender"
                                style={{ width: '50px', height: '30px', backgroundColor: "#d4dbf9", color: "#556ee6", justifyContent: 'center' }}
                                id={`category-${index}`}
                                data-tip
                                data-for={`category-${index}`}
                            >
                                {item.category}
                                <ReactTooltip place="bottom" id={`category-${index}`} effect="solid" >
                                    {item.categoryName}
                                </ReactTooltip>
                            </div>
                        }
                    </>
                )
            },
        },
        {
            dataField: "sampleType",
            text: t("Sample Type"),
            headerStyle: { width: "100px", paddingLeft: "0px", paddingRight: "0px", textAlign: "left" },
            style: { padding: "0px",paddingLeft: "3px" },
            formatter: (cellContent, code, index) => {
                return <span>{(code?.children && code?.children[0]?.sampleTypeName) || code.sampleTypeName}</span>
            }
            // (
            //     <>

            //         {(code.type != TestRequest_Test_Type.PROFILE_GROUP) && !code.sampleType &&
            //             <div onClick={e => e.stopPropagation()}>
            //                 <CustomPopover isSearch={true} code={parameterCode.SAMPLE_TYPES} title={code.sampleTypeName}
            //                     valueName={code.sampleTypeName} value={code.sampleType}
            //                     onChange={(item) => {
            //                         onEditSampleType(code, item)
            //                     }}
            //                 />
            //             </div>
            //         }

            //         {(code.type != TestRequest_Test_Type.PROFILE_GROUP) && code.sampleType &&
            //             <div>{code.sampleTypeName}</div>
            //         }
            //     </>
            // ),
        },
        {
            dataField: "action",
            text: "",
            isDummyField: true,
            headerStyle: { width: "65px" },
            style: { textAlign: "center", width: "65px" },
            formatter: (cellContent, code, index) => {
                return (
                    <Check resource={RESOURCE} permission={permissionType.D} fallback={(
                        code.isNew && <div href="#" style={{
                            position: 'relative',
                            width: 20,
                            height: 20,
                            margin: '0 auto'
                        }}
                            onClick={() => onDelete(code)}
                        >
                            <i style={{ position: 'absolute', top: -5, left: 0 }}
                                className="mdi mdi-trash-can font-size-20 text-danger me-1" />{" "}
                        </div>)
                    } >
                        {!disabled && <div href="#" style={{
                            position: 'relative',
                            width: 20,
                            height: 20,
                            margin: '0 auto'
                        }}
                            onClick={() => onDelete(code)}
                        >
                            <i style={{ position: 'absolute', top: -5, left: 0 }}
                                className="mdi mdi-trash-can font-size-20 text-danger me-1" />{" "}
                        </div>}
                    </Check>
                )
            },
        },
    ]
    const columnChild = (rowIndex) => [
        {
            dataField: "id",
            text: "id",
            hidden: true,
            headerAttrs: {
                hidden: true
            }
        },
        {
            dataField: "idx",
            text: "#",
            headerStyle: { width: "50px" },
            formatter: (cellContent, sample, index) => `${rowIndex + 1}.${index + 1}`
        },
        {
            dataField: "name",
            text: t("Profile/Test"),
            style: { paddingLeft: "0px", paddingRight: "0px", width: "300px" },
            headerStyle: { width: "300px" },
            formatter: (cellContent, code, index) => {
                return (
                    <>
                        {
                            (code.type == TestRequest_Test_Type.PROFILE) &&
                            <div className="mx-3" style={isCancel(code.state)}>
                                <i className={`fas fa-arrow-right ${code.code}-${code.id}`}
                                    style={code.children && code.children.length > 0 ? { color: "#556ee6" } : {}} >
                                </i>
                                <span className="px-2" style={{ fontWeight: 'bold' }}>{code.name}</span>
                            </div>
                        }
                        {
                            (code.type == TestRequest_Test_Type.TEST && !code.groupCode) &&
                            <div className="mx-3" style={isCancel(code.state)}>
                                <span>-</span>
                                <span className="px-2">{code.name}</span>
                            </div>
                        }
                        {
                            (code.type == TestRequest_Test_Type.TEST && code.groupCode) &&
                            <div className="mx-5" style={isCancel(code.state)}>
                                <span>-</span>
                                <span className="px-2">{code.name}</span>
                            </div>
                        }
                    </>
                )
            }
        },
        {
            dataField: "code",
            text: t("Code"),
            style: { paddingLeft: "0px", paddingRight: "0px" },
            headerStyle: { width: "120px", padding: "10px" },
        },
        {
            dataField: "subSID",
            text: t("SubSID"),
            style: { paddingLeft: "0px", paddingRight: "0px" },
            headerStyle: { width: "40px", paddingLeft: "0px", paddingRight: "0px" },
            formatter: (cellContent, item, index) => {
                return (
                    <span>
                        {cellContent != 0 && cellContent}
                    </span>
                )
            },
        },
        {
            dataField: "categoryName",
            text: t("Category"),
            style: { padding: "0px" },
            headerStyle: { width: "120px", paddingLeft: "0px", paddingRight: "0px" },
            formatter: (cellContent, item, index) => {
                return <div
                    className="px-2 d-flex badge-gender"
                    style={{ width: '50px', height: '30px', backgroundColor: "#d4dbf9", color: "#556ee6", justifyContent: 'center' }}
                    id={`category-${index}`}
                    data-tip
                    data-for={`category-${index}`}
                >
                    {item.category}
                    <ReactTooltip place="bottom" id={`category-${index}`} effect="solid" >
                        {item.categoryName}
                    </ReactTooltip>
                </div>
            }
        },
        {
            dataField: "sampleType",
            text: t("Sample Type"),
            headerStyle: { width: "100px", paddingLeft: "0px", paddingRight: "0px", textAlign: "center" },
            style: { padding: "0px" },
            events: {
                onClick: (cellContent, code, index) => { e => e.stopPropagation() },
            },
            formatter: (cellContent, code, index) => (
                <>
                    {(!code.sampleType || code.isNewSampleType) && !disabled &&
                        <div onClick={e => e.stopPropagation()} >
                            <CustomPopover isSearch={true} code={parameterCode.SAMPLE_TYPES} title={code.sampleTypeName}
                                valueName={code.sampleTypeName} value={code.sampleType}
                                onChange={(item) => {
                                    onEditSampleType(code, item)
                                }}
                            />
                        </div>


                    }
                    {code.sampleType && !code.isNewSampleType &&
                        <div>{code.sampleTypeName}</div>
                    }
                </>


            ),
        },
        {
            dataField: "action",
            text: "Action",
            isDummyField: true,
            headerStyle: { width: "50px" },
            style: { padding: "0px", textAlign: "center" },
            formatter: (cellContent, code, index) => (
                // <span></span>
                <Check resource={RESOURCE} permission={permissionType.D} fallback={(
                    code.isNew && <div href="#" style={{
                        position: 'relative',
                        width: 20,
                        height: 20,
                        margin: '0 auto'
                    }}
                        onClick={() => onDelete(code)}
                    >
                        <i style={{ position: 'absolute', top: -5, left: 0 }}
                            className="mdi mdi-trash-can font-size-20 text-danger me-1" />{" "}
                    </div>
                )} >
                    {!disabled && <div href="#" style={{
                        position: 'relative',
                        width: 20,
                        height: 20,
                        margin: '0 auto'
                    }}
                        onClick={() => onDelete(code)}
                    >
                        <i style={{ position: 'absolute', top: -5, left: 0 }}
                            className="mdi mdi-trash-can font-size-20 text-danger me-1" />{" "}
                    </div>}
                </Check>
            ),
        },
    ]
const expandRow = {
    renderer: (row, rowIndex) => {
        return (
            row.hasChildren && row.hasChildren === true ?
                <div className="table-expand-custom-header tableinline-scrollable">
                    <CustomBootstrapInlineTable
                        columns={columnChild(rowIndex)}
                        data={row.children}
                        onSelect={onSelect}
                        onSelectAll={onSelectAll}
                        isScrollable
                        expandRow={expandRow}
                        loading={loading}
                        searchText={""}
                        draggable
                        tableHeaderClass={"col-hidden"}
                        keyField={"code"}
                        {...rest}
                    />
                </div>
                : null
        )
    },
    parentClassName: 'row-expanded'
};

return (
    <div className="table-expand-custom table-delivery">
        <CustomBootstrapInlineTable
            columns={columns}
            data={subProfileData}
            onSelect={onSelect}
            onSelectAll={onSelectAll}
            isScrollable
            expandRow={expandRow}
            loading={loading}
            searchText={""}
            draggable
            keyField={"code"}
            style={{ maxHeight: window.innerHeight >= 945 ? `${(window.innerHeight - 630) + space}px` : `${(window.innerHeight - 450) + space}px` }}
            {...rest}
        />
    </div>
)
}

export default withTranslation(["testRequestPage", "common"])(TestsTabTable)
