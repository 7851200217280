import {
    ButtonDropdownGroup, Check,
    CustomButton,
    TitleAndInformation
} from "components/Common"
import { RESULT_STATE_Text, ReportAction, TestRequest_Test_Type, permissionType } from "constant"
import { convertDateFormat } from "helpers/utilities"
import PropTypes from "prop-types"
import { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import ReactTooltip from "react-tooltip"
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle
} from "reactstrap"

const TitleAndInfo = ({
    onEdit,
    onClone,
    data,
    resource,
    t,
    testsLoadingComplete,
    requestTestSamplesCount,
    requestTestTestsCount,
    resourceReport,
    onPreviewClick,
    onCreateClick,
    onExportClick,
    onPrintClick,
    onReportSettingClick,
    testResults,
    generalSetting,
    isFastValidate,
    testResult,
    userValidate,
    currentTab,
    ...rest
}) => {
    const [loadingPrint, setLoadingPrint] = useState(false)
    const [loadingPreview, setLoadingPreview] = useState(false)
    const [loadingCreate, setLoadingCreate] = useState(false)
    const [loadingExport, setLoadingExport] = useState(false)
    const [isShow, setIsShow] = useState(false)
    const [enablePrintBtn, setEnablePrintBtn] = useState(false)
    // const [enablePreviewBtn, setEnablePreviewBtn] = useState(false)
    const [isPrint, setIsPrint] = useState(false)
    const [isReport, setIsReport] = useState(true)
    const [pageReport, setPageReport] = useState()

    const isIntegration = generalSetting && generalSetting?.find(item => item.fieldName === 'IntegratedApproval')?.fieldValue === 'Integration'

    const checkIsTestHasResult = () => {
        let count_Result = 0;
        let count_Test_Cancel_RDS = 0;
        let count_Result_Test_State = [];
        testResults.forEach(element => {
            if (element.type == TestRequest_Test_Type.TEST) {
                count_Result_Test_State.push(element.state)
                if (element.result || element.resultText) {
                    count_Result++
                }
                if (element.state === RESULT_STATE_Text.Canceled || element.state === RESULT_STATE_Text.RDS) {
                    count_Test_Cancel_RDS++
                }
            }
            if (element.children && element.children.length > 0) {
                element.children.forEach(parent => {
                    if (parent.type == TestRequest_Test_Type.TEST) {
                        count_Result_Test_State.push(parent.state)
                        if (parent.result || parent.resultText) {
                            count_Result++
                        }
                        if (parent.state === RESULT_STATE_Text.Canceled || parent.state === RESULT_STATE_Text.RDS) {
                            count_Test_Cancel_RDS++
                        }
                    }
                    if (parent.children && parent.children.length > 0) {
                        parent.children.forEach(child => {
                            if (child.type == TestRequest_Test_Type.TEST) {
                                count_Result_Test_State.push(child.state)
                                if (child.result || child.resultText) {
                                    count_Result++
                                }
                                if (child.state === RESULT_STATE_Text.Canceled || child.state === RESULT_STATE_Text.RDS) {
                                    count_Test_Cancel_RDS++
                                }
                            }
                        });
                    }
                });
            }
        });

        if (count_Result === 0 || count_Test_Cancel_RDS === count_Result_Test_State.length) {
            setEnablePrintBtn(true)
        }
        else setEnablePrintBtn(false)
    }
    const checkState = () => {
        let count_isShow = 0;
        let count_isReport = 0;
        let countValid = 0;
        let countSigned = 0;
        let count_isCheck = 0;
        let count_isPrint = 0;
        let count_isPrint_fail = 0;
        let count_unPrint = 0;
        let count_firstPrint = 0;
        let pageReport = []

        testResults.forEach(element => {
            if (element.type == TestRequest_Test_Type.TEST) {
                if (element.state >= RESULT_STATE_Text.Validated) {
                    countSigned++;
                }
                if (element.isValid == true) {
                    countValid++
                    count_isCheck++
                    if (element.state < RESULT_STATE_Text.Validated
                        || ((element.result == null || element.result == '')
                            && (element.resultText == null || element.resultText == ''))) {
                        count_isReport++
                    }
                    if (!(element.result == null || element.result == '')) {
                        count_isShow++;
                    }

                    if (element.state <= RESULT_STATE_Text.InProcess || element.state == RESULT_STATE_Text.Canceled || element.state == RESULT_STATE_Text.RDS) {
                        count_unPrint++;
                    }

                    if ((element.result != null && element.result != '') || (element.resultText != null && element.resultText != '')) {
                        // count_valid++;
                        count_isPrint++;
                        if (!pageReport.includes(element.reportTypeName)) {
                            pageReport.push(element.reportTypeName)
                        }
                    }
                }

                if ((element.result != null && element.result != '') || (element.resultText != null && element.resultText != '')) {
                    count_firstPrint++;
                }

            }
            if (element.children && element.children.length > 0) {
                element.children.forEach(parent => {
                    if (parent.type == TestRequest_Test_Type.TEST) {
                        if (parent.state >= RESULT_STATE_Text.Validated) {
                            countSigned++;
                        }
                        if (parent.isValid == true) {
                            countValid++
                            count_isCheck++
                            if (parent.state < RESULT_STATE_Text.Validated
                                || ((parent.result == null || parent.result == '')
                                    && (parent.resultText == null || parent.resultText == ''))) {
                                count_isReport++
                            }
                            if (!((parent.result == null || parent.result == '') && (parent.resultText == null || parent.resultText == ''))) {
                                count_isShow++;
                            }

                            if (parent.state <= RESULT_STATE_Text.InProcess || element.state == RESULT_STATE_Text.Canceled || element.state == RESULT_STATE_Text.RDS) {
                                count_unPrint++;
                            }

                            if ((parent.result != null && parent.result != '') || (parent.resultText != null && parent.resultText != '')) {
                                count_isPrint++;
                                if (!pageReport.includes(parent.reportTypeName)) {
                                    pageReport.push(parent.reportTypeName)
                                }
                            } else {
                                count_isPrint_fail++
                            }

                        }
                        if ((parent.result != null && parent.result != '') || (parent.resultText != null && parent.resultText != '')) {
                            count_firstPrint++
                        }
                        // else count_isPrint--
                    }
                    if (parent.children && parent.children.length > 0) {
                        parent.children.forEach(child => {
                            if (child.type == TestRequest_Test_Type.TEST) {
                                if (child.state >= RESULT_STATE_Text.Validated) {
                                    countSigned++;
                                }
                                if (child.isValid == true) {
                                    countValid++
                                    count_isCheck++
                                    if (parent.state < RESULT_STATE_Text.Validated || ((child.result == null || child.result == '') && (child.resultText == null || child.resultText == ''))) {
                                        count_isReport++
                                    }
                                    if (!((child.result == null || child.result == '') && (child.resultText == null || child.resultText == ''))) {
                                        count_isShow++;
                                    }

                                    if (child.state <= RESULT_STATE_Text.InProcess || element.state == RESULT_STATE_Text.Canceled || element.state == RESULT_STATE_Text.RDS) {
                                        count_unPrint++;
                                    }

                                    if ((child.result != null && child.result != '') || (child.resultText != null && child.resultText != '')) {
                                        count_isPrint++;
                                        if (!pageReport.includes(child.reportTypeName)) {
                                            pageReport.push(child.reportTypeName)
                                        }
                                    } else {
                                        count_isPrint_fail++
                                    }
                                }

                                if ((child.result != null && child.result != '') || (child.resultText != null && child.resultText != '')) {
                                    count_firstPrint++
                                }
                                // else count_isPrint--
                            }
                        });
                    }
                });
            }
        });

        if (count_isPrint === 0 && count_unPrint === 0 && count_isPrint_fail === 0) {
            setIsPrint(false)
        }
        if ((count_isPrint > 0 && count_unPrint === 0) || (count_unPrint > 0 && count_isPrint > 0)) {
            setIsPrint(false)
        }
        if (count_isPrint === 0 && (count_isPrint_fail > 0 || count_unPrint > 0)) {
            setIsPrint(true)
        }
        // if (count_unPrint > 0 && count_isPrint === 0) {
        //     setIsPrint(true)
        // }

        // if (count_isPrint_fail > 0 && count_isPrint === 0 && count_unPrint === 0) {
        //     setEnablePreviewBtn(true)
        // } else if (count_isPrint > 0) {
        //     setEnablePreviewBtn(false)
        // }

        if (count_firstPrint === 0) {
            setEnablePrintBtn(true)
        }

        setIsShow(countValid == 0 ? false : (count_isShow == 0))
        if (countSigned > 0) {
            setIsReport(false)
        }

        setPageReport(pageReport)

    }

    useEffect(() => {
        checkState()
    }, [testResults])

    useEffect(() => {
        checkIsTestHasResult()
    }, [testResults])


    const setLoading = (name) => {
        switch (name) {
            case "Create":
                setLoadingCreate(true)
                setLoadingPreview(false)
                setLoadingPrint(false)
                setLoadingExport(false)
                break;
            case "Preview":
                setLoadingPreview(true)
                setLoadingCreate(false)
                setLoadingPrint(false)
                setLoadingExport(false)
                break;
            case "Export":
                setLoadingPreview(false)
                setLoadingCreate(false)
                setLoadingPrint(false)
                setLoadingExport(true)
                break;
            case "Print":
                setLoadingPreview(false)
                setLoadingCreate(false)
                setLoadingPrint(true)
                setLoadingExport(false)
                break;
        }
    }


    return (
        <TitleAndInformation
            onEdit={onEdit}
            onClone={onClone}
            resource={resource}
            {...rest}
            external={true}
            customButtons={() => (
                <>
                    <Check permission={ReportAction.Print} resource={resourceReport}>
                        <CustomButton
                            disabled={enablePrintBtn || isPrint || currentTab === '2'}
                            color={isFastValidate ? "warning" : "primary"}
                            isEdit={loadingPrint}
                            outline
                            onClick={() => {
                                onPrintClick(ReportAction.Create, pageReport)
                                setLoading("Print")
                            }}>
                            {t("common:Print")}
                        </CustomButton>
                    </Check>

                    <ButtonDropdownGroup
                        onDeleteClick={() => { }}
                        onCloneClick={() => { }}
                    >
                        <Check permission={ReportAction.Create} resource={resourceReport}>
                            <div className="tooltip-custom">
                                <CustomButton
                                    data-tip data-for={'sending-tooltip'}
                                    disabled={isReport || testResult.state == RESULT_STATE_Text.Releasing || testResult.state == RESULT_STATE_Text.Canceled || currentTab === '2'}
                                    color={isIntegration ? "success" : "primary"}
                                    isEdit={loadingCreate}
                                    onClick={() => { onCreateClick(ReportAction.Create), setLoading("Create") }}
                                    outline>
                                    {testResult.state == RESULT_STATE_Text.Releasing ? t("common:Sending") : t("common:Create Report")}
                                </CustomButton>
                                {testResult.state == RESULT_STATE_Text.Releasing &&
                                    <span className="tooltiptext-custom" style={{ bottom: '-20px' }}>
                                        {convertDateFormat(testResult.updatedDate)}
                                    </span>
                                }
                            </div>
                        </Check>
                        <Check permission={ReportAction.Preview} resource={resourceReport}>
                            <CustomButton
                                disabled={testResults?.length == 0 || currentTab === '2'}
                                color="primary" isEdit={loadingPreview}
                                onClick={() => { onPreviewClick(ReportAction.Preview), setLoading("Preview") }}
                                outline>
                                {t("common:Preview")}
                            </CustomButton>
                        </Check>
                        {/* <Check permission={ReportAction.Export} resource={resourceReport}>
                            <CustomButton disabled={isShow} color="primary" isEdit={loadingExport} onClick={() => { onExportClick(ReportAction.Export), setLoading("Export") }} outline>
                                {t("common:Export")}
                            </CustomButton>
                        </Check> */}

                        {/* <Check
                            permissions={[permissionType.U, permissionType.C]}
                            resource={resource}
                        >
                            <Check permission={permissionType.D} resource={resource}>
                                <DropdownToggle caret color="primary" outline>
                                    <i className="mdi mdi-chevron-down"></i>
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem disabled>{t("common:Action")}</DropdownItem>
                                    <DropdownItem onClick={() => { }}>
                                        {t("common:Delete")}
                                    </DropdownItem>
                                    <DropdownItem onClick={() => onReportSettingClick("")}>
                                        {t("common:Report Settings")}
                                    </DropdownItem>
                                </DropdownMenu>
                            </Check>
                        </Check> */}
                    </ButtonDropdownGroup>
                </>
            )}
        >
        </TitleAndInformation>
    )
}

TitleAndInfo.propTypes = {
    onEdit: PropTypes.func,
    onClone: PropTypes.func,
    data: PropTypes.object,
    t: PropTypes.any
}

const mapStateToProps = ({ testRequest, testResult }) => ({
    testsLoadingComplete: testRequest.testsLoadingComplete,
    requestTestSamplesCount: testRequest.requestTestSamplesCount,
    requestTestTestsCount: testRequest.requestTestTestsCount,
    isFastValidate: testResult.isFastValidate,
    userValidate: testResult.userValidate,
    testResult: testResult.testResult
})

const mapDispatchToProps = dispatch => ({
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation(["testRequestPage", "testResultPage", "message", "common"])(TitleAndInfo))