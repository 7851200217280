import { spreadSearchQuery } from "helpers/utilities"
import { get, put, post, del } from "../api_helper2"

const BASE_API_URL = `${process.env.REACT_APP_LABORATORY_ENDPOINT}/tests`

// TESTS
// Get All Tests Method
const getAllTests = payload => {
    let q = spreadSearchQuery(payload)
    return get(`${BASE_API_URL}?${q}`)
}

const getFullTest = payload => {
    let q = spreadSearchQuery(payload)
    return get(`${BASE_API_URL}/GetFullTest?${q}`)
}

const getTestInsurance = payload => {
    let q = spreadSearchQuery(payload)
    return get(`${BASE_API_URL}/GetTestInsurance?${q}`)
}

// Get Test By Id Method
const getTestById = (id, effectiveTime) => {
    if (effectiveTime !== undefined) {
        return get(`${BASE_API_URL}/${Number(id)}?effectiveTime=${effectiveTime}`)
    }
    return get(`${BASE_API_URL}/${id}`)
}

const getTestByCode = (id, effectiveTime) => {
    if (effectiveTime !== undefined) {
        return get(`${BASE_API_URL}/GetTestByCode/${id}?effectiveTime=${effectiveTime}`)
    }
    return get(`${BASE_API_URL}/GetTestByCode/${id}`)
}

// Delete Test By Id Method
const deleteTestByIds = tests => {
    let ids = ""
    tests.forEach(_test => (ids += `id=${_test.id}&`))

    return del(`${BASE_API_URL}?${ids}`)
}

const deleteTestVendor = params => {
    let ids = ""
    params.forEach(_param => (ids += `ids=${_param}&`))
    return del(`${BASE_API_URL}/DeleteTestVendor?${ids}`)
}

// Update Test By Id Method
const updateTestById = req => {
    const { id } = req
    return put(`${BASE_API_URL}/${id}`, req)
}

const updateInfoTestById = req => {
    const { id } = req
    return put(`${BASE_API_URL}/UpdateInfoTest/${id}`, req)
}

const updateTestVendor = req => {
    return post(`${BASE_API_URL}/UpdateTestVendor`, req)
}

const updateOrderDisplayTest = req => {
    return put(`${BASE_API_URL}/UpdateDisplayOrderTest`, req)
}

// Create Test By Id Method
const createTest = req => {
    return post(`${BASE_API_URL}`, req)
}

const downloadImportTestTemplate = () => {
    return post(`${BASE_API_URL}/DownloadImportTestTemplate`, null, {
        responseType: 'arraybuffer',
        headers:
        {
            'Content-Disposition': "attachment; filename=template.xlsx",
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        },
    })
}

const exportTests = (payload) => {
    let q = spreadSearchQuery(payload)
    return post(`${BASE_API_URL}/ExportTest?${q}`, null, {
        responseType: 'arraybuffer',
        headers:
        {
            'Content-Disposition': "attachment; filename=template.xlsx",
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        },
    })
}

const ImportTests = req => {
    return post(`${BASE_API_URL}/ImportTest`, req)
}

const getCountTestOfCategory = () => {
    return get(`${BASE_API_URL}/CountTestOfCategory`)
}

const UpdateAdditionalInfoTestField = req => {
    return put(`${BASE_API_URL}/update-additional-info-field`, req)
}

const getTestByListCode = (codes) => {
    return post(`${BASE_API_URL}/GetTestByListCode`, { codes: codes })
}

const getTestCategoryIQC = () => {
    return get(`${BASE_API_URL}/GetTestCategoryIQC`)
}

export {
    getAllTests, getTestById, updateTestById, deleteTestByIds, createTest,
    getFullTest, downloadImportTestTemplate, ImportTests, exportTests, getCountTestOfCategory,
    updateOrderDisplayTest, updateTestVendor, deleteTestVendor, UpdateAdditionalInfoTestField,
    getTestInsurance, updateInfoTestById, getTestByCode, getTestByListCode,
    getTestCategoryIQC,
}
