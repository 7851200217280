import { post } from "../api_helper2"

const BASE_API_URL = `${process.env.REACT_APP_LABORATORY_ENDPOINT}/tests`

const updateTestQC = req => {
  return post(`${BASE_API_URL}/UpdateTestQC`, req)
}

export {
  updateTestQC
}

