
import { ModalBody, ModalFooter, TabContent, TabPane } from "reactstrap"
import { ConfirmModal2, CustomBreadcrumb, CustomNav, CustomNavLink, PageContent, showToast } from "components/Common";
import { withTranslation } from "react-i18next";
import {
    CustomButton,
    CustomModal
} from "components/Common"

import React, { useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
import KPH from "./KPH";
import KP from "./KP";
import { AvForm } from "availity-reactstrap-validation";
import { checkCurrentReportNumber, getCurrentReportNumber, getResultRuleActionDetail, getResultRuleCauseDetail, updateQCResultRuleAction, updateQCResultRuleCause } from "helpers/app-backend";
import moment from "moment";

const ResultQCReportModal = ({
    modal,
    toggle,
    t,
    data
}) => {

    const [currentTab, setCurrentTab] = useState('1')
    const [reportCodeKPH, setReportCodeKPH] = useState('')
    const [reportCodeKP, setReportCodeKP] = useState('')
    const [confirmModal, setConfirmModal] = useState(false)
    const [dataSubmit, setDataSubmit] = useState({})
    const [oldDataKPH, setOldDataKPH] = useState({ source: [], causes: [] });
    const [oldDataKP, setOldDataKP] = useState({ action: [] });
    const [isEditKPH, setIsEditKPH] = useState(false)
    const [isEditKP, setIsEditKP] = useState(false)
    const refKPH = useRef()
    const refKP = useRef()
    useEffect(() => {
        if (data && data.id) {
            getDataKPH()
            getDataKP()
        }
    }, [data])

    useEffect(() => {
        getReportCodeKPH()
        getReportCodeKP()
    }, [])

    const getStateButtonEdit = () => {
        if (currentTab == '1') {
            if (isEditKPH == true) {
                return false;
            }
            return true
        }
        else {
            if (isEditKP == true) {
                return false;
            }
            return true
        }
    }

    const getStateButtonSave = () => {
        if (currentTab == '1') {
            if (isEditKPH == true) {
                return true;
            }
            return false
        }
        else {
            if (isEditKP == true) {
                return true;
            }
            return false
        }

    }

    const getDataKPH = async () => {
        let res = await getResultRuleCauseDetail({
            RuleName: data.rule,
            DatapointId: data.id
        })
        if (res && res.id != 0) {
            setIsEditKPH(true)
            setReportCodeKPH(res.reportCode)
            res.source = res.source?.split(',') || []
            res.causes = res.causes?.split(',') || []
            setOldDataKPH(res)
        }
    }

    const getDataKP = async () => {
        let res = await getResultRuleActionDetail({
            RuleName: data.rule,
            DatapointId: data.id
        })
        if (res && res.id != 0) {
            setIsEditKP(true)
            setReportCodeKP(res.reportCode)
            res.action = res.action?.split(',') || []
            setOldDataKP(res)
        }
    }


    const getReportCodeKPH = async () => {
        let res = await getCurrentReportNumber({ isKPH: true, runTime: moment(data.runTime).format("YYYY-MM-DD") })
        if (res) {
            setReportCodeKPH(res.reportCode || '')
        }
    }

    const getReportCodeKP = async () => {
        let res = await getCurrentReportNumber({ isKPH: false, runTime: moment(data.runTime).format("YYYY-MM-DD") })
        if (res) {
            setReportCodeKP(res.reportCode || '')
        }
    }

    const handleValidSubmitKPH = async (e, vals) => {
        setDataSubmit(vals)
        let check = await checkCurrentReportNumber({ isKPH: true, reportCode: reportCodeKPH })
        if (check && check.isExist == true) {
            setConfirmModal(true)
        }
        else {
            sendDataKPH(vals)
        }
    }

    const handleValidSubmitKP = async (e, vals) => {
        setDataSubmit(vals)
        let check = await checkCurrentReportNumber({ isKPH: false, reportCode: reportCodeKPH })
        if (check && check.isExist == true) {
            setConfirmModal(true)
        }
        else {
            sendDataKP(vals)
        }
    }


    const submitKPHAgain = async () => {
        sendDataKPH(dataSubmit, true)
    }

    const submitKPAgain = async () => {
        sendDataKP(dataSubmit, true)
    }
    const sendDataKPH = async (vals, isNewReport = false) => {
        let dt = {
            datapointId: data.id,
            ruleName: data.rule,
            causes: vals.causes.join(),
            discoverer: vals.discoverer,
            discovertTime: moment(vals.discovertTime, "HH:mm DD-MM-YYYY").format("YYYY-MM-DD HH:mm"),
            errorType: vals.errorType,
            location: vals.location,
            source: vals.source.join(),
            runtime: moment(data.runTime).format("YYYY-MM-DD HH:mm"),
            reportCode: reportCodeKPH,
            isNewReport: isNewReport,
        }
        let res = await updateQCResultRuleCause(dt);
        showToast(
            `${t("message:Succeeded")}`
        )
        setConfirmModal(false)
        toggle()

    }

    const sendDataKP = async (vals, isNewReport = false) => {
        let dt = {
            datapointId: data.id,
            ruleName: data.rule,
            action: vals.action.join(),
            discoverer: vals.discoverer,
            improve: vals.improve,
            result: vals.result,
            remark: vals.remark,
            runtime: moment(data.runTime).format("YYYY-MM-DD HH:mm"),
            reportCode: reportCodeKP,
            isNewReport: isNewReport,
        }
        let res = await updateQCResultRuleAction(dt);
        showToast(
            `${t("message:Succeeded")}`
        )
        setConfirmModal(false)
        toggle()

    }

    return (
        <React.Fragment>
            <CustomModal modal={true} title={t("")} onToggle={toggle} size="xl" customClass={"result-qc-report-modal"} centered={false}>
                <button type="button" className="btn-close" aria-label="Close" onClick={toggle}></button>
                <ModalBody>
                    <CustomNav
                        onToggle={e => {
                            setCurrentTab(e);
                        }}
                        defaultTab={"1"}
                        tabs
                        className="nav-tabs-custom"
                        tabContents={customActiveTab => (
                            <TabContent
                                activeTab={customActiveTab}
                                className="p-0 text-muted nav-tabs-custom-result-report-qc"
                            >
                                <TabPane tabId="1">
                                    <AvForm ref={refKPH}
                                        onValidSubmit={(e, v) => {
                                            handleValidSubmitKPH(e, v)
                                        }}
                                    >
                                        <KPH
                                            isEdit={isEditKPH}
                                            reportCode={reportCodeKPH}
                                            data={data}
                                            oldData={oldDataKPH}
                                        />
                                    </AvForm>
                                </TabPane>
                                <TabPane tabId="2">
                                    <AvForm ref={refKP}
                                        onValidSubmit={(e, v) => {
                                            handleValidSubmitKP(e, v)
                                        }}
                                    >
                                        <KP
                                            isEdit={isEditKP}
                                            reportCode={reportCodeKP}
                                            data={data}
                                            oldData={oldDataKP}
                                        />
                                    </AvForm>
                                </TabPane>
                            </TabContent>
                        )}
                    >
                        <CustomNavLink tabId="1">
                            <span>{t("Report Causal")}</span>
                        </CustomNavLink>
                        <CustomNavLink tabId="2">
                            <span>{t("Report Corrective Action")}</span>
                        </CustomNavLink>
                    </CustomNav>
                </ModalBody>
                <ModalFooter>
                    <CustomButton
                        text={t("common:Preview")}
                        type="button"
                        onClick={() => { }}
                        className="button-width"
                        color="info"
                    />
                    <CustomButton
                        text={t("common:Edit")}
                        type="button"
                        disabled={getStateButtonEdit()}
                        onClick={() => {
                            if (currentTab == '1') {
                                setIsEditKPH(false)
                            }
                            else {
                                setIsEditKP(false)
                            }
                        }}
                        className="button-width"
                        color="warning"
                    />
                    <CustomButton
                        text={t("common:Save")}
                        type="button"
                        color="success"
                        disabled={getStateButtonSave()}
                        onClick={() => {
                            if (currentTab == '1') {
                                refKPH.current?.submit()
                            }
                            else {
                                refKP.current?.submit()
                            }
                        }}
                        className="save-user button-width"
                        isEdit
                    />
                    <CustomButton
                        text={t("Print Report")}
                        type="button"
                        onClick={() => { }}
                        className="button-width"
                        color="primary"
                    />
                </ModalFooter>
            </CustomModal>
            <ConfirmModal2
                modal={confirmModal}
                title={t("message:Warning")}
                message={`Mã báo cáo ${reportCodeKPH} trùng, xác nhận tạo mã mới!`}
                onToggle={() => { setConfirmModal(prev => !prev) }}
                onConfirm={() => {
                    if (currentTab == '1') {
                        submitKPHAgain()
                    }
                    else {
                        submitKPAgain()
                    }
                    setConfirmModal(false)
                }}
                btnConfirmText={t("common:Ok")}
                isEdit
            />
        </React.Fragment >
    )
}

ResultQCReportModal.defaultProps = {}

const mapStateToProps = ({ testResult }) => ({
})

const mapDispatchToProps = dispatch => ({
    // onGetNotifications: payload => dispatch(getNotifications(payload)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation(["resultIQCPage", "common"])(ResultQCReportModal))
