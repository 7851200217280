import i18n from "i18next"
import { call, put, select, takeEvery } from "redux-saga/effects"
import {
    ADD_NEW_LOT_QC,
    ADD_NEW_LOT_TEST_QC,
    DELETE_LOT_QC,
    GET_LIST_LOT_LIST_FROM_LOT_TEST,
    GET_LIST_LOT_QC,
    GET_LOT_QC_DETAIL,
    GET_QC_LOT_TESTS,
    UPDATE_LOT_QC,
    UPDATE_LOT_TEST_QC
} from "./actionTypes"
import {
    addNewLotQCFail,
    addNewLotQCSuccess,
    addNewLotTestQCFail,
    addNewLotTestQCSuccess,
    deleteLotQCFail,
    deleteLotQCSuccess,
    getListLotQCFail,
    getListLotQCSuccess,
    getLotQCDetailFail,
    getLotQCDetailSuccess,
    getLotQCListFromLotTestFail,
    getLotQCListFromLotTestSuccess,
    getQCLotTestFail,
    getQCLotTestSuccess,
    setLotQCSearchQuery,
    updateLotQCFail,
    updateLotQCSuccess,
    updateLotTestQCFail,
    updateLotTestQCSuccess
} from "./actions"
import { showToast } from "components/Common"
import { createQCLot, deleteQCLotById, getQCLotById, getQCLotList, updateQCLotById } from "helpers/app-backend"
import { createQCLotTest, GetLotQCListFromLotTest, GetQCLotTestList, updateQCLotTest } from "helpers/app-backend/IQC/QCLotTest_backend_helper"
import { getAllMachine, getInstrumentListByIns } from "helpers/app-backend/machines_backend_helper"
import { convertDateFormat } from "helpers/utilities"
const t = (msg, param) => i18n.t(msg, param)
function* fetchListLotQC({ payload }) {
    try {
        const searchQuery = yield select(state => state.lotQCs.searchQuery);
        const mergedPayload = { ...searchQuery, ...payload };
        const response = yield call(getQCLotList, mergedPayload);
        let newData = response?.data || [];
        if (newData.length > 0) {
            const uniqueInstrumentIds = [
                ...new Set(
                    newData
                        ?.filter(x => x?.instrumentIds)
                        ?.map(x => x?.instrumentIds?.split(','))
                        ?.flat()
                ),
            ];
            if (uniqueInstrumentIds.length > 0) {
                const resIns = yield call(getAllMachine, { id: uniqueInstrumentIds });
                const instrumentMap = resIns?.data?.reduce((acc, instrument) => {
                    acc[instrument?.id] = instrument?.name;
                    return acc;
                }, {}) || {};
                newData = newData.map(element => {
                    const instrumentIds = element?.instrumentIds?.split(',') || [];
                    const machineNames = instrumentIds
                        ?.map(id => instrumentMap?.[id] || '')
                        ?.filter(Boolean);
                    element.machineName = machineNames?.join(', ') || '';
                    return element;
                });
            }
        }
        response.data = newData;
        yield put(getListLotQCSuccess(response));
        yield put(setLotQCSearchQuery(mergedPayload));
    } catch (error) {
        yield put(getListLotQCFail(error));
    }
}
function* fetchLotQCDetail({ id, callback }) {
    try {
        const response = yield call(getQCLotById, id)
        yield put(getLotQCDetailSuccess(response))
        callback && callback(response)
    } catch (error) {
        yield put(getLotQCDetailFail(error))
    }
}
function* onUpdateQClotById({ payload: { lotQC, callback } }) {
    try {
        yield call(updateQCLotById, lotQC)
        yield put(updateLotQCSuccess(lotQC))
        showToast(
            `${t("message:UpdatedMessage", {
                field: `${t(
                    "managementQCPage:Lot"
                )} <span class='text-decoration-underline fw-bold'>${lotQC["lotName"]}</span>`,
            })}`
        )
        callback && callback()
    } catch (error) {
        yield put(updateLotQCFail(error))
    }
}
function* onDeleteQCLot({ payload: { lotQC, callback } }) {
    try {
        yield call(deleteQCLotById, lotQC)
        yield put(deleteLotQCSuccess(lotQC))
        showToast(
            `${t("message:DeletedMessage", {
                field: `${t("managementQCPage:Lot")}`,
            })}`
        )
        callback && callback()
    } catch (error) {
        yield put(deleteLotQCFail(error))
    }
}
function* onAddNewQCLOT({ payload: { lotQC, callback } }) {
    try {
        const response = yield call(createQCLot, lotQC)
        yield put(addNewLotQCSuccess(lotQC))
        showToast(
            `${t("message:CreatedMessage", {
                field: `${t(
                    "managementQCPage:Lot"
                )} <span class='text-decoration-underline fw-bold'>${lotQC["lotName"]
                    }</span>`,
            })}`
        )
        callback && callback()
    } catch (error) {
        console.log(error)
        yield put(addNewLotQCFail(error))
    }
}
function* onAddNewLotTestQC({ payload: { data, callback } }) {
    try {
        const response = yield call(createQCLotTest, data)
        yield put(addNewLotTestQCSuccess())
        showToast(
            `${t("message:CreatedMessage", {
                field: ``,
            })}`
        )
        callback && callback()
    } catch (error) {
        console.log(error)
        yield put(addNewLotTestQCFail(error))
    }
}
function* fetchLotQCListFromLotTest({ payload: { data, callback } }) {
    try {
        if (data.end) {
            data.end = convertDateFormat(data.end, "YYYY-MM-DD")
        }
        if (data.start) {
            data.start = convertDateFormat(data.start, "YYYY-MM-DD")
        }
        let response = yield call(GetLotQCListFromLotTest, data)
        let newData = response.data || []
        if (newData.length > 0) {
            let ins = newData.filter(x => x.instrumentId != null && x.instrumentId != '').map(x => x.instrumentId)
            if (ins.length > 0) {
                let resIns = yield call(getAllMachine, { id: ins, size: 0 })
                if (resIns.data && resIns.data.length > 0) {
                    newData.forEach(element => {
                        element.idqc = element.id
                        element.id = `${element.id}-${element.instrumentId}`
                        element.machineName = resIns.data.find(x => x.id == element.instrumentId)?.name || ''
                    });
                }
            }
            else {
                newData.forEach(element => {
                    element.idqc = element.id
                    element.id = `${element.id}-${element.instrumentId}`
                });
            }
        }
        response.data = newData
        yield put(getLotQCListFromLotTestSuccess(response))
        callback && callback()
    } catch (error) {
        console.log(error)
        yield put(getLotQCListFromLotTestFail(error))
    }
}
function* fetchQCLotTests({ payload: { data, callback } }) {
    try {
        let response = yield call(GetQCLotTestList, data)
        let newData = response || []
        if (newData.length > 0) {
            let ins = newData.filter(x => x.insId != null && x.insId != '').map(x => x.insId)
            if (ins.length > 0) {
                let resIns = yield call(getAllMachine, { id: ins, size: 0 })
                if (resIns.data && resIns.data.length > 0) {
                    newData.forEach(element => {
                        element.machineName = resIns.data.find(x => x.id == element.insId)?.name || ''
                    });
                }
            }
        }
        response = newData
        yield put(getQCLotTestSuccess(response))
        callback && callback()
    } catch (error) {
        console.log(error)
        yield put(getQCLotTestFail(error))
    }
}
function* onUpdateQCLotTest({ payload: { data, callback } }) {
    try {
        const response = yield call(updateQCLotTest, data)
        yield put(updateLotTestQCSuccess())
        showToast(
            `${t("message:UpdatedMessage")}`
        )
        callback && callback()
    } catch (error) {
        console.log(error)
        yield put(updateLotTestQCFail(error))
    }
}
function* lotQCsSaga() {
    yield takeEvery(GET_LIST_LOT_QC, fetchListLotQC)
    yield takeEvery(GET_LIST_LOT_LIST_FROM_LOT_TEST, fetchLotQCListFromLotTest)
    yield takeEvery(GET_LOT_QC_DETAIL, fetchLotQCDetail)
    yield takeEvery(ADD_NEW_LOT_QC, onAddNewQCLOT)
    yield takeEvery(UPDATE_LOT_QC, onUpdateQClotById)
    yield takeEvery(DELETE_LOT_QC, onDeleteQCLot)
    yield takeEvery(ADD_NEW_LOT_TEST_QC, onAddNewLotTestQC)
    yield takeEvery(GET_QC_LOT_TESTS, fetchQCLotTests)
    yield takeEvery(UPDATE_LOT_TEST_QC, onUpdateQCLotTest)
}
export default lotQCsSaga