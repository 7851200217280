import PropTypes from "prop-types";
import { Line } from "react-chartjs-2";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { withTranslation } from "react-i18next";
import { getQCDatapoints } from "store/actions";

ChartJS.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend
);

const TabChart = ({
  showBothCharts,
  onGetQCDatapoints,
  showUnvalidatedPoint,
  levels,
  payload,
  datapoints,
  loadingDatapoints,
  error,
}) => {
  const chartOptions = {
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {
          title: (tooltipItems) => {
            return `Tháng: ${tooltipItems[0].label}`;
          },
          label: (tooltipItem) => {
            return `Doanh số: ${tooltipItem.formattedValue} triệu VND`;
          },
        },
        backgroundColor: '#fff',
        titleColor: '#000',
        bodyColor: '#333',
        borderColor: '#ccc',
        borderWidth: 1,
        padding: 10,
        displayColors: false,
      },
      legend: {
        display: true, // Enable the legend for debugging
        position: 'top', // Position as needed
      },
      title: {
        display: true,
        text: "Quality Control Chart",
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Date",
        },
      },
      y: {
        title: {
          display: true,
          text: "Value",
        },
      },
    },
  };

  const [dataset, setDataset] = useState({});

  useEffect(() => {
    if (payload) {
      onGetQCDatapoints(payload);
    }
  }, [payload, onGetQCDatapoints]);

  useEffect(() => {
    if (!datapoints || typeof datapoints !== "object") {
      setDataset({});
      return;
    }
  
    const levelType = showUnvalidatedPoint ? "total" : "validated";
    const levelData = datapoints.dicDatapointByLevel?.[levelType];
  
    if (!levelData) {
      setDataset({});
      return;
    }
  
    let newDataset = {};
  
    if (levels) {
      const specificLevelData = levelData[levels];
      if (specificLevelData) {
        newDataset[levels] = specificLevelData;
        newDataset["mean"] = specificLevelData.mean;
        newDataset["cv"] = specificLevelData.cv;
        newDataset["sd"] = specificLevelData.sd;
      }
    } else {
      newDataset = { ...levelData };
      newDataset["mean"] = showUnvalidatedPoint ? datapoints.meanAllTotal : datapoints.meanAllValidated;
      newDataset["cv"] = showUnvalidatedPoint ? datapoints.cvAllTotal : datapoints.cvAllValidated;
      newDataset["sd"] = showUnvalidatedPoint ? datapoints.sdAllTotal : datapoints.sdAllValidated;
    }
  
    setDataset(newDataset);
  }, [levels, showUnvalidatedPoint, datapoints]);

  // Enhanced createChartData Function
  const createChartData = (dataset) => {
    const defaultColors = ["blue", "green", "red"];
    const defaultPointStyles = ["circle", "triangle", "rect"];

    const allDataPoints = [];
    const allFixedLines = [];
    const mean = Number(dataset.mean);
    const sd = Number(dataset.sd);
    let maxDataLength = 0;

    // Define fixed lines based on mean and sd
    if (!isNaN(mean) && !isNaN(sd)) {
      allFixedLines.push(
        { label: `Mean`, value: mean, borderColor: "#76b5c5", borderWidth: 2 },
        { label: `+3 SD`, value: mean + 3 * sd, borderColor: "red", borderWidth: 2 },
        { label: `-3 SD`, value: mean - 3 * sd, borderColor: "red", borderWidth: 2 },
        { label: `+1 SD`, value: mean + sd, borderColor: "black", borderDash: [5, 5], borderWidth: 1 },
        { label: `-1 SD`, value: mean - sd, borderColor: "black", borderDash: [5, 5], borderWidth: 1 },
        { label: `+2 SD`, value: mean + 2 * sd, borderColor: "black", borderDash: [10, 5], borderWidth: 2 },
        { label: `-2 SD`, value: mean - 2 * sd, borderColor: "black", borderDash: [10, 5], borderWidth: 2 },
      );
    }

    // Iterate over each key in the dataset
    Object.keys(dataset).forEach((key, index) => {
      // Skip non-data keys like 'mean', 'cv', 'sd'
      if (["mean", "cv", "sd"].includes(key)) return;

      const entry = dataset[key];
      if (
        !entry.getQCDatapointsDetails ||
        !Array.isArray(entry.getQCDatapointsDetails)
      ) {
        console.warn(`Invalid data points for ${key}`);
        return;
      }

      // Extract numeric data
      const datachart = entry.getQCDatapointsDetails
        .map((detail) => Number(detail.result))
        .filter((num) => !isNaN(num));

      // Update maxDataLength for labels
      if (datachart.length > maxDataLength) {
        maxDataLength = datachart.length;
      }

      allDataPoints.push({
        label: `${key} Data`,
        data: datachart,
        color: defaultColors[index % defaultColors.length],
        pointStyle: defaultPointStyles[index % defaultPointStyles.length],
        pointRadius: 5,
        borderWidth: 3, // Increased for better visibility
        spanGaps: true,
      });
    });

    // Generate labels based on the maximum data length
    const labels = Array.from({ length: maxDataLength }, (_, idx) => `Point ${idx + 1}`);

    // Pad shorter datasets with null to align with labels
    allDataPoints.forEach((dataSet) => {
      if (dataSet.data.length < maxDataLength) {
        const padding = Array(maxDataLength - dataSet.data.length).fill(null);
        dataSet.data = [...dataSet.data, ...padding];
      }
    });

    // Generate dynamic datasets with distinct colors and styles
    const dynamicDatasets = allDataPoints.map((point, index) => ({
      label: point.label,
      data: point.data,
      borderColor: point.color,
      backgroundColor: point.color,
      pointStyle: point.pointStyle,
      pointRadius: point.pointRadius,
      spanGaps: point.spanGaps,
      borderWidth: point.borderWidth,
      borderDash: point.borderDash || [],
      fill: false, // Ensure no fill
      order: 2, // Render dynamic datasets on top
    }));

    // Generate fixed datasets with distinct colors and lower order
    const fixedDatasets = allFixedLines.map((line) => ({
      label: line.label,
      data: Array(labels.length).fill(line.value),
      borderColor: line.borderColor,
      borderDash: line.borderDash || [],
      pointRadius: 0,
      borderWidth: line.borderWidth,
      fill: false, // Ensure no fill
      order: 1, // Render fixed lines below dynamic datasets
    }));

    console.log("dynamicDatasets", dynamicDatasets);
    console.log("fixedDatasets", fixedDatasets);

    return {
      labels,
      datasets: [...fixedDatasets, ...dynamicDatasets], // Fixed first, dynamic last
    };
  };

  if (loadingDatapoints) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!datapoints || Object.keys(dataset).length === 0) {
    return <div>No data available to display the chart.</div>;
  }

  const chartData = createChartData(dataset);
  console.log("chartData", chartData);

  return (
    <div style={{ maxHeight: "900px", overflowY: "auto", padding: "10px" }}>
      <div style={{ width: "100%", maxWidth: "1200px", margin: "40px auto" }}>
        <h3 style={{ textAlign: "center" }}>QC Chart with Multiple Datasets</h3>
        <Line data={chartData} options={chartOptions} />
      </div>
    </div>
  );
};

TabChart.propTypes = {
  showBothCharts: PropTypes.bool,
  onGetQCDatapoints: PropTypes.func.isRequired,
  showUnvalidatedPoint: PropTypes.bool,
  levels: PropTypes.string, // Adjust according to actual type
  payload: PropTypes.object, // Adjust according to actual type
  datapoints: PropTypes.object, // Changed from array to object
  loadingDatapoints: PropTypes.bool,
  error: PropTypes.string,
};

const mapStateToProps = ({ qcDatapoints }) => ({
  datapoints: qcDatapoints.datapoints,
  loadingDatapoints: qcDatapoints.loadingDatapoints,
  error: qcDatapoints.error,
});

const mapDispatchToProps = (dispatch) => ({
  onGetQCDatapoints: (payload) => dispatch(getQCDatapoints(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withRouter(withTranslation(["chartiQCPage", "common"])(TabChart))
);
