import PropTypes from "prop-types";
//i18n
import { CustomNav, CustomNavLink, PageContent } from "components/Common";
import { ModuleIds } from "constant";
import { useState } from "react";
import { withTranslation } from "react-i18next";
import { Card, CardBody, TabContent, TabPane } from "reactstrap";
import TabHistory from "./TabHistory";
import TabCharts from "./TabCharts";
import Header from "./headerFilter";

const RESOURCE = ModuleIds.ChartiQC

const BodyMid = ({ t, payload }) => {
    const titleTag = t(`Chart iQC`)
    const [currentTab, setCurrentTab] = useState('1')
    const [showBothCharts, setShowBothCharts] = useState(false);
    const [levels, setLevels] = useState(null);
    const [lotIdOrName, setLotIdOrName] = useState([]);
    const [showUnvalidatedPoint, setShowUnvalidatedPoint] = useState(false);
    return (
        
        <PageContent title={titleTag} className="page-content-management-qc" >
        <Header showBothCharts={showBothCharts} setShowBothCharts={setShowBothCharts} levels={levels} setLevels={setLevels} showUnvalidatedPoint={showUnvalidatedPoint} setShowUnvalidatedPoint={setShowUnvalidatedPoint} />
            <Card>
                <CardBody className="card-body-management-qc">
                    <CustomNav
                        onToggle={e => {
                            setCurrentTab(e);
                        }}
                        defaultTab={"1"}
                        tabs
                        className="nav-tabs-custom"
                        tabContents={customActiveTab => (
                            <TabContent
                                activeTab={customActiveTab}
                                className="p-0 text-muted nav-tabs-custom-management-qc"
                            >
                                <TabPane tabId="1">
                                    
                                    {currentTab == "1" &&
                                        <TabCharts payload={payload} showBothCharts={showBothCharts} levels={levels} showUnvalidatedPoint={showUnvalidatedPoint}/>
                                    }
                                </TabPane>
                                <TabPane tabId="2">
                                    {currentTab == "2" &&
                                        <TabHistory />
                                    }
                                </TabPane>
                            </TabContent>
                        )}
                    >
                        <CustomNavLink tabId="1">
                            <span>{t("Chart")}</span>
                        </CustomNavLink>
                        <CustomNavLink tabId="2">
                            <span>{t("History")}</span>
                        </CustomNavLink>
                    </CustomNav>
                </CardBody>
            </Card>
        </PageContent>
    );
}

BodyMid.propTypes = {
    t: PropTypes.any,
}

export default withTranslation(["chartiQCPage", "message", "common"])(BodyMid)