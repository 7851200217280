import { AvForm } from "availity-reactstrap-validation"
import {
  Accordion,
  AccordionBody,
  AccordionWrapper,
  CustomButton,
  CustomDatePicker,
  CustomSelectAsync
} from "components/Common"
import FormSwitchForm from "components/Common/FormSwitchForm"
import { CV_FORMULA, MEAN_FORMULA, parameterCode } from "constant"
import { convertDateFormat, getInvalidMessageI18n } from "helpers/utilities"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { getLottestListQCSuccess } from "store/actions"
import AddCVInfoModal from "./AddCVInfoModal"
import AddMeanPeerInfoModal from "./AddMeanPeerInfoModal"

let cvCalculateName = ''
let meanCalculateName = ''

let newDataCalculate = []

const ConfigCalculateModal = ({
  t,
  submitConfig,
  model,
  data,
  onGetLottestListQCSuccess
}) => {
  const [height, setHeight] = useState(window.innerHeight)
  const [isRender, setIsRender] = useState(false)
  const [modal, setModal] = useState(false)
  const [isCV, setIsCV] = useState(false)
  const [isLot, setIsLot] = useState(false)
  const [isMean, setIsMean] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const [isAuto, setIsAuto] = useState(false)
  const [cvCalculation, setCVcalCulation] = useState('')
  const [meanCalculation, setMeanCalculation] = useState('')
  const [cvDate, setCVDate] = useState([convertDateFormat(new Date(), "DD-MM-YYYY"), convertDateFormat(new Date(), "DD-MM-YYYY")])
  const [meanDate, setMeanDate] = useState([convertDateFormat(new Date(), "DD-MM-YYYY"), convertDateFormat(new Date(), "DD-MM-YYYY")])
  const [lots, setLots] = useState([])
  const [cvModal, setCVModal] = useState(false)
  const [meanModal, setMeanModal] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      getHeight()
    }, 500)
  }, [])

  const getHeight = () => {
    let top = document
      .getElementById("rule-sigma-config-button")
      .getBoundingClientRect().top
    setHeight((window.innerHeight - top - 65) * window.devicePixelRatio)
  }


  useEffect(() => {
    if (modal == false) {
      setIsRender(false)
    } else {
      newDataCalculate = data
      setIsRender(true)
    }
  }, [modal])

  useEffect(() => {
    setLots([])
  }, [model?.insId])

  const onInputInfo = (values) => {
    values.forEach(item => {
      item.lottests.forEach(x => {
        x.sd = (x.cv * x.mean) / 100
      })
    })
    newDataCalculate = values
  }

  return (
    <React.Fragment>
      <div className="rule-sigma-config">
        <CustomButton
          color="secondary"
          outline
          disabled={!model.insId}
          id="rule-sigma-config-button"
          onClick={() => setModal(prev => !prev)}
        >
          <i style={{ fontSize: 16 }} className="bx bx-cog"></i>
        </CustomButton>

        {isRender && (
          <div
            className={`settings-config-modal ${modal ? 'active' : 'inactive'}`}
            style={{ height: "auto", maxHeight: `${height}px` }}
          >
            <div className="settings-config-modal-loading">
              <div className="col-12">
                <AccordionWrapper defaultTab={["1"]}>
                  <Accordion tabId={"1"} title={t("Calculate Setting")}>
                    <AccordionBody>
                      <AvForm
                        onValidSubmit={(e, values) => {
                          values.cvCalculateName = cvCalculateName
                          values.meanCalculateName = meanCalculateName
                          submitConfig(values)
                          setIsRender(prev => !prev)
                          onGetLottestListQCSuccess(newDataCalculate)
                        }}
                      >
                        <div className="d-flex mx-3">
                          <FormSwitchForm
                            name="isCV"
                            label={t("CV Calculate Method")}
                            value={isCV}
                            onChange={(value) => {
                              setIsCV(value)
                              if (!value) {
                                setIsLot(false)
                                setCVcalCulation('')
                                setCVDate([convertDateFormat(new Date(), "DD-MM-YYYY"), convertDateFormat(new Date(), "DD-MM-YYYY")])
                                setLots([])
                                meanCalculateName = ''
                              }
                            }}
                          />
                        </div>
                        <div className="d-flex gap-1 mx-3 mb-2">
                          <div className="col-sm-6">
                            <CustomSelectAsync
                              name="cvCalculation"
                              portal
                              isEdit
                              readOnly={!isCV}
                              required={isCV}
                              value={cvCalculation}
                              customSearchQuery={{ applicable: 'CV' }}
                              code={parameterCode.EXPRESSION_IQC}
                              errorMessage={getInvalidMessageI18n(t, "CV Calculate Method")}
                              onChange={(name, value, label, item) => {
                                setCVcalCulation(value)
                                cvCalculateName = label
                                console.log('item', item)
                                if (item[0].manageCode == CV_FORMULA.CVNGK) {
                                  setCVModal(true)
                                }
                              }}
                            />
                          </div>
                          <div className="col-sm-6">
                            <CustomDatePicker
                              name="cvDate"
                              value={cvDate}
                              isEdit
                              disabled={!(isCV && !isLot)}
                              placeholder={"DD-MM-YYYY"}
                              dateFormat={"d-m-Y"}
                              onChangeHandler={(date, value) => {
                                if (date.time.length == 2) {
                                  setCVDate([
                                    convertDateFormat(date.time[0] || new Date(), "DD-MM-YYYY"),
                                    convertDateFormat(date.time[1] || new Date(), "DD-MM-YYYY")
                                  ])
                                }
                              }}
                              mode="range"
                              closeOnSelect={true}
                            />
                          </div>
                        </div>

                        <div className="d-flex mx-3">
                          <FormSwitchForm
                            name="lotQcs"
                            label={t("Lot Calculate")}
                            value={isLot}
                            disabled={!isCV}
                            onChange={(value) => {
                              setIsLot(value)
                              if (!value) {
                                setLots([])
                              }
                            }}
                          />
                        </div>
                        <div className="gap-1 mx-3 mb-5 ">
                          <div className="col-sm-12">
                            <CustomSelectAsync
                              isMulti
                              name="LotIds"
                              portal
                              isEdit
                              readOnly={!isLot}
                              required={isLot}
                              customSearchQuery={{ ...model, instrumentId: model.insId }}
                              errorMessage={getInvalidMessageI18n(t, "Lot Calculate")}
                              value={lots}
                              code={parameterCode.LOT_QC}
                              onChange={(name, value) => {
                                setLots(value)
                              }}
                            />
                          </div>
                        </div>

                        <div className="mx-3">
                          <FormSwitchForm
                            name="isMean"
                            label={t("Mean Calculate Method")}
                            value={isMean}
                            onChange={(value) => {
                              setIsMean(value)
                              if (!value) {
                                setMeanCalculation('')
                                setMeanDate([convertDateFormat(new Date(), "DD-MM-YYYY"), convertDateFormat(new Date(), "DD-MM-YYYY")])
                                cvCalculateName = ''
                              }
                            }}
                          />
                        </div>
                        <div className="d-flex mx-3 mb-5 gap-1">
                          <div className="col-sm-6">
                            <CustomSelectAsync
                              name="meanCalculation"
                              customSearchQuery={{ applicable: 'MEAN' }}
                              portal
                              value={meanCalculation}
                              isEdit
                              errorMessage={getInvalidMessageI18n(t, "Mean Calculate Method")}
                              required={isMean}
                              readOnly={!isMean}
                              code={parameterCode.EXPRESSION_IQC}
                              onChange={(name, value, label, item) => {
                                setMeanCalculation(value)
                                meanCalculateName = label
                                if (item[0].manageCode == MEAN_FORMULA.MEAN_PEER) {
                                  setMeanModal(true)
                                }
                              }}
                            />
                          </div>

                          <div className="col-sm-6">
                            <CustomDatePicker
                              name="meanDate"
                              disabled={!isMean}
                              value={meanDate}
                              isEdit
                              dateFormat={"d-m-Y"}
                              placeholder={"DD-MM-YYYY"}
                              onChangeHandler={(date, value) => {
                                if (date.time.length == 2) {
                                  setMeanDate([
                                    convertDateFormat(date.time[0] || new Date(), "DD-MM-YYYY"),
                                    convertDateFormat(date.time[1] || new Date(), "DD-MM-YYYY")
                                  ])
                                }
                              }}
                              mode="range"
                              closeOnSelect={true}
                            />
                          </div>
                        </div>

                        <div className="mx-3 mb-3">
                          <FormSwitchForm
                            name="isValid"
                            label={t("Point Valid Calculate")}
                            value={isValid}
                            onChange={(value) => {
                              setIsValid(value)
                            }}
                          />
                        </div>

                        {/* <div className="mx-3 mb-3">
                          <FormSwitchForm
                            name="calculationValidAuto"
                            label={t("Auto Calculate After Valid Reuslt")}
                            value={isAuto}
                            onChange={(value) => {
                              setIsAuto(value)
                            }}
                          />
                        </div> */}
                        <div>
                          <CustomButton
                            text={t("common:Save")}
                            type="submit"
                            className="m-3"
                            color="primary"
                            isEdit
                          />
                        </div>
                      </AvForm>
                    </AccordionBody>
                  </Accordion>
                </AccordionWrapper>
              </div>
            </div>
          </div>
        )}

        <AddMeanPeerInfoModal
          modal={meanModal}
          toggle={() => { setMeanModal(prev => !prev) }}
          data={data}
          onInputInfo={onInputInfo}
        />

        <AddCVInfoModal
          modal={cvModal}
          toggle={() => { setCVModal(prev => !prev) }}
          data={data}
          onInputInfo={onInputInfo}
        />
      </div>
    </React.Fragment>

  )
}

ConfigCalculateModal.defaultProps = {}

const mapStateToProps = ({ testResult }) => ({})

const mapDispatchToProps = dispatch => ({
  onGetLottestListQCSuccess: (payload) => dispatch(getLottestListQCSuccess(payload))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["calculationQCPage", "common"])(ConfigCalculateModal))
