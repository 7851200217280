import { CustomBreadcrumb, PageContent } from "components/Common"
import { ModuleIds } from "constant"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import WestgardRulesQC from "./WestgardRulesQC"

const RESOURCE = ModuleIds.WestgardiQC

const WestgardRulesQCContainer = ({ t }) => {
  const titleTag = `WestgardRulesIQC`
  return (
    <PageContent title={t(titleTag)}>
      <CustomBreadcrumb
        breadcrumbItem={t("WestgardRulesQCList")}
        resource={RESOURCE}
      />
      <WestgardRulesQC />
    </PageContent>
  )
}

WestgardRulesQCContainer.propTypes = {
  t: PropTypes.any,
}

export default withTranslation(["westgardSigmaQCPage"])(
  WestgardRulesQCContainer
)
