import {
    ADD_NEW_CAUSAL_ACTION_QC,
    ADD_CAUSAL_ACTION_QC_FAIL,
    ADD_CAUSAL_ACTION_QC_SUCCESS,
    DELETE_CAUSAL_ACTION_QC,
    DELETE_CAUSAL_ACTION_QC_FAIL,
    DELETE_CAUSAL_ACTION_QC_SUCCESS,
    GET_FULL_CAUSAL_ACTION_QC,
    GET_FULL_CAUSAL_ACTION_QC_FAIL,
    GET_FULL_CAUSAL_ACTION_QC_SUCCESS,
    GET_CAUSAL_ACTION_QC_DETAIL,
    GET_CAUSAL_ACTION_QC_DETAIL_FAIL,
    GET_CAUSAL_ACTION_QC_DETAIL_SUCCESS,
    GET_CAUSAL_ACTION_QC,
    GET_CAUSAL_ACTION_QC_FAIL,
    GET_CAUSAL_ACTION_QC_SUCCESS,
    RESET_CAUSAL_ACTION_QC_SEARCH_QUERY,
    SET_CAUSAL_ACTION_QC_SEARCH_QUERY,
    UPDATE_CAUSAL_ACTION_QC,
    UPDATE_CAUSAL_ACTION_QC_FAIL,
    UPDATE_CAUSAL_ACTION_QC_SUCCESS
} from "./actionTypes"

/* CausalActionQC search query */
export const resetSearchQuery = () => ({
    type: RESET_CAUSAL_ACTION_QC_SEARCH_QUERY
})

export const setCausalActionQCSearchQuery = searchQuery => ({
    type: SET_CAUSAL_ACTION_QC_SEARCH_QUERY,
    payload: searchQuery,
})

/* Get causalActionQCs */
export const getCausalActionQCDetail = payload => {
    return {
        type: GET_CAUSAL_ACTION_QC_DETAIL,
        payload: payload || {},
    }
}

export const getCausalActionQCDetailSuccess = causalActionQCs => ({
    type: GET_CAUSAL_ACTION_QC_DETAIL_SUCCESS,
    payload: causalActionQCs,
})

export const getCausalActionQCDetailFail = error => ({
    type: GET_CAUSAL_ACTION_QC_DETAIL_FAIL,
    payload: error,
})


/* Add causalActionQC */
export const addNewCausalActionQC = (causalActionQC, callback) => ({
    type: ADD_NEW_CAUSAL_ACTION_QC,
    payload: causalActionQC,
    callback
})

export const addCausalActionQCSuccess = causalActionQC => ({
    type: ADD_CAUSAL_ACTION_QC_SUCCESS,
    payload: causalActionQC
})

export const addCausalActionQCFail = error => ({
    type: ADD_CAUSAL_ACTION_QC_FAIL,
    payload: error
})

/* Update a causalActionQC */
export const updateCausalActionQC = (causalActionQC, callback) => ({
    type: UPDATE_CAUSAL_ACTION_QC,
    payload: causalActionQC,
    callback
})

export const updateCausalActionQCSuccess = causalActionQC => ({
    type: UPDATE_CAUSAL_ACTION_QC_SUCCESS,
    payload: causalActionQC
})

export const updateCausalActionQCFail = error => ({
    type: UPDATE_CAUSAL_ACTION_QC_FAIL,
    payload: error
})

/* Delete causalActionQCs */
export const deleteCausalActionQCs = causalActionQCs => ({
    type: DELETE_CAUSAL_ACTION_QC,
    payload: causalActionQCs
})

export const deleteCausalActionQCsSuccess = causalActionQCs => ({
    type: DELETE_CAUSAL_ACTION_QC_SUCCESS,
    payload: causalActionQCs
})

export const deleteCausalActionQCsFail = error => ({
    type: DELETE_CAUSAL_ACTION_QC_FAIL,
    payload: error
})

export const getFullCausalActionQC = payload => ({
    type: GET_FULL_CAUSAL_ACTION_QC,
    payload: payload || {},
})

export const getFullCausalActionQCSuccess = causalActionQCs => ({
    type: GET_FULL_CAUSAL_ACTION_QC_SUCCESS,
    payload: causalActionQCs,
})

export const getFullCausalActionQCFail = error => ({
    type: GET_FULL_CAUSAL_ACTION_QC_FAIL,
    payload: error,
})