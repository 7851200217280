import { AvCheckbox, AvCheckboxGroup, AvInput } from "availity-reactstrap-validation"
import PropTypes from "prop-types"
import { useEffect, useState } from "react";
import { Label } from "reactstrap"

const FormSwitchForm = ({ label, value, name, color, style, onVendorChange, onChange, disabled }) => {
    const [defaultValue, setDefaultValue] = useState(false);

    useEffect(() => {
        setDefaultValue(value)
    }, [value])

    const onChangeHandler = () => {
        if (onVendorChange) {
            setDefaultValue(!defaultValue)
            onVendorChange(!defaultValue)
        } else {
            if (onChange) {
                onChange(!defaultValue)
            } else {
                setDefaultValue(!defaultValue)
            }
        }
    }

    return (
        <div className="d-flex">

            <div className="d-none">
                <AvInput
                    type="checkbox"
                    name={name}
                    checked={defaultValue}
                    onChange={onChangeHandler}
                    disabled={disabled}
                />
            </div>
            <div className="form-check form-switch" style={{
                marginLeft: '6px',
                ...style
            }}>
                <input
                    type="checkbox"
                    className="form-check-input"
                    style={{
                        backgroundColor: defaultValue ? `#${color}` : '',
                        borderColor: defaultValue ? `#${color}` : '',
                    }}
                    disabled={disabled}
                    checked={defaultValue}
                    onChange={onChangeHandler}
                />
            </div>
            {label && (
                <Label>
                    {label}
                </Label>
            )}
        </div>
    )
}

FormSwitchForm.propTypes = {
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    className: PropTypes.string,
    name: PropTypes.string,
    color: PropTypes.string,
}

FormSwitchForm.defaultProps = {
    label: "label",
    value: "",
    className: "",
}

export default FormSwitchForm
