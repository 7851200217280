import { CustomBreadcrumb, PageContent } from "components/Common"
import { ModuleIds } from "constant"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import CausalActionQCTab from "./CausalActionQCTab"

const RESOURCE = ModuleIds.CausalActioniQC

const CausalActionQCContainer = ({ t }) => {
  const titleTag = `CausalActionQC`
  return (
    <PageContent title={t(titleTag)}>
      <CustomBreadcrumb
        breadcrumbItem={t("CausalActionQCList")}
        resource={RESOURCE}
      />
      <CausalActionQCTab />
    </PageContent>
  )
}

CausalActionQCContainer.propTypes = {
  t: PropTypes.any,
}

export default withTranslation(["causalActionQCPage"])(
  CausalActionQCContainer
)
