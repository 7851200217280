import { Check, CustomAvInput, CustomBootstrapTable } from "components/Common"
import BadgeLabel from "components/Common/BadgeLabel"
import CustomButtonIcon from "components/Common/Button/CustomButtonIcon"
import { ModuleIds, permissionType, PrintConfigType, PrintConfigTypeName, PrintConfigTypeNumber } from "constant"
import { indexCalculator } from "helpers/utilities"
import { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import ReactTooltip from "react-tooltip"
import FilterForm from "./FilterForm"
import { AvForm } from "availity-reactstrap-validation"

const RESOURCE = ModuleIds.PrintConfig

const PrintConfigTable = ({
    onSelect,
    onSelectAll,
    printConfigs,
    onSearch,
    onRefresh,
    onPageChange,
    paging,
    onSizePerPageChange,
    onSubmitFilter,
    onSort,
    onDelete,
    loading,
    t,
    updatedTime,
    onLabelChange
}) => {
    const [data, setData] = useState([])
    const [model, setModel] = useState({
        search: "",
        type: [],
    })

    useEffect(() => {
        setData(printConfigs)
    }, [printConfigs])

    const columns = [
        {
            dataField: "id",
            text: "#",
            style: { width: 50 },
            formatter: (cellContent, testPrintConfig, index) => {
                return indexCalculator(paging.page, paging.size, index + 1)
            },
        },
        {
            dataField: "id",
            text: "#",
            hidden: true,
            formatter: (cellContent, testPrintConfig, index) => {
                return cellContent
            },
        },
        {
            dataField: "type",
            text: t("common:Type"),
            sort: true,
            align: 'center',
            headerAlign: 'center',
            style: { width: 150 },
            formatter: (cellContent, user, index) => {
                PrintConfigType.SAMPLE
                return <BadgeLabel
                    value={t(`printConfigPage:${PrintConfigTypeName[cellContent]}`)}
                    type={PrintConfigTypeNumber[cellContent]}
                    className="badge-width"
                />
            },
        },
        {
            dataField: "code",
            text: t("common:Code"),
            style: { width: 200 },
            formatter: (cellContent) => {
                return <span style={{ fontWeight: 'bold' }}>{cellContent}</span>
            }
        },
        {
            dataField: "name",
            text: t("common:Name"),
            style: { width: 750 },
            formatter: (cellContent, item, index) => {
                return item.testName ? <Link to={`/Test/${item?.code}/view`} target="_blank">{item?.testName}</Link> :
                    (item.profileName ? <Link to={`/TestProfile/${item?.code}/view`} target="_blank">{item?.profileName}</Link> : item?.typeName)
            },
        },
        {
            dataField: "numOfLabel",
            text: t("printConfigPage:Number Of Label"),
            formatter: (cellContent, item, index) => (
                <div
                    onClick={e => {
                        document.getElementById(`numOfLabel-${index}`).style.display = ""
                        document.getElementById(`numOfLabel-${index}`).focus()
                        document.getElementById(`numOfLabel-text-${index}`).style.display =
                            "none"
                    }}
                >
                    <a href="#" id={`numOfLabel-text-${index}`}>
                        {cellContent}
                    </a>
                    <AvForm>
                        <CustomAvInput
                            style={{ display: "none", height: '32px', width: '120px' }}
                            id={`numOfLabel-${index}`}
                            name={`numOfLabel`}
                            value={item.numOfLabel || ""}
                            type="number"
                            min={0}
                            onBlur={e => {
                                if (e.target.value != item.numOfLabel) {
                                    onLabelChange(item, e.target.value)
                                }
                                document.getElementById(`numOfLabel-${index}`).style.display =
                                    "none"
                                document.getElementById(`numOfLabel-text-${index}`).style.display =
                                    ""
                            }}
                        />
                    </AvForm>
                </div>
            ),
        },
        {
            dataField: "action",
            text: "",
            isDummyField: true,
            formatter: (cellContent, param, index) => {
                return (
                    <div className="d-flex toolbar button-items justify-content-end">
                        {param.type != 1 && <Check permission={permissionType.D} resource={RESOURCE}>
                            <CustomButtonIcon id={`delete-${index}`} color="btn btn-outline-danger btn-soft-danger"
                                data-tip
                                data-for={`delete-${index}`}
                                onClick={e => { onDelete(e, param) }}
                            >
                                <i className="mdi mdi-delete-outline" />
                            </CustomButtonIcon>
                            <ReactTooltip place="top" id={`delete-${index}`} effect="solid" >
                                {t("common:Delete")}
                            </ReactTooltip>
                        </Check>}
                    </div>
                )
            },
        },
    ]
    return (
        <CustomBootstrapTable
            columns={columns}
            search
            onSort={onSort}
            data={data}
            paging={paging}
            onSelect={onSelect}
            onSelectAll={onSelectAll}
            onSearch={onSearch}
            onRefresh={onRefresh}
            filterForm={() => <FilterForm model={model} />}
            onPageChange={onPageChange}
            onSizePerPageChange={onSizePerPageChange}
            onSubmitFilter={onSubmitFilter}
            searchText={model.search}
            loading={loading}
            updatedTime={updatedTime}
            resource={RESOURCE}
        />
    )
}
export default withTranslation(["common", "printConfigPage"])(PrintConfigTable)