import React from "react"
import { Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap"
import PropTypes from "prop-types"

//i18n
import { withTranslation } from "react-i18next"
import { CustomButton } from "../Button"

const ConfirmModal3 = ({
  modal,
  onToggle,
  onConfirm,
  message,
  title,
  btnConfirmText,
  t,
  isEdit,
  style
}) => {
  return (
    <Modal centered={true} scrollable={true} isOpen={modal}>
      <ModalHeader toggle={onToggle} tag="h5">
        {title}
      </ModalHeader>
      <ModalBody style={style}>
        <Row>
          <div>{message}</div>
        </Row>
      </ModalBody>
      <ModalFooter>
        <CustomButton
          type="button"
          onClick={onToggle}
          className="btn btn-secondary button-width"
          data-dismiss="modal"
        >
          {t("Cancel")}
        </CustomButton>
        <CustomButton
          type="button"
          onClick={() => {
            onConfirm()
          }}
          className="btn btn-info save-user button-width"
          isEdit={isEdit}
        >
          {t(btnConfirmText)}
        </CustomButton>
      </ModalFooter>
    </Modal>
  )
}

ConfirmModal3.propTypes = {
  onConfirm: PropTypes.func,
  onToggle: PropTypes.func,
  modal: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.string,
  btnConfirmText: PropTypes.string,
  t: PropTypes.any,
}
ConfirmModal3.defaultProps = {
  onConfirm: () => { },
  onToggle: () => { },
  title: "title",
  message: "message",
  btnConfirmText: "OK",
}

export default withTranslation(["common"])(ConfirmModal3)
