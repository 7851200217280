import { withTranslation } from "react-i18next"
import { CustomButton } from "components/Common"
import { exportWestgardSigmaQCs } from "helpers/app-backend/IQC/QC_Westgard_Sigma_backend_helper"
import { WESTGARD_TYPE } from "constant"

const ImportExportButton = ({ t, model }) => {
  const ExportTests = async () => {
    model.type = WESTGARD_TYPE.WESTGARD_RULES
    const fileData = await exportWestgardSigmaQCs(model)
    const blob = new Blob([fileData])
    saveAs(blob, "WestgardExport.xlsx")
  }

  return (
    <div className="tooltip-custom">
      <CustomButton
        isEdit
        color="secondary"
        outline
        onClick={() => {
          ExportTests()
        }}
      >
        <i className={"fas fa-download"}></i>
      </CustomButton>
      <span className="tooltiptext-custom-button-icon-left">
        {t("Export All")}
      </span>
    </div>
  )
}

export default withTranslation(["testPage", "common"])(ImportExportButton)
