
import {
  ADD_TESTREQUEST_SAMPLE_FAIL,
  ADD_TESTREQUEST_SAMPLE_SUCCESS,
  ADD_TESTREQUEST_TEST_FAIL,
  ADD_TESTREQUEST_TEST_SUCCESS,
  CANCEL_COLLECT_SAMPLE_FAIL,
  CANCEL_COLLECT_SAMPLE_SUCCESS,
  CANCEL_TEST_REQUEST_FAIL,
  CANCEL_TEST_REQUEST_SUCCESS,
  CHANGE_REQUEST_SAMPLE_TABLE,
  CHANGE_REQUEST_SAMPLE_TABLE_FAIL,
  CHANGE_REQUEST_SAMPLE_TABLE_SUCCESS,
  CLEAR_DATA_REQUEST_DETAIL_IN_PATIENT_VISIT,
  CLEAR_DETAIL_INFO_PATIENT_VISIT,
  COUNT_PATIENT_AND_TEST,
  COUNT_PATIENT_AND_TEST_FAIL,
  COUNT_PATIENT_AND_TEST_SUCCESS,
  CREATE_NEW_COLLECTOR_AREA_FAIL,
  CREATE_NEW_COLLECTOR_AREA_SUCCESS,
  CREATE_NEW_REQUEST_FAIL,
  CREATE_NEW_REQUEST_SUCCESS,
  DELETE_REQUESTS_FAIL,
  DELETE_REQUESTS_SUCCESS,
  DELETE_TESTREQUEST_SAMPLE_FAIL,
  DELETE_TESTREQUEST_SAMPLE_SUCCESS,
  DELETE_TESTREQUEST_TESTS_FAIL,
  DELETE_TESTREQUEST_TESTS_SUCCESS,
  EDIT_TESTREQUEST_NUMBER_OF_LABELS,
  EDIT_TESTREQUEST_NUMBER_OF_LABELS_FAIL,
  EDIT_TESTREQUEST_NUMBER_OF_LABELS_SUCCESS,
  EDIT_TESTREQUEST_SAMPLEID,
  EDIT_TESTREQUEST_SAMPLEID_FAIL,
  EDIT_TESTREQUEST_SAMPLEID_SUCCESS,
  EDIT_TESTREQUEST_TEST_SAMPLETYPE,
  EDIT_TESTREQUEST_TEST_SAMPLETYPE_FAIL,
  EDIT_TESTREQUEST_TEST_SAMPLETYPE_SUCCESS,
  EMPTY_TESTREQUEST_DETAIL,
  GET_BILLING_TYPES,
  GET_BILLING_TYPES_FAIL,
  GET_BILLING_TYPES_SUCCESS,
  GET_COLLECTOR_AREA,
  GET_COLLECTOR_AREA_FAIL,
  GET_COLLECTOR_AREA_SUCCESS,
  GET_PATIENT_VISIT_SUCCESS,
  GET_REQUESTS, GET_REQUESTS_FAIL, GET_REQUESTS_SUCCESS,
  GET_TESTREQUEST_DETAIL,
  GET_TESTREQUEST_DETAIL_FAIL,
  GET_TESTREQUEST_DETAIL_SUCCESS,
  GET_TESTREQUEST_GENERAL_DETAIL,
  GET_TESTREQUEST_GENERAL_DETAIL_FAIL,
  GET_TESTREQUEST_GENERAL_DETAIL_SUCCESS,
  GET_TESTREQUEST_PATIENT_VISIT_DETAIL,
  GET_TESTREQUEST_PATIENT_VISIT_DETAIL_FAIL,
  GET_TESTREQUEST_PATIENT_VISIT_DETAIL_SUCCESS,
  GET_TESTREQUEST_PATIENT_VISIT_GENERAL_DETAIL,
  GET_TESTREQUEST_PATIENT_VISIT_GENERAL_DETAIL_FAIL,
  GET_TESTREQUEST_PATIENT_VISIT_GENERAL_DETAIL_SUCCESS,
  GET_TESTREQUEST_PATIENT_VISIT_PROFILE_DETAIL,
  GET_TESTREQUEST_PATIENT_VISIT_PROFILE_DETAIL_FAIL,
  GET_TESTREQUEST_PATIENT_VISIT_PROFILE_DETAIL_SUCCESS,
  GET_TESTREQUEST_PROFILE_DETAIL,
  GET_TESTREQUEST_PROFILE_DETAIL_FAIL,
  GET_TESTREQUEST_PROFILE_DETAIL_SUCCESS,
  GET_TESTREQUEST_SAMPLE,
  GET_TESTREQUEST_SAMPLES_DETAIL,
  GET_TESTREQUEST_SAMPLES_DETAIL_FAIL,
  GET_TESTREQUEST_SAMPLES_DETAIL_SUCCESS,
  GET_TESTREQUEST_SAMPLE_FAIL,
  GET_TESTREQUEST_SAMPLE_SUCCESS,
  GET_TESTREQUEST_TESTS_DETAIL,
  GET_TESTREQUEST_TESTS_DETAIL_FAIL,
  GET_TESTREQUEST_TESTS_DETAIL_SUCCESS,
  GET_TESTREQUEST_TESTS_LOADING_COMPLETE,
  GET_TESTREQUEST_TEST_DETAIL,
  GET_TESTREQUEST_TEST_DETAIL_FAIL,
  GET_TESTREQUEST_TEST_DETAIL_SUCCESS,
  LOADING_PRINT_SAMPLE_LIST,
  PRINT_SAMPLE_LIST,
  RELOAD_STATE_TESTREQUEST_DETAIL,
  RELOAD_STATE_TESTREQUEST_DETAIL_FAIL,
  RELOAD_STATE_TESTREQUEST_DETAIL_SUCCESS,
  RESET_SEARCH_TEST_MANUAL,
  RESET_TESTREQUESTS_SEARCH_QUERY,
  SAMPLE_PATIENT_VISIT_CHANGE,
  SET_REQUEST_SAMPLE_CLEAR,
  SET_REQUEST_SAMPLE_COLLECTOR, SET_REQUEST_SAMPLE_COLLECT_TIME,
  SET_REQUEST_SAMPLE_NUMB_OF_LABELS,
  SET_REQUEST_SAMPLE_NUMB_OF_LABELS_FAIL,
  SET_REQUEST_SAMPLE_NUMB_OF_LABELS_SUCCESS,
  SET_REQUEST_SAMPLE_QUALITY,
  SET_REQUEST_SAMPLE_RECEIVED_TIME,
  SET_REQUEST_SAMPLE_RECEIVER,
  SET_REQUEST_SAMPLE_SID,
  SET_REQUEST_SAMPLE_SID_FAIL,
  SET_REQUEST_SAMPLE_SID_SUCCESS,
  SET_TESTREQUESTS_SEARCH_QUERY,
  TEST_PATIENT_VISIT_CHANGE,
  UPDATE_REQUESTSSAMPLE,
  UPDATE_REQUESTSSAMPLE_FAIL,
  UPDATE_REQUESTSSAMPLE_SUCCESS,
  UPDATE_REQUEST_GENERAL,
  UPDATE_REQUEST_GENERAL_FAIL,
  UPDATE_REQUEST_GENERAL_SUCCESS,
  UPDATE_REQUEST_SID,
  UPDATE_REQUEST_SID_FAIL,
  UPDATE_REQUEST_SID_SUCCESS,
  UPDATE_REQUEST_STTGPB_FAIL,
  UPDATE_REQUEST_STTGPB_SUCCESS,
  UPDATE_REQUEST_TESTS,
  UPDATE_REQUEST_TESTS_FAIL,
  UPDATE_REQUEST_TESTS_SUCCESS,
  UPDATE_REQUEST_WAITING_FAIL,
  UPDATE_REQUEST_WAITING_SUCCESS,
  UPDATE_SAMPLE_NUMBOFLABELS,
  UPDATE_SAMPLE_NUMBOFLABELS_FAIL,
  UPDATE_SAMPLE_NUMBOFLABELS_SUCCESS,
  UPDATE_TESTREQUESTS_FAIL,
  UPDATE_TESTREQUESTS_SUCCESS
} from "./actionTypes";

const INIT_STATE = {
  testRequests: [],
  collectors: [],
  testRequest: {},
  patientVisit: {},
  paging: {},
  loadingRequests: false,
  updateRequestsTime: new Date(),
  searchQuery: {},
  error: {},
  billingTypes: [],
  testsLoadingComplete: false,

  loadingRequestDetail: false,
  updateRequestDetailTime: new Date(),

  loadingRequestSample: false,
  updateRequestSampleTime: new Date(),

  loadingRequestTestDetail: false,
  updateRequestTestDetailTime: new Date(),

  requestTestTestsCount: 0,
  requestTestSamplesCount: 0,

  printSampleList: false,
  loadingPrintSampleList: false,
  testRequestOldValue: {},
  isResetTestManual: false,

  testChange: [],
  sampleChange: []
}

const TestRequest = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_REQUESTS:
      return {
        ...state,
        //testRequests: [],
        error: {},
        loadingRequests: true,
      }

    case GET_REQUESTS_FAIL:
      return {
        ...state,
        error: action.payload,
        loadingRequests: false,
      }

    case GET_REQUESTS_SUCCESS: {
      const { data, totalElements, ...rest } = action.payload
      return {
        ...state,
        testRequests: data,
        paging: { ...rest, dataSize: totalElements },
        loadingRequests: false,
        updateRequestsTime: new Date(),
      }
    }

    case SET_TESTREQUESTS_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: { ...action.payload },
      }
    case RESET_TESTREQUESTS_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: {},
      }

    case GET_TESTREQUEST_DETAIL: {
      return {
        ...state,
        loadingRequestDetail: true,
        testsLoadingComplete: false,
        testRequest: {},
        error: {},
      }
    }
    case GET_TESTREQUEST_DETAIL_SUCCESS: {
      const { testRequest, billingTypes } = action.payload

      return {
        ...state,
        testRequest: testRequest,
        billingTypes: billingTypes,
        updateRequestDetailTime: new Date(),
        loadingRequestDetail: false,
      }
    }
    case GET_TESTREQUEST_DETAIL_FAIL: {
      return {
        ...state,
        error: action.payload,
      }
    }

    case GET_TESTREQUEST_PATIENT_VISIT_DETAIL: {
      return {
        ...state,
        loadingRequestDetail: true,
        testsLoadingComplete: false,
        testRequest: {},
        error: {},
      }
    }
    case GET_TESTREQUEST_PATIENT_VISIT_DETAIL_SUCCESS: {
      const { testRequest, billingTypes } = action.payload

      return {
        ...state,
        testRequest: testRequest,
        billingTypes: billingTypes,
        updateRequestDetailTime: new Date(),
        loadingRequestDetail: false,
      }
    }
    case GET_TESTREQUEST_PATIENT_VISIT_DETAIL_FAIL: {
      return {
        ...state,
        error: action.payload,
      }
    }

    case EMPTY_TESTREQUEST_DETAIL: {
      return {
        ...state,
        testRequest: {},
        error: {},
      }
    }

    // GET_BILLING_TYPES
    case GET_BILLING_TYPES: {
      return {
        ...state,
        billingTypes: [],
        error: {},
      }
    }
    case GET_BILLING_TYPES_SUCCESS: {
      const billingTypes = action.payload
      return {
        ...state,
        billingTypes,
      }
    }
    case GET_BILLING_TYPES_FAIL: {
      return {
        ...state,
        error: action.payload,
      }
    }
    // GET_BILLING_TYPES END

    case RESET_SEARCH_TEST_MANUAL:
      // testRequest.samples = []
      // testRequest.tests = []
      return {
        ...state,
        isResetTestManual: action.payload,
      }

    case CREATE_NEW_REQUEST_SUCCESS:
      let testRequest = state.testRequest
      // testRequest.samples = []
      // testRequest.tests = []
      return {
        ...state,
        testRequest: testRequest,
      }

    case CREATE_NEW_REQUEST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    // case CANCEL_TEST_REQUEST:

    //     return {
    //         ...state,
    //     }

    case CANCEL_TEST_REQUEST_SUCCESS:
      return {
        ...state,
      }

    case CANCEL_TEST_REQUEST_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_TESTREQUESTS_SUCCESS:
      return {
        ...state,
      }
    case UPDATE_TESTREQUESTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_REQUESTS_SUCCESS:
      const payload = action.payload
      const testRequestUpdate = state.testRequests.filter(
        testRequest =>
          payload.findIndex(_payload => testRequest.id === _payload.id) < 0
      )
      const statePaging = { ...state.paging }
      statePaging.dataSize = statePaging.dataSize - payload.length
      if (statePaging.dataSize < 0) statePaging = 0

      return {
        ...state,
        testRequests: testRequestUpdate,
        paging: statePaging,
      }

    case DELETE_REQUESTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    //test request sample
    case DELETE_TESTREQUEST_SAMPLE_SUCCESS:
      return {
        ...state,
        testRequest: action.payload,
      }
    case DELETE_TESTREQUEST_SAMPLE_FAIL:
      return {
        ...state,
      }
    case ADD_TESTREQUEST_SAMPLE_SUCCESS:
      return {
        ...state,
        testRequest: action.payload,
      }

    case ADD_TESTREQUEST_SAMPLE_FAIL:
      return {
        ...state,
      }
    //test request test
    case DELETE_TESTREQUEST_TESTS_SUCCESS:
      return {
        ...state,
        testRequest: action.payload,
      }
    case DELETE_TESTREQUEST_TESTS_FAIL:
      return {
        ...state,
      }
    case ADD_TESTREQUEST_TEST_SUCCESS:
      return {
        ...state,
        testRequest: action.payload,
      }

    case ADD_TESTREQUEST_TEST_FAIL:
      return {
        ...state,
      }

    //get test info
    case GET_TESTREQUEST_TEST_DETAIL:
      return {
        ...state,
      }
    case GET_TESTREQUEST_TEST_DETAIL_SUCCESS: {
      return {
        ...state,
        testRequest: action.payload,
      }
    }
    case GET_TESTREQUEST_TEST_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case EDIT_TESTREQUEST_TEST_SAMPLETYPE:
      return {
        ...state,
      }
    case EDIT_TESTREQUEST_TEST_SAMPLETYPE_SUCCESS: {
      return {
        ...state,
        testRequest: action.payload,
      }
    }
    case EDIT_TESTREQUEST_TEST_SAMPLETYPE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_TESTREQUEST_PROFILE_DETAIL: {
      return {
        ...state,
      }
    }
    case GET_TESTREQUEST_PROFILE_DETAIL_SUCCESS: {
      return {
        ...state,
        testRequest: action.payload,
      }
    }
    case GET_TESTREQUEST_PROFILE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_TESTREQUEST_PATIENT_VISIT_PROFILE_DETAIL: {
      return {
        ...state,
      }
    }
    case GET_TESTREQUEST_PATIENT_VISIT_PROFILE_DETAIL_SUCCESS: {
      return {
        ...state,
        testRequest: action.payload,
      }
    }
    case GET_TESTREQUEST_PATIENT_VISIT_PROFILE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_TESTREQUEST_SAMPLE:
      return {
        ...state,
      }
    case GET_TESTREQUEST_SAMPLE_SUCCESS: {
      let testRequest = state.testRequest
      testRequest.samples = action.payload.sort((a, b) => {
        return a.sid - b.sid
      })

      return {
        ...state,
        testRequest: JSON.parse(JSON.stringify(testRequest)),
      }
    }
    case GET_TESTREQUEST_SAMPLE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case SET_REQUEST_SAMPLE_COLLECTOR: {
      const { item, value } = action.payload

      let testRequest = state.testRequest

      let index = testRequest.samples.findIndex(
        x => x.sampleType === item.sampleType && x.subSID === item.subSID
      )

      if (index >= 0) {
        testRequest.samples[index].collectorUserId = value.id
        testRequest.samples[
          index
        ].collectorName = `${value.familyName} ${value.givenName}`
      }

      return {
        ...state,
        testRequest: JSON.parse(JSON.stringify(testRequest)),
      }
    }
    case SET_REQUEST_SAMPLE_SID: {
      return {
        ...state,
      }
    }
    case SET_REQUEST_SAMPLE_SID_SUCCESS: {
      const { item, value } = action.payload

      let testRequest = state.testRequest

      let index = testRequest.samples.findIndex(
        x => x.sampleType === item.sampleType && x.subSID === item.subSID
      )

      if (index >= 0) {
        testRequest.samples[index].sid = value
      }

      return {
        ...state,
        testRequest: JSON.parse(JSON.stringify(testRequest)),
      }
    }

    case SET_REQUEST_SAMPLE_SID_FAIL: {
      return {
        ...state,
      }
    }

    case SET_REQUEST_SAMPLE_NUMB_OF_LABELS: {
      return {
        ...state,
      }
    }

    case SET_REQUEST_SAMPLE_NUMB_OF_LABELS_SUCCESS: {
      const { item, value } = action.payload

      let testRequest = state.testRequest

      let index = testRequest.samples.findIndex(
        x => x.sampleType === item.sampleType && x.subSID === item.subSID
      )

      if (index >= 0) {
        testRequest.samples[index].numberOfLabels = value
      }

      return {
        ...state,
        testRequest: JSON.parse(JSON.stringify(testRequest)),
      }
    }

    case SET_REQUEST_SAMPLE_NUMB_OF_LABELS_FAIL: {
      return {
        ...state,
      }
    }

    case SET_REQUEST_SAMPLE_RECEIVER: {
      const { item, value } = action.payload

      let testRequest = state.testRequest

      let index = testRequest.samples.findIndex(
        x => x.sampleType === item.sampleType && x.subSID === item.subSID
      )

      if (index >= 0) {
        testRequest.samples[index].receiverUserId = value.id
        testRequest.samples[
          index
        ].receiverName = `${value.familyName} ${value.givenName}`
      }

      return {
        ...state,
        testRequest: JSON.parse(JSON.stringify(testRequest)),
      }
    }

    case CHANGE_REQUEST_SAMPLE_TABLE_SUCCESS: {
      let testRequest = state.testRequest
      testRequest.samples = action.payload
      return {
        ...state,
        testRequest: JSON.parse(JSON.stringify(testRequest)),
      }
    }
    case CHANGE_REQUEST_SAMPLE_TABLE:
      return {
        ...state,
      }
    case CHANGE_REQUEST_SAMPLE_TABLE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case SET_REQUEST_SAMPLE_COLLECT_TIME: {
      const { item, value } = action.payload

      let testRequest = state.testRequest
      let index = testRequest.samples.findIndex(
        x => x.sampleType === item.sampleType && x.subSID === item.subSID
      )

      if (index >= 0) {
        testRequest.samples[index].collectionTime = value
      }
      return {
        ...state,
        testRequest: JSON.parse(JSON.stringify(testRequest)),
      }
    }
    case SET_REQUEST_SAMPLE_RECEIVED_TIME: {
      const { item, value } = action.payload

      let testRequest = state.testRequest
      let index = testRequest.samples.findIndex(
        x => x.sampleType === item.sampleType && x.subSID === item.subSID
      )

      if (index >= 0) {
        testRequest.samples[index].receivedTime = value
      }
      return {
        ...state,
        testRequest: JSON.parse(JSON.stringify(testRequest)),
      }
    }

    case SET_REQUEST_SAMPLE_CLEAR: {
      const { item, value } = action.payload
      let testRequest = state.testRequest
      let index = testRequest.samples.findIndex(
        x => x.sampleType === item.sampleType && x.subSID === item.subSID
      )
      if (index >= 0) {
        const sample = testRequest.samples[index];
        Object.assign(sample, {
          isClear: value,
          isCollected: value,
          // ...(sample.collectionTime && sample.collectorUserId && sample.isCollected || sample.isClear && {
          collectionTime: item.collectionTime,
          collectorUserId: item.collectorUserId,
          collectorName: item.collectorName,
          qualityName: item.qualityName,
          // }),
          ...(sample.receivedTime && sample.receiverUserId && sample.isReceived && {
            isReceived: value,
            receivedTime: item.receivedTime,
            receiverUserId: item.receiverUserId,
            receiverName: item.receiverName,
            qualityName: item.qualityName
          })
        });
      }
      return {
        ...state,
        testRequest: JSON.parse(JSON.stringify(testRequest)),
      }
    }

    case SET_REQUEST_SAMPLE_QUALITY: {
      const { item, value } = action.payload

      let testRequest = state.testRequest
      let index = testRequest.samples.findIndex(
        x => x.sampleType === item.sampleType && x.subSID === item.subSID
      )
      if (index >= 0) {
        testRequest.samples[index].quality = value.value
        testRequest.samples[index].qualityName = value.label
      }
      return {
        ...state,
        testRequest: JSON.parse(JSON.stringify(testRequest)),
      }
    }
    case UPDATE_REQUEST_GENERAL:
      return {
        ...state,
      }

    case UPDATE_REQUEST_GENERAL_SUCCESS:
      return {
        ...state,
      }

    case UPDATE_REQUEST_GENERAL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_REQUEST_SID:
      return {
        ...state,
      }

    case UPDATE_REQUEST_SID_SUCCESS:
      return {
        ...state,
      }

    case UPDATE_REQUEST_SID_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_REQUEST_TESTS:
      return {
        ...state,
      }
    case UPDATE_REQUEST_TESTS_SUCCESS:
      return {
        ...state,
      }

    case UPDATE_REQUEST_TESTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_REQUESTSSAMPLE:
      return {
        ...state,
        loadingRequestSample: true,
      }
    case UPDATE_REQUESTSSAMPLE_SUCCESS:
      return {
        ...state,
        loadingRequestSample: false,
        updateRequestSampleTime: new Date(),
      }
    case UPDATE_REQUESTSSAMPLE_FAIL:
      return {
        ...state,
        error: action.payload,
        loadingRequestSample: false,
      }

    //get test request detail
    case GET_TESTREQUEST_GENERAL_DETAIL: {
      return {
        ...state,
        error: {},
        loadingRequestDetail: true,
      }
    }
    case GET_TESTREQUEST_GENERAL_DETAIL_SUCCESS: {
      const { request, billingTypes } = action.payload
      let testRequest = state.testRequest
      let samples = testRequest.samples || []
      let tests = testRequest.tests || []
      request.samples = [...samples]
      request.tests = [...tests]

      return {
        ...state,
        testRequest: request,
        billingTypes: billingTypes,
        loadingRequestDetail: false,
        updateRequestDetailTime: new Date(),
      }
    }
    case GET_PATIENT_VISIT_SUCCESS: {
      const { request, billingTypes } = action.payload
      let testRequest = state.testRequest
      let samples = testRequest.samples || []
      let tests = testRequest.tests || []
      request.samples = [...samples]
      request.tests = [...tests]
      return {
        ...state,
        patientVisit: request,
        billingTypes: billingTypes,
        loadingRequestDetail: false,
        updateRequestDetailTime: new Date(),
      }
    }
    case GET_TESTREQUEST_GENERAL_DETAIL_FAIL: {
      return {
        ...state,
        error: action.payload,
      }
    }

    case GET_TESTREQUEST_PATIENT_VISIT_GENERAL_DETAIL: {
      return {
        ...state,
        error: {},
        loadingRequestDetail: true,
      }
    }
    case GET_TESTREQUEST_PATIENT_VISIT_GENERAL_DETAIL_SUCCESS: {
      const { request, billingTypes } = action.payload
      let testRequest = state.testRequest
      let samples = testRequest.samples || []
      let tests = testRequest.tests || []
      request.samples = [...samples]
      request.tests = [...tests]

      return {
        ...state,
        testRequest: request,
        billingTypes: billingTypes,
        loadingRequestDetail: false,
        updateRequestDetailTime: new Date(),
      }
    }
    case GET_TESTREQUEST_PATIENT_VISIT_GENERAL_DETAIL_FAIL: {
      return {
        ...state,
        error: action.payload,
      }
    }

    case GET_TESTREQUEST_TESTS_DETAIL: {
      let testRequest = state.testRequest
      testRequest.tests = []
      return {
        ...state,
        loadingRequestTestDetail: true,
        testsLoadingComplete: false,
        error: {},
      }
    }
    case GET_TESTREQUEST_TESTS_DETAIL_SUCCESS: {
      const { tests, requestTestTestsCount } = action.payload
      let testRequest = state.testRequest

      testRequest.tests = {}
      testRequest.tests = [...tests]
      return {
        ...state,
        testRequest: testRequest,
        updateRequestTestDetailTime: new Date(),
        updateRequestSampleTime: new Date(),
        loadingRequestTestDetail: false,
        requestTestTestsCount: requestTestTestsCount,
      }
    }
    case GET_TESTREQUEST_TESTS_DETAIL_FAIL: {
      return {
        ...state,
        error: action.payload,
      }
    }
    case GET_TESTREQUEST_SAMPLES_DETAIL: {
      return {
        ...state,
        loadingRequestDetail: true,
        error: {},
      }
    }
    case GET_TESTREQUEST_SAMPLES_DETAIL_SUCCESS: {
      const { samples } = action.payload
      return {
        ...state,
        testRequest: { ...state.testRequest, samples: [...samples] },
        testRequestOldValue: { ...state.testRequest, samples: [...samples] },
        updateRequestSampleTime: new Date(),
        loadingRequestSample: false,
        requestTestSamplesCount: samples?.length || 0,
      }
    }
    case GET_TESTREQUEST_SAMPLES_DETAIL_FAIL: {
      return {
        ...state,
        error: action.payload,
      }
    }
    case GET_TESTREQUEST_TESTS_LOADING_COMPLETE:
      {
        let testRequestOldValue = state.testRequestOldValue
        testRequestOldValue.tests = action.payload || []
        return {
          ...state,
          testRequestOldValue: testRequestOldValue,
          testsLoadingComplete: true,
        }
      }
    //reload state
    case RELOAD_STATE_TESTREQUEST_DETAIL: {
      return {
        ...state,
        error: {},
      }
    }
    case RELOAD_STATE_TESTREQUEST_DETAIL_SUCCESS: {
      const { testRequest } = action.payload

      return {
        ...state,
        testRequest: testRequest,
      }
    }
    case RELOAD_STATE_TESTREQUEST_DETAIL_FAIL: {
      return {
        ...state,
        error: action.payload,
      }
    }
    case UPDATE_SAMPLE_NUMBOFLABELS: {
      return {
        ...state,
        error: {},
      }
    }
    case UPDATE_SAMPLE_NUMBOFLABELS_SUCCESS: {
      let testRequest = state.testRequest
      testRequest.samples = action.payload
      return {
        ...state,
        testRequest: JSON.parse(JSON.stringify(testRequest)),
      }
    }
    case UPDATE_SAMPLE_NUMBOFLABELS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case EDIT_TESTREQUEST_SAMPLEID:
      return {
        ...state,
      }
    case EDIT_TESTREQUEST_SAMPLEID_SUCCESS: {
      return {
        ...state,
        testRequest: action.payload,
      }
    }
    case EDIT_TESTREQUEST_SAMPLEID_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case EDIT_TESTREQUEST_NUMBER_OF_LABELS:
      return {
        ...state,
      }
    case EDIT_TESTREQUEST_NUMBER_OF_LABELS_SUCCESS: {
      return {
        ...state,
        testRequest: action.payload,
      }
    }
    case EDIT_TESTREQUEST_NUMBER_OF_LABELS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case CLEAR_DETAIL_INFO_PATIENT_VISIT:
      return {
        ...state,
        patientVisit: {},
        sampleChange: [],
        testChange: []
      }
    case COUNT_PATIENT_AND_TEST:
      return {
        ...state,
        patient: action.payload,
        error: {},
      }

    case COUNT_PATIENT_AND_TEST_SUCCESS: {
      return {
        ...state,
        patient: action.payload,
      }
    }
    case COUNT_PATIENT_AND_TEST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case CREATE_NEW_COLLECTOR_AREA_SUCCESS:
      return {
        ...state
      }

    case CREATE_NEW_COLLECTOR_AREA_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_COLLECTOR_AREA:
      return {
        ...state,
        //testRequests: [],
        error: {},
      }

    case GET_COLLECTOR_AREA_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_COLLECTOR_AREA_SUCCESS: {
      // const { data, ...rest } = action.payload
      return {
        ...state,
        collectors: action.payload,
      }
    }

    case PRINT_SAMPLE_LIST: {
      return {
        ...state,
        printSampleList: action.payload,
      }
    }

    case LOADING_PRINT_SAMPLE_LIST: {
      return {
        ...state,
        loadingPrintSampleList: action.payload,
      }
    }
    case UPDATE_REQUEST_WAITING_SUCCESS:
      return {
        ...state,
      }
    case UPDATE_REQUEST_WAITING_FAIL:
      return {
        ...state,
      }
    case CANCEL_COLLECT_SAMPLE_SUCCESS:
      return {
        ...state,
      }
    case CANCEL_COLLECT_SAMPLE_FAIL:
      return {
        ...state,
      }
    case UPDATE_REQUEST_STTGPB_SUCCESS:
      return {
        ...state,
      }
    case UPDATE_REQUEST_STTGPB_FAIL:
      return {
        ...state,
      }
    case CLEAR_DATA_REQUEST_DETAIL_IN_PATIENT_VISIT: {
      return INIT_STATE
    }
    case SAMPLE_PATIENT_VISIT_CHANGE: {
      const { isCollect, sample, samples } = action.payload
      const { sampleChange } = state

      let newSampeleChange = sampleChange

      if (isCollect && samples) {
        newSampeleChange = samples
      } else {
        if (!sample) {
          newSampeleChange = []
        } else {
          if (isCollect && sample) {
            newSampeleChange.push(sample)
          } else {
            newSampeleChange = newSampeleChange.filter(x => !(x.sid == sample.sid && x.sampleType == sample.sampleType && x.subSID == sample.subSID))
          }
        }
      }

      return {
        ...state,
        sampleChange: newSampeleChange
      }
    }
    case TEST_PATIENT_VISIT_CHANGE: {
      const { testChange } = action.payload

      return {
        ...state,
        testChange
      }
    }
    default:
      return state
  }
}

export default TestRequest;