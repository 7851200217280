import {
    GET_LIST_RESULT_QC,
    GET_LIST_RESULT_QC_FAIL,
    GET_LIST_RESULT_QC_SUCCESS,
    CLICK_ADD_NEW_RESULT_IQC,
    UPDATE_LIST_RESULT_QC,
    UPDATE_LIST_RESULT_QC_SUCCESS,
    UPDATE_LIST_RESULT_QC_FAIL,
    VALID_RESULT_QC,
    VALID_RESULT_QC_SUCCESS,
    VALID_RESULT_QC_FAIL
} from "./actionTypes"
const INIT_STATE = {
    resultQCs: [],
    paging: {},
    loading: false,
    updating: false,
    updatedTime: new Date(),
    error: {},
    isAddNew: false,
}
const ExpressionQCs = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_LIST_RESULT_QC:
            return {
                ...state,
                error: {},
                loading: true,
            }
        case GET_LIST_RESULT_QC_SUCCESS:
            return {
                ...state,
                resultQCs: action.payload,
                loading: false,
                updatedTime: new Date(),
            }
        case GET_LIST_RESULT_QC_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            }
        case CLICK_ADD_NEW_RESULT_IQC:
            return {
                ...state,
                isAddNew: action.payload,
            }
        //update results qc
        case UPDATE_LIST_RESULT_QC:
            return {
                ...state,
                error: {},
                updating: true,
            }
        case UPDATE_LIST_RESULT_QC_SUCCESS:
            {
                return {
                    ...state,
                    updating: false,
                }
            }
        case UPDATE_LIST_RESULT_QC_FAIL:
            return {
                ...state,
                error: action.payload,
                updating: false,
            }

        //valid
        case VALID_RESULT_QC:
            return {
                ...state,
                error: {},
                updating: true,
            }
        case VALID_RESULT_QC_SUCCESS:
            {
                return {
                    ...state,
                    updating: false,
                }
            }
        case VALID_RESULT_QC_FAIL:
            return {
                ...state,
                error: action.payload,
                updating: false,
            }
        default:
            return state
    }
}
export default ExpressionQCs