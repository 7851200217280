import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect, useSelector } from "react-redux"

import {
  Check,
  ConfirmModal2,
  CustomAutoComplete,
  CustomButton,
  CustomSelectAsync,
  TitleAndTable,
  WarningModal
} from "components/Common"

import {
  convertDateFormat,
  convertStringForSearch,
  selectCheckboxHandler
} from "helpers/utilities"

import { AvForm } from "availity-reactstrap-validation"
import { ModuleIds, SAMPLES_DELIVERY_MODAL_TYPE, SAMPLES_DELIVERY_TYPE, parameterCode, permissionType } from "constant"
import { getDeliveryTestsTabModal } from "helpers/app-backend"
import moment from "moment"
import {
  addDeliveryTest,
  deleteDeliveryTest,
  getDeliveryAllTests,
  updateDeliveryTests,
} from "store/actions"
import TestTabModal from "./Modal/TestTabModal"
import CustomSearchBar from "./SearchBar"
import TestTable from "./TestTable"
const RESOURCE = ModuleIds.DeliveryManagement

const TabTest = ({
  deliveryId,
  delivery,
  loadingTests,
  updatedTestTime,
  onSaveDeliveryTests,
  onGetDeliveryAllTests,
  samples,
  t,
  handleTest,
  onSubmit,
  onDelete,
  isCollapse,
  deliveryType,
  handlerUserId,
  enableBarcode,
  resource,
  onChangeDataTableTest
}) => {
  const [saveConfirmModal, setSaveConfirmModal] = useState(false)
  const [row, setRow] = useState({})
  const [rows, setRows] = useState([])
  const [sampleData, setSampleData] = useState([])
  const [testTabModal, setTestTabModal] = useState(false)
  const [warningModal, setWarningModal] = useState(false)
  const [dataTable, setDataTable] = useState([])
  const [model, setModel] = useState({
    search: ''
  })
  const [reason, setReason] = useState({})
  const updating = useSelector(state => state.common.updating)

  useEffect(() => {
    if (!updating) {
      setReason([])
    }
  }, [updating])

  useEffect(() => {
    onChangeDataTableTest && onChangeDataTableTest(dataTable)
  }, [dataTable])
  
  useEffect(() => {
    if (handlerUserId) {
      dataTable.forEach(item => {
        item.confirmedUserId = handlerUserId?.value
        item.confirmedUserName = handlerUserId?.name
      })
      setDataTable([...dataTable])
    }
  }, [handlerUserId])

  const onAddTestsClick = e => {
    setTestTabModal(true)
  }

  const resetSelectedRows = () => {
    setRows([])
    setRow({})
  }

  const onSelectCheckbox = (row, isSelected) => {
    const { rowsState, currentRow } = selectCheckboxHandler(
      rows,
      row,
      isSelected
    )
    setRows(rowsState)
    setRow(currentRow)
  }

  const onSelectAllCheckbox = rows => {
    setRows(rows)
    if (rows.length < 1) setRow({})
    else setRow(rows[rows.length - 1])
  }

  const onRefreshHandler = () => {
    onGetDeliveryAllTests(deliveryId)
  }

  const onUpdateTestsSuccess = () => {
    resetSelectedRows()
    onRefreshHandler()
  }

  const toggleConfirmSaveTest = () => {
    setSaveConfirmModal(!saveConfirmModal)
  }

  const saveTestHandlder = () => {
    toggleConfirmSaveTest()
    onSaveDeliveryTests(deliveryId, model.remark, rows, onUpdateTestsSuccess)
  }

  const loadData = async () => {
    const sampleNotExsist = samples.filter(item => !sampleData.find(x => x.sid === item.sid
      && x.sampleType === item.sampleType
      && x.requestId === item.requestId
      && x.subSID === item.subSID))
    const search = sampleNotExsist.map(item => `${item.requestId}${item.sid}${item.sampleType}${item.subSID || ''}`)

    setSampleData([...samples])
    if (samples?.length === 0) {
      setDataTable([])
      handleTest({ items: [] })
    } else {
      if (sampleNotExsist.length > 0) {
        const res = await getDeliveryTestsTabModal({
          search: search,
          IsFilterTest: true,
          type: deliveryType || ''
        })
        res.forEach(item => {
          item.confirmedUserId = samples[0].confirmedUserId
          item.confirmedUserName = samples[0].confirmedUserName
          item.confirmedTime = convertDateFormat(new Date())
          item.isGPB = (item.sttgpb !== undefined && item.sttgpb !== null && item.sttgpb !== '')
        })
        handleTest({ items: [...dataTable, ...res] })
        setDataTable((oldData) => [...oldData, ...res])

      } else {
        const sample = sampleData.filter(item => !samples.find(x => x.sid === item.sid && x.sampleType === item.sampleType && x.requestId === item.requestId && x.subSID === item.subSID))
        if (sample.length > 0) {
          const data = dataTable.filter(item => !sample.find(x => x.sid === item.sid && x.sampleType === item.sampleType && x.requestId === item.requestId && x.subSID === item.subSID))
          handleTest({ items: [...data] })
          setDataTable([...data])
        }
      }
    }
  }

  useEffect(() => {
    loadData()
  }, [samples])

  const SubmitHandler = values => {
    let data = dataTable

    values.forEach((item) => {
      if (item?.children && item.children.length > 0) {
        data = [...data, ...item.children]
      } else {
        data = [item, ...data]
      }
    })

    data.forEach(item => {
      item.reason = reason.code
      item.reasonName = reason.label
      item.confirmedUserName = samples[0].confirmedUserName
      // item.confirmedTime = samples[0].confirmedTime
    })
    values.items = data
    setDataTable([...data])
    handleTest(values)
    setTestTabModal(prev => !prev)
  }
  const onSearchChange = value => {
    model.search = value
  }

  const onSearchClear = () => {
    model.search = ""
    setModel({ ...model })
  }

  const onSearchSubmit = e => {
    e.preventDefault()
    setModel({ ...model })
  }

  const onReasonChange = (value) => {
    dataTable.forEach(item => {
      item.reason = value.code
      item.reasonName = value.label
    })
    setDataTable([...dataTable])
    setReason(value)
  }

  const onChangeReason = (value, sample) => {
    if (sample.children && sample.children.length > 0) {
      dataTable.forEach(item => {
        if (
          item.sid === sample.sid &&
          item.patientId === sample.patientId &&
          item.sampleType === sample.sampleType &&
          item.subSID === sample.subSID
        ) {
          item.reason = value.value
          item.reasonName = value.label
        }
      })
    } else {
      dataTable.forEach(item => {
        if (
          item.id === sample.id &&
          item.testCode === sample.testCode &&
          item.requestId === sample.requestId
        ) {
          item.reason = value.value
          item.reasonName = value.label
        }
      })
    }

  }

  const onChangeSTTGPB = (value, sample) => {
    if (sample.children && sample.children.length > 0) {
      dataTable.forEach(item => {
        if (
          item.sid === sample.sid &&
          item.patientId === sample.patientId &&
          item.sampleType === sample.sampleType &&
          item.subSID === sample.subSID
        ) {
          item.sttgpb = value
        }
      })
    } else {
      dataTable.forEach(item => {
        if (
          item.id === sample.id &&
          item.testCode === sample.testCode &&
          item.requestId === sample.requestId
        ) {
          item.sttgpb = value
        }
      })
    }

  }

  const onDeleteTest = (data) => {
    onDelete(data)
    setDataTable(data.items)
  }

  const onMultiDeleteHandler = () => {
    if (rows.length === 0) {
      setWarningModal(true)
    } else {
      const data = dataTable.filter(item => !rows.find(x => (x.testCode === item.testCode
        && x.sid === item.sid
        && x.sampleType === item.sampleType
      )
        || (!!x.profileCode && (x.profileCode === item.profileCode && x.sid === item.sid))
      ))
      setDataTable([...data])
      let result = []
      data.forEach(item => {
        if (item.children) {
          result = [...result, ...item.children]
        } else {
          result.push(item)
        }
      })
      onDelete({ items: result })
      handleTest({ items: result })
      setRows([])
    }
  }

  let dataFilter = dataTable
  if (model.search) {
    const searchConvert = convertStringForSearch(model.search)
    dataFilter = dataFilter.filter(item => convertStringForSearch(item?.sid.toString())?.includes(searchConvert)
      || convertStringForSearch(item?.testName).includes(searchConvert)
      || convertStringForSearch(item?.patientName).includes(searchConvert)
      || convertStringForSearch(item?.testCode).includes(searchConvert))
  }

  return (
    <React.Fragment>
      <TitleAndTable
        resource={RESOURCE}
        table={() => (
          <TestTable
            data={dataFilter}
            onSelect={onSelectCheckbox}
            onSelectAll={onSelectAllCheckbox}
            onDelete={onDeleteTest}
            loading={loadingTests}
            updatedTime={updatedTestTime}
            delivery={delivery}
            onChangeReason={onChangeReason}
            onChangeSTTGPB={onChangeSTTGPB}
            handleTest={handleTest}
            isCollapse={isCollapse}
            deliveryType={deliveryType}
            customButtonTable={() => (
              deliveryType === SAMPLES_DELIVERY_TYPE.Cancelation && <AvForm onValidSubmit={onSubmit}>
                <div className="d-flex" style={{ width: '800px', justifyContent: 'right' }}>
                  <CustomSearchBar
                    onSearch={onSearchChange}
                    searchText={model.search}
                    placeholder={t("common:Search")}
                    onClear={onSearchClear}
                    onSubmit={onSearchSubmit}
                    disabled={enableBarcode}
                  />
                  <div style={{ width: "27%" }} className="mx-1">
                    <CustomAutoComplete
                      name="reasonCorrection"
                      emptySelect
                      portal
                      value={reason.code}
                      errorMessage={""}
                      isClearable={false}
                      code={parameterCode.REASON_CANCEL}
                      label={t("")}
                      placeholder={t("Reason")}
                      onChange={(e, value, item) => {
                        onReasonChange({ code: item[0]?.value, label: item[0]?.label })
                      }}
                    />
                  </div>
                  <CustomButton
                    color="primary"
                    outline
                    onClick={onMultiDeleteHandler}
                    style={{ height: "38px" }}
                  >
                    {t("common:Delete")}
                  </CustomButton>
                  <CustomButton
                    color="primary"
                    outline
                    onClick={onAddTestsClick}
                    style={{ height: "38px" }}
                  >
                    {t("deliveryPage:Add test")}
                  </CustomButton>
                  <Check permission={permissionType.C} resource={resource}>
                    <CustomButton
                      style={{ height: "38px" }}
                      color="success"
                      type="submit"
                      isEdit
                      disabled={dataTable?.length === 0}
                    >
                      {t("common:Save")}
                    </CustomButton>
                  </Check>
                </div>
              </AvForm>
            )}
          />
        )}
        icon={false}
        external
      />
      {testTabModal && (
        <TestTabModal
          tests={dataTable}
          modal={testTabModal}
          countSelected={rows.length}
          toggle={() => setTestTabModal(prev => !prev)}
          onValidSubmit={values => {
            SubmitHandler(values)
          }}
          deliveryId={deliveryId}
          samples={samples}
          sampleData={sampleData}
        />
      )}
      <WarningModal
        modal={warningModal}
        onToggle={() => setWarningModal(prev => !prev)}
        message={t("message:SelectRowWarning")}
      />

      <ConfirmModal2
        modal={saveConfirmModal}
        title={`${t("common:Save")} ${t("common:Test")}`}
        message={t("SaveTestConfirm")}
        onToggle={toggleConfirmSaveTest}
        onConfirm={saveTestHandlder}
        btnConfirmText={t("common:Save")}
      />
    </React.Fragment>
  )
}

TabTest.propTypes = {
  deliveryId: PropTypes.string.isRequired,
  tests: PropTypes.array,
  loadingTests: PropTypes.bool,
  updatedTestTime: PropTypes.any,
  onSaveDeliveryTests: PropTypes.func,
  t: PropTypes.any,
}

TabTest.defaultProps = {}

const mapStateToProps = ({ sampleDelivery }) => ({
  tests: sampleDelivery.deliveryTests,
  requestTests: sampleDelivery.requestTests,
  loadingTests: sampleDelivery.loadingTests,
  updatedTestTime: sampleDelivery.updatedTestTime,
  delivery: sampleDelivery.delivery,
})

const mapDispatchToProps = dispatch => ({
  onDeleteDeliveryTests: (tests, callback) =>
    dispatch(deleteDeliveryTest({ tests, callback })),
  onGetDeliveryAllTests: payload => dispatch(getDeliveryAllTests(payload)), //
  onSaveDeliveryTests: (deliveryId, remark, tests, callback) =>
    dispatch(updateDeliveryTests({ deliveryId, remark, tests, callback })),
  onCreateDeliveryTest: (test, callback) =>
    dispatch(addDeliveryTest({ test, callback })),
  onUpdateDeliveryTests: (test, callback) =>
    dispatch(updateDeliveryTests({ test, callback })),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["deliveryPage", "common", "message"])(TabTest))
