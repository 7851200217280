
import { ModalBody, ModalFooter } from "reactstrap"

import {
    CustomBootstrapInlineTable,
    CustomButton,
    CustomModal,
    LinkCustom
} from "components/Common"

//i18n
import { ModuleIds, QCLotTestLogAction } from "constant"
import { getInstrumentLogByIdentifier } from "helpers/app-backend/IQC/QCLotTest_backend_helper"
import { convertDateFormat } from "helpers/utilities"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import HistoryPayloadModal from "./HistoryPayloadModal"

const FieldNotCheckResult = ['CreatedBy', 'CreatedDate', 'UpdatedBy', 'UpdatedDate', 'ResultTestId', 'Id',
    'ResultTestId', 'State', 'RequestId', 'LotId', 'InsId', 'TestCode', 'Levels', 'TestName', "Unit"]
const HistoryLotTestModal = ({
    modal,
    toggle,
    t,
    data,
    config,
}) => {

    const [dataSource, setDataSource] = useState([])
    const [modalPayload, setModalPayload] = useState(false)
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (modal == true && data.id) {
            getData()
        }
        else if (modal == false) {
            setDataSource([])
        }
    }, [modal])

    const getData = async () => {
        try {
            setLoading(true)
            let res = await getInstrumentLogByIdentifier(`LA:QCLotTest:${data.id || ''}`)
            mappingHistory(res)
        } catch (error) {
            console.log(error);
            setLoading(false)
        }
    }
    const checkFieldChange = (oldData, newData) => {
        let res = []
        for (const [key, value] of Object.entries(oldData)) {
            if (!FieldNotCheckResult.includes(key))
                if (newData[key] != value) {
                    res.push(key)
                }
        }
        return res
    }

    const firstKeyChange = (val) => {
        for (const [key, value] of Object.entries(val)) {
            if (!([...FieldNotCheckResult, 'InstanceId']).includes(key))
                if (value != "")
                    return key
        }
        return ""
    }

    const arraySigmaToObject = (payload) => {
        let res = payload.reduce((obj, item) => {
            obj[item.Code] = item.Value;
            return obj;
        }, {});
        return res;
    }

    const mappingHistory = (vals) => {

        let res = [...vals];
        //cho payload thành object
        res = res.map(x => {
            let tmp = x
            try {
                const pl = x.payload.replaceAll("ISODate(", "").replaceAll('Z")', '"').replaceAll("NumberLong(", "").replaceAll(")", "")
                tmp.payload = JSON.parse(pl)
            } catch (error) {
                tmp.payload = {}
            }
            return x
        })

        for (let index = 0; index < res.length; index++) {
            let element = res[index];
            if (QCLotTestLogAction.Create == element.action || QCLotTestLogAction.Created == element.action) {
                element.displayContent = `
                <span class="font-weight-semibold">${t(element.action)} </span>`
            }
            else if (QCLotTestLogAction.Update == element.action || QCLotTestLogAction.Updated == element.action || QCLotTestLogAction.UpdateSigma == element.action) {
                if (QCLotTestLogAction.UpdateSigma == element.action) {
                    element.payload = arraySigmaToObject(element.payload)
                }
                let tmp = element.payload
                element.displayContent = `
                <span class="font-weight-semibold">${t(element.action)}</span>:<br/>`
                try {
                    let fin = res.filter(x => moment(x.timeStamp) < moment(element.timeStamp) && x.action == element.action && x.payload.id === tmp.id)
                    let oldData = {}
                    if (fin && fin.length > 0) {
                        oldData = fin[0].payload
                    }
                    else {
                        oldData = res[res.length - 1].payload
                    }
                    if (QCLotTestLogAction.UpdateSigma == element.action) {
                        oldData = arraySigmaToObject(oldData)
                    }
                    const keyChanges = checkFieldChange(oldData, element.payload)
                    keyChanges.forEach(keyChange => {
                        let oldVal = oldData[keyChange]
                        element.displayContent += `<span class="font-weight-semibold">${t(keyChange)}</span> <span class="history-text-before">  ${(oldVal || "") != "" ? oldVal : '" "'}</span><span class="history-text-arrow"></span>`;
                        element.displayContent += ` ${tmp[keyChange] != "" ? tmp[keyChange] : '" "'}<br/>`;
                    });
                } catch (error) {
                    console.log(error);
                }
            }
            else if (QCLotTestLogAction.Delete == element.action || QCLotTestLogAction.Deleted == element.action) {
                if (element.payload?.TestCode) {
                    element.displayContent = `
                    <span class="font-weight-semibold">${t(element.action)}</span>
                    <span class="font-weight-semibold"></span>: ${element.payload?.TestCode}<br/>`
                }
            }
            else {
            }
        }
        setDataSource(res)
        setLoading(false)
    }
    const columns = [
        {
            dataField: "id",
            text: "id",
            hidden: true,
            searchable: false,
        },
        {
            dataField: "idx",
            text: "#",
            searchable: false,
            headerStyle: { width: "50px" },
            formatter: (cellContent, user, index) => {
                return index + 1
            },
        },
        {
            dataField: "identifier",
            text: t("Id"),
            style: { width: "100px" },
            formatter: (cellContent) => {
                return <span>{cellContent.split(':')[2]}</span>
            },
        },
        {
            dataField: "timeStamp",
            text: t("testRequestPage:Timestamp"),
            formatter: (cellContent) => (
                <span>{convertDateFormat(cellContent, "HH:mm:ss DD-MM-YYYY")}</span>
            ),
        },
        {
            dataField: "userName",
            text: t("common:User"),
            formatter: (cellContent, user) => {
                // return <Link to={`/User/${user.userId}/view?tab=1`} target="_blank">{cellContent ?? "System User"}</Link>
                return <LinkCustom resource={ModuleIds.User} link={`/User/${user.userId}/view?tab=1`} content={cellContent ?? "System User"} />
            },
        },
        // {
        //     dataField: "module",
        //     text: t("common:Module"),
        //     style: { width: "150px" },
        //     formatter: (cellContent, user) => {
        //         return <span className="d-flex badge-profile">{cellContent}</span>
        //     },
        // },
        {
            dataField: "message",
            text: t("Content"),
            searchable: false,
            sort: false,
            formatter: (cellContent, item, index) => (
                <div dangerouslySetInnerHTML={{ __html: item.displayContent }} />
            ),
        },
        {
            dataField: "payload",
            text: t("Payload"),
            style: { textAlign: "center" },
            headerAlign: "center",
            formatter: (cellContent, item) => (
                <div onClick={e => {
                    setMessage(item.message)
                    setModalPayload(true)
                }}>
                    <i className="mdi mdi-eye history-lot-test-eye-payload" >
                    </i>
                </div>
            ),
        },
        {
            dataField: "action",
            text: t("common:Action"),
            // headerStyle: { paddingLeft: "25px", width:'100px', minWidth:'100px', maxWidth:'100px', },
            // style: { paddingLeft: "25px", width:'100px', minWidth:'100px', maxWidth:'100px', },
            formatter: (cellContent) => {
                return <span className="d-flex badge-test">{t(`${cellContent}`)}</span>
            },
        },
    ];

    return (
        <React.Fragment>
            <CustomModal modal={modal} title={t("History Lot Test")} onToggle={toggle} size="xl" customClass={""} centered={false}>
                <ModalBody>
                    <div style={{ fontSize: "1rem" }}>
                        {data.testCode} - {data.testName}
                    </div>
                    <div style={{ fontSize: "1rem" }}>
                        {t("Lot")}:{config.lotId} - {config.lotName} - {t("Level")}: {config.levels}
                        <br />
                        {t("Machine")}: {config.machineName}
                    </div>
                    <div className="position-relative">
                        {loading && (
                            <div
                                className="spinner-border text-primary m-1 history-lot-test-spinner"
                                role="status"
                            >
                                <span className="sr-only">{t("Loading...")}</span>
                            </div>
                        )}
                        <CustomBootstrapInlineTable
                            columns={columns}
                            data={dataSource || []}
                            isScrollable
                            // updatedTime={updatedTime}
                            isEnableLoadInfo={false}
                            searchText={""}
                            loading={loading}
                            keyField={"id"}
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <CustomButton
                        text={t("common:Cancel")}
                        type="button"
                        onClick={toggle}
                        data-dismiss="modal"
                        className="button-width"
                    />
                </ModalFooter>
            </CustomModal>
            <HistoryPayloadModal
                modal={modalPayload}
                toggle={() => { setModalPayload(x => !x) }}
                message={message}
            />
        </React.Fragment >
    )
}

HistoryLotTestModal.defaultProps = {}

const mapStateToProps = ({ testResult }) => ({
})

const mapDispatchToProps = dispatch => ({
    // onGetNotifications: payload => dispatch(getNotifications(payload)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation(["managementQCPage", "common"])(HistoryLotTestModal))
