
//i18n
import { ReactTootopRatio125 } from "helpers/utilities"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import ReactTooltip from "react-tooltip"

let sidLength = localStorage.getItem("SidLength") || 10
const CustomSID = ({
  keyToolTip,
  style = {},
  isToolTip = false,
  toolTip,
  placeToolTip = "top",
  sid = "",
  emergency = false,
  textOnly = false,
}) => {
  let sidDisplay = sid;
  let subsid = "";
  if (`${sid}`.includes('-')) {
    sidDisplay = (`${sid}`.split('-')[0]).trim()
    subsid = `-` + (`${sid}`.split('-')[1]).trim()
  }
  return (
    <>
      {textOnly == true ?
        <span>{`${sidDisplay}`.padStart(sidLength, "0")}{subsid !== '-null' ? subsid : ''}</span>
        :
        <label data-tip data-for={`sidlength-${keyToolTip}`}
          onMouseEnter={e => {
            ReactTootopRatio125('.__react_component_tooltip ')
          }}
          style={{
            color: '#5F5F5F',
            backgroundColor: '#fff',
            padding: '1px 5px',
            marginRight: '5px',
            borderRadius: '5px',
            border: emergency ? `2px solid #dc3545` : `1px solid #808080`,
            minWidth: 40,
            textAlign: "center",
            ...style
          }}>
          {`${sidDisplay}`.padStart(sidLength, "0")}{subsid !== '-null' ? subsid : ''}
          {isToolTip &&
            <ReactTooltip id={`sidlength-${keyToolTip}`} place={placeToolTip} effect="solid">
              {toolTip}
            </ReactTooltip>
          }
        </label>
      }
    </>
  )
}

CustomSID.propTypes = {
  keyToolTip: PropTypes.string,
  style: PropTypes.object,
  isToolTip: PropTypes.bool,
  emergency: PropTypes.bool,
  textOnly: PropTypes.bool,
  // toolTip: PropTypes.string,
  sid: PropTypes.string,
  placeToolTip: PropTypes.string,
}
CustomSID.defaultProps = {
  style: {},
  isToolTip: false,
  textOnly: false,
  emergency: false,
  placeToolTip: "top",
  sid: "",
}

export default withTranslation(["button"])(CustomSID)