import { CustomSelect, CustomSelectAsync } from "components/Common"
import { parameterCode } from "constant/utility"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { Row } from "reactstrap"
const FilterForm = ({ model, t }) => {
    return (
        <>
            <Row>
                <div className="mb-3">
                    <CustomSelect
                        name="instrumentId"
                        portal
                        value={model.instrumentId || ""}
                        code={parameterCode.INSTRUMENT}
                        showID
                        label={t("Instrument")}
                        onChange={(name, value, label, item) => {
                            model.instrumentId = value[0];
                        }}
                    />
                </div>
            </Row>
        </>
    )
}

FilterForm.propTypes = {
    model: PropTypes.object,
    t: PropTypes.any,
}
export default withTranslation(["managementQCPage", "common"])(FilterForm)