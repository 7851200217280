import images from 'assets/images';
import { CustomButton, WarningModal } from 'components/Common';
import { showWanringToast } from 'components/Common/ShowWarningToastr';
import { RESULT_STATE_Text } from 'constant';
import { getWithImage } from 'helpers/api_helper2';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { withTranslation } from 'react-i18next';
import { Col, Label, Row } from 'reactstrap';


const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
};

const thumb = {
    display: 'inline-flex',
    position: 'relative',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
};

const thumbInner = {
    display: 'flex',
    position: 'relative',
    width: '100%',
    minWidth: 0,
    overflow: 'hidden'
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
}

const overlay = {
    position: 'absolute',
    textAlign: 'right',
    width: '100%'
}

const UploadImage8GPB = ({ t, handleFileChange, resultTestId, state, bool_display }) => {
    const [files, setFiles] = useState([])    
    const { getRootProps, getInputProps } = useDropzone({
        acceptedFiles: {
            'image/*': []
        },
        onDrop: acceptedFiles => {
            const data = acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })).filter(item => {
                const type = item?.path?.split('.')
                return ['png', 'jpg', 'jpeg', 'bmp'].includes((type[type.length - 1])?.toLocaleLowerCase())
            })
            if(files.length >= 2){
                if(data.length <= 1){
                    removeFile(0)
                    setFiles([...files, ...data]);
                    showWanringToast("Chỉ cho phép chọn 02 ảnh, muốn thay đổi vui lòng xóa bớt ảnh đã chọn")
                }
                else{
                    removeFile(0)
                    removeFile(0)
                    const result = data.length >= 2 ? data.slice(-2) : data;
                    setFiles([...files, ...result]);
                    showWanringToast("Chỉ cho phép chọn 02 ảnh, muốn thay đổi vui lòng xóa bớt ảnh đã chọn")
                }
            }
            else{
                if(files.length == 1){
                    if(data.length <= 1){
                        setFiles([...files, ...data]);
                    }
                    else{
                        removeFile(0)
                        const result = data.length >= 2 ? data.slice(-2) : data;
                    setFiles([...files, ...result]);
                    showWanringToast("Chỉ cho phép chọn 02 ảnh, muốn thay đổi vui lòng xóa bớt ảnh đã chọn")
                    }
                } else if (data.length > 2){
                    const result = data.length >= 2 ? data.slice(-2) : data;
                    setFiles([...files, ...result]);
                    showWanringToast("Chỉ cho phép chọn 02 ảnh, muốn thay đổi vui lòng xóa bớt ảnh đã chọn")
                }
                else{
                    setFiles([...files, ...data])
                }
                
            }
            // if(files.length >= 2){
            //     if(data.length >= 2){
            //         const result = data.length >= 2 ? data.slice(-2) : data;
            //         setFiles(...files, []);
            //         setFiles(...files, ...result);
            //         showWanringToast("Chỉ cho phép chọn 02 ảnh, muốn thay đổi vui lòng xóa bớt ảnh đã chọn")
            //     }
            //     else if(data.length == 1){
            //         const newfile = files.length >= 1 ? files.slice(-1) : files;
            //         setFiles(...files, ...newfile)
            //         setFiles(...files, ...data)
            //         showWanringToast("Chỉ cho phép chọn 02 ảnh, muốn thay đổi vui lòng xóa bớt ảnh đã chọn")
            //     }   
            // } else{
            //     if(data.length >= 2){
            //         const result = data.length >= 2 ? data.slice(-2) : data;
            //         // setFiles(...files, [])
            //         setFiles(...files, ...result);
            //         showWanringToast("Chỉ cho phép chọn 02 ảnh, muốn thay đổi vui lòng xóa bớt ảnh đã chọn")
            //     }
            //     else{
            //         setFiles(...files, ...data);
            //     }
            // }
        },
    })
    useEffect(() => {
        handleFileChange(files)
    }, [files])

    useEffect(() => {
        getFile()
    }, [resultTestId])

    const removeFile = (index) => {
        files.splice(index, 1)
        const data = files.map(file => {
            return Object.assign(file, {
                preview: URL.createObjectURL(file)
            })
        })
        setFiles([...data])
    }

    const downloadFromUrl = async (url, name) => {
        try {
            const response = await fetch(url)
            const blob = await response.blob()
            files.push(new File([blob], name))

            const data = files.map(file => {
                return Object.assign(file, {
                    preview: URL.createObjectURL(file),
                    path: file.name
                })
            })

            setFiles([...data])
        } catch (error) {
            throw error
        }
    }

    const getFile = async () => {
        try {
            const response = await getWithImage(
                `${process.env.REACT_APP_LABORATORY_ENDPOINT}/global/files/file-pathology/${resultTestId}`
            )

            if (response.filePath) {
                response.filePath.forEach((item) => {
                    const url = `${process.env.REACT_APP_LABORATORY_ENDPOINT}/FilePathology/${item}`
                    let name = ''
                    if (item.includes('/')) {
                        name = item.split('/')
                    } else {
                        name = item.split('\\')
                    }
                    downloadFromUrl(url, name[name.length - 1])
                })
            }
        } catch (error) {
        }
    }

    const thumbs = files.map((file, index) => {
        return (
            <div style={thumb} key={index}>
                <div style={thumbInner}>
                    <img
                        src={file.preview}
                        style={img}
                        onLoad={() => { URL.revokeObjectURL(file.preview) }}
                    />
                    {state < RESULT_STATE_Text.Confirmed && <div style={overlay} onClick={() => { removeFile(index) }}>
                        <i className='mdi mdi-delete font-size-16 text-danger' />
                    </div>}

                </div>
            </div>
        )
    })

    useEffect(() => {
        return () => files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [])

    return (
        <Row >
            <Col sm={3} style={{ display: bool_display ? 'block' : 'none',alignSelf: 'center' }}>
                <Label className="form-check-label">{t('File')}</Label>
            </Col>
            <Col sm={9} style={{ display: bool_display ? 'block' : 'none',alignSelf: 'center', paddingRight: '6px', paddingLeft: '6px' }} >
                <section className="container">
                    {state < RESULT_STATE_Text.Confirmed && <div {...getRootProps({ className: 'dropzone' })} style={{ alignContent: 'center', textAlign: '-webkit-center' }}>
                        <input {...getInputProps()} />
                        <div className='d-flex justify-content-center align-items-center'>
                            <p className='m-1'>{t('Click, drag or drop file')}</p>
                            {/* <CustomButton color="success">{t('File')}</CustomButton> */}
                            <i className='mdi mdi-upload' style={{ fontSize: 'xx-large', color: '#7474d6' }} />
                        </div>
                    </div>}
                    <aside style={thumbsContainer}>
                        {thumbs}
                    </aside>
                </section>
            </Col>
              
        </Row>

    )
}

export default withTranslation(["testResultPage", "common"])(UploadImage8GPB)