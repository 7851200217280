import { spreadSearchQuery } from "helpers/utilities"
import { get, put, post, del } from "../../api_helper2"

const BASE_API_URL = `${process.env.REACT_APP_LABORATORY_ENDPOINT}/qc/causal-action`

const getCausalActionQCList = payload => {
    let q = spreadSearchQuery(payload)
    return get(`${BASE_API_URL}?${q}`)
}

const getCausalActionQCById = (id) => {
    return get(`${BASE_API_URL}/${id}`)
}

const deleteCausalActionQCByIds = causalActionQCs => {
    let ids = ""
    causalActionQCs.forEach(_causalActionQC => (ids += `id=${_causalActionQC.id}&`))

    return del(`${BASE_API_URL}?${ids}`)
}

const updateCausalActionQCById = req => {
    const { id } = req
    return put(`${BASE_API_URL}/${id}`, req)
}

const createCausalActionQC = req => {
    return post(`${BASE_API_URL}`, req)
}

const exportCausalActionQCs = (payload) => {
    let q = spreadSearchQuery(payload)
    return post(`${BASE_API_URL}/ExportQCCausalAction?${q}`, null, {
        responseType: 'arraybuffer',
        headers:
        {
            'Content-Disposition': "attachment; filename=template.xlsx",
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        },
    })
}

const getCausalActionQCByListCode = (codes) => {
    return post(`${BASE_API_URL}/GetcausalActionQCByListCode`, { codes: codes })
}

export {
    getCausalActionQCById, updateCausalActionQCById, deleteCausalActionQCByIds, createCausalActionQC,
    getCausalActionQCList, getCausalActionQCByListCode, exportCausalActionQCs
}
