import { useEffect, useRef, useState } from "react";
import { AvForm } from "availity-reactstrap-validation";
import { ModalBody, ModalFooter } from "reactstrap";
import {
  CustomAvField,
  CustomButton,
  CustomModal,
  CustomSelect,
} from "components/Common";
import { parameterCode } from "constant/utility";
import { getInvalidMessageI18n } from "helpers/utilities";
import { isEmpty } from "lodash";
import { withTranslation } from "react-i18next";
import { getCodesByParameterId } from "helpers/app-backend";

const ChangeSIDQCModal = ({
  modal,
  toggle,
  isEdit,
  onValidSubmit,
  data,
  t,
}) => {
  const formEl = useRef();
  const [maxLevels, setMaxLevels] = useState(0);
  const [allLevels, setAllLevels] = useState([]);
  const [filteredLevels, setFilteredLevels] = useState([]);
  const [lotName, setLotName] = useState("");
  const [levels, setLevels] = useState(null);
  const [sIDQCChg, setSIDQCChg] = useState("");

  const fetchLevels = async () => {
    const query = { size: 0 };
    let res = await getCodesByParameterId(parameterCode.LEVEL_QC, query);
    res = res.filter(r => r.inUse);
    res = res?.map(_item => {
      _item.value = _item.code;
      _item.label = _item.message;
      return _item;
    });
    setAllLevels(res);
  };

  useEffect(() => {
    const filtered = allLevels.filter((level) => level.value <= maxLevels);
    setFilteredLevels(filtered);
  }, [maxLevels, allLevels]);

  useEffect(() => {
    fetchLevels();
  }, []);

  useEffect(() => {
    if (lotName && levels) {
      setSIDQCChg(`${lotName}${levels}`);
    }
  }, [lotName, levels]);
  const title =
    (!!isEdit ? t("common:Edit") : t("common:Add")) +
    " " +
    t("Change SID QC Title");

  if (isEdit && isEmpty(data)) return null;
  return (
    <CustomModal modal={modal} title={title} onToggle={toggle}>
      <ModalBody>
        <AvForm
          ref={formEl}
          id="changeSIDForm"
          onValidSubmit={(e, values) => {
            let dataSubmit = {
              id: values.id || "",
              insId: values.insId || null,
              lotId: values.lotId || "",
              sIDQCIn: values.sIDQCIn || "",
              levels: values.levels || "",
              sIDQCChg: sIDQCChg || values.sIDQCChg,
            };
            onValidSubmit(e, dataSubmit);
          }}
          model={data}
        >
          <CustomAvField name="id" type="hidden" value={data.id || ""} />
          <div className="row px-3">
            <div className="col-md-6">
              <div className="mb-3">
                <CustomSelect
                  required
                  name="insId"
                  value={data.insId || null}
                  errorMessage={getInvalidMessageI18n(t, "Instrument")}
                  code={parameterCode.INSTRUMENT}
                  label={t("Instrument Name")}
                  detected={isEdit}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <CustomSelect
                  required
                  name="lotId"
                  value={`${data?.lotId || ""}`}
                  errorMessage={getInvalidMessageI18n(t, "Lot Name")}
                  code={parameterCode.LOT_QC}
                  label={t("Lot Name")}
                  detected={isEdit}
                  onChange={(name, value, label, item) => {
                    setLotName(item[0]?.lotName || '');
                    if (item[0]?.levels) {
                      setMaxLevels(item[0]?.levels);
                    }
                  }}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <CustomAvField
                  required
                  name="sIDQCIn"
                  type="text"
                  value={`${data.sidqcIn || ""}`}
                  label={t("SID QC")}
                  detected={isEdit}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <CustomSelect
                  label={t("Levels")}
                  name="levels"
                  portal
                  required
                  errorMessage={getInvalidMessageI18n(t, "Levels")}
                  value={data?.levels || null}
                  code={parameterCode.LEVEL_QC}
                  options={filteredLevels}
                  onChange={(name, value, label) => {
                      setLevels(value);
                  }}
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="mb-3">
                <CustomAvField
                  name="sIDQCChg"
                  type="text"
                  value={sIDQCChg ||data?.sidqcChg} 
                  label={t("SID QC Change")}
                  detected={isEdit}
                  disabled
                />
              </div>
            </div>
          </div>
          <button type="submit" className="d-none" />
        </AvForm>
      </ModalBody>
      <ModalFooter>
        <CustomButton
          text={t("common:Cancel")}
          type="button"
          onClick={toggle}
          data-dismiss="modal"
          className="button-width"
        />
        <CustomButton
          text={t("common:Save")}
          type="submit"
          color="primary"
          onClick={() => {
            formEl?.current?.submit();
          }}
          className="save-user button-width"
          isEdit
        />
      </ModalFooter>
    </CustomModal>
  );
};

export default withTranslation(["changeSIDQCPage", "common"])(ChangeSIDQCModal);
