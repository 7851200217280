//set search query
export const SET_VALIDRESULT_SEARCH_QUERY = "SET_VALIDRESULT_SEARCH_QUERY";

//reset search query
export const RESET_VALIDRESULT_SEARCH_QUERY = "RESET_VALIDRESULT_SEARCH_QUERY";

//get list result
export const GET_VALIDRESULTS_LIST = "GET_VALIDRESULTS_LIST";
export const GET_VALIDRESULTS_LIST_SUCCESS = "GET_VALIDRESULTS_LIST_SUCCESS";
export const GET_VALIDRESULTS_LIST_FAIL = "GET_VALIDRESULTS_LIST_FAIL";

//update valid result
export const UPDATE_VALID_VAL_RESULTS = "UPDATE_VALID_VAL_RESULTS";
export const UPDATE_VALID_VAL_RESULTS_SUCCESS = "UPDATE_VALID_VAL_RESULTS_SUCCESS";
export const UPDATE_VALID_VAL_RESULTS_FAIL = "UPDATE_VALID_VAL_RESULTS_FAIL";

//update invalid result
export const UPDATE_INVALID_REV_RESULTS = "UPDATE_INVALID_REV_RESULTS";
export const UPDATE_INVALID_REV_RESULTS_SUCCESS = "UPDATE_INVALID_REV_RESULTS_SUCCESS";
export const UPDATE_INVALID_REV_RESULTS_FAIL = "UPDATE_INVALID_REV_RESULTS_FAIL";

//update UPL result
export const UPDATE_UPL_RESULTS = "UPDATE_UPL_RESULTS";
export const UPDATE_UPL_RESULTS_SUCCESS = "UPDATE_UPL_RESULTS_SUCCESS";
export const UPDATE_UPL_RESULTS_FAIL = "UPDATE_UPL_RESULTS_FAIL";

//update VAL result
export const UPDATE_VAL_RESULTS = "UPDATE_VAL_RESULTS";
export const UPDATE_VAL_RESULTS_SUCCESS = "UPDATE_VAL_RESULTS_SUCCESS";
export const UPDATE_VAL_RESULTS_FAIL = "UPDATE_VAL_RESULTS_FAIL";

//update E* result
export const UPDATE_E_RESULTS = "UPDATE_E_RESULTS";
export const UPDATE_E_RESULTS_SUCCESS = "UPDATE_E_RESULTS_SUCCESS";
export const UPDATE_E_RESULTS_FAIL = "UPDATE_E_RESULTS_FAIL";

//delete result
export const DELETE_VALIDRESULTS = "DELETE_VALIDRESULTS";
export const DELETE_VALIDRESULTS_SUCCESS = "DELETE_VALIDRESULTS_SUCCESS";
export const DELETE_VALIDRESULTS_FAIL = "DELETE_VALIDRESULTS_FAIL";

//get list result samples
export const GET_RESULT_SAMPLES_LIST = "GET_RESULT_SAMPLES_LIST";
export const GET_RESULT_SAMPLES_LIST_SUCCESS = "GET_RESULT_SAMPLES_LIST_SUCCESS";
export const GET_RESULT_SAMPLES_LIST_FAIL = "GET_RESULT_SAMPLES_LIST_FAIL";
export const CLEAN_RESULT_SAMPLES_LIST = "CLEAN_RESULT_SAMPLES_LIST";


//update result
export const UPDATE_VALID_RESULTS_SAMPLEID = "UPDATE_VALID_RESULTS_SAMPLEID";
export const UPDATE_VALID_RESULTS_SAMPLEID_SUCCESS = "UPDATE_VALID_RESULTS_SAMPLEID_SUCCESS";
export const UPDATE_VALID_RESULTS_SAMPLEID_FAIL = "UPDATE_VALID_RESULTS_SAMPLEID_FAIL";

//change SID QC
export const UPDATE_VALID_RESULTS_SID_QC = "UPDATE_VALID_RESULTS_SID_QC";
export const UPDATE_VALID_RESULTS_SID_QC_SUCCESS = "UPDATE_VALID_RESULTS_SID_QC_SUCCESS";
export const UPDATE_VALID_RESULTS_SID_QC_FAIL = "UPDATE_VALID_RESULTS_SID_QC_FAIL";


//get list result samples
export const GET_VALID_RESULT_SAMPLES_LIST = "GET_VALID_RESULT_SAMPLES_LIST";
export const GET_VALID_RESULT_SAMPLES_LIST_SUCCESS = "GET_VALID_RESULT_SAMPLES_LIST_SUCCESS";
export const GET_VALID_RESULT_SAMPLES_LIST_FAIL = "GET_VALID_RESULT_SAMPLES_LIST_FAIL";
export const CLEAN_VALID_RESULT_SAMPLES_LIST = "CLEAN_VALID_RESULT_SAMPLES_LIST";