import PropTypes from "prop-types"

const FormSwitch = ({ label, value, onChangeSwitch, className, disabled, defaultChecked, style, id='checkbox_1' }) => {
    return (
        <div>
            <div className="form-check form-switch">
                <input
                    style={style}
                    type="checkbox"
                    className="form-check-input checkbox"
                    id={id}
                    checked={value}
                    disabled={disabled}
                    onChange={(e) => {
                        onChangeSwitch(e.target.checked)
                    }}
                />
                <label className="form-check-label">
                    {label}
                </label>
            </div>
        </div>
    )
}

FormSwitch.propTypes = {
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    className: PropTypes.string,
}

FormSwitch.defaultProps = {
    label: "label",
    value: "",
    className: "",
}

export default FormSwitch
