import { ModuleIds } from "constant"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import ChartDetailTable from "./chartDetailTable"
import Header from "./header"
import LotTestModal from "./Modal/LotTestModal"
import { addNewLotTestQC, getLotQCListFromLotTest, getQCLotTest } from "store/actions"

const RESOURCE = ModuleIds.ChartiQC


const BodyRight = ({
    t,
    updatedTimeLotTests,
    loadingLotTests,
    lotTests,
    config,
    onAddNewLotTestQC,
    onGetQCLotTest
}) => {
    const [testModal, setTestModal] = useState(false)
    useEffect(() => {
        if (config.lotId)
            getQCLotTest()
    }, [config])

    const getQCLotTest = () => {
        onGetQCLotTest({
            data: {
                insId: config.instrumentId || '',
                // levels: config.levels,
                lotId: config.lotId,
            }
        })
    }

    const onSubmitAddNew = (values) => {
        onAddNewLotTestQC({
            data: values, callback: () => {
                getQCLotTest()
                setTestModal(prev => !prev)
            }
        })

    }

    return (
        <React.Fragment>
            <div className="management-qc-right">
                {loadingLotTests &&
                    <div className=" w-100" style={{
                        height: '93%',
                        position: 'absolute', zIndex: 9,
                        background: 'rgba(256,256,256,0.5)'
                    }}>
                        <div className="w-100 " style={{ height: '93%' }}>
                            <div className="text-center" style={{ marginTop: '50px' }}>
                                <div className="spinner-border text-primary" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <Header
                    onClickAdd={() => {
                        setTestModal(true)
                    }}
                    config={config}
                />
                <ChartDetailTable
                    updatedTime={updatedTimeLotTests}
                    // onSelect={onSelectCheckbox}
                    data={lotTests || []}
                    // rows={rows}
                    // row={row}
                    // search={search}
                    // onSelectAll={onSelectAllCheckbox}
                    // onSearch={onSearch}
                    // onRefresh={onRefreshHandler}
                    onChangeData={(datas) => {
                    }}

                />
            </div>
            <LotTestModal
                modal={testModal}
                toggle={() => { setTestModal(prev => !prev) }}
                data={config}
                onValidSubmit={val => {
                    onSubmitAddNew(val)
                }}
            />
        </React.Fragment>
    )
}
const mapStateToProps = ({ lotQCs }) => ({
    updatedTimeLotTests: lotQCs.updatedTimeLotTests,
    loadingLotTests: lotQCs.loadingLotTests,
    lotTests: lotQCs.lotTests
})
const mapDispatchToProps = dispatch => ({
    onAddNewLotTestQC: payload => dispatch(addNewLotTestQC(payload)),
    onGetQCLotTest: payload => dispatch(getQCLotTest(payload)),
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation(["chartiQCPage", "message", "common"])(BodyRight)))
