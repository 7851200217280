import { getLottestQCList, updateLottestQC } from "helpers/app-backend/IQC/QC_Calculation_backend_helper"
import i18n from "i18next"
import { call, put, select, takeEvery } from "redux-saga/effects"
import {
    GET_LOT_TEST_QC_LIST,
    UPDATE_LOT_TEST_CALCULATION_QC
} from "./actionTypes"
import {
    getLottestListQCFail,
    getLottestListQCSuccess,
    updateLottestCalculationQCFail,
    updateLottestCalculationQCSuccess
} from "./actions"
import { showToast } from "components/Common"

const t = (msg, param) => i18n.t(msg, param)

function* fetchLottestList({ payload }) {
    try {
        const response = yield call(getLottestQCList, payload)

        yield put(getLottestListQCSuccess(response))
    } catch (error) {
        yield put(getLottestListQCFail(error))
    }
}

function* onUpdateLottestCalculationQC({ payload, callback }) {
    try {
        const response = yield call(updateLottestQC, payload)
        yield put(updateLottestCalculationQCSuccess(response))

        showToast(
            `${t("message:UpdatedMessage", {
                field: `${t(
                    "lottestCalculationQCPage:LottestCalculationQC"
                )}`,
            })}`
        )
        callback()
    } catch (error) {
        yield put(updateLottestCalculationQCFail(error))
    }
}

function* calculationQCsSaga() {
    yield takeEvery(GET_LOT_TEST_QC_LIST, fetchLottestList)
    yield takeEvery(UPDATE_LOT_TEST_CALCULATION_QC, onUpdateLottestCalculationQC)
}

export default calculationQCsSaga