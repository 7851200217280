import { AvForm } from "availity-reactstrap-validation"
import { CustomAvInput } from "components/Common"
import { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"

const InputCalculateInfo = ({
    data,
    defaultValue,
    index,
    onChange,
}) => {

    const [info, setInfo] = useState('')

    useEffect(() => {
        if (defaultValue) {
            setInfo(defaultValue)
        }
    }, [defaultValue])

    return (
        <div key={index}
            onClick={e => {
                document.getElementById(`${data.id}${index}`).style.display = ""
                document.getElementById(`${data.id}${index}`).focus()
                document.getElementById(`text-${data.id}${index}`).style.display = "none"
            }}
        >
            <a
                href="javascript:void(0)"
                id={`text-${data.id}${index}`}
            >{info || '---'}</a>
            <AvForm>
                <CustomAvInput
                    name='cv'
                    forcus
                    value={info || ''}
                    onChange={(name, value) => {
                        if (value != info) {
                            setInfo(value)
                        }
                    }}
                    id={`${data.id}${index}`}
                    style={{ display: 'none' }}
                    onBlur={() => {
                        document.getElementById(`${data.id}${index}`).style.display = "none"
                        document.getElementById(`text-${data.id}${index}`).style.display = ""
                        onChange(info)
                    }}
                />
            </AvForm>
        </div>
    )
}

InputCalculateInfo.defaultProps = {

}

const mapDispatchToProps = dispatch => ({

})

export default connect(
    null,
    mapDispatchToProps
)(withTranslation([""])(InputCalculateInfo))
