import { AvForm } from "availity-reactstrap-validation"
import { CustomDatePicker, CustomSelect } from "components/Common"
import { parameterCode } from "constant"
import { convertDateFormat } from "helpers/utilities"
import React from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"

const ConfigLotQCButtons = ({ model, onChange, onSubmit, t }) => {
  return (
    <React.Fragment>
      <AvForm>
        <div className="d-flex">
          <div style={{width : '200px', marginLeft: "5px" }}>
            <CustomSelect
              name="instrumentId"
              portal
              placeholder={t("Machines")}
              value={model.instrumentId || ""}
              code={parameterCode.INSTRUMENT}
              onChange={(name, value, label, item) => {
                onChange({
                  instrumentId: value[0],
                })
                setTimeout(() => {
                  onSubmit({ ...model, instrumentId: value[0] || "" })
                }, 100)
              }}
            />
          </div>
          <div style={{ width: "200px", marginLeft: "5px" }}>
            <CustomDatePicker
              name="start"
              placeholder={"YYYY-MM-DD HH:mm"}
              value={convertDateFormat(
                model?.start || new Date(),
                "00:00 DD-MM-YYYY"
              )}
              enableTime={true}
              dateFormat={"H:i d-m-Y"}
              onChangeHandler={e => {
                if (e.time.length > 0) {
                  onChange({
                    start: convertDateFormat(e.time[0], "YYYY-MM-DD HH:mm"),
                  })
                  setTimeout(() => {
                    onSubmit({
                      ...model,
                      start: convertDateFormat(e.time[0], "YYYY-MM-DD HH:mm"),
                    })
                  }, 100)
                } else {
                  onChange({
                    start: convertDateFormat(new Date(), "00:00 DD-MM-YYYY"),
                  })
                }
              }}
              maxDate={new Date()}
            />
          </div>
          <div style={{ width: "200px", marginLeft: "5px" }}>
            <CustomDatePicker
              name="end"
              placeholder={"YYYY-MM-DD HH:mm"}
              value={convertDateFormat(
                model?.end || new Date(),
                "HH:mm DD-MM-YYYY"
              )}
              enableTime={true}
              dateFormat={"H:i d-m-Y"}
              onChangeHandler={e => {
                if (e.time.length > 0) {
                  onChange({
                    end: convertDateFormat(e.time[0], "YYYY-MM-DD HH:mm"),
                  })
                  setTimeout(() => {
                    onSubmit({
                      ...model,
                      end: convertDateFormat(e.time[0], "YYYY-MM-DD HH:mm"),
                    })
                  }, 100)
                } else {
                  onChange({
                    end: convertDateFormat(new Date(), "HH:mm DD-MM-YYYY"),
                  })
                }
              }}
              maxDate={new Date()}
            />
          </div>
        </div>
      </AvForm>
    </React.Fragment>
  )
}

const mapStateToProps = ({ hisConnector }) => ({})
const mapDispatchToProps = dispatch => ({})

ConfigLotQCButtons.displayName = "ConfigLotQCButtons"
export default withTranslation(["managementQCPage", "common", "button"], {
  withRef: true,
})(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    ConfigLotQCButtons
  )
)
