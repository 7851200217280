import React, { useEffect, useState } from "react"
import { AvForm } from "availity-reactstrap-validation"
import { ModalBody, ModalFooter } from "reactstrap"

import { CustomButton, CustomModal } from "components/Common"
import { GeneralInformation, CompanyInformation } from "../Form"

//i18n
import { withTranslation } from "react-i18next"
import { isEmpty } from "lodash"

const UserModal = ({
  modal,
  toggle,
  isEdit,
  onValidSubmit,
  data,
  formEl,
  t,
  isClone,
  handleImageChange,
  handleImageDelete,
  selectedImage,
}) => {
  const title =
    (!!isEdit ? t("common:Edit") : t("common:Add")) + " " + t("User")
  if (isEdit && isEmpty(data)) return null
  return (
    <CustomModal modal={modal} title={title} onToggle={toggle}>
      <ModalBody className="">
        <AvForm
          ref={formEl}
          id="userForm"
          onValidSubmit={onValidSubmit}
          model={data}
          autoComplete="off"
        >
          {/* GeneralInformation */}
          <GeneralInformation t={t} data={data}
            isEdit={isEdit}
            handleImageChange={(e) => {
              console.log(541);
              
              handleImageChange(e)
            }}
            handleImageDelete={handleImageDelete}
            selectedImage={selectedImage}
          />
          {/* CompanyInformation */}
          <CompanyInformation t={t} data={data} isEdit={isEdit} isClone={isClone} />
          <button type="submit" className="d-none" />
        </AvForm>
      </ModalBody>
      <ModalFooter>
        <CustomButton
          type="button"
          onClick={toggle}
          data-dismiss="modal"
          text={"common:Cancel"}
          className="button-width"
        />
        <CustomButton
          type="submit"
          onClick={() => formEl?.current?.submit()}
          color="primary"
          className="save-user button-width"
          text={"common:Save"}
          isEdit
        />
      </ModalFooter>
    </CustomModal>
  )
}

export default withTranslation(["userPage", "common"])(UserModal)
