import { AvField, AvRadio, AvRadioGroup } from "availity-reactstrap-validation"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { Label, Row } from "reactstrap"

const FilterForm = ({ model, t }) => {
  return (
    <>
      <Row>
        <div className="mb-3">
          <Label for="example">{t("Code Search")}</Label>
          <AvField
            name="search"
            type="text"
            className="form-input"
            placeholder={t("codeSearchPlaceholder")}
            value={model.search || ""}
          />
        </div>
      </Row>
      <Row>
        <div className="mb-3">
          <Label for="example">{t("common:In Use")}</Label>
          <AvRadioGroup name="inUse" value={model.inUse} defaultValue={model.inUse}>
          <AvRadio label={t("common:Yes")} value="true" />
          <AvRadio label={t("common:No")} value="false" />
          </AvRadioGroup>
        </div>
      </Row>
    </>
  )
}

FilterForm.propTypes = {
  model: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation(["parameterPage", "common"])(FilterForm)
