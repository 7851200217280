import { takeEvery, fork, put, all, call, takeLatest } from "redux-saga/effects"

// Login Redux States
import {
  EDIT_PROFILE,
  GET_AUTHORIZE_MENUS,
  GET_AUTHORIZE_PERMISSIONS,
} from "./actionTypes"
import {
  profileSuccess,
  profileError,
  getAuthorizeMenusFail,
  getAuthorizeMenusSuccess,
  getAuthorizePermissionsSuccess,
  getAuthorizePermissionsFail,
  getAuthorizePermissionsResultSuccess,
  setAuthorizeModuleForbiddenError,
} from "./actions"

//Include Both Helper File with needed methods
import { postFakeProfile, postJwtProfile } from "helpers/fakebackend_helper"

import { getAuthorizeMenu, getAuthorizePage, getAuthorizePageByResourceName, getAuthorizePageByResourceNameWithoutError } from "helpers/app-backend"
import { isEmptyValues } from "helpers/utilities"
import { DefaultMenuDisplayInfo, ModuleIds } from "constant"

function* editProfile({ payload: { user } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(postJwtProfile, "/post-jwt-profile", {
        username: user.username,
        idx: user.idx,
      })
      yield put(profileSuccess(response))
    } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
      const response = yield call(postFakeProfile, {
        username: user.username,
        idx: user.idx,
      })
      yield put(profileSuccess(response))
    }
  } catch (error) {
    yield put(profileError(error))
  }
}

// AUTHORIZE
function* fetchAuthorizeMenus({ payload: { role, callback } }) {
  try {
    const response = yield call(getAuthorizeMenu, role)
    const _DisplayInfo = response._DisplayInfo
    delete response._DisplayInfo
    const _M3Info = response._M3Info || []
    delete response._M3Info
    const _DisplayM4 = response._DisplayM4 || []
    delete response._DisplayM4
    // convert response object to array
    const result = menusHandler(response) // {pageCode: "", parent: [pageCode]}
    let menus = menuIconHandler(result, _DisplayInfo, _M3Info)
    if (_DisplayM4.length > 0) {
      menus = menus.concat(_DisplayM4)
    }
    yield put(getAuthorizeMenusSuccess(menus))
    let defaultPages = _M3Info.filter(x => x.item.startsWith(`Role:${role}:`))
    let page = {}
    if (defaultPages.length > 0) {
      page = defaultPages[0]
    }
    if (callback) callback(result?.find(_menu => _menu.level === 4), page)
  } catch (error) {
    console.log(error)
    yield put(getAuthorizeMenusFail(error))
  }
}

const menuIconHandler = (menus, displayInfo, m3Info) => {
  return menus.map(_menu => {
    let idx = displayInfo.findIndex(_info => _info.item === _menu.pageCode)
    let displayOrder = "";
    let color = DefaultMenuDisplayInfo.color,
      icon = DefaultMenuDisplayInfo.icon
    if (idx >= 0) {
      icon = displayInfo[idx].icon || icon
      color = displayInfo[idx].color || color
      displayOrder = displayInfo[idx].displayOrder || ''
    }
    else {
      idx = m3Info.findIndex(_info => _info.item === _menu.pageCode)
      if (idx >= 0) {
        icon = m3Info[idx].icon || icon
        color = m3Info[idx].color || color
        displayOrder = m3Info[idx].displayOrder || ''
      }
    }

    _menu.icon = icon
    _menu.color = color
    _menu.displayOrder = displayOrder

    return _menu
  })
}

const menusHandler = (object, parent) => {
  const result = []

  const isArray = Array.isArray(object)
  const arrayObject = isArray ? object : Object.keys(object)

  let level = 0
  if (!parent) level = 1
  else if (parent === "_OneLIS") level = 2
  else if (isArray) level = 4
  else if (parent !== "_OneLIS") level = 3

  arrayObject.forEach(_key => {
    result.push({
      pageCode: _key,
      parent,
      level,
      hasChildren: !isEmptyValues(object[_key]),
    })

    if (!isArray && !isEmptyValues(object[_key]))
      result.push.apply(result, menusHandler(object[_key], _key))
    else return
  })

  return result
}

// AUTHORIZE
function* fetchAuthorizePermissions({ payload: { role, module } }) {
  try {
    let response = yield call(getAuthorizePage, role, module)

    const _Permissions = { ...response._Permissions }
    const _DisplayInfo = [...(response._DisplayInfo || [])]

    response._Permissions = permissionIconHandler(_Permissions, _DisplayInfo)

    if (module === "_" + ModuleIds.TestResult
      || module === "_" + ModuleIds.TestRequest
      || module === "_" + ModuleIds.DeliveryManagement
      || module === "_" + ModuleIds.Test
      || module === "_" + ModuleIds.Profile
    ) {
      try {
        const res = yield call(getAuthorizePageByResourceNameWithoutError, "_" + ModuleIds.Logs, role)
        if (res && res._Permissions && res._Permissions['Logs']) {
          response._Permissions.Logs = { permissions: res._Permissions['Logs'] }
        }
      } catch (error) {
      }
    }

    yield put(getAuthorizePermissionsSuccess(response))
    if (module == "_" + ModuleIds.Patient) {
      const res = yield call(getAuthorizePageByResourceName, "_" + ModuleIds.TestResult, role)
      yield put(getAuthorizePermissionsResultSuccess({ _ReportInfo: res._ReportInfo }))
    }
    if (module == "_" + ModuleIds.PatientVisit) {
      const res = yield call(getAuthorizePageByResourceName, "_" + ModuleIds.TestRequest, role)
      yield put(getAuthorizePermissionsResultSuccess({ _ReportInfo: res._ReportInfo }))
    }
    if (module == "_" + ModuleIds.PatientGroup) {

      const res = yield call(getAuthorizePageByResourceName, "_" + ModuleIds.TestResult, role)
      const res2 = yield call(getAuthorizePageByResourceName, "_" + ModuleIds.TestRequest, role)
      yield put(getAuthorizePermissionsResultSuccess({ _ReportInfo: res._ReportInfo }))
      if (res) {
        let keys = Object.keys(res._Permissions);
        let i = keys.findIndex(x => x.includes("Report:"))
        if (i >= 0) {
          let propertyName = keys[i];
          let reportPermission = {
            permissions: res._Permissions[propertyName]
          }
          response._Permissions[propertyName] = reportPermission
          response._ReportInfo = res2._ReportInfo
          yield put(getAuthorizePermissionsSuccess(response))
        }
      }


    }
    if (module == "_" + ModuleIds.TestResult) {
      const res = yield call(getAuthorizePageByResourceName, "_" + ModuleIds.TestResult)
      yield put(getAuthorizePermissionsResultSuccess({ _ReportInfo: res._ReportInfo }))
    }

  } catch (error) {
    console.log(error)
    yield put(setAuthorizeModuleForbiddenError(true))
    yield put(getAuthorizePermissionsFail(error))
  }
}

// const getPermissions = () => {
//   let selectedRole = getUserInfoStorage("selectedRole")
//   console.log(selectedRole,moduleId);
//   if (!isEmptyValues(selectedRole) && !isEmptyValues(moduleId))
//     dispatch(
//       getAuthorizePermissions({ role: selectedRole, module: moduleId })
//     )
// }

const permissionIconHandler = (permissions, displayInfo) => {
  const arrayPermission = Object.keys(permissions)
  arrayPermission?.forEach(_key => {
    let idx = displayInfo.findIndex(_info => _info.item === _key)
    idx =
      idx < 0 ? displayInfo.findIndex(_info => _info.item === "_default") : idx

    let color = DefaultMenuDisplayInfo.color,
      icon = DefaultMenuDisplayInfo.icon

    if (idx >= 0) {
      icon = displayInfo[idx].icon || icon
      color = displayInfo[idx].color || color
    }
    permissions[_key] = {
      permissions: permissions[_key],
      icon: icon,
      color: color,
    }
  })

  return permissions
}

export function* watchProfile() {
  yield takeEvery(EDIT_PROFILE, editProfile)
  yield takeLatest(GET_AUTHORIZE_MENUS, fetchAuthorizeMenus)
  yield takeLatest(GET_AUTHORIZE_PERMISSIONS, fetchAuthorizePermissions)
}

function* ProfileSaga() {
  yield all([fork(watchProfile)])
}

export default ProfileSaga
