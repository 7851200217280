import { call, put, takeEvery } from "redux-saga/effects"

import {
  UPDATE_TEST_QC
} from "./actionTypes"

import {
  updateTestQCFail,
  updateTestQCSuccess,
} from "./actions"
import { updateTestQC } from "helpers/app-backend/testsqc_backend_helper"

function* onUpdateTestQC({ payload, callback }) {
  try {
    const response = yield call(updateTestQC, payload)
    yield put(updateTestQCSuccess(response))
    if (callback) {
      callback()
    }
  } catch (error) {
    console.log(error)
    yield put(updateTestQCFail(error))
  }
}

function* testQCSaga() {
  yield takeEvery(UPDATE_TEST_QC, onUpdateTestQC)
}

export default testQCSaga
