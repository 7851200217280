import {
    ButtonDropdownGroup,
    Check,
    CustomButton
} from "components/Common"
import { ModuleIds, PartyProfile, RESULT_STATE_Text, TestRequest_Test_Type, permissionType } from "constant"
import PropTypes from "prop-types"

import {
    DropdownItem, DropdownMenu, DropdownToggle,
    UncontrolledTooltip
} from "reactstrap"

//i18n
import { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { setFastValidate } from "store/actions"
import HaemogramCommentModal from "./Modal/HaemogramCommentModal"
import { isEmpty, set } from "lodash"
import { getGeneralSetting } from "store/setting/generalSetting/actions"
import ReactTooltip from "react-tooltip"
const HeaderButtons = ({
    resource,
    onDelete,
    onRefreshClick,
    t,
    onSubmitConfirm,
    onSubmitCancelConfirm,
    onSubmitValidate,
    onSubmitSign,
    onSubmitUnsigned,
    onSubmitCancelValidate,
    onConfigClick,
    onPerformClick,
    loadingRefresh = false,
    loadingPerform = false,
    onRunRuleClick,
    onHistoryResultClick,
    onNotifyClick,
    data,
    onWarningRuleClick,
    onConcludeClick,
    generalSetting,
    onTestSend,
    dataInfoResult,
    onGetGeneralSetting
}) => {
    const [isConfirm, setIsConfirm] = useState(false)
    const [isRerun, setIsRerun] = useState(false)
    const [isValid, setIsValid] = useState(false)
    const [isValidAndSend, setIsValidAndSend] = useState(false)
    const [isUnConfirm, setIsUnConfirm] = useState(false)
    const [isInValid, setIsInValid] = useState(false)
    const [isUnsigned, setIsUnsigned] = useState(false)
    const [isSigned, setIsSigned] = useState(false)
    const [approvalLevel, setApprovalLevel] = useState(false)
    const userInfo = JSON.parse(localStorage.getItem("userInfo"))
    const isIntegration = generalSetting && generalSetting?.find(item => item.fieldName === 'IntegratedApproval')?.fieldValue === 'Integration'
    const [haemogramCommentModal, setHaemogramCommentModal] = useState(false)
    const [reportType, setReportType] = useState([])
    const [checkHeamogramComment, setCheckHeamogramComment] = useState(false)
    const [isHaemogramComment, setIsHaemogramComment] = useState(false)

    useEffect(() => {
        if (isEmpty(generalSetting)) {
            onGetGeneralSetting(userInfo?.company)
        }
    }, [])

    useEffect(() => {
        if (generalSetting && generalSetting.length > 0) {
            setApprovalLevel(generalSetting.find(x => x.fieldName == "ApprovalLevel")?.fieldValue)
        }
    }, [generalSetting])

    useEffect(() => {
        checkState(data || [])
    }, [data])

    const GetAllReportType = () => {
        let res = [];
        let reportTypeNames = [];
        const dt = data;
        dt.forEach(row => {
            if (row.type === TestRequest_Test_Type.TEST) {
                let reportTypeName = row.reportTypeName
                if (reportTypeName !== "" && !reportTypeNames.includes(reportTypeName) && row?.state !== RESULT_STATE_Text.Canceled) {
                    res.push(reportTypeName);
                    reportTypeNames.push(reportTypeName);
                }
            }
            if (row.children && row.children.length > 0) {
                row.children.forEach(sub => {
                    if (sub.type === TestRequest_Test_Type.TEST) {
                        let reportTypeName = sub.reportTypeName
                        if (reportTypeName !== "" && !reportTypeNames.includes(reportTypeName) && sub?.state !== RESULT_STATE_Text.Canceled) {
                            res.push(reportTypeName);
                            reportTypeNames.push(reportTypeName);
                        }
                    }
                    if (sub.children && sub.children.length > 0) {
                        sub.children.forEach(child => {
                            if (child.type === TestRequest_Test_Type.TEST) {
                                let reportTypeName = child.reportTypeName
                                if (reportTypeName !== "" && !reportTypeNames.includes(reportTypeName) && child?.state !== RESULT_STATE_Text.Canceled) {
                                    res.push(reportTypeName);
                                    reportTypeNames.push(reportTypeName);
                                }
                            }
                        });
                    }
                });
            }
        });
        setReportType(res)
        if (res.includes('HD')) {
            setCheckHeamogramComment(true)
        }
    }

    const GetAllStateTests = () => {
        let res = [];
        let stateTest = [];
        const dt = data;
        dt.forEach(row => {
            // if (row.type === TestRequest_Test_Type.TEST) {
            //     let state = row.state
            //     if (state !== "" && !stateTest.includes(state) && row?.state !== RESULT_STATE_Text.Canceled) {
            //         res.push(state);
            //         stateTest.push(state);
            //     }
            // }
            if (row.children && row.children.length > 0) {
                row.children.forEach(sub => {
                    if (sub.type === TestRequest_Test_Type.TEST && sub.reportTypeName === 'HD') {
                        let state = sub.state
                        if (state !== "" && !stateTest.includes(state) && sub?.state !== RESULT_STATE_Text.Canceled) {
                            res.push(state);
                            stateTest.push(state);
                        }
                    }
                    if (sub.children && sub.children.length > 0) {
                        sub.children.forEach(child => {
                            if (child.type === TestRequest_Test_Type.TEST && child.reportTypeName === 'HD') {
                                let state = child.state
                                if (state !== "" && !stateTest.includes(state) && child?.state !== RESULT_STATE_Text.Canceled) {
                                    res.push(state);
                                    stateTest.push(state);
                                }
                            }
                        });
                    }
                });
            }
        });
        // if (res?.includes(RESULT_STATE_Text.Confirmed) || res?.includes(RESULT_STATE_Text.Validated) || res?.includes(RESULT_STATE_Text.Releasing) || res?.includes(RESULT_STATE_Text.Signed) || res?.includes(RESULT_STATE_Text.Released)) {
        //     setIsHaemogramComment(true)
        // }
        setIsHaemogramComment(res.every(_res => _res >= RESULT_STATE_Text.Confirmed))
    }

    useEffect(() => {
        GetAllReportType()
        GetAllStateTests()
    }, [data])

    const checkState = (testResults) => {
        let count_invalid = 0;
        let count_unconfirm = 0;
        let count_valid = 0;//record đủ tiêu chí valid
        let count_valid_fail = 0;//record không đủ tiêu chí valid
        let count_confirm = 0;//record đủ tiêu chí confirm
        let count_confirm_fail = 0;//record không đủ tiêu chí confirm
        let count_isCheck = 0;
        let count_disable = 0;

        let confirmed = 0;
        let validated = 0;
        let total = 0;

        let count_signed = 0;
        //testResults chỉ xem cét những record được check (inValid)
        testResults.forEach(element => {
            if (element.type == TestRequest_Test_Type.TEST) {
                if (element.isValid == true) {
                    count_isCheck++;
                    if (element.state == RESULT_STATE_Text.Canceled || element.state == RESULT_STATE_Text.RDS) {
                        count_disable++;
                    }
                    if (element.state >= RESULT_STATE_Text.Signed) {
                        count_signed++;
                    }
                    if (element.state >= RESULT_STATE_Text.Validated) {
                        count_invalid++;
                        count_unconfirm++;
                    } else if (element.state >= RESULT_STATE_Text.Confirmed) {
                        count_unconfirm++;
                    }
                    if ((element.result != null && element.result != '') || (element.resultText != null && element.resultText != '')) {
                        count_valid++;
                        count_confirm++;
                    }
                    else {
                        // count_valid--;
                        // count_confirm--;
                        count_valid_fail++;
                        count_confirm_fail++
                    }
                } else {
                    if ((element.result != null && element.result != '') || (element.resultText != null && element.resultText != '')) {
                        count_valid++;
                        count_confirm++;
                    }

                    if (!(element.state == RESULT_STATE_Text.Canceled || element.state == RESULT_STATE_Text.RDS)) {
                        total++;
                    }

                    if (element.state >= RESULT_STATE_Text.Validated && element.state != RESULT_STATE_Text.RDS) {
                        confirmed++
                        validated++
                    } else if (element.state >= RESULT_STATE_Text.Confirmed && element.state != RESULT_STATE_Text.RDS) {
                        confirmed++
                    }
                }
            }
            if (element.children && element.children.length > 0) {
                element.children.forEach(parent => {
                    if (parent.type == TestRequest_Test_Type.TEST) {
                        if (parent.isValid == true) {
                            count_isCheck++;
                            if (parent.state == RESULT_STATE_Text.Canceled || parent.state == RESULT_STATE_Text.RDS) {
                                count_disable++;
                            }
                            if (parent.state >= RESULT_STATE_Text.Signed) {
                                count_signed++;
                            }
                            if (parent.state >= RESULT_STATE_Text.Validated) {
                                count_invalid++;
                                count_unconfirm++;
                            }
                            else if (parent.state >= RESULT_STATE_Text.Confirmed) {
                                count_unconfirm++;
                            }
                            if ((parent.result != null && parent.result != '') || (parent.resultText != null && parent.resultText != '')) {
                                count_valid++;
                                count_confirm++;
                            } else {
                                count_valid_fail++;
                                count_confirm_fail++
                            }
                        } else {
                            if (!(parent.state == RESULT_STATE_Text.Canceled || parent.state == RESULT_STATE_Text.RDS)) {
                                total++;
                            }

                            if ((parent.result != null && parent.result != '') || (parent.resultText != null && parent.resultText != '')) {
                                count_valid++;
                                count_confirm++;
                            }

                            if (parent.state >= RESULT_STATE_Text.Validated && parent.state != RESULT_STATE_Text.RDS) {
                                confirmed++
                                validated++
                            } else if (parent.state >= RESULT_STATE_Text.Confirmed && parent.state != RESULT_STATE_Text.RDS) {
                                confirmed++
                            }
                        }
                    }
                    if (parent.children && parent.children.length > 0) {
                        parent.children.forEach(child => {
                            if (child.type == TestRequest_Test_Type.TEST) {
                                if (child.isValid == true) {
                                    count_isCheck++;
                                    if (child.state == RESULT_STATE_Text.Canceled || child.state == RESULT_STATE_Text.RDS) {
                                        count_disable++;
                                    }
                                    if (child.state >= RESULT_STATE_Text.Signed) {
                                        count_signed++;
                                    }
                                    if (child.state >= RESULT_STATE_Text.Validated) {
                                        count_invalid++;
                                        count_unconfirm++;
                                    }
                                    else if (child.state >= RESULT_STATE_Text.Confirmed) {
                                        count_unconfirm++;
                                    }
                                    if ((child.result != null && child.result != '') || (child.resultText != null && child.resultText != '')) {
                                        count_valid++;
                                        count_confirm++;
                                    } else {
                                        count_valid_fail++;
                                        count_confirm_fail++
                                    }
                                } else {

                                    if ((child.result != null && child.result != '') || (child.resultText != null && child.resultText != '')) {
                                        count_valid++;
                                        count_confirm++;
                                    }

                                    if (!(child.state == RESULT_STATE_Text.Canceled || child.state == RESULT_STATE_Text.RDS)) {
                                        total++;
                                    }

                                    if (child.state >= RESULT_STATE_Text.Validated && child.state != RESULT_STATE_Text.RDS) {
                                        confirmed++
                                        validated++
                                    } else if (child.state >= RESULT_STATE_Text.Confirmed && child.state != RESULT_STATE_Text.RDS) {
                                        confirmed++
                                    }
                                }
                            }
                        });
                    }
                });
            }
        });
        if (count_signed > 0 && count_isCheck == count_signed) {
            setIsUnsigned(true)
        }
        else {
            setIsUnsigned(false)
        }
        if (validated > 0) {
            setIsSigned(true)
        }
        else {
            setIsSigned(false)
        }
        if (count_disable == count_isCheck) {
            setIsConfirm(false)
            setIsValid(false)
            setIsUnConfirm(false)
            setIsInValid(false)
            setIsValidAndSend(false)
        }
        else {
            setIsConfirm((count_confirm > 0 && count_confirm_fail == 0 && count_unconfirm === 0))
            setIsValid(count_valid > 0 && count_valid_fail == 0 && count_invalid === 0)
            setIsValidAndSend(count_invalid > 0 || count_valid_fail == 0)
            setIsUnConfirm(count_unconfirm > 0)
            setIsInValid(count_invalid > 0)
        }

        if (count_isCheck == 0)//ko chọn gì hết
        {
            setIsConfirm(confirmed !== total && count_confirm - confirmed > 0)
            setIsValid(validated !== total && count_valid - validated > 0)
            setIsValidAndSend(count_valid > 0)
        }

        if (count_invalid == 0 && count_isCheck > 0) {
            setIsRerun(false)
        }
        else {
            setIsRerun(true)
        }
    }

    return (
        <>
            <Check permission={permissionType.R} resource={resource}>
                <>
                    <ReactTooltip
                        id={`tooltip-custom-child-ex`}
                        place="bottom"
                        effect="solid"
                        className="custom-tooltip"
                    >
                        <div className="tooltip-content">
                            <div className="tooltip-item">
                                <span className="tooltip-label-test-send">{t("The test are created manually.")}</span>
                            </div>
                        </div>
                    </ReactTooltip>
                    <span
                        className="tooltip-icon-wrapper"
                        data-tip
                        data-for={`tooltip-custom-child-ex`}
                    >
                        <i className="fas fa-exclamation-circle text-primary font-size-16"></i>
                    </span>
                </>
            </Check>
            <Check permission={permissionType.R} resource={resource}>
                <CustomButton
                    color="secondary"
                    outline
                    onClick={() => {
                        onRefreshClick()
                    }}
                >
                    <i
                        className={`fas fa-sync-alt ${loadingRefresh ? "loading-spin" : ""
                            }`}
                    ></i>
                </CustomButton>
            </Check>
            <Check permission={permissionType.R} resource={resource}>
                <CustomButton className="loading-spin-hover" color="secondary" outline onClick={() => {
                    onConfigClick()
                }}>
                    <i className="fa fa-cog loading-spin-hover-content"></i>
                </CustomButton>
            </Check>
            {/* <Check permission={permissionType.U} resource={resource}>
                <CustomButton
                    color="primary" outline onClick={() => {
                        onNotifyClick && onNotifyClick()
                    }}>
                    {t("Notifications")}
                </CustomButton>
            </Check> */}
            {resource === ModuleIds.TestResult && <Check permission={permissionType.U} resource={resource}>
                <CustomButton
                    color="primary"
                    disabled={dataInfoResult?.state === RESULT_STATE_Text.Canceled}
                    outline
                    onClick={onTestSend}>
                    {t("XN Send")}
                </CustomButton>
            </Check>}
            <Check permission={permissionType.U} resource={resource}>
                <CustomButton
                    color="primary" outline
                    disabled={dataInfoResult?.state === RESULT_STATE_Text.Canceled}
                    onClick={() => {
                        onConcludeClick && onConcludeClick()
                    }}>
                    {t("KL chung")}
                </CustomButton>
            </Check>
            <Check permission={permissionType.RunRule} resource={resource}>
                <CustomButton
                    isEdit
                    disabled={dataInfoResult?.state === RESULT_STATE_Text.Canceled}
                    color="primary" outline onClick={() => {
                        onRunRuleClick && onRunRuleClick()
                    }}>
                    {t("Run Rule")}
                </CustomButton>
            </Check>
            {/* <Check permission={permissionType.U} resource={resource}>
                <CustomButton
                    color="primary" outline onClick={() => {
                        onWarningRuleClick && onWarningRuleClick()
                    }}>
                    {t("Warning Rule")}
                </CustomButton>
            </Check> */}
            <Check permission={permissionType.ReRun} resource={resource}>
                <CustomButton
                    disabled={isRerun || dataInfoResult?.state === RESULT_STATE_Text.Canceled}
                    isEdit
                    color="primary" outline onClick={() => {
                        onPerformClick && onPerformClick()
                    }}>
                    {t("Rerun")}
                </CustomButton>
            </Check>
            {/* <Check permission={permissionType.R} resource={resource}> */}
            <Check permission={permissionType.OldResult} resource={resource}>
                <CustomButton
                    color="primary" outline onClick={() => {
                        onHistoryResultClick()
                    }}>
                    {t("Old Result")}
                </CustomButton>
            </Check>

            <ButtonDropdownGroup
                onDeleteClick={() => { }}
                onCloneClick={() => { }}
            >
                {!isIntegration && (approvalLevel == 'True' || approvalLevel == true) &&
                    <Check
                        permission={permissionType.Confirm}
                        resource={resource}
                        fallback={(<Check permission={permissionType.Confirm} resource={resource}>
                            <CustomButton disabled color="primary" outline> {t("Confirm")} </CustomButton>
                        </Check>)}
                    >
                        <CustomButton disabled={!isConfirm} color="primary" className="btn-confirm" onClick={() => {
                            onSubmitConfirm && onSubmitConfirm()
                        }} outline>
                            {t("Confirm")}
                        </CustomButton>
                    </Check>
                }
                {!isIntegration &&
                    <>
                        <Check
                            permission={permissionType.Validate}
                            resource={resource}
                            fallback={(<Check permission={permissionType.Validate} resource={resource}>
                                <CustomButton disabled color="primary" outline className="btn-valid"> {t("Validate")} </CustomButton>
                            </Check>)}
                        >
                            <CustomButton disabled={!isValid} color="primary" className="btn-valid" onClick={() => {
                                onSubmitValidate && onSubmitValidate()
                            }} outline>
                                {t("Validate")}
                            </CustomButton>
                        </Check>
                        <Check
                            permission={permissionType.ValidAndSend}
                            resource={resource}
                            fallback={(<Check permission={permissionType.ValidAndSend} resource={resource}>
                                <CustomButton disabled color="warning" outline className="btn-valid"> {t("Validate")} </CustomButton>
                            </Check>)}
                        >
                            <div className="tooltip-custom">
                                <CustomButton disabled={!isValidAndSend} color="warning" className="btn-valid" onClick={() => {
                                    onSubmitValidate && onSubmitValidate(true)
                                }} outline id='tooltip_validandsend1'>
                                    {t("Validate")}
                                </CustomButton>
                                <span className="tooltiptext-custom-button-icon-left">
                                    {t("ValidAndSend")}
                                </span>
                            </div>
                        </Check>
                    </>}
                <Check permission={permissionType.Signature} resource={resource}>
                    <CustomButton disabled={!isSigned || dataInfoResult?.state === RESULT_STATE_Text.Canceled}
                        color="primary"
                        onClick={() => {
                            onSubmitSign && onSubmitSign()
                        }} outline>
                        {t("Sign")}
                    </CustomButton>
                </Check>
                <Check
                    permissions={[permissionType.U, permissionType.C, permissionType.Validate, permissionType.Confirm, permissionType.ValidAndSend]} resource={resource}
                >
                    <DropdownToggle caret color="primary" outline>
                        <i className="mdi mdi-chevron-down"></i>
                    </DropdownToggle>
                    <DropdownMenu>
                        {/* <DropdownItem disabled>{t("Action")}</DropdownItem> */}
                        {
                            !isIntegration && (approvalLevel == 'True' || approvalLevel == true) && (
                                <Check
                                    permission={permissionType.Confirm}
                                    resource={resource}
                                    fallback={(<Check permission={permissionType.Confirm} resource={resource}>
                                        <DropdownItem disabled>
                                            {t("Cancel Confirm")}
                                        </DropdownItem>
                                    </Check>)}
                                >
                                    <DropdownItem
                                        disabled={isInValid || !isUnConfirm}
                                        onClick={() => {
                                            onSubmitCancelConfirm && onSubmitCancelConfirm();
                                        }}>
                                        {t("Cancel Confirm")}
                                    </DropdownItem>
                                </Check>)
                        }
                        <Check permissions={[permissionType.Validate, permissionType.ValidAndSend]} resource={resource}>
                            <DropdownItem
                                disabled={dataInfoResult?.state === RESULT_STATE_Text.Signed || !isInValid}
                                onClick={() => {
                                    onSubmitCancelValidate && onSubmitCancelValidate();
                                }}
                                fallback={(<Check permissions={[permissionType.Validate, permissionType.ValidAndSend]} resource={resource}>
                                    <DropdownItem disabled>
                                        {t("Cancel Valid")}
                                    </DropdownItem>
                                </Check>)}
                            >
                                {t("Cancel Valid")}
                            </DropdownItem>
                        </Check>
                        <Check permission={permissionType.Signature} resource={resource}>
                            <DropdownItem disabled={!isUnsigned} onClick={() => {
                                onSubmitUnsigned && onSubmitUnsigned();
                            }}>
                                {t("Unsigned")}
                            </DropdownItem>
                        </Check>
                        {checkHeamogramComment && <Check permission={permissionType.U} resource={resource}>
                            <DropdownItem disabled={isHaemogramComment} onClick={() => {
                                setHaemogramCommentModal(true)
                            }}>
                                {t("Haemogram Comment")}
                            </DropdownItem>
                        </Check>}
                        {/* <DropdownItem onClick={() => {
                            onDelete && onDelete()
                        }}>
                            {t("common:Delete")}
                        </DropdownItem> */}
                    </DropdownMenu>
                </Check>
            </ButtonDropdownGroup >
            {
                haemogramCommentModal && <HaemogramCommentModal
                    modal={haemogramCommentModal}
                    data={dataInfoResult}
                    toggle={() => {
                        setHaemogramCommentModal(null)
                        // onRefresh()
                    }}
                />
            }
        </>
    )
}

HeaderButtons.propTypes = {
    resource: PropTypes.string.isRequired,
    onRefreshClick: PropTypes.func.isRequired,
    loadingPerform: PropTypes.bool,
    loadingRefresh: PropTypes.bool,
}

const mapDispatchToProps = (dispatch) => ({
    onSetFastValidate: (payload) => dispatch(setFastValidate(payload)),
    onGetGeneralSetting: payload => dispatch(getGeneralSetting(payload)),
})

const mapStateToProps = ({ testResult, generalSetting }) => ({
    generalSetting: generalSetting.generalSetting
})

export default withTranslation(["testResultPage", "common"])(connect(
    mapStateToProps,
    mapDispatchToProps, null, { forwardRef: true }
)(HeaderButtons))
