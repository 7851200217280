import {
    ADD_NEW_WESTGARD_SIGMA_QC,
    ADD_WESTGARD_SIGMA_QC_FAIL,
    ADD_WESTGARD_SIGMA_QC_SUCCESS,
    DELETE_WESTGARD_SIGMA_QC_FAIL,
    DELETE_WESTGARD_SIGMA_QC_SUCCESS,
    GET_FULL_WESTGARD_SIGMA_QC,
    GET_FULL_WESTGARD_SIGMA_QC_FAIL,
    GET_FULL_WESTGARD_SIGMA_QC_SUCCESS,
    GET_WESTGARD_SIGMA_QC_DETAIL,
    GET_WESTGARD_SIGMA_QC_DETAIL_FAIL,
    GET_WESTGARD_SIGMA_QC_DETAIL_SUCCESS,
    RESET_WESTGARD_SIGMA_QC_SEARCH_QUERY,
    SET_WESTGARD_SIGMA_QC_SEARCH_QUERY,
    UPDATE_WESTGARD_SIGMA_QC,
    UPDATE_WESTGARD_SIGMA_QC_FAIL,
    UPDATE_WESTGARD_SIGMA_QC_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
    westgardSigmaQCs: [],
    paging: {},
    loadingWestgardSigmaQCs: false,
    updatingWestgardSigmaQC: true,
    updatedTime: new Date(),
    westgardSigmaQC: {},
    searchQuery: {},
    errorWestgardSigmaQC: {},
    error: {},
    treeViewItem: {},
    position: {}
}

const WestgardSigmaQCs = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_WESTGARD_SIGMA_QC_DETAIL:
            return {
                ...state,
                westgardSigmaQC: {},
                error: {},
                updatingWestgardSigmaQC: true,
            }

        case GET_WESTGARD_SIGMA_QC_DETAIL_SUCCESS:
            const westgardSigmaQC = action.payload
            return {
                ...state,
                westgardSigmaQC: westgardSigmaQC,
                updatingWestgardSigmaQC: false,
            }

        case GET_WESTGARD_SIGMA_QC_DETAIL_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        // case GET_WESTGARD_SIGMA_QC:
        //     return {
        //         ...state,
        //         //westgardSigmaQCs: [],
        //         error: {},
        //         loadingWestgardSigmaQCs: true,
        //     }

        // case GET_WESTGARD_SIGMA_QC_SUCCESS:
        //     return {
        //         ...state,
        //         westgardSigmaQC: action.payload,
        //         paging: { dataSize: action.payload.length },
        //         loadingWestgardSigmaQCs: false,
        //         updatedTime: new Date(),
        //     }

        // case GET_WESTGARD_SIGMA_QC_FAIL:
        //     return {
        //         ...state,
        //         error: action.payload,
        //         loadingWestgardSigmaQCs: false,
        //     }
        case ADD_NEW_WESTGARD_SIGMA_QC:
            return {
                ...state,
                updatingWestgardSigmaQC: true,
                errorCode: {},
            }
        case ADD_WESTGARD_SIGMA_QC_SUCCESS:
            return {
                ...state,
                westgardSigmaQC: action.payload
            }

        case ADD_WESTGARD_SIGMA_QC_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        case UPDATE_WESTGARD_SIGMA_QC:
            return {
                ...state,
                updatingWestgardSigmaQC: true,
                error: {},
            }

        case UPDATE_WESTGARD_SIGMA_QC_SUCCESS:
            return {
                ...state,
                westgardSigmaQCs: state.westgardSigmaQCs,
                updatingWestgardSigmaQC: false,
            }

        case UPDATE_WESTGARD_SIGMA_QC_FAIL:
            return {
                ...state,
                error: action.payload,
                updatingWestgardSigmaQC: false,
            }

        case DELETE_WESTGARD_SIGMA_QC_SUCCESS:
            const payload = action.payload
            const westgardSigmaQCsUpdate = state.westgardSigmaQCs.filter(
                _westgardSigmaQC => payload.findIndex(_payload => _westgardSigmaQC.id === _payload.id) < 0
            )

            const statePaging = { ...state.paging }
            statePaging.dataSize = statePaging.dataSize - payload.length
            if (statePaging.dataSize < 0) statePaging = 0

            return {
                ...state,
                westgardSigmaQCs: westgardSigmaQCsUpdate,
                paging: statePaging,
            }

        case DELETE_WESTGARD_SIGMA_QC_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        case SET_WESTGARD_SIGMA_QC_SEARCH_QUERY:
            return {
                ...state,
                searchQuery: { ...action.payload },
            }
        case RESET_WESTGARD_SIGMA_QC_SEARCH_QUERY:
            return {
                ...state,
                searchQuery: {},
            }
        case GET_FULL_WESTGARD_SIGMA_QC:
            return {
                ...state,
                //westgardSigmaQCs: [],
                error: {},
                loadingWestgardSigmaQCs: true,
            }

        case GET_FULL_WESTGARD_SIGMA_QC_SUCCESS:
            const { data, totalElements, ...rest } = action.payload
            return {
                ...state,
                westgardSigmaQCs: data,
                paging: { ...rest, dataSize: totalElements },
                loadingWestgardSigmaQCs: false,
                updatedTime: new Date(),
            }

        case GET_FULL_WESTGARD_SIGMA_QC_FAIL:
            return {
                ...state,
                error: action.payload,
                loadingWestgardSigmaQCs: false,
            }
        default:
            return state
    }
}

export default WestgardSigmaQCs