import { AvForm } from "availity-reactstrap-validation"
import {
  Accordion,
  AccordionBody,
  AccordionWrapper,
  CustomAutoComplete,
  CustomAvField,
  CustomAvInput,
  CustomButton,
  CustomModal,
  CustomDatePicker,
} from "components/Common"
import { RESULT_STATE_Text, parameterCode } from "constant"
import { showWanringToast } from 'components/Common/ShowWarningToastr';
import { convertDateFormat, getInvalidMessageI18n, isRequiredError } from "helpers/utilities"
import { isEmpty } from "lodash"
import { useEffect, useRef, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { Col, Label, ModalBody, ModalFooter, Row } from "reactstrap"
import { getTissueCellResult, getTissueCellResultSuccess, updateTissueCellResult, getParamByNameLang } from "store/actions"
import UploadImage8GPB from "./UploadImage8GPB"
import { postWithFormData } from "helpers/api_helper_withoutError"

const TissueCellResultModal = ({
  modal,
  toggle,
  onGetTissueCellResult,
  onUpdateTissueCellResult,
  onGetParamByNameLang,
  tissueCellResult,
  paramByNameLang,
  onRefreshTissueCellResult,
  data,
  t
}) => {
  const formEl = useRef(null)
  const title = t("Result Content Detail")
  const [tissueCellResultTest, setTissueCellResultTest] = useState()
  const [fixTime, setFixTime] = useState()
  const [dissectionTime, setDissectionTime] = useState()
  const [coldIschemiaTime, setColdIschemiaTime] = useState()
  const [collectionTime, setCollectionTime] = useState(null)
  const [paramByNameLangTest, setParamByNameLangTest ] = useState()
  const [totalFixTime, setTotalFixTime] = useState()
  const [isDetect, setIsDetect] = useState()
  const [file, setFile] = useState([])
  const [currentDateTime, setCurrentDateTime] = useState();
  
  useEffect(() => {
    if(tissueCellResult) {
      setTissueCellResultTest(tissueCellResult)
    }
  },[tissueCellResult])

  const getTissueCellVal = (key) => {
    if (tissueCellResultTest && tissueCellResultTest[key]) {
      return tissueCellResultTest[key]; 
    }
    return null; 
  };
  useEffect(() => {
    if (data && data?.resultTestId) {
      onGetTissueCellResult(data.resultTestId, (data) => {
        if (data) {
          setIsDetect(!!data)
        }
      })
      
    }
  }, [data])

  useEffect(() => {
    if (data && data?.reportTypeName === 'MBH') {
      onGetParamByNameLang('L435')
    }
    else if (data && data?.reportTypeName === 'CELL') {
      onGetParamByNameLang('L436')
    }
    else if (data && data?.reportTypeName === 'FNA') {
      onGetParamByNameLang('L437')
    }
    else if (data && data?.reportTypeName === 'TBH') {
      onGetParamByNameLang('L438')
    }
    else if (data && data?.reportTypeName === 'STCL') {
      onGetParamByNameLang('L439')
    }
    else if (data && data?.reportTypeName === 'HMMD') {
      onGetParamByNameLang('L440')
    }
  }, [data])

  useEffect(() => {
    setParamByNameLangTest({ ...paramByNameLang })
  }, [paramByNameLang])

  const getItemByKey = (key) => {
    if (paramByNameLangTest && paramByNameLangTest[key]) {
      return paramByNameLangTest[key]; // Access the element by index
    }
    return null; 
  };

  const getCurrentConfig = () => {
    if (tissueCellResultTest && tissueCellResultTest["currentConfig"] != null) {
      if(tissueCellResultTest["currentConfig"] != ""){
        return tissueCellResultTest["currentConfig"];
      }
      else{
        let allKeys = Object.keys(paramByNameLang);
        let trueKeys = allKeys.filter(key => paramByNameLang[key] === true);
        return trueKeys.join('|');
      }
    }
    return null; 
  };

  useEffect(() => {
    let now = new Date();
    if(tissueCellResult && tissueCellResult["currentConfig"] != ""){
      let collectTime = new Date(data['collectiontime'])
      setDissectionTime(tissueCellResult["dissectionTime"])
      setFixTime(tissueCellResult["fixtime"])
      if(tissueCellResult['coldIschemiaTime']){
        setCollectionTime(collectTime)
      }
      else{
        setCollectionTime(null)
      }
      setTotalFixTime(tissueCellResult["totalFixTime"])
      setColdIschemiaTime(tissueCellResult["coldIschemiaTime"])
    }
    else{
      setCurrentDateTime(now);
      setDissectionTime(now);
      setFixTime(now);
      let collectTime = new Date(data['collectiontime'])
      setCollectionTime(collectTime);
      setColdIschemiaTime(minusTime(now,collectTime,t(`FTGreaterCT`)));
      setTotalFixTime(minusTime(now,now,t(`DTGreaterFT`)));
    }
  }, [tissueCellResult])

  const minusTime = (str_time1, str_time2, alert_str) => {
    let time1 = new Date(str_time1)
    let time2 = new Date(str_time2)
    if(time1>=time2){
      return ((time1 - time2) / 3600000).toFixed(2)
    }
    else{
      showWanringToast(alert_str);
      return null;
    }
  }

  const onSaveFile = async () => {
    const formData = new FormData()
    file.forEach((item) => {
      formData.append("files", item)
    })
  
    try {
      await postWithFormData(`${process.env.REACT_APP_LABORATORY_ENDPOINT}/global/files/upload-file/${data.resultTestId}`, formData)
    } catch (error) {

    }
  }

  const handleSubmit = (value) => {
    value.resultTestId = data?.resultTestId
    value.fileName = file.map(item => item.path)
    if(tissueCellResultTest){
      let curConfig = getCurrentConfig()
      if(curConfig.includes("DissectionTime") && fixTime > dissectionTime){
        showWanringToast(t(`DTGreaterFT`))
        return;
      }
    }else{
      if(getItemByKey("DissectionTime") && fixTime > dissectionTime){
        showWanringToast(t(`DTGreaterFT`))
        return;
      }
    }
    
    if(collectionTime > fixTime){
      showWanringToast(t(`FTGreaterCT`))
      return;
    }
    onUpdateTissueCellResult(value, () => {
      onSaveFile()
      onToggle()
    })
  }

  const onToggle = () => {
    toggle()
    onRefreshTissueCellResult(null)
    setTissueCellResultTest(null)
    setParamByNameLangTest(null)
    setIsDetect(null)
  }

  const handleFileChange = (file) => {
      setFile(file)
  }

  if(tissueCellResultTest){
    let curConfig = getCurrentConfig()
    return (
      <CustomModal
        modal={modal}
        title={title}
        onToggle={onToggle}
        size="xl"
      >
        <ModalBody>
          {(!isEmpty(tissueCellResultTest) || !isDetect) && <AvForm
            ref={formEl}
            id="tissueCellForm"
            onValidSubmit={(e, value) => {
              handleSubmit(value)
            }}
          >
            <AccordionWrapper defaultTab="1" className="px-2">
              <Accordion tabId={"1"} title={t('Description')}>
                <AccordionBody>
                  <CustomAvInput
                    type='text'
                    name={'id'}
                    className='d-none'
                    value={data?.resultTestId}
                  />
                  <Row >
                    <Col sm={3} style={{display: curConfig.includes("SamplePosition") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t(`Sample Position`)}<span className="text-danger">*</span></Label>
                    </Col>
                    <Col sm={9} style={{display: curConfig.includes("SamplePosition") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomAvField
                        name='samplePosition'
                        label=''
                        type = 'text'
                        value={tissueCellResultTest?.samplePosition || ''}
                        required={curConfig.includes("SamplePosition")}
                        {...isRequiredError("Sample Position", curConfig.includes("SamplePosition"), t)}
                        detected={isDetect}
                        disabled={data.state >= RESULT_STATE_Text.Confirmed}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={3} style={{ display: curConfig.includes("SampleDescription") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t(`Sample Description`)}</Label>
                    </Col>
                    <Col sm={9} style={{ display: curConfig.includes("SampleDescription") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomAvField
                        name='sampleDescription'
                        label=''
                        type = 'text'
                        value={tissueCellResultTest?.sampleDescription || ''}
                        detected={isDetect}
                        disabled={data.state >= RESULT_STATE_Text.Confirmed}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col sm={3} style={{ display: curConfig.includes("Method") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Method')}<span className="text-danger">*</span></Label>
                    </Col>
                    <Col sm={9} style={{ display: curConfig.includes("Method") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomAutoComplete
                        name={"method"}
                        value={tissueCellResultTest?.method || ''}
                        code={parameterCode.METHOD}
                        label={""}
                        required={curConfig.includes("Method")}
                        {...isRequiredError("Method", curConfig.includes("Method"), t)}
                        detected={isDetect}
                        readOnly={data.state >= RESULT_STATE_Text.Confirmed}
                        disabled={data.state >= RESULT_STATE_Text.Confirmed}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col sm={3} style={{ display: curConfig.includes("Technician") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Technician')}<span className="text-danger">*</span></Label>
                    </Col>
                    <Col sm={9} style={{ display: curConfig.includes("Technician") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomAutoComplete
                        name="technician"
                        value={tissueCellResultTest?.technician || ''}
                        code={parameterCode.USERS}
                        label={""}
                        isCommentTIGPB={true}
                        detected={isDetect}
                        required={curConfig.includes("Technician")}
                        readOnly={data.state >= RESULT_STATE_Text.Confirmed}
                        {...isRequiredError("Technician", curConfig.includes("Technician"), t)}
                        disabled={data.state >= RESULT_STATE_Text.Confirmed}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col sm={3} style={{ display: curConfig.includes("Quality") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Quality Test')}<span className="text-danger">*</span></Label>
                    </Col>
                    <Col sm={9} style={{ display: curConfig.includes("Quality") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomAutoComplete
                        name="quality"
                        value={tissueCellResultTest?.quality || '01'}
                        code={parameterCode.QUALITY}
                        label={""}
                        required={curConfig.includes("Quality")}
                        {...isRequiredError("Quality Test", curConfig.includes("Quality"), t)}
                        detected={isDetect}
                        readOnly={data.state >= RESULT_STATE_Text.Confirmed}
                        disabled={data.state >= RESULT_STATE_Text.Confirmed}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col sm={3} style={{ display: curConfig.includes("Fixative") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t(`Fixative`)}<span className="text-danger">*</span></Label>
                    </Col>
                    <Col sm={9} style={{ display: curConfig.includes("Fixative") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomAvField
                        name='fixative'
                        label=''
                        type = 'text'
                        value={tissueCellResultTest?.fixative || ''}
                        required={curConfig.includes("Fixative")}
                        {...isRequiredError("Fixative", curConfig.includes("Fixative"), t)}
                        detected={isDetect}
                        disabled={data.state >= RESULT_STATE_Text.Confirmed}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={3} style={{ display: curConfig.includes("Fixtime") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t(`Fixtime`)}<span className="text-danger">*</span></Label>
                    </Col>
                    <Col sm={9} style={{ display: curConfig.includes("Fixtime") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomDatePicker
                        name="Fixtime"
                        placeholder={"HH:mm DD/MM/YYYY"}
                        value={
                          convertDateFormat(fixTime || currentDateTime, 'HH:mm DD-MM-YYYY')
                        }
                        enableTime={true}
                        dateFormat={"H:i d-m-Y"}
                        detected={isDetect}
                        required={curConfig.includes("Fixtime")}
                        {...isRequiredError("Fixtime", curConfig.includes("Fixtime"), t)}
                        disabled={data.state >= RESULT_STATE_Text.Confirmed}
                        onChangeHandler={e => {
                          let value = (e.time[0])
                          let collectionTime2 = new Date(collectionTime)
                          let dissectionTime2 = new Date(dissectionTime)
                          setFixTime(value)
                          if(value >= collectionTime2){
                            setColdIschemiaTime(minusTime(value,collectionTime2,t(`FTGreaterCT`)))
                          }
                          if(value <= dissectionTime2 && curConfig.includes("DissectionTime")){
                            setTotalFixTime(minusTime(dissectionTime2,value,t(`FTSmallerDT`)))
                          }
                          if(value > dissectionTime2 && curConfig.includes("DissectionTime")){
                            showWanringToast(t(`FTSmallerDT`))
                            setTotalFixTime('')
                          }
                          if(value < collectionTime2){
                            showWanringToast(t(`FTGreaterCT`))
                            setColdIschemiaTime('')
                          }
                        }}
                      />
                    </Col>
                  </Row>
                </AccordionBody>
              </Accordion>
            </AccordionWrapper>
            <AccordionWrapper defaultTab="2">
              <Accordion tabId={"2"} title={t('Description Detail')}>
                <AccordionBody>
                <Row>
                    <Col sm={3} style={{ display: curConfig.includes("ColdIschemiaTime") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('ColdIschemiaTime')}<span className="text-danger">*</span></Label>
                    </Col>
                    <Col sm={9} style={{ display: curConfig.includes("ColdIschemiaTime") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomAvField
                        type='text'
                        label=''
                        value={coldIschemiaTime}
                        name='coldIschemiaTime'
                        detected={isDetect}
                        readOnly={true}
                        required={curConfig.includes("ColdIschemiaTime")}
                        {...isRequiredError("ColdIschemiaTime", curConfig.includes("ColdIschemiaTime"), t)}
                        disabled={data.state >= RESULT_STATE_Text.Confirmed}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col sm={3} style={{ display: curConfig.includes("Dissector") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Dissector')}<span className="text-danger">*</span></Label>
                    </Col>
                    <Col sm={9} style={{ display: curConfig.includes("Dissector") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomAvField
                        type='text'
                        value={tissueCellResultTest?.dissector || ''}
                        name='Dissector'
                        detected={isDetect}
                        label={""}
                        required={curConfig.includes("Dissector")}
                        {...isRequiredError("Dissector", curConfig.includes("Dissector"), t)}
                        disabled={data.state >= RESULT_STATE_Text.Confirmed}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col sm={3} style={{ display: curConfig.includes("DissectionTime") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('DissectionTime')}<span className="text-danger">*</span></Label>
                    </Col>
                    <Col sm={9} style={{ display: curConfig.includes("DissectionTime") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomDatePicker
                        name="DissectionTime"
                        placeholder={"HH:mm DD/MM/YYYY"}
                        value={
                          convertDateFormat(dissectionTime || currentDateTime, 'HH:mm DD-MM-YYYY')
                        }
                        enableTime={true}
                        dateFormat={"H:i d-m-Y"}
                        detected={isDetect}
                        required={curConfig.includes("DissectionTime")}
                        {...isRequiredError("DissectionTime", curConfig.includes("DissectionTime"), t)}
                        disabled={data.state >= RESULT_STATE_Text.Confirmed}
                        onChangeHandler={e => {
                          let value = (e.time[0])
                          let fixtime2 = new Date(fixTime)
                          setDissectionTime(value)
                          if(value >= fixtime2 && curConfig.includes("Fixtime")){
                            setTotalFixTime(minusTime(value,fixtime2,t(`DTGreaterFT`)))
                          }
                          else if (value < fixtime2 && curConfig.includes("Fixtime")){
                            showWanringToast(t(`DTGreaterFT`))
                            setTotalFixTime('')
                          }                          
                        }}
                      />
                    </Col>
                  </Row>
                  <Row >
                    <Col sm={3} style={{ display: curConfig.includes("TotalFixTime") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('TotalFixTime')}<span className="text-danger">*</span></Label>
                    </Col>
                    <Col sm={9} style={{ display: curConfig.includes("TotalFixTime") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomAvField
                        type='text'
                        label=''
                        name='totalFixTime'
                        value={totalFixTime}
                        detected={isDetect}
                        required={curConfig.includes("TotalFixTime")}
                        {...isRequiredError("TotalFixTime", curConfig.includes("TotalFixTime"), t)}
                        disabled={data.state >= RESULT_STATE_Text.Confirmed}
                        readOnly={true}
                      />
                    </Col>
                  </Row>
                  <Row >
                    <Col sm={3} style={{ display: curConfig.includes("NumberOfBlock") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('NumberOfBlock')}<span className="text-danger">*</span></Label>
                    </Col>
                    <Col sm={9} style={{ display: curConfig.includes("NumberOfBlock") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomAvField
                        type='number'
                        label=''
                        value={tissueCellResultTest?.numberOfBlock || 1}
                        min={1}
                        name='numberOfBlock'
                        detected={isDetect}
                        required={curConfig.includes("NumberOfBlock")}
                        {...isRequiredError("NumberOfBlock", curConfig.includes("NumberOfBlock"), t)}
                        disabled={data.state >= RESULT_STATE_Text.Confirmed}
                      />
                    </Col>
                  </Row>
                  <Row >
                    <Col sm={3} style={{ display: curConfig.includes("NumberOfSpecimen") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('NumberOfSpecimen')}<span className="text-danger">*</span></Label>
                    </Col>
                    <Col sm={9} style={{ display: curConfig.includes("NumberOfSpecimen") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomAvField
                        type='number'
                        label=''
                        name='numberOfSpecimen'
                        value={tissueCellResultTest?.numberOfSpecimen || 1}  
                        min={1}                    
                        detected={isDetect}
                        required={curConfig.includes("NumberOfSpecimen")}
                        {...isRequiredError("NumberOfSpecimen", curConfig.includes("NumberOfSpecimen"), t)}
                        disabled={data.state >= RESULT_STATE_Text.Confirmed}
                      />
                    </Col>
                  </Row>
                  <Row >
                    <Col sm={3} style={{ display: curConfig.includes("Macroscopic") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('MacroscopicComment')}</Label>
                    </Col>
                    <Col sm={9} style={{ display: curConfig.includes("Macroscopic") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomAvField
                        type='textarea'
                        label=''
                        value={tissueCellResultTest?.macroscopic || ''}
                        name='macroscopic'
                        detected={isDetect}
                        disabled={data.state >= RESULT_STATE_Text.Confirmed}
                      />
                    </Col>
                  </Row>
                  <Row >
                    <Col sm={3} style={{ display: curConfig.includes("CommentSample") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('CommentSample')}</Label>
                    </Col>
                    <Col sm={9} style={{ display: curConfig.includes("CommentSample") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomAvField
                        type='textarea'
                        label=''
                        value={tissueCellResultTest?.commentSample || ''}
                        name='commentSample'
                        disabled={data.state >= RESULT_STATE_Text.Confirmed}                      />
                    </Col>
                  </Row>
                  <Row >
                    <Col sm={3} style={{ display: curConfig.includes("Microscopic") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('MicroscopicComment')}<span className="text-danger">*</span></Label>
                    </Col>
                    <Col sm={9} style={{ display: curConfig.includes("Microscopic") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomAvField
                        type='textarea'
                        name='microscopic'
                        label=''
                        value={tissueCellResultTest?.microscopic || ''}             
                        detected={isDetect}
                        required={curConfig.includes("Microscopic")}
                        {...isRequiredError("Microscopic", curConfig.includes("Microscopic"), t)}
                        disabled={data.state >= RESULT_STATE_Text.Confirmed}
                      />
                    </Col>
                  </Row>
                  
                  <UploadImage8GPB bool_display={true}
                    state={data.state}
                    handleFileChange={handleFileChange}
                    resultTestId={data?.resultTestId} />
                </AccordionBody>
              </Accordion>
            </AccordionWrapper >
            <AccordionWrapper defaultTab="3">
              <Accordion tabId={"3"} title={t('Conclude')}>
                <AccordionBody>
                  <Row className="mb-3">
                    <Col sm={3} style={{ display: curConfig.includes("Comment") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Comment Result')}<span className="text-danger">*</span></Label>
                    </Col>
                    <Col sm={9} style={{ display: curConfig.includes("Comment") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomAutoComplete
                        name="comment"
                        value={tissueCellResultTest?.comment || '' }
                        code={parameterCode.COMMENT}
                        label={""}
                        isCommentTIGPB={true}
                        required={curConfig.includes("Comment")}
                        {...isRequiredError("Comment", curConfig.includes("Comment"), t)}
                        readOnly={data.state >= RESULT_STATE_Text.Confirmed}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col sm={3} style={{ display: curConfig.includes("ReportTime") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('ReportTime')}<span className="text-danger">*</span></Label>
                    </Col>
                    <Col sm={9} style={{ display: curConfig.includes("ReportTime") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomDatePicker
                        name="ReportTime"
                        placeholder={"HH:mm DD/MM/YYYY"}
                        value={
                          convertDateFormat(tissueCellResultTest?.reportTime || currentDateTime, 'HH:mm DD-MM-YYYY')
                        }
                        enableTime={true}
                        dateFormat={"H:i d-m-Y"}
                        detected={isDetect}
                        required={curConfig.includes("ReportTime")}
                        {...isRequiredError("ReportTime", curConfig.includes("ReportTime"), t)}
                        disabled={data.state >= RESULT_STATE_Text.Confirmed}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col sm={3} style={{ display: curConfig.includes("Reporter") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Reporter')}<span className="text-danger">*</span></Label>
                    </Col>
                    <Col sm={9} style={{ display: curConfig.includes("Reporter") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomAutoComplete
                        name="reporter"
                        type = 'text'
                        label=''
                        value={tissueCellResultTest?.reporter || ''}
                        code={parameterCode.USERS}
                        isReporterTIGPB={true}
                        detected={isDetect}
                        required={curConfig.includes("Reporter")}
                        {...isRequiredError("Reporter", curConfig.includes("Reporter"), t)}
                        readOnly={data.state >= RESULT_STATE_Text.Confirmed}
                        disabled={data.state >= RESULT_STATE_Text.Confirmed}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col sm={3} style={{ display: curConfig.includes("InfoReceiver") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('InfoReceiver')}<span className="text-danger">*</span></Label>
                    </Col>
                    <Col sm={9} style={{ display: curConfig.includes("InfoReceiver") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomAvField
                        name="infoReceiver"
                        type = 'text'
                        label=''
                        value={tissueCellResultTest?.infoReceiver || ''}
                        required={curConfig.includes("InfoReceiver")}
                        {...isRequiredError("InfoReceiver", curConfig.includes("InfoReceiver"), t)}
                        detected={isDetect}
                        disabled={data.state >= RESULT_STATE_Text.Confirmed}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col sm={3} style={{ display: curConfig.includes("Recommendations") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Recommendations')}<span className="text-danger">*</span></Label>
                    </Col>
                    <Col sm={9} style={{ display: curConfig.includes("Recommendations") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomAutoComplete
                        name="recommendations"
                        value={tissueCellResultTest?.recommendations || '01'}
                        code={parameterCode.RECOMMENT}
                        label={""}
                        required={curConfig.includes("Recommendations")}
                        {...isRequiredError("Recommendations", curConfig.includes("Recommendations"), t)}
                        detected={isDetect}
                        readOnly={data.state >= RESULT_STATE_Text.Confirmed}
                        disabled={data.state >= RESULT_STATE_Text.Confirmed}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col sm={3} style={{ display: curConfig.includes("PhysicianID") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Physician Result')}<span className="text-danger">*</span></Label>
                    </Col>
                    <Col sm={9} style={{ display: curConfig.includes("PhysicianID") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomAutoComplete
                        name="physicianId"
                        value={tissueCellResultTest?.physicianId || ''}
                        code={parameterCode.PHYSICIANS_PATHOLOGY}
                        label={""}
                        required={curConfig.includes("PhysicianID")}
                        {...isRequiredError("Physician Result", curConfig.includes("PhysicianID"), t)}
                        detected={isDetect}
                        readOnly={data.state >= RESULT_STATE_Text.Confirmed}
                        disabled={data.state >= RESULT_STATE_Text.Confirmed}
                      />
                    </Col>
                  </Row>
                </AccordionBody>
              </Accordion>
            </AccordionWrapper>
          </AvForm>}
        </ModalBody>
        <ModalFooter>
          <CustomButton
            text={t("common:Cancel")}
            type="button"
            onClick={onToggle}
            data-dismiss="modal"
            className="button-width"
          />
          <CustomButton
            text={t("common:Save")}
            type="submit"
            color="primary"
            onClick={() => formEl?.current?.submit()}
            disabled={data.state >= RESULT_STATE_Text.Confirmed}
            className="save-user button-width"
            isEdit
          />
        </ModalFooter>
      </CustomModal>
    )
  }
  
  else{
    return (
      <CustomModal
        modal={modal}
        title={title}
        onToggle={onToggle}
        size="xl"
      >
        <ModalBody>
          {(!isEmpty(tissueCellResultTest) || !isDetect) && <AvForm
            ref={formEl}
            id="tissueCellForm"
            onValidSubmit={(e, value) => {
              handleSubmit(value)
            }}
          >
            <AccordionWrapper defaultTab="1" className="px-2">
              <Accordion tabId={"1"} title={t('Description')}>
                <AccordionBody>
                  <CustomAvInput
                    type = 'text'
                    name={'id'}
                    className='d-none'
                    value={data?.resultTestId}
                  />
                  <Row >
                    <Col sm={3} style={{display: getItemByKey("SamplePosition") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t(`Sample Position`)}<span className="text-danger">*</span></Label>
                    </Col>
                    <Col sm={9} style={{display: getItemByKey("SamplePosition") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomAvField
                        type = 'text'
                        name='samplePosition'
                        label=''
                        value={tissueCellResultTest?.samplePosition || ''}
                        required={getItemByKey("SamplePosition")}
                        {...isRequiredError("Sample Position", getItemByKey("SamplePosition"), t)}
                        detected={isDetect}
                        disabled={data.state >= RESULT_STATE_Text.Confirmed}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={3} style={{ display: getItemByKey("SampleDescription") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t(`Sample Description`)}</Label>
                    </Col>
                    <Col sm={9} style={{ display: getItemByKey("SampleDescription") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomAvField
                        name='sampleDescription'
                        type = 'text'
                        label=''
                        value={tissueCellResultTest?.sampleDescription || ''}
                        detected={isDetect}
                        disabled={data.state >= RESULT_STATE_Text.Confirmed}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col sm={3} style={{ display: getItemByKey("Method") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Method')}<span className="text-danger">*</span></Label>
                    </Col>
                    <Col sm={9} style={{ display: getItemByKey("Method") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomAutoComplete
                        name={"method"}
                        value={tissueCellResultTest?.method || ''}
                        code={parameterCode.METHOD}
                        label={""}
                        required={getItemByKey("Method")}
                        {...isRequiredError("Method", getItemByKey("Method"), t)}
                        detected={isDetect}
                        
                        readOnly={data.state >= RESULT_STATE_Text.Confirmed}
                        disabled={data.state >= RESULT_STATE_Text.Confirmed}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col sm={3} style={{ display: getItemByKey("Technician") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Technician')}<span className="text-danger">*</span></Label>
                    </Col>
                    <Col sm={9} style={{ display: getItemByKey("Technician") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomAutoComplete
                        name="technician"
                        label={""}
                        value={tissueCellResultTest?.technician || ''}
                        code={parameterCode.USERS}
                        isCommentTIGPB={true}
                        detected={isDetect}
                        required={getItemByKey("Technician")}
                        
                        {...isRequiredError("Technician", getItemByKey("Technician"), t)}
                        readOnly={data.state >= RESULT_STATE_Text.Confirmed}
                        disabled={data.state >= RESULT_STATE_Text.Confirmed}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col sm={3} style={{ display: getItemByKey("Quality") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Quality Test')}<span className="text-danger">*</span></Label>
                    </Col>
                    <Col sm={9} style={{ display: getItemByKey("Quality") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomAutoComplete
                        name="quality"
                        value={tissueCellResultTest?.quality || '01'}
                        code={parameterCode.QUALITY}
                        label={""}
                        required={getItemByKey("Quality")}
                        {...isRequiredError("Quality Test", getItemByKey("Quality"), t)}
                        detected={isDetect}
                        readOnly={data.state >= RESULT_STATE_Text.Confirmed}
                        disabled={data.state >= RESULT_STATE_Text.Confirmed}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col sm={3} style={{ display: getItemByKey("Fixative") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t(`Fixative`)}<span className="text-danger">*</span></Label>
                    </Col>
                    <Col sm={9} style={{ display: getItemByKey("Fixative") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomAvField
                        name='fixative'
                        type = 'text'
                        label=''
                        value={tissueCellResultTest?.fixative || ''}
                        required={getItemByKey("Fixative")}
                        {...isRequiredError("Fixative", getItemByKey("Fixative"), t)}
                        detected={isDetect}
                        disabled={data.state >= RESULT_STATE_Text.Confirmed}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={3} style={{ display: getItemByKey("Fixtime") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t(`Fixtime`)}<span className="text-danger">*</span></Label>
                    </Col>
                    <Col sm={9} style={{ display: getItemByKey("Fixtime") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomDatePicker
                          name="Fixtime"
                          placeholder={"HH:mm DD/MM/YYYY"}
                          value={
                            convertDateFormat(fixTime, 'HH:mm DD-MM-YYYY')
                          }
                          enableTime={true}
                          dateFormat={"H:i d-m-Y"}
                          detected={isDetect}
                          required={getItemByKey("Fixtime")}
                          {...isRequiredError("Fixtime", getItemByKey("Fixtime"), t)}
                          disabled={data.state >= RESULT_STATE_Text.Confirmed}
                          onChangeHandler={e => {
                            let value = (e.time[0])
                            let collectionTime2 = new Date(collectionTime)
                            let dissectionTime2 = new Date(dissectionTime)
                            setFixTime(value)
                            if(value >= collectionTime2 ){
                              setColdIschemiaTime(minusTime(value,collectionTime2,t(`FTGreaterCT`)))
                            }
                            if(value <= dissectionTime2 && getItemByKey("DissectionTime")){
                              setTotalFixTime(minusTime(dissectionTime2,value,t(`FTSmallerDT`)))
                            }
                            if(value > dissectionTime2 && getItemByKey("DissectionTime")){
                              showWanringToast(t(`FTSmallerDT`))
                              setTotalFixTime('')
                            }
                            if(value < collectionTime2 ){
                              showWanringToast(t(`FTGreaterCT`))
                              setColdIschemiaTime('')
                            }
                          }}
                        />
                    </Col>
                  </Row>
                </AccordionBody>
              </Accordion>
            </AccordionWrapper>
            <AccordionWrapper defaultTab="2">
              <Accordion tabId={"2"} title={t('Description Detail')}>
                <AccordionBody>
                <Row>
                    <Col sm={3} style={{ display: getItemByKey("ColdIschemiaTime") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('ColdIschemiaTime')}<span className="text-danger">*</span></Label>
                    </Col>
                    <Col sm={9} style={{ display: getItemByKey("ColdIschemiaTime") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomAvField
                          type='text'
                          value={coldIschemiaTime}
                          name='coldIschemiaTime'
                          detected={isDetect}
                          label=''
                          required={getItemByKey("ColdIschemiaTime")}
                          {...isRequiredError("ColdIschemiaTime", getItemByKey("ColdIschemiaTime"), t)}
                          disabled={data.state >= RESULT_STATE_Text.Confirmed}
                          readOnly={true}
                        />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col sm={3} style={{ display: getItemByKey("Dissector") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Dissector')}<span className="text-danger">*</span></Label>
                    </Col>
                    <Col sm={9} style={{ display: getItemByKey("Dissector") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomAvField
                        type='text'
                        value={tissueCellResultTest?.dissector || ''}
                        name='Dissector'
                        detected={isDetect}
                        label={""}
                        required={getItemByKey("Dissector")}
                        {...isRequiredError("Dissector", getItemByKey("Dissector"), t)}
                        disabled={data.state >= RESULT_STATE_Text.Confirmed}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col sm={3} style={{ display: getItemByKey("DissectionTime") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('DissectionTime')}<span className="text-danger">*</span></Label>
                    </Col>
                    <Col sm={9} style={{ display: getItemByKey("DissectionTime") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomDatePicker
                          name="DissectionTime"
                          placeholder={"HH:mm DD/MM/YYYY"}
                          value={
                            convertDateFormat(dissectionTime, 'HH:mm DD-MM-YYYY')
                          }
                          enableTime={true}
                          dateFormat={"H:i d-m-Y"}
                          detected={isDetect}
                          required={getItemByKey("DissectionTime")}
                          {...isRequiredError("DissectionTime", getItemByKey("DissectionTime"), t)}
                          disabled={data.state >= RESULT_STATE_Text.Confirmed}
                          onChangeHandler={e => {
                            let value = (e.time[0])
                            let fixtime2 = new Date(fixTime)
                            setDissectionTime(value)
                            if(value >= fixtime2 && getItemByKey("Fixtime")){
                              setTotalFixTime(minusTime(value,fixtime2,t(`DTGreaterFT`)))
                            }
                            else if(value < fixtime2 && getItemByKey("Fixtime")){
                              showWanringToast(t(`DTGreaterFT`))
                              setTotalFixTime('')
                            }
                          }}
                        />
                    </Col>
                  </Row>
                  <Row >
                    <Col sm={3} style={{ display: getItemByKey("TotalFixTime") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('TotalFixTime')}<span className="text-danger">*</span></Label>
                    </Col>
                    <Col sm={9} style={{ display: getItemByKey("TotalFixTime") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomAvField
                        type='text'
                        name='totalFixTime'
                        value={totalFixTime}
                        detected={isDetect}
                        label=''
                        required={getItemByKey("TotalFixTime")}
                        {...isRequiredError("TotalFixTime", getItemByKey("TotalFixTime"), t)}
                        disabled={data.state >= RESULT_STATE_Text.Confirmed}
                        readOnly={true}
                      />
                    </Col>
                  </Row>
                  <Row >
                    <Col sm={3} style={{ display: getItemByKey("NumberOfBlock") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('NumberOfBlock')}<span className="text-danger">*</span></Label>
                    </Col>
                    <Col sm={9} style={{ display: getItemByKey("NumberOfBlock") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomAvField
                        type='number'
                        min={1}
                        value={tissueCellResultTest?.numberOfBlock || 1}
                        name='numberOfBlock'
                        detected={isDetect}
                        label=''
                        required={getItemByKey("NumberOfBlock")}
                        {...isRequiredError("NumberOfBlock", getItemByKey("NumberOfBlock"), t)}
                        disabled={data.state >= RESULT_STATE_Text.Confirmed}
                      />
                    </Col>
                  </Row>
                  <Row >
                    <Col sm={3} style={{ display: getItemByKey("NumberOfSpecimen") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('NumberOfSpecimen')}<span className="text-danger">*</span></Label>
                    </Col>
                    <Col sm={9} style={{ display: getItemByKey("NumberOfSpecimen") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomAvField
                        type='number'
                        name='numberOfSpecimen'
                        value={tissueCellResultTest?.numberOfSpecimen || 1}  
                        min={1}                    
                        detected={isDetect}
                        label=''
                        required={getItemByKey("NumberOfSpecimen")}
                        {...isRequiredError("NumberOfSpecimen", getItemByKey("NumberOfSpecimen"), t)}
                        disabled={data.state >= RESULT_STATE_Text.Confirmed}
                      />
                    </Col>
                  </Row>
                  <Row >
                    <Col sm={3} style={{ display: getItemByKey("Macroscopic") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('MacroscopicComment')}</Label>
                    </Col>
                    <Col sm={9} style={{ display: getItemByKey("Macroscopic") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomAvField
                        type='textarea'
                        label=''
                        value={tissueCellResultTest?.macroscopic || ''}
                        name='macroscopic'
                        detected={isDetect}
                        disabled={data.state >= RESULT_STATE_Text.Confirmed}
                      />
                    </Col>
                  </Row>
                  <Row >
                    <Col sm={3} style={{ display: getItemByKey("CommentSample") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('CommentSample')}</Label>
                    </Col>
                    <Col sm={9} style={{ display: getItemByKey("CommentSample") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomAvField
                        type='textarea'
                        label=''
                        value={tissueCellResultTest?.commentSample || ''}
                        name='commentSample'
                        readOnly={data.state >= RESULT_STATE_Text.Confirmed}                      />
                    </Col>
                  </Row>
                  <Row >
                    <Col sm={3} style={{ display: getItemByKey("Microscopic") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('MicroscopicComment')}<span className="text-danger">*</span></Label>
                    </Col>
                    <Col sm={9} style={{ display: getItemByKey("Microscopic") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomAvField
                        type='textarea'
                        name='microscopic'
                        label=''
                        value={tissueCellResultTest?.microscopic || ''}             
                        detected={isDetect}
                        required={getItemByKey("Microscopic")}
                        {...isRequiredError("Microscopic", getItemByKey("Microscopic"), t)}
                        disabled={data.state >= RESULT_STATE_Text.Confirmed}
                      />
                    </Col>
                  </Row>
                  <UploadImage8GPB bool_display={true}
                    state={data.state}
                    handleFileChange={handleFileChange}
                    resultTestId={data?.resultTestId} />
                </AccordionBody>
              </Accordion>
            </AccordionWrapper >
            <AccordionWrapper defaultTab="3">
              <Accordion tabId={"3"} title={t('Conclude')}>
                <AccordionBody>
                  <Row className="mb-3">
                    <Col sm={3} style={{ display: getItemByKey("Comment") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Comment Result')}<span className="text-danger">*</span></Label>
                    </Col>
                    <Col sm={9} style={{ display: getItemByKey("Comment") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomAutoComplete
                        name="comment"
                        value={tissueCellResultTest?.comment || ''}
                        code={parameterCode.COMMENT}
                        label={""}
                        isCommentTIGPB={true}
                        required={getItemByKey("Comment")}
                        {...isRequiredError("Comment", getItemByKey("Comment"), t)}
                        // readOnly={data.state >= RESULT_STATE_Text.Confirmed}
                        disabled={data.state >= RESULT_STATE_Text.Confirmed}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col sm={3} style={{ display: getItemByKey("ReportTime") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('ReportTime')}<span className="text-danger">*</span></Label>
                    </Col>
                    <Col sm={9} style={{ display: getItemByKey("ReportTime") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomDatePicker
                        name="ReportTime"
                        placeholder={"HH:mm DD/MM/YYYY"}
                        value={
                          convertDateFormat(tissueCellResultTest?.reportTime || currentDateTime, 'HH:mm DD-MM-YYYY')
                        }
                        enableTime={true}
                        dateFormat={"H:i d-m-Y"}
                        detected={isDetect}
                        required={getItemByKey("ReportTime")}
                        {...isRequiredError("ReportTime", getItemByKey("ReportTime"), t)}
                        disabled={data.state >= RESULT_STATE_Text.Confirmed}  
                        />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col sm={3} style={{ display: getItemByKey("Reporter") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Reporter')}<span className="text-danger">*</span></Label>
                    </Col>
                    <Col sm={9} style={{ display: getItemByKey("Reporter") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomAutoComplete
                        name="reporter"
                        type = 'text'
                        label=''
                        value={tissueCellResultTest?.reporter || ''}
                        detected={isDetect}
                        isReporterTIGPB={true}
                        code={parameterCode.USERS}
                        required={getItemByKey("Reporter")}
                        {...isRequiredError("Reporter", getItemByKey("Reporter"), t)}
                        readOnly={data.state >= RESULT_STATE_Text.Confirmed}
                        disabled={data.state >= RESULT_STATE_Text.Confirmed}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col sm={3} style={{ display: getItemByKey("InfoReceiver") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('InfoReceiver')}<span className="text-danger">*</span></Label>
                    </Col>
                    <Col sm={9} style={{ display: getItemByKey("InfoReceiver") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomAvField
                        name="infoReceiver"
                        type = 'text'
                        label=''
                        value={tissueCellResultTest?.infoReceiver || ''}
                        required={getItemByKey("InfoReceiver")}
                        {...isRequiredError("InfoReceiver", getItemByKey("InfoReceiver"), t)}
                        detected={isDetect}
                        disabled={data.state >= RESULT_STATE_Text.Confirmed}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col sm={3} style={{ display: getItemByKey("Recommendations") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Recommendations')}<span className="text-danger">*</span></Label>
                    </Col>
                    <Col sm={9} style={{ display: getItemByKey("Recommendations") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomAutoComplete
                        name="recommendations"
                        value={tissueCellResultTest?.recommendations || '01'}
                        code={parameterCode.RECOMMENT}
                        label={""}
                        required={getItemByKey("Recommendations")}
                        {...isRequiredError("Recommendations", getItemByKey("Recommendations"), t)}
                        detected={isDetect}
                        readOnly={data.state >= RESULT_STATE_Text.Confirmed}
                        disabled={data.state >= RESULT_STATE_Text.Confirmed}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col sm={3} style={{ display: getItemByKey("PhysicianID") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Physician Result')}<span className="text-danger">*</span></Label>
                    </Col>
                    <Col sm={9} style={{ display: getItemByKey("PhysicianID") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomAutoComplete
                        name="physicianId"
                        value={tissueCellResultTest?.physicianId || ''}
                        code={parameterCode.PHYSICIANS_PATHOLOGY}
                        label={""}
                        required={getItemByKey("PhysicianID")}
                        {...isRequiredError("Physician Result", getItemByKey("PhysicianID"), t)}
                        detected={isDetect}
                        readOnly={data.state >= RESULT_STATE_Text.Confirmed}
                        disabled={data.state >= RESULT_STATE_Text.Confirmed}
                      />
                    </Col>
                  </Row>
                </AccordionBody>
              </Accordion>
            </AccordionWrapper>
          </AvForm>}
        </ModalBody>
        <ModalFooter>
          <CustomButton
            text={t("common:Cancel")}
            type="button"
            onClick={onToggle}
            data-dismiss="modal"
            className="button-width"
          />
          <CustomButton
            text={t("common:Save")}
            type="submit"
            color="primary"
            onClick={() => formEl?.current?.submit()}
            disabled={data.state >= RESULT_STATE_Text.Confirmed}
            className="save-user button-width"
            isEdit
          />
        </ModalFooter>
      </CustomModal>
    )
  }
}

TissueCellResultModal.propTypes = {}

const mapStateToProps = ({ testResult }) => ({
  tissueCellResult: testResult.tissueCellResult,
  paramByNameLang: testResult.paramByNameLang,
  loading: testResult.loadingPatientResult,
})

const mapDispatchToProps = dispatch => ({
  onGetTissueCellResult: (payload, callback) => dispatch(getTissueCellResult(payload, callback)),
  onGetParamByNameLang: (payload) => dispatch(getParamByNameLang(payload)),
  onUpdateTissueCellResult: (payload, callback) => dispatch(updateTissueCellResult(payload, callback)),
  onRefreshTissueCellResult: payload => dispatch(getTissueCellResultSuccess(payload)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["testResultPage", "common"])(TissueCellResultModal))
