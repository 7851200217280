import {
    GET_LOT_TEST_QC_LIST,
    GET_LOT_TEST_QC_LIST_FAIL,
    GET_LOT_TEST_QC_LIST_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
    lottests: [],
    paging: {},
    loadingLottest: false,
    updatingLottest: true,
    updatedTime: new Date(),
    loadingLottest: {},
    searchQuery: {},
    errorLottest: {},
    error: {},
}

const CalculationQC = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_LOT_TEST_QC_LIST:
            return {
                ...state,
                error: {},
                loadingLottest: true,
            }
        case GET_LOT_TEST_QC_LIST_SUCCESS:
            const data = action.payload
            return {
                ...state,
                lottests: data,
                loadingLottest: false,
                updatedTime: new Date(),
            }
        case GET_LOT_TEST_QC_LIST_FAIL:
            return {
                ...state,
                error: action.payload,
                loadingLottest: false,
            }
        default:
            return state
    }
}
export default CalculationQC