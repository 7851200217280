import {
  ConfirmModal,
  TitleAndTable,
  WarningModal
} from "components/Common"
import { ModuleIds } from "constant"
import HeaderButtons from "./HeaderButtons"

import {
  convertDateFormat,
  insertUrlParam,
  onDelete,
  onDeleteToggle,
  selectCheckboxHandler
} from "helpers/utilities"

import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import { validResultExport } from "helpers/app-backend/valid_results_backend_helper"
import {
  addNewLotQC,
  addNewLotTestQC,
  deleteLotQC,
  getListLotQC,
  getLotQCDetail,
  updateLotQC,
} from "store/actions"
import ConfigLotQCTable from "./CalculationSigmaTETable"
import ConfigLotQCModal from "./Modals/ConfigLotQCModal"
import AddLotTestModal from "./Modals/AddLotTestModal"

const RESOURCE = ModuleIds.CalculationiQC

const CalculationSigmaTE = ({
  paging,
  onGetListLotQC,
  onGetQCLotDetail,
  onAddNewQCLot,
  onUpdateLotQC,
  onDeleteLotQC,
  loadingResult,
  updatedTime,
  lotQCs,
  lotQC,
  t,
  onAddNewLotTestQC
}) => {
  const [confirmModal, setConfirmModal] = useState(false)
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(true)
  const [isSelected, setIsSelected] = useState(false)
  const [row, setRow] = useState({})
  const [rowDelete, setRowDelete] = useState({})
  const [rows, setRows] = useState([])
  const [warningModal, setWarningModal] = useState(false)
  const [addLotTestModal, setAddLotTestModal] = useState(false)
  const formEl = useRef(null)
  const [model, setModel] = useState({
    search: "",
    page: 1,
    size: 10,
    fromDate: convertDateFormat(new Date(), "YYYY-MM-DD 00:00"),
    toDate: convertDateFormat(new Date(), "YYYY-MM-DD HH:mm"),
  })
  useEffect(() => {
    featchLotQC()
  }, [])
  const onGetLotQCList = payload => {
    insertUrlParam(payload)
    onGetListLotQC(payload)
  }

  const toggle = () => {
    setModal(prev => !prev)
  }

  const handleValidSubmit = (e, values) => {
    if (isEdit) {
      onUpdateLotQC({ lotQC: values, callback: afterUpdate })
    } else {
      onAddNewQCLot({ lotQC: values, callback: afterUpdate })
    }
  }

  const afterUpdate = () => {
    onRefreshHandler()
    toggle()
  }
  const resetState = () => {
    setRows([])
    setRow({})
  }

  const addNewLotQC = () => {
    setIsEdit(false)
    toggle()
  }
  const onEditHandler = (e, Id, param) => {
    const id = Id || row?.id
    if (id) {
      onGetQCLotDetail(id)
      setIsEdit(true)
      toggle()
    } else {
      setWarningModal(true)
    }
  }

  const onDeleteToggleHandler = (e, lotQC) => {
    onDeleteToggle({
      rows,
      row: lotQC || rowDelete,
      setConfirmModal,
      setWarningModal,
      setRowDelete,
    })
    if (confirmModal) {
      setRowDelete({})
    }
  }

  const onDeleteMultipleRows = rowsState => {
    onDeleteLotQC({ lotQC: rowsState, callback: resetState })
  }

  const onDeleteSingleRow = rowsState => {
    onDeleteLotQC({
      lotQC: rowsState,
      callback: () => {
        setRows(prev => prev.filter(x => x.id !== rowDelete.id))
      },
    })
    setRowDelete({})
    setRow({})
  }

  const onDeleteTestHandler = () => {
    onDelete({
      rowDelete,
      rows,
      onDeleteSingleRow,
      onDeleteMultipleRows,
    })
    setConfirmModal(false)
  }

  const ids = rows.map(item => item.id)
  const onSelectCheckbox = (row, isSelected) => {
    const { rowsState, currentRow } = selectCheckboxHandler(
      rows,
      row,
      isSelected
    )
    setRows(rowsState)
    setRow(currentRow)
  }

  const onSelectAllCheckbox = rows => {
    setRows(rows)
    if (rows.length < 1) setRow({})
    else setRow(rows[rows.length - 1])
  }

  const featchLotQC = () => {
    onGetListLotQC({
      ...model,
      page: 1,
    })
  }

  const onRefreshHandler = () => {
    resetState()
    featchLotQC()
  }

  const onSearch = searchText => {
    let val = {
      search: searchText || "",
    }
    setModel({ ...model, ...val })
    onGetLotQCList({ ...model, ...val, page: 1, size: 10 })
  }

  const onSizePerPageChange = size => {
    setModel({ ...model })
    onGetLotQCList({ ...model, page: 1, size })
  }

  const onPageChange = page => {
    setModel({ ...model })
    onGetLotQCList({ ...model, page })
  }

  const onSubmitFilter = values => {
    onGetLotQCList({ page: 1, ...values })
  }

  const onSortHandler = (field, order) => {
    const sortString = `${field}:${order}`
    onGetLotQCList({ page: 1, sort: sortString })
  }

  const onAnalysisProcessExport = async () => {
    let payload = { page: 1, sort: "createdDate:desc" }
    const fileData = await validResultExport({
      ...payload,
      ...searchQuery,
      page: payload.page,
      size: payload.size,
    })
    const blob = new Blob([fileData])
    saveAs(blob, "ExportValidResult.xlsx")
  }
  const onSubmitCalCulate = async (vals) => {
    // let data = ref.current?.onGetData();
    // if (data.data.length == 0) {
    //     setWarningModal(true)
    //     return;
    // }

    // let dt = {
    //     lotTestId: data.data.map(x => x.id),
    //     rules: vals
    // }

    // await updateQCLotTestSigma(dt)
    // showToast(
    //     `${t("message:UpdatedMessage")}`
    // )
}
  const onSubmitAddLotTest = (values) => {
    if ((values.qcInfo == undefined || values.qcInfo.length == 0) && (values.qcTextInfo == undefined || values.qcTextInfo.length == 0)) {
      showErrToast(t("Please input data level"))
      return;
    }
    onAddNewLotTestQC({
      data: values, callback: () => {
        setAddLotTestModal(false)
      }
    })
  }
  return (
    <React.Fragment>
      <TitleAndTable
        table={() => (
          <ConfigLotQCTable
            lotQCs={lotQCs}
            onSelect={onSelectCheckbox}
            onSelectAll={onSelectAllCheckbox}
            onSearch={onSearch}
            onSort={onSortHandler}
            onRefresh={onRefreshHandler}
            onPageChange={onPageChange}
            paging={paging}
            onSizePerPageChange={onSizePerPageChange}
            onSubmitFilter={onSubmitFilter}
            isSelected={isSelected}
            onEdit={onEditHandler}
            onDelete={onDeleteToggleHandler}
            loading={loadingResult}
            updatedTime={updatedTime}
            onSubmitCalCulate={onSubmitCalCulate}
            onAnalysisProcessExport={onAnalysisProcessExport}
            onChangeModel={val => {
              setModel({ ...model, ...val })
            }}
          />
        )}
        resource={RESOURCE}
        buttons={() => (
          <HeaderButtons
            resource={RESOURCE}
            onAdd={addNewLotQC}
            onDelete={onDeleteToggleHandler}
            isButtonEnabled={rows?.length === 1}
            onAddLotTest={() => setAddLotTestModal(true)}
          />
        )}
        external
        onEdit={onEditHandler}
        onDelete={onDeleteToggleHandler}
        onClone={() => { }}
        title={t("Cấu hình QC")}
        subtitle={t("Thông tin lô QC")}
      />

      <WarningModal
        modal={warningModal}
        onToggle={() => setWarningModal(prev => !prev)}
        message={t("message:SelectRowWarning")}
      />
      <ConfirmModal
        modal={confirmModal}
        title={`${t("common:Delete")} ${t("Lot")}`}
        message={t("message:DeleteConfirm")}
        onToggle={onDeleteToggleHandler}
        onDelete={onDeleteTestHandler}
      />
      <ConfigLotQCModal
        formEl={formEl}
        modal={modal}
        isEdit={isEdit}
        onValidSubmit={handleValidSubmit}
        toggle={toggle}
        data={!isEdit ? {} : lotQC}
      />
      <AddLotTestModal
        modal={addLotTestModal}
        toggle={() => { setAddLotTestModal(prev => !prev) }}
        data={row || {}}
        onValidSubmit={val => {
          onSubmitAddLotTest(val)
        }}
      />
    </React.Fragment>
  )
}

CalculationSigmaTE.propTypes = {
  lotQCs: PropTypes.array,
  lotQC: PropTypes.object,
  paging: PropTypes.object,
  loadingResult: PropTypes.bool,
  updatingResult: PropTypes.bool,
  updateResultTime: PropTypes.any,
  t: PropTypes.any,
  onAddNewQCLot: PropTypes.func,
  onGetListLotQC: PropTypes.func,
  onGetQCLotDetail: PropTypes.func,
  onUpdateLotQC: PropTypes.func,
  onDeleteLotQC: PropTypes.func,
}

const mapStateToProps = ({ lotQCs }) => {
  return {
    lotQCs: lotQCs.lotQCs,
    lotQC: lotQCs.lotQC,
    paging: lotQCs.paging,
    updatinglotQC: lotQCs.updatinglotQC,
    loadinglotQC: lotQCs.loadinglotQC,
    updatedTime: lotQCs.updatedTime,
  }
}

const mapDispatchToProps = dispatch => ({
  onAddNewQCLot: (lotQC, callback) => dispatch(addNewLotQC(lotQC, callback)),
  onGetListLotQC: payload => dispatch(getListLotQC(payload)),
  onGetQCLotDetail: payload => dispatch(getLotQCDetail(payload)),
  onUpdateLotQC: payload => dispatch(updateLotQC(payload)),
  onDeleteLotQC: payload => dispatch(deleteLotQC(payload)),
  onAddNewLotTestQC: payload => dispatch(addNewLotTestQC(payload)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation(["managementQCPage", "common"])(CalculationSigmaTE)))
