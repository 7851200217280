import React, { useRef, useState } from "react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"

import {
  ButtonDropdown,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap"

import { AvForm } from "availity-reactstrap-validation"

//i18n
import { withTranslation } from "react-i18next"

const FilterButton = ({ onReset, onSubmit, children, t, direction, className, disableToggle = false }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const formEl = useRef(null)
  const onToggle = () => {
    if (disableToggle == false || isMenuOpen == false) {
      setIsMenuOpen(prev => !prev)
    }
  }

  const onResetHandler = () => {
    formEl?.current.reset()
    onReset()
    setIsMenuOpen(prev => !prev)
  }

  const onSubmitHandler = (e, values) => {
    setIsMenuOpen(prev => !prev)
    onSubmit(e, values)
  }

  return (
    
    <ButtonDropdown
      direction={direction}
      className={className}
      isOpen={isMenuOpen}
      toggle={onToggle}
    >
      <DropdownToggle color="secondary" outline>
        <i className="fas fa-filter"></i>
      </DropdownToggle>
      {isMenuOpen && (
        <DropdownMenu
          data-popper-placement="left-start"
          className="dropdown-menu-lg drop-down-menu-custom"
        >
          <AvForm ref={formEl} onValidSubmit={onSubmitHandler}>
            <Card className="mb-0">
              <CardHeader className="bg-transparent">
                <div className="rightbar-title">
                  <Link
                    to="#"
                    onClick={()=>{setIsMenuOpen(prev => !prev)}}
                    className="right-bar-toggle float-end"
                  >
                    <i className="mdi mdi-close noti-icon" />
                  </Link>
                  <h5 className="m-0">{t("Quick Filter")}</h5>
                </div>
              </CardHeader>
              <hr className="my-0" />
              {/* CardBody */}
              <CardBody className="">{children}</CardBody>
              <hr className="my-0" />
              {/* CardFooter */}
              <CardFooter>
                <div className="d-flex">
                  <div className="d-inline">
                    <button
                      type="button"
                      onClick={()=>{setIsMenuOpen(prev => !prev)}}
                      className="btn btn-secondary mx-0 my-0"
                      style={{ minWidth: "84px" }}
                    >
                      {t("Cancel")}
                    </button>
                  </div>
                  <div className="text-md-right ms-auto align-self-center d-none d-md-block">
                    <Link
                      to="#"
                      onClick={onResetHandler}
                      className="text-primary"
                    >
                      {t("Clear All Filters")}
                    </Link>
                  </div>
                  <div className="text-md-right ms-auto">
                    <button
                      type="submit"
                      // onClick={toggle}
                      className="btn btn-primary mx-0 my-0"
                      style={{ minWidth: "84px" }}
                    >
                      {t("Apply")}
                    </button>
                  </div>
                </div>
                <div className="ms-auto text-center d-block d-sm-none">
                  <Link
                    to="#"
                    onClick={onResetHandler}
                    className="text-primary"
                  >
                    <span className="">{t("Clear All Filters")}</span>
                  </Link>
                </div>
              </CardFooter>
            </Card>
          </AvForm>
        </DropdownMenu>
      )}
    </ButtonDropdown>
  )
}

FilterButton.propTypes = {
  onReset: PropTypes.func,
  onSubmit: PropTypes.func,
  t: PropTypes.any,
  direction: PropTypes.any,
  className: PropTypes.any
}

FilterButton.defaultProps = {
  onReset: () => { },
  onSubmit: (e, values) => { },
  direction: 'left',
  className: 'btn-filter dropstart'
}

export default withTranslation(["common"])(FilterButton)
