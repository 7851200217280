import PropTypes from "prop-types"
import {
    CustomBreadcrumb,
    CustomNav,
    CustomNavLink,
    PageContent,
} from "components/Common"
import { ModuleIds, permissionType } from "constant"
import { Suspense, useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { Card, CardBody, TabContent, TabPane } from "reactstrap"
import ConfigLotQC from "./ConfigLotQC"
import ConfigLotTestQC from "./ConfigLotTestQC"
import { connect } from "react-redux"

const RESOURCE = ModuleIds.ManagementQC

const listScreen = [
  { value: ModuleIds.LotQC, key: "1" },
  { value: ModuleIds.LotTestQC, key: "2" },
]
const ManagementQCContainer = ({ t, userPermissions }) => {
  const titleTag = t(`Management QC`)
  const [currentTab, setCurrentTab] = useState("1")

  const checkPermission = (resource, permission) => {
    const userPermission = userPermissions[resource]?.permissions || []
    return userPermission.indexOf(permission) >= 0
  }

  const setScreenAccept = () => {
    for (let index = 0; index < listScreen.length; index++) {
      const element = listScreen[index]
      if (checkPermission(element.value, permissionType.R)) {
        setCurrentTab(element.key)
        return
      }
    }
  }
  useEffect(() => {
    setScreenAccept()
  }, [userPermissions])

  const renderTabContent = (tabId, Component) => (
    <TabPane tabId={tabId}>
      {currentTab === tabId &&
        checkPermission(
          listScreen.find(x => x.key == tabId).value,
          permissionType.R
        ) && (
          <Suspense fallback={<div>Loading...</div>}>
            <Component />
          </Suspense>
        )}
    </TabPane>
  )
  return (
        <PageContent title={titleTag} className="page-content-management-qc" style={{ height: window.devicePixelRatio * 100 + 'vh' }}>
      <CustomBreadcrumb
        breadcrumbItem={t("Management QC")}
        resource={RESOURCE}
      />
      <Card>
                <CardBody className="card-body-management-qc">
          <CustomNav
            onToggle={e => {
              setCurrentTab(e)
            }}
            defaultTab={"1"}
            tabs
            className="nav-tabs-custom"
            tabContents={customActiveTab => (
              <TabContent
                activeTab={customActiveTab}
                                className="p-0 text-muted nav-tabs-custom-management-qc"
              >
                                <TabPane tabId="1">
                                    {currentTab == "1" &&
                                        <ConfigLotQC />
                                    }
                                </TabPane>
                                <TabPane tabId="2">
                                    {currentTab == "2" &&
                                        <ConfigLotTestQC />
                                    }
                                </TabPane>
              </TabContent>
            )}
          >
            {checkPermission(ModuleIds.LotQC, permissionType.R) && (
              <CustomNavLink tabId="1">
                <span>{t("Config Lot QC")}</span>
              </CustomNavLink>
            )}
            {checkPermission(ModuleIds.LotTestQC, permissionType.R) && (
              <CustomNavLink tabId="2">
                <span>{t("Config Lot Test QC")}</span>
              </CustomNavLink>
            )}
          </CustomNav>
        </CardBody>
      </Card>
    </PageContent>
  )
}

ManagementQCContainer.propTypes = {
  t: PropTypes.any,
}

const mapStateToProps = ({ Authorization }) => {
    return {
        userPermissions: Authorization.permissions,
    }
}

export default connect(
    mapStateToProps,
    null
)(withTranslation(["managementQCPage", "message", "common"])(ManagementQCContainer))