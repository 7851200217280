import { AvForm } from "availity-reactstrap-validation"

import {
    CustomAvField,
    CustomAvInput,
    CustomButton,
    CustomDatePicker,
    CustomModal,
    CustomSelect,
    CustomSelectAsync,
    DatePicker,
} from "components/Common"
import { parameterCode } from "constant/utility"
import { useEffect, useState } from "react"
import { Col, Label, ModalBody, ModalFooter, Row } from "reactstrap"
import { convertDateFormat, getInvalidMessageI18n } from "helpers/utilities"
import { isEmpty } from "lodash"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import {
    cleanValidResultSamplesList,
    getValidResultSamplesList,
} from "store/analysis-process/validresults/actions"
import ChangeSIDModalTable from "./ChangeSIDModalTable"

const ChangeSIDQCModal = ({
    modal,
    toggle,
    isEdit,
    onValidSubmit,
    data,
    formEl,
    results,
    resultSamples,
    ongetValidResultSamplesList,
    oncleanValidResultSamplesList,
    pagingModalValid,
    isSID_QC,
    t,
}) => {

    const [model, setModel] = useState({
        currentSID: '',
        levelQC: '',
        lotIQC: '',
        sidChange: ''
    })

    const [levels, setLevels] = useState()

    const title = t("resultPage:Change SID iQC")
    if (isEdit && isEmpty(data)) return null;

    useEffect(() => {
        if (model.instrumentId) {
            ongetValidResultSamplesList(model)
        } else {
            oncleanValidResultSamplesList()
        }
    }, [model])

    useEffect(() => {
        if (model.lotIQC && model.levelQC) {
            setModel(prevModel => ({
                ...prevModel,
                sidChange: `${model.lotIQC}.${model.levelQC}`
            }));
        }
    }, [model.lotIQC, model.levelQC]);

    const onValidSubmitHandler = () => {
        let values = {
            currentSID: model.currentSID,
            lotIQC: model.lotIQC,
            levelQC: Number(model.levelQC),
            sidChange: model.sidChange,
        }
        onValidSubmit(model)
    }

    const generateOptions = (value) => {
        return Array.from({ length: value }, (_, i) => ({
            label: i + 1,
            value: i + 1,
        }));
    };

    const options = generateOptions(Number(levels));

    return (
        <CustomModal modal={modal} title={title} onToggle={toggle} size="lg">
            <ModalBody>
                <AvForm
                    ref={formEl}
                    id="sidModalForm"
                    onValidSubmit={onValidSubmitHandler}
                    model={model}
                >
                    <Row>
                        <Col lg={2} style={{ alignContent: 'center', paddingRight: 0 }}>
                            <Label for="currentSid" className="font-weight-semibold">
                                {t("resultPage:Current SID")}
                            </Label>
                        </Col>
                        <Col lg={10} style={{}}>
                            <div className="mb-1">
                                <CustomAvInput
                                    style={{ marginLeft: '18px', width: 'calc(97% + 3px' }}
                                    name={'currentSid'}
                                    type={'text'}
                                    placeholder={t("resultPage:Current SID")}
                                    onChange={e => {
                                        setModel({ ...model, currentSID: e.target.value || '' })
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={8} style={{ display: 'flex' }}>
                            <Col lg={2} style={{ alignContent: 'center' }}>
                                <Label for="lotIQC" className="font-weight-semibold">
                                    {t("resultPage:Lot iQC")}
                                </Label>
                            </Col>
                            <Col lg={10}>
                                <div className="mb-1" style={{ width: '80%', marginLeft: '66px' }}>
                                    <CustomSelectAsync
                                        name="lotIQC"
                                        code={parameterCode.QC_LOT_LIST_FROM_LOT_TEST}
                                        placeholder={t("common:Select Custom")}
                                        // required={true}
                                        // errorMessage={getInvalidMessageI18n(t, "Test")}
                                        onChange={(e, vals, data, values) => {
                                            setLevels(values[0]?.levels || '')
                                            setModel({ ...model, lotIQC: data || '' })
                                        }}
                                    />
                                </div>
                            </Col>
                        </Col>

                        <Col lg={4} style={{ display: 'flex' }}>

                            <Col lg={2} style={{ alignContent: 'center' }}>
                                <Label for="level" className="font-weight-semibold">
                                    {t("resultPage:Level")}
                                </Label>
                            </Col>
                            <Col lg={10}>
                                <div className="mb-1">
                                    <CustomSelectAsync
                                        name="level"
                                        options={options}
                                        placeholder={t("common:Select Custom")}
                                        onChange={(e, vals, data, values) => {
                                            setModel({ ...model, levelQC: data || '' })
                                        }}
                                    />
                                </div>
                            </Col>

                        </Col>
                    </Row>

                    <Row>
                        <Col lg={2} style={{ alignContent: 'center', paddingRight: 0 }}>
                            <Label for="sidChange" className="font-weight-semibold">
                                {t("resultPage:SID Change")}
                            </Label>
                        </Col>
                        <Col lg={10} style={{}}>
                            <div className="mb-1">
                                <CustomAvInput
                                    style={{ marginLeft: '18px', width: 'calc(97% + 3px' }}
                                    name={'sidChange'}
                                    placeholder={t("resultPage:SID Change")}
                                    value={model.sidChange}
                                    onChange={e => {
                                        setModel({ ...model, sidChange: e.target.value || '' })
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>
                </AvForm>
            </ModalBody>
            <ModalFooter>
                <CustomButton
                    text={t("common:Cancel")}
                    type="button"
                    onClick={toggle}
                    data-dismiss="modal"
                />

                <CustomButton
                    text={t("common:Save")}
                    type="submit"
                    color="primary"
                    onClick={() => formEl?.current?.submit()}
                    className="save-user"
                    isEdit
                />
            </ModalFooter>
        </CustomModal >
    )
}

const mapStateToProps = ({ validResult }) => {
    return ({
        results: validResult.results,
        resultSamples: validResult.resultSamples,
        pagingModalValid: validResult.pagingModalValid,
    })
}

const mapDispatchToProps = dispatch => ({
    ongetValidResultSamplesList: payload => dispatch(getValidResultSamplesList(payload)),
    oncleanValidResultSamplesList: payload => dispatch(cleanValidResultSamplesList(payload)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation(["resultPage", "message", "common"])(ChangeSIDQCModal)))