import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import PathologyCodeConfigTable from "./PathologyCodeConfigTable"

import {
    Check,
    ConfirmModal,
    CustomButton,
    TitleAndTable,
    WarningModal
} from "components/Common"
import {
    onDelete,
    onDeleteToggle,
    selectCheckboxHandler,
} from "helpers/utilities"


import { ModuleIds, permissionType } from "constant"
import { withTranslation } from "react-i18next"
import { addNewSttGPB, deleteSttGPBs, getSttGPBDetail, getSttGPBs, updateSttGPB } from "store/actions"
import PathologyCodeConfigModal from "./Modal/PathologyCodeConfigModal"

const RESOURCE = ModuleIds.PathologyCode

const PathologyCodeConfig = ({
    onGetSttGPBConfig,
    sttgpb,
    paging,
    updatingSttGPB,
    updatedTime,
    sttgpbs,
    loadingSttGPBs,
    onGetSttGPBConfigDetail,
    onCreateSttGPBConfig,
    onUpdateSttGPBConfig,
    onDeleteSttGPBConfig,
    t,
}) => {
    const [confirmModal, setConfirmModal] = useState(false)
    const [modal, setModal] = useState(false)
    const [isEdit, setIsEdit] = useState(true)
    const [row, setRow] = useState({})
    const [rowDelete, setRowDelete] = useState({})
    const [rows, setRows] = useState([])
    const [warningModal, setWarningModal] = useState(false)

    const onEditHandler = (e, testId, param) => {
        const id = testId || row?.id;
        if (id) {
            setRow(param)
            onGetSttGPBConfigDetail(id)

            setIsEdit(true)
            setModal(true)
        } else {
            setWarningModal(true)
        }
    }

    const resetState = () => {
        setRow({})
        setRows([])
    }


    const onDeleteToggleHandler = (e, test) => {
        onDeleteToggle({
            rows,
            row: test || rowDelete,
            setConfirmModal,
            setWarningModal,
            setRowDelete
        })
        if (confirmModal) {
            setRowDelete({})
        }
    }

    const onDeleteMultipleRows = rowsState => {
        onDeleteSttGPBConfig({
            sttGPB: rowsState, callback: () => {
                afterSubmit()
                resetState()
            }
        })
    }

    const onDeleteSingleRow = rowState => {
        onDeleteSttGPBConfig({
            sttGPB: rowState,
            callback: () => {
                afterSubmit()
                resetState()
            }
        })

        setRowDelete({})
        setRow({})
    }

    const onDeleteTestHandler = () => {
        onDelete({
            rowDelete,
            rows,
            onDeleteSingleRow,
            onDeleteMultipleRows,
        })
        // toggle modal
        setConfirmModal(false)
    }

    /**Get selected row and set to state
    *
    */
    const onSelectCheckbox = (row, isSelected) => {
        const { rowsState, currentRow } = selectCheckboxHandler(
            rows,
            row,
            isSelected
        )
        setRows(rowsState)
        setRow(currentRow)
    }

    const onSelectAllCheckbox = rows => {
        setRows(rows)
        if (rows.length < 1) setRow({})
        else setRow(rows[rows.length - 1])
    }

    const fetchTests = () => {
        onGetSttGPBConfig()
    }

    const onRefreshHandler = () => {
        fetchTests()
    }

    const onSearch = searchText => {
        onGetSttGPBConfig({ page: 1, search: searchText })
    }

    const onSizePerPageChange = size => {
        onGetSttGPBConfig({ page: 1, size })
    }

    const onPageChange = page => {
        onGetSttGPBConfig({ page })
    }

    const onSubmitFilter = values => {
        onGetSttGPBConfig({ page: 1, ...values })
    }

    const afterSubmit = () => {
        setModal(false)
        onRefreshHandler()
    }
    const onSubmit = (e, val) => {
        val.reportType = val.reportType.join('|')
        if (isEdit) {
            onUpdateSttGPBConfig({ sttGPB: val, callback: afterSubmit })
        } else {
            onCreateSttGPBConfig({ sttGPB: val, callback: afterSubmit })
        }
    }
    useEffect(() => {
        onRefreshHandler()
    }, [])

    return (
        <React.Fragment>
            <TitleAndTable
                table={() => (
                    <PathologyCodeConfigTable
                        dataConfigs={sttgpbs}
                        onSelect={onSelectCheckbox}
                        onSelectAll={onSelectAllCheckbox}
                        onSearch={onSearch}
                        onRefresh={onRefreshHandler}
                        onPageChange={onPageChange}
                        paging={paging}
                        onSizePerPageChange={onSizePerPageChange}
                        onSubmitFilter={onSubmitFilter}
                        onEdit={onEditHandler}
                        onDelete={onDeleteToggleHandler}
                        loading={loadingSttGPBs}
                        updatedTime={updatedTime}
                    />
                )}
                resource={RESOURCE}
                onEdit={onEditHandler}
                onDelete={onDeleteToggleHandler}
                isDisableClone
                isHideEdit
                isDropDown
                title={t("Pathology Code")}
                subtitle={t("Config Pathology Code")}
                buttons={() => (
                    <Check permission={permissionType.C} resource={RESOURCE}>
                        <CustomButton color="primary" onClick={() => {
                            setIsEdit(false)
                            setModal(true)
                        }} outline>
                            {t("common:Add")}
                        </CustomButton>
                    </Check>
                )}
            />
            <WarningModal
                modal={warningModal}
                onToggle={() => setWarningModal(prev => !prev)}
                message={t("message:SelectRowWarning")}
            />
            <ConfirmModal
                modal={confirmModal}
                title={`${t("common:Delete")}`}
                message={t("message:DeleteConfirm")}
                onToggle={onDeleteToggleHandler}
                onDelete={onDeleteTestHandler}
            />
            <PathologyCodeConfigModal
                modal={modal}
                isEdit={isEdit}
                onValidSubmit={(e, val) => { onSubmit(e, val) }}
                toggle={() => { setModal(!modal) }}
                data={!isEdit ? {} : sttgpb}
            />
        </React.Fragment>
    )
}

PathologyCodeConfig.propTypes = {
    t: PropTypes.any,
}

const mapStateToProps = ({ sttGPBConfig }) => ({
    sttgpb: sttGPBConfig.sttgpb,
    paging: sttGPBConfig.paging,
    loadingSttGPBs: sttGPBConfig.loadingSttGPBs,
    updatingSttGPB: sttGPBConfig.updatingSttGPB,
    updatedTime: sttGPBConfig.updatedTime,
    sttgpbs: sttGPBConfig.sttgpbs

})

const mapDispatchToProps = dispatch => ({
    onGetSttGPBConfig: payload => dispatch(getSttGPBs(payload)),
    onGetSttGPBConfigDetail: payload => dispatch(getSttGPBDetail(payload)),
    onCreateSttGPBConfig: payload => dispatch(addNewSttGPB(payload)),
    onUpdateSttGPBConfig: payload => dispatch(updateSttGPB(payload)),
    onDeleteSttGPBConfig: payload => dispatch(deleteSttGPBs(payload)),

})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation(["testPage", "testProfilePage", "message"])(PathologyCodeConfig)))