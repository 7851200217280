/* CAUSAL_ACTION_QCS */
export const GET_CAUSAL_ACTION_QC = "GET_CAUSAL_ACTION_QC"
export const GET_CAUSAL_ACTION_QC_SUCCESS = "GET_CAUSAL_ACTION_QC_SUCCESS"
export const GET_CAUSAL_ACTION_QC_FAIL = "GET_CAUSAL_ACTION_QC_FAIL"

/* SEARCH QUERY */
export const SET_CAUSAL_ACTION_QC_SEARCH_QUERY = "SET_CAUSAL_ACTION_QC_SEARCH_QUERY"

/* RESET SEARCH QUERY */
export const RESET_CAUSAL_ACTION_QC_SEARCH_QUERY = "RESET_CAUSAL_ACTION_QC_SEARCH_QUERY"

/* DETAIL */
export const GET_CAUSAL_ACTION_QC_DETAIL = "GET_CAUSAL_ACTION_QC_DETAIL"
export const GET_CAUSAL_ACTION_QC_DETAIL_SUCCESS = "GET_CAUSAL_ACTION_QC_DETAIL_SUCCESS"
export const GET_CAUSAL_ACTION_QC_DETAIL_FAIL = "GET_CAUSAL_ACTION_QC_DETAIL_FAIL"

/* ADD */
export const ADD_NEW_CAUSAL_ACTION_QC = "ADD_NEW_CAUSAL_ACTION_QC"
export const ADD_CAUSAL_ACTION_QC_SUCCESS = "ADD_CAUSAL_ACTION_QC_SUCCESS"
export const ADD_CAUSAL_ACTION_QC_FAIL = "ADD_CAUSAL_ACTION_QC_FAIL"

/* EDIT */
export const UPDATE_CAUSAL_ACTION_QC = "UPDATE_CAUSAL_ACTION_QC"
export const UPDATE_CAUSAL_ACTION_QC_SUCCESS = "UPDATE_CAUSAL_ACTION_QC_SUCCESS"
export const UPDATE_CAUSAL_ACTION_QC_FAIL = "UPDATE_CAUSAL_ACTION_QC_FAIL"

/* DELETE */
export const DELETE_CAUSAL_ACTION_QC = "DELETE_CAUSAL_ACTION_QC"
export const DELETE_CAUSAL_ACTION_QC_SUCCESS = "DELETE_CAUSAL_ACTION_QC_SUCCESS"
export const DELETE_CAUSAL_ACTION_QC_FAIL = "DELETE_CAUSAL_ACTION_QC_FAIL"


export const GET_FULL_CAUSAL_ACTION_QC = "GET_FULL_CAUSAL_ACTION_QC"
export const GET_FULL_CAUSAL_ACTION_QC_SUCCESS = "GET_FULL_CAUSAL_ACTION_QC_SUCCESS"
export const GET_FULL_CAUSAL_ACTION_QC_FAIL = "GET_FULL_CAUSAL_ACTION_QC_FAIL"




