import {
    CustomButton,
    CustomModal
} from "components/Common"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import ReactJson from 'react-json-view'
import { ModalBody, ModalFooter } from "reactstrap"

const HistoryQCResultPayloadModal = ({
    modal,
    toggle,
    message,
    t,
}) => {
    const title = 'Payload'

    const [dataDisplay, setDataDisplay] = useState('');
    const [isJson, setIsJson] = useState(false);

    useEffect(() => {
        let tmp = '';
        try {
            tmp = JSON.parse(message)
            setIsJson(true)
        } catch (e) {
            tmp = message || ''
            setIsJson(false)
        }
        setDataDisplay(tmp)
    }, [message])

    return (
        <React.Fragment>
            <CustomModal modal={modal} title={title} onToggle={toggle} size="lg">
                <ModalBody className="requestModal">
                    {isJson ?
                        <ReactJson src={dataDisplay} />
                        :
                        <span dangerouslySetInnerHTML={{ __html: dataDisplay }}></span>
                    }
                </ModalBody>
                <ModalFooter>
                    <CustomButton
                        text={t("common:Cancel")}
                        type="button"
                        // color="primary"
                        onClick={toggle}
                        data-dismiss="modal"
                        className="button-width"
                    />
                </ModalFooter>
            </CustomModal>
        </React.Fragment>
    )
}

HistoryQCResultPayloadModal.propTypes = {
}

export default withTranslation(["resultIQCPage", "common"])(HistoryQCResultPayloadModal)