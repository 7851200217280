import {
    GET_LIST_EXPRESSION_QC,
    GET_LIST_EXPRESSION_QC_FAIL,
    GET_LIST_EXPRESSION_QC_SUCCESS,
    GET_EXPRESSION_QC_DETAIL,
    GET_EXPRESSION_QC_DETAIL_FAIL,
    GET_EXPRESSION_QC_DETAIL_SUCCESS,
    ADD_NEW_EXPRESSION_QC,
    ADD_NEW_EXPRESSION_QC_FAIL,
    ADD_NEW_EXPRESSION_QC_SUCCESS,
    UPDATE_EXPRESSION_QC,
    UPDATE_EXPRESSION_QC_FAIL,
    UPDATE_EXPRESSION_QC_SUCCESS,
    DELETE_EXPRESSION_QC,
    DELETE_EXPRESSION_QC_FAIL,
    DELETE_EXPRESSION_QC_SUCCESS
} from "./actionTypes"

export const getListExpressionQC = payload => {
    return {
        type: GET_LIST_EXPRESSION_QC,
        payload: payload || {},
    }
}
export const getListExpressionQCSuccess = payload => ({
    type: GET_LIST_EXPRESSION_QC_SUCCESS,
    payload: payload,
})
export const getListExpressionQCFail = error => ({
    type: GET_LIST_EXPRESSION_QC_FAIL,
    payload: error,
})

export const getLotExpressionDetail = (id, callback) => ({
    type: GET_EXPRESSION_QC_DETAIL,
    id,
    callback,
})
export const getLotExpressionDetailSuccess = payload => ({
    type: GET_EXPRESSION_QC_DETAIL_SUCCESS,
    payload: payload,
})
export const getLotExpressionDetailFail = error => ({
    type: GET_EXPRESSION_QC_DETAIL_FAIL,
    payload: error,
})

export const addNewExpressionQC = payload => ({
    type: ADD_NEW_EXPRESSION_QC,
    payload: payload,
})
export const addNewExpressionQCSuccess = payload => ({
    type: ADD_NEW_EXPRESSION_QC_SUCCESS,
    payload: payload,
})
export const addNewExpressionQCFail = error => ({
    type: ADD_NEW_EXPRESSION_QC_FAIL,
    payload: error,
})

export const updateExpressionQC = payload => ({
    type: UPDATE_EXPRESSION_QC,
    payload: payload,
})
export const updateExpressionQCSuccess = payload => ({
    type: UPDATE_EXPRESSION_QC_SUCCESS,
    payload: payload,
})
export const updateExpressionQCFail = error => ({
    type: UPDATE_EXPRESSION_QC_FAIL,
    payload: error,
})

export const deleteExpessionQC = payload => ({
    type: DELETE_EXPRESSION_QC,
    payload: payload,
})
export const deleteExpessionQCSuccess = payload => ({
    type: DELETE_EXPRESSION_QC_SUCCESS,
    payload: payload,
})
export const deleteExpessionQCFail = error => ({
    type: DELETE_EXPRESSION_QC_FAIL,
    payload: error,
})
