import {
    TitleAndTable,
    WarningModal
} from "components/Common"
import { ModuleIds } from "constant"


import {
    insertUrlParam,
    selectCheckboxHandler
} from "helpers/utilities"

import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import { exportLottestQCs } from "helpers/app-backend/IQC/QC_Calculation_backend_helper"
import moment from "moment"
import { getLottestListQC, getLottestListQCSuccess, updateLottestCalculationQC } from "store/actions"
import ConfigLotQCTable from "./CalculationIndexTable"
import HeaderButtons from "./HeaderButtons"
import OptionApplyCalculationQCModal from "./Modal/OptionApplyCalculationQCModal"

const RESOURCE = ModuleIds.CalculationiQC

let lottestCalculates = []

const CalculationIndex = ({
    paging,
    onGetLotTestQCList,
    onUpdateLottestCalculationQC,
    onGetLottestListQCSuccess,
    loadingLottest,
    updatedTime,
    lottests,
    t,
}) => {
    const [rows, setRows] = useState([])
    const [warningModal, setWarningModal] = useState(false)
    const [optionApplyModal, setOptionApplyModal] = useState(false)
    const [config, setConfig] = useState({})
    const [model, setModel] = useState({
        search: ""
    })

    useEffect(() => {
        onGetLottestListQCSuccess([])
    }, [])

    const onGetLotQCList = payload => {
        onGetLotTestQCList(payload)
    }

    const resetState = () => {
        setRows([])
    }

    const onSelectCheckbox = (row, isSelected) => {
        if (isSelected) {
            rows.push(row)
            setRows(rows)
        } else {
            const newRows = rows.filter(item => item.id != row.id)
            setRows([...newRows])
        }
    }

    const featchLotQC = () => {
        onGetLotTestQCList({
            ...model,
            page: 1,
        })
    }

    const onRefreshHandler = () => {
        resetState()
        featchLotQC()
    }

    const onSearch = searchText => {
        let val = {
            search: searchText || "",
        }
        setModel({ ...model, ...val })
        onGetLotQCList({ ...model, ...val })
    }

    const onSubmitFilter = values => {
        onGetLotQCList({ page: 1, ...values })
    }

    const onSortHandler = (field, order) => {
        const sortString = `${field}:${order}`
        onGetLotQCList({ page: 1, sort: sortString })
    }

    const onLottestExport = async () => {
        const fileData = await exportLottestQCs({ ...model })
        const blob = new Blob([fileData])
        saveAs(blob, "LottestCalculation.xlsx")
    }
    const submitConfig = (values) => {
        setConfig(values)
    }

    const onSubmitOptionApply = (value) => {
        setOptionApplyModal(prev => !prev)
        value.fromDate = value.scope == "appliedRangeDate" ? moment(value.appliedRangeDate[0], 'DD-MM-YYYY').format("YYYY-MM-DD 00:00:00") : null
        value.toDate = value.scope == "appliedRangeDate" ? moment(value.appliedRangeDate[1], 'DD-MM-YYYY').format("YYYY-MM-DD 23:59:59") : null

        let lottestData = []
        let lottests = []

        if (rows.length > 0) {
            lottests = rows
        } else {
            lottests = lottestCalculates
        }

        lottests.forEach(item => {
            lottestData =
                [...lottestData,
                ...item.lottests.map(x => ({
                    id: x.id,
                    lotId: item.lotId,
                    testCode: item.testCode,
                    levels: x.levels,
                    insId: x.insId,
                    method: x.method,
                    mean: value.type == 'All' || value.type == 'Mean' ? x.mean : null,
                    sd: value.type == 'All' || value.type == 'SD' ? x.sd : null,
                    cv: value.type == 'All' || value.type == 'SD' ? x.cv : null,
                    calNum: value.scope == "calNum" ? value.calNum : null,
                    appliedRangeDate: value.scope == "appliedRangeDate" ? value?.appliedRangeDate?.join(' || ') : null
                }))]
        })

        const data = {
            ...value,
            ...model,
            lottestCalculates: lottestData
        }

        onUpdateLottestCalculationQC(data)
    }

    const onLottestChange = (lottests) => {
        lottestCalculates = lottests
    }

    const onCalculate = () => {
        if (config.isCV) {
            config.formulaCodeCV = config.cvCalculation
            if (config.lotQcs) {
                config.startCV = null
                config.endCV = null
            } else {
                config.lotIds = null
                config.startCV = moment(config?.cvDate[0], 'DD-MM-YYYY').format("YYYY-MM-DD 00:00:00")
                config.endCV = moment(config?.cvDate[1], 'DD-MM-YYYY').format("YYYY-MM-DD 00:00:00")
            }
        }

        if (config.isMean) {
            config.formulaCodeMean = config.meanCalculation
            config.startMean = moment(config?.meanDate[0], 'DD-MM-YYYY').format("YYYY-MM-DD 00:00:00")
            config.endMean = moment(config?.meanDate[1], 'DD-MM-YYYY').format("YYYY-MM-DD 00:00:00")
        }

        onGetLotTestQCList({ ...model, ...config })
    }

    return (
        <React.Fragment>
            <TitleAndTable
                table={() => (
                    <ConfigLotQCTable
                        lottests={lottests}
                        config={config}
                        onSelect={onSelectCheckbox}
                        onSearch={onSearch}
                        onSort={onSortHandler}
                        onRefresh={onRefreshHandler}
                        paging={paging}
                        onSubmitFilter={onSubmitFilter}
                        loading={loadingLottest}
                        updatedTime={updatedTime}
                        submitConfig={submitConfig}
                        onLottestExport={onLottestExport}
                        onLottestChange={onLottestChange}
                        onChangeModel={val => {
                            setModel({ ...model, ...val })
                        }}
                    />
                )}
                resource={RESOURCE}
                buttons={() => (
                    <HeaderButtons
                        disabledApply={lottests?.length <= 0}
                        resource={RESOURCE}
                        onApply={() => setOptionApplyModal(true)}
                        onCalculate={onCalculate}
                    />
                )}
                external
                title={t("CalculationTitle")}
                subtitle={t("Calculation List")}
            />

            <WarningModal
                modal={warningModal}
                onToggle={() => setWarningModal(prev => !prev)}
                message={t("message:SelectRowWarning")}
            />

            <OptionApplyCalculationQCModal
                modal={optionApplyModal}
                toggle={() => { setOptionApplyModal(prev => !prev) }}
                onValidSubmit={val => {
                    onSubmitOptionApply(val)
                }}
            />
        </React.Fragment>
    )
}

CalculationIndex.propTypes = {
    lottests: PropTypes.array,
    onGetLotTestQCList: PropTypes.func,
    onUpdateLottestCalculationQC: PropTypes.func,
    loadingLottest: PropTypes.bool,
    t: PropTypes.any,
}

const mapStateToProps = ({ calculationQC }) => ({
    lottests: calculationQC.lottests,
    loadingLottest: calculationQC.loadingLottest,
})

const mapDispatchToProps = dispatch => ({
    onGetLotTestQCList: payload => dispatch(getLottestListQC(payload)),
    onUpdateLottestCalculationQC: (payload, callback) => dispatch(updateLottestCalculationQC(payload, callback)),
    onGetLottestListQCSuccess: (payload) => dispatch(getLottestListQCSuccess(payload))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation(["calculationQCPage", "message", "common"])(CalculationIndex)))