import { Check, CustomButton } from "components/Common"
import { permissionType } from "constant"
import PropTypes from "prop-types"

import { withTranslation } from "react-i18next"

const HeaderButtons = ({ resource, onCalculate, t, onApply, disabledApply }) => {
  return (
    <>
      <Check permission={permissionType.C} resource={resource}>
        <CustomButton color="warning" onClick={onApply} disabled={disabledApply} outline>
          {t("Apply")}
        </CustomButton>
        <CustomButton color="primary" onClick={onCalculate} outline disabled={""}>
          {t("Calculate")}
        </CustomButton>
      </Check>
    </>
  )
}

HeaderButtons.propTypes = {
  resource: PropTypes.string.isRequired,
  onAddConfiglotQCModalClick: PropTypes.func.isRequired,
  onUpdateValidClick: PropTypes.func.isRequired,
  onUpdateInvalidClick: PropTypes.func.isRequired,
  // onDeleteClick: PropTypes.func.isRequired,
}

export default withTranslation(["calculationQCPage", "common"])(HeaderButtons)
