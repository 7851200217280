import { del, post } from "../api_helper2"

const BASE_API_URL = `${process.env.REACT_APP_INSTRUMENT_ENDPOINT}/qc/machines`

// Add machine
const createMachineQC = req => {
  return post(`${BASE_API_URL}`, req)
}

// Delete machine By Id Method
const deleteMachineQC = param => {
  return del(`${BASE_API_URL}?instrumentID=${param.instrumentID}`)
}
export {
  createMachineQC,
  deleteMachineQC
}

