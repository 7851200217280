import {
  GET_QC_DATAPOINTS,
  GET_QC_DATAPOINTS_FAIL,
  GET_QC_DATAPOINTS_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  datapoints: [],
  loadingDatapoints: false,
  error: null,
}

const qcDatapoints = (state = INIT_STATE, action) => {
  switch (action.type) {
    // case GET_QC_DATAPOINTS:
    //   return {
    //     ...state,
    //     error: null, 
    //     loadingDatapoints: true,
    //   }

    // case GET_QC_DATAPOINTS_SUCCESS:
    //   const { data } = action.payload
    //   return {
    //     ...state,
    //     datapoints: data,
    //     loadingDatapoints: false,
    //   }

    // case GET_QC_DATAPOINTS_FAIL:
    //   return {
    //     ...state,
    //     error: action.payload,
    //     loadingDatapoints: false,
    //   }
    
    case GET_QC_DATAPOINTS_SUCCESS:
      return {
          ...state,
          datapoints: action.payload,
          updateNotifyTime: new Date(),
          loadingDatapoints: false
      }
    case GET_QC_DATAPOINTS_FAIL:
      return {
          ...state,
      }
  default:
      return state
  }
}

export default qcDatapoints
