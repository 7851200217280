import {
    ADD_NEW_CAUSAL_ACTION_QC,
    ADD_CAUSAL_ACTION_QC_FAIL,
    ADD_CAUSAL_ACTION_QC_SUCCESS,
    DELETE_CAUSAL_ACTION_QC_FAIL,
    DELETE_CAUSAL_ACTION_QC_SUCCESS,
    GET_FULL_CAUSAL_ACTION_QC,
    GET_FULL_CAUSAL_ACTION_QC_FAIL,
    GET_FULL_CAUSAL_ACTION_QC_SUCCESS,
    GET_CAUSAL_ACTION_QC_DETAIL,
    GET_CAUSAL_ACTION_QC_DETAIL_FAIL,
    GET_CAUSAL_ACTION_QC_DETAIL_SUCCESS,
    RESET_CAUSAL_ACTION_QC_SEARCH_QUERY,
    SET_CAUSAL_ACTION_QC_SEARCH_QUERY,
    UPDATE_CAUSAL_ACTION_QC,
    UPDATE_CAUSAL_ACTION_QC_FAIL,
    UPDATE_CAUSAL_ACTION_QC_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
    causalActionQCs: [],
    paging: {},
    loadingCausalActionQCs: false,
    updatingCausalActionQC: true,
    updatedTime: new Date(),
    causalActionQC: {},
    searchQuery: {},
    errorCausalActionQC: {},
    error: {},
    treeViewItem: {},
    position: {}
}

const CausalActionQC = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_CAUSAL_ACTION_QC_DETAIL:
            return {
                ...state,
                causalActionQC: {},
                error: {},
                updatingCausalActionQC: true,
            }

        case GET_CAUSAL_ACTION_QC_DETAIL_SUCCESS:
            const causalActionQC = action.payload
            return {
                ...state,
                causalActionQC: causalActionQC,
                updatingCausalActionQC: false,
            }

        case GET_CAUSAL_ACTION_QC_DETAIL_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        // case GET_CAUSAL_ACTION_QC:
        //     return {
        //         ...state,
        //         //causalActionQCs: [],
        //         error: {},
        //         loadingCausalActionQCs: true,
        //     }

        // case GET_CAUSAL_ACTION_QC_SUCCESS:
        //     return {
        //         ...state,
        //         causalActionQC: action.payload,
        //         paging: { dataSize: action.payload.length },
        //         loadingCausalActionQCs: false,
        //         updatedTime: new Date(),
        //     }

        // case GET_CAUSAL_ACTION_QC_FAIL:
        //     return {
        //         ...state,
        //         error: action.payload,
        //         loadingCausalActionQCs: false,
        //     }
        case ADD_NEW_CAUSAL_ACTION_QC:
            return {
                ...state,
                updatingCausalActionQC: true,
                errorCode: {},
            }
        case ADD_CAUSAL_ACTION_QC_SUCCESS:
            return {
                ...state,
                causalActionQC: action.payload
            }

        case ADD_CAUSAL_ACTION_QC_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        case UPDATE_CAUSAL_ACTION_QC:
            return {
                ...state,
                updatingCausalActionQC: true,
                error: {},
            }

        case UPDATE_CAUSAL_ACTION_QC_SUCCESS:
            return {
                ...state,
                causalActionQCs: state.causalActionQCs,
                updatingCausalActionQC: false,
            }

        case UPDATE_CAUSAL_ACTION_QC_FAIL:
            return {
                ...state,
                error: action.payload,
                updatingCausalActionQC: false,
            }

        case DELETE_CAUSAL_ACTION_QC_SUCCESS:
            const payload = action.payload
            const causalActionQCsUpdate = state.causalActionQCs.filter(
                _causalActionQC => payload.findIndex(_payload => _causalActionQC.id === _payload.id) < 0
            )

            const statePaging = { ...state.paging }
            statePaging.dataSize = statePaging.dataSize - payload.length
            if (statePaging.dataSize < 0) statePaging = 0

            return {
                ...state,
                causalActionQCs: causalActionQCsUpdate,
                paging: statePaging,
            }

        case DELETE_CAUSAL_ACTION_QC_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        case SET_CAUSAL_ACTION_QC_SEARCH_QUERY:
            return {
                ...state,
                searchQuery: { ...action.payload },
            }
        case RESET_CAUSAL_ACTION_QC_SEARCH_QUERY:
            return {
                ...state,
                searchQuery: {},
            }
        case GET_FULL_CAUSAL_ACTION_QC:
            return {
                ...state,
                //causalActionQCs: [],
                error: {},
                loadingCausalActionQCs: true,
            }

        case GET_FULL_CAUSAL_ACTION_QC_SUCCESS:
            const { data, totalElements, ...rest } = action.payload
            return {
                ...state,
                causalActionQCs: data,
                paging: { ...rest, dataSize: totalElements },
                loadingCausalActionQCs: false,
                updatedTime: new Date(),
            }

        case GET_FULL_CAUSAL_ACTION_QC_FAIL:
            return {
                ...state,
                error: action.payload,
                loadingCausalActionQCs: false,
            }
        default:
            return state
    }
}

export default CausalActionQC