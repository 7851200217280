import { Check, CustomButton } from "components/Common"
import { permissionType } from "constant"
import PropTypes from "prop-types"

import { withTranslation } from "react-i18next"
const HeaderButtons = ({ resource, onAdd, t, isButtonEnabled, onAddLotTest }) => {
  return (
    <>
      <Check permission={permissionType.C} resource={resource}>
        <CustomButton color="warning" onClick={onAddLotTest} outline disabled={!isButtonEnabled}>
          {t("Apply")}
        </CustomButton>
        <CustomButton color="primary" onClick={onAdd} outline disabled={""}>
          {t("Calculate")}
        </CustomButton>
      </Check>
    </>
  )
}

HeaderButtons.propTypes = {
  resource: PropTypes.string.isRequired,
  onAddConfiglotQCModalClick: PropTypes.func.isRequired,
  onUpdateValidClick: PropTypes.func.isRequired,
  onUpdateInvalidClick: PropTypes.func.isRequired,
  // onDeleteClick: PropTypes.func.isRequired,
}

export default withTranslation(["managementQCPage", "common"])(HeaderButtons)
