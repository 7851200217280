
import { withTranslation } from "react-i18next";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { CustomAvField, CustomDatePicker, CustomSelect } from "components/Common";
import { parameterCode } from "constant";
import { AvForm } from "availity-reactstrap-validation";
import { convertDateFormat, getInvalidMessageI18n } from "helpers/utilities";
import { getCurrentReportNumber } from "helpers/app-backend";

const KPModal = ({
    modal,
    toggle,
    isEdit,
    t,
    data = {},
    reportCode,
    oldData
}) => {
    const [actions, setActions] = useState([])

    return (
        <React.Fragment>
            <div className="report-result-qc-title pt-2 mb-2">
                <div className="report-result-qc-title-content" style={{ flex: 1 }}>
                    <div className="report-result-qc-title-content-left">{t("Report With")}:</div>
                    <div>{data.testCode} - {data.testName}</div>
                </div>
                <div className="report-result-qc-title-content">
                    <div className="report-result-qc-title-content-left">{t("Machine")}:</div>
                    <div>{data.machineName}</div>
                </div>
                <div className="report-result-qc-title-content">
                    <div className="report-result-qc-title-content-left">{t("Lot")}:</div>
                    <div>{data.lotId}</div>
                </div>
                <div className="report-result-qc-title-content">
                    <div className="report-result-qc-title-content-left">{t("Level")}: </div>
                    <div>{data.levels}</div>
                </div>
                <div className="report-result-qc-title-content">
                    <div className="report-result-qc-title-content-left">{t("Single Rule")}:</div>
                    <div>{data.rule}</div>
                </div>
                <div className="report-result-qc-title-content" style={{ width: '225px' }}>
                    <div className="report-result-qc-title-content-left">{t("Report Code")}:</div>
                    <div>{data.reportCode || reportCode}</div>
                </div>
            </div>
            <div className="report-result-qc-title-content-left mb-2">{t("Action KP")}</div>

            <div className="row">
                <div className="col-md-6 d-flex mb-3">
                    <div className="report-result-qc-title-content-input">{t("Action")}</div>
                    <div className="flex-1">
                        <CustomSelect
                            required
                            readOnly={isEdit}
                            errorMessage={getInvalidMessageI18n(t, "Action")}
                            name="action"
                            value={oldData.action || []}
                            isMulti={true}
                            code={parameterCode.QC_RESULT_RULE_ACTIONS}
                            label={""}
                            onChange={(e, vals) => {
                                setActions(vals)
                            }}
                        />
                    </div>
                </div>
                <div className="col-12 d-flex mb-3">
                    <div className="report-result-qc-title-content-input">{t("Improve System")}</div>
                    <div className="flex-1">
                        <CustomAvField
                            name="improve"
                            value={oldData.improve || ""}
                            label={""}
                            type="textarea"
                            rows="3"
                            disabled={isEdit}
                        />
                    </div>
                </div>
                <div className="col-md-6 d-flex mb-3">
                    <div className="report-result-qc-title-content-input">{t("Discoverer")}</div>
                    <div className="flex-1">
                        <CustomSelect
                            // required
                            readOnly={isEdit}
                            // errorMessage={getInvalidMessageI18n(t, "Discoverer")}
                            name="discoverer"
                            value={oldData.discoverer || ""}
                            code={parameterCode.USERS}
                            label={""}
                            onChange={(e, vals) => {
                                // setReportTypes(vals)
                            }}
                        />
                    </div>
                </div>
                <div className="col-md-6 d-flex mb-3">
                    <div className="report-result-qc-title-content-input">{t("Check Result")}</div>
                    <div className="flex-1">
                        <CustomSelect
                            // required
                            readOnly={isEdit}
                            // errorMessage={getInvalidMessageI18n(t, "Check Result")}
                            name="result"
                            value={oldData.result || ""}
                            code={parameterCode.QC_RESULT_RULE_CHECK_RESULT}
                            label={""}
                            onChange={(e, vals) => {
                                // setReportTypes(vals)
                            }}
                        />
                    </div>
                </div>
                <div className="col-12 d-flex">
                    <div className="report-result-qc-title-content-input">{t("Remark")}</div>
                    <div className="flex-1">
                        <CustomAvField
                            name="remark"
                            value={oldData.remark || ""}
                            label={""}
                            type="textarea"
                            rows="3"
                            disabled={isEdit}
                        />
                    </div>
                </div>
            </div>
        </React.Fragment >
    )
}

KPModal.defaultProps = {}

const mapStateToProps = ({ testResult }) => ({
})

const mapDispatchToProps = dispatch => ({
    // onGetNotifications: payload => dispatch(getNotifications(payload)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation(["resultIQCPage", "common"])(KPModal))
