/* WESTGARD_SIGMA_QCS */
export const GET_WESTGARD_SIGMA_QC = "GET_WESTGARD_SIGMA_QC"
export const GET_WESTGARD_SIGMA_QC_SUCCESS = "GET_WESTGARD_SIGMA_QC_SUCCESS"
export const GET_WESTGARD_SIGMA_QC_FAIL = "GET_WESTGARD_SIGMA_QC_FAIL"

/* SEARCH QUERY */
export const SET_WESTGARD_SIGMA_QC_SEARCH_QUERY = "SET_WESTGARD_SIGMA_QC_SEARCH_QUERY"

/* RESET SEARCH QUERY */
export const RESET_WESTGARD_SIGMA_QC_SEARCH_QUERY = "RESET_WESTGARD_SIGMA_QC_SEARCH_QUERY"

/* DETAIL */
export const GET_WESTGARD_SIGMA_QC_DETAIL = "GET_WESTGARD_SIGMA_QC_DETAIL"
export const GET_WESTGARD_SIGMA_QC_DETAIL_SUCCESS = "GET_WESTGARD_SIGMA_QC_DETAIL_SUCCESS"
export const GET_WESTGARD_SIGMA_QC_DETAIL_FAIL = "GET_WESTGARD_SIGMA_QC_DETAIL_FAIL"

/* ADD */
export const ADD_NEW_WESTGARD_SIGMA_QC = "ADD_NEW_WESTGARD_SIGMA_QC"
export const ADD_WESTGARD_SIGMA_QC_SUCCESS = "ADD_WESTGARD_SIGMA_QC_SUCCESS"
export const ADD_WESTGARD_SIGMA_QC_FAIL = "ADD_WESTGARD_SIGMA_QC_FAIL"

/* EDIT */
export const UPDATE_WESTGARD_SIGMA_QC = "UPDATE_WESTGARD_SIGMA_QC"
export const UPDATE_WESTGARD_SIGMA_QC_SUCCESS = "UPDATE_WESTGARD_SIGMA_QC_SUCCESS"
export const UPDATE_WESTGARD_SIGMA_QC_FAIL = "UPDATE_WESTGARD_SIGMA_QC_FAIL"

/* DELETE */
export const DELETE_WESTGARD_SIGMA_QC = "DELETE_WESTGARD_SIGMA_QC"
export const DELETE_WESTGARD_SIGMA_QC_SUCCESS = "DELETE_WESTGARD_SIGMA_QC_SUCCESS"
export const DELETE_WESTGARD_SIGMA_QC_FAIL = "DELETE_WESTGARD_SIGMA_QC_FAIL"


export const GET_FULL_WESTGARD_SIGMA_QC = "GET_FULL_WESTGARD_SIGMA_QC"
export const GET_FULL_WESTGARD_SIGMA_QC_SUCCESS = "GET_FULL_WESTGARD_SIGMA_QC_SUCCESS"
export const GET_FULL_WESTGARD_SIGMA_QC_FAIL = "GET_FULL_WESTGARD_SIGMA_QC_FAIL"




