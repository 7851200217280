import { AvForm } from "availity-reactstrap-validation"

import { ModalBody, ModalFooter } from "reactstrap"

import {
    Accordion,
    AccordionBody,
    AccordionWrapper,
    CustomAvField,
    CustomButton,
    CustomModal,
    CustomSelectAsync
} from "components/Common"

//i18n
import React, { useRef } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { parameterCode } from "constant"
import { isEmpty } from "lodash"
import { convertDateFormat } from "helpers/utilities"

const LotTestModal = ({
    modal,
    toggle,
    data,
    t,
    onValidSubmit,
}) => {
    const formEl = useRef()
    if (isEmpty(data)) return null;
    return (
        <React.Fragment>
            <CustomModal modal={modal} title={t("Add Lot Test - Quantitative")} onToggle={toggle} size="xl">
                <ModalBody>
                    <AvForm
                        ref={formEl}
                        id="quantitative"
                        onValidSubmit={(e, vals) => {
                            let tmp = {
                                testCode: vals.testCode,
                                temperature: vals.temperature,
                                tea: vals.tea,
                                method: vals.method || '',
                                machine: data.machine || '',
                                lotId: data.lotId,
                                machine: data.machine,
                                isPosNeg: true,
                                qcInfo: []
                            }
                            if (vals.meanPI && vals.meanPI != '' && vals.sdpi && vals.sdpi != '') {
                                tmp.qcInfo.push({
                                    level: 1, meanPI: vals.meanPI, sdpi: vals.sdpi, deCOdd: vals.deCOdd
                                })
                            }
                            if (vals.meanPI2 && vals.meanPI2 != '' && vals.sdpi2 && vals.sdpi2 != '') {
                                tmp.qcInfo.push({
                                    level: 2, meanPI: vals.meanPI2, sdpi: vals.sdpi2, deCOdd: vals.deCOdd2
                                })
                            }
                            if (vals.meanPI3 && vals.meanPI3 != '' && vals.sdpi3 && vals.sdpi3 != '') {
                                tmp.qcInfo.push({
                                    level: 3, meanPI: vals.meanPI3, sdpi: vals.sdpi3, deCOdd: vals.deCOdd3
                                })
                            }
                            onValidSubmit && onValidSubmit(tmp)
                        }}
                        model={data}
                    >
                        <div className="row">
                            <div className="col-12">
                                <AccordionWrapper defaultTab={["1"]}>
                                    <Accordion tabId={"1"} title={t("Lot Test Infomation")}>
                                        <AccordionBody className="accordion-body px-0 pb-0">
                                            <div className="row">
                                                <div className="col-12 mb-3 modal-add-lot-test-title">
                                                    {t("Lot")}:
                                                    <span>{data.lotId} - {data.lotName} - {t("Level")}: {data.levels}, {t("Expiration")}: {convertDateFormat(data.expireDate, "HH:mm DD-MM-YYYY")},</span>
                                                    {t("Machine")}: {data.machineName}
                                                </div>
                                                <div className="col-12 mb-3">
                                                    {/* <CustomSelectAsync
                                                        name="testCode"
                                                        value={""}
                                                        valueName={""}
                                                        code={parameterCode.TEST_CODE}
                                                        label={`${t("Test")}`}
                                                        onChange={(e, vals) => {

                                                        }}
                                                    /> */}
                                                </div>
                                                <div className="col-lg-4">
                                                    <CustomAvField
                                                        name="temperature"
                                                        type="text"
                                                        value={data.temperature}
                                                        label={t("Temperature")}
                                                        maxLength={10}
                                                    />
                                                </div>
                                                <div className="col-lg-4">
                                                    <CustomAvField
                                                        name="method"
                                                        type="text"
                                                        value={data.method}
                                                        label={t("Method")}
                                                        maxLength={155}
                                                    />
                                                </div>
                                                <div className="col-lg-4">
                                                    <CustomAvField
                                                        name="tea"
                                                        type="number"
                                                        value={data.tea}
                                                        label={t("TEA %")}
                                                    />
                                                </div>
                                            </div>
                                        </AccordionBody>
                                    </Accordion>
                                </AccordionWrapper>
                            </div>
                        </div>
                        <div className="col-12">
                            <AccordionWrapper defaultTab={["1"]}>
                                <Accordion tabId={"1"} title={t("QC Levels Infomation")}>
                                    <AccordionBody>
                                        <div className="row">
                                            <div className="col-12">
                                                {t("Level")} 1
                                            </div>
                                            <div className="col-lg-4">
                                                <CustomAvField
                                                    name="meanPI"
                                                    type="number"
                                                    value={data.meanPI}
                                                    label={t("Mean - Trung bình")}
                                                />
                                            </div>
                                            <div className="col-lg-4">
                                                <CustomAvField
                                                    name="sdpi"
                                                    type="number"
                                                    value={data.sdpi}
                                                    label={t("SD - Độ lệch chuẩn")}
                                                />
                                            </div>
                                            <div className="col-lg-4">
                                                <CustomAvField
                                                    name="deCOdd"
                                                    type="number"
                                                    value={data.deCOdd || 0}
                                                    label={t("Odd")}
                                                />
                                            </div>

                                            <div className="col-12">
                                                {t("Level")} 2
                                            </div>
                                            <div className="col-lg-4">
                                                <CustomAvField
                                                    name="meanPI2"
                                                    type="number"
                                                    value={data.meanPI2}
                                                    label={t("Mean - Trung bình")}
                                                />
                                            </div>
                                            <div className="col-lg-4">
                                                <CustomAvField
                                                    name="sdpi2"
                                                    type="number"
                                                    value={data.sdpi2}
                                                    label={t("SD - Độ lệch chuẩn")}
                                                />
                                            </div>
                                            <div className="col-lg-4">
                                                <CustomAvField
                                                    name="deCOdd2"
                                                    type="number"
                                                    value={data.deCOdd2 || 0}
                                                    label={t("Odd")}
                                                />
                                            </div>

                                            <div className="col-12">
                                                {t("Level")} 3
                                            </div>
                                            <div className="col-lg-4">
                                                <CustomAvField
                                                    name="meanPI3"
                                                    type="number"
                                                    value={data.meanPI3}
                                                    label={t("Mean - Trung bình")}
                                                />
                                            </div>
                                            <div className="col-lg-4">
                                                <CustomAvField
                                                    name="sdpi3"
                                                    type="number"
                                                    value={data.sdpi3}
                                                    label={t("SD - Độ lệch chuẩn")}
                                                />
                                            </div>
                                            <div className="col-lg-4">
                                                <CustomAvField
                                                    name="deCOdd3"
                                                    type="number"
                                                    value={data.deCOdd3 || 0}
                                                    label={t("Odd")}
                                                />
                                            </div>
                                        </div>
                                    </AccordionBody>
                                </Accordion>
                            </AccordionWrapper>
                        </div>
                    </AvForm>

                </ModalBody>
                <ModalFooter>
                    <CustomButton
                        text={t("common:Cancel")}
                        type="button"
                        onClick={toggle}
                        data-dismiss="modal"
                        className="button-width"
                    />

                    <CustomButton
                        text={t("common:Save")}
                        type="submit"
                        color="primary"
                        onClick={() => formEl?.current?.submit()}
                        className="save-user button-width"
                        isEdit
                    />
                </ModalFooter>
            </CustomModal>
        </React.Fragment>
    )
}

LotTestModal.defaultProps = {}

const mapStateToProps = ({ testResult }) => ({
})

const mapDispatchToProps = dispatch => ({
    // onGetNotifications: payload => dispatch(getNotifications(payload)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation(["chartiQCPage", "common"])(LotTestModal))
