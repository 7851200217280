import PropTypes from "prop-types"
import { Label, Row } from "reactstrap"
//i18n
import { AvField } from "availity-reactstrap-validation"
import { CustomDatePicker, CustomSelect } from "components/Common"
import { parameterCode } from "constant"
import { convertDateFormat } from "helpers/utilities"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"

const FilterForm = ({ model, t, onChange }) => {
    return (
        <>
            <Row>
                <div className="col-12 mb-3">
                    <Label for="a">{t("Date")}</Label>
                    <CustomDatePicker
                        conjunction=" - "
                        name="date"
                        label={""}
                        checkSameTime={true}
                        dateFormat={"d-m-Y"}
                        value={[convertDateFormat(model.start || new Date(), "DD-MM-YYYY"), convertDateFormat(model.end || new Date(), "DD-MM-YYYY")]}
                        closeOnSelect={true}
                        onKeyDown={e => {
                            if (e.keyCode == 13) {
                                // onSubmit && onSubmit()
                            }
                        }}
                        onChangeHandler={date => {
                            if (date.time.length == 2) {
                                onChange({
                                    start: convertDateFormat(date.time[0], "YYYY-MM-DD"),
                                    end: convertDateFormat(date.time[1], "YYYY-MM-DD"),
                                })
                            } else {
                                onChange({
                                    start: null,//convertDateFormat(new Date(), "YYYY-MM-DD"),
                                    end: null,//convertDateFormat(new Date(), "YYYY-MM-DD"),
                                })
                            }
                        }}
                        maxDate={new Date()}
                        mode="range"
                    />
                </div>
                <div className="col-12 mb-3">
                    <CustomSelect
                        label={t("Group Lot QC")}
                        portal
                        name="groupLot"
                        value={model.lotGroup || ''}
                        code={parameterCode.LOT_GROUP_QC}
                        onChange={(e, vals) => {
                            onChange({ lotGroup: vals[0] || '' })
                        }}
                    />
                </div>
                <div className="col-12 d-flex">
                    <Label for="isExpirated" style={{ marginRight: 5 }}>{t("Statistical Expirated")}</Label>
                    <AvField
                        name="isExpirated"
                        type="checkbox"
                        className="form-input"
                        value={model.isExpired}
                        onChange={(e, vals) => {
                            onChange({ isExpired: e.target.checked || false })
                        }}
                    />
                </div>
            </Row>

        </>
    )
}

FilterForm.propTypes = {
    model: PropTypes.object,
    t: PropTypes.any
}

const mapDispatchToProps = dispatch => ({
})

export default withTranslation(["managementQCPage", "common"])(connect(
    null,
    mapDispatchToProps
)(FilterForm))