import { CustomBootstrapTable } from "components/Common";
import { ModuleIds } from "constant";
import { convertDateFormat, getColorBookmark, indexCalculator } from "helpers/utilities";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import FilterForm from "./FilterForm";

const RESOURCE = ModuleIds.ChartiQC;

const TestQCTable = ({
onSelect,
    onSelectAll,
    configs,
    onSearch,
    onSort,
    onRefresh,
    onPageChange,
    paging,
    onSizePerPageChange,
    onSubmitFilter,
    loading,
    t,
    updatedTime,
    onChangeModel,
    model,
    onReset,
    onSelected
}) => {
    const [resultId, setResultId] = useState('')
    const [data, setData] = useState([]);

    useEffect(() => {
        setData(configs)
    }, [configs])

    const activeStyle = {
        fontWeight: 'bold'
    }
    const checkActiveRow = (row) => {
        if (row.id == resultId) {
            return activeStyle;
        }
        return {}
    }

    let columns = [
        {
            dataField: "id",
            text: "#",
            hidden: true,
            formatter: (cellContent, row, index) => {
                return <span style={checkActiveRow(row)}>{cellContent}</span>
            },
        },
        {
            dataField: "SEQ",
            text: "#",
            headerStyle: { width: "30px" },
            formatter: (cellContent, row, index) => {
                return <span style={checkActiveRow(row)}>{indexCalculator(paging.page, paging.size, index + 1)}</span>
            },
        },
        {
            dataField: "lotId",
            text: t("LotId"),
            headerStyle: { whiteSpace: 'nowrap' },
            style: { whiteSpace: 'nowrap' },
            formatter: (cellContent, row, index) => {
                return <span style={checkActiveRow(row)}>{cellContent}</span>
            },
        },
        {
            dataField: "lotName",
            text: t("Lot Name"),
            style: { whiteSpace: 'nowrap' },
            headerStyle: { whiteSpace: 'nowrap' },
            formatter: (cellContent, row, index) => {
                return <span style={{ color: "#556ee6", ...checkActiveRow(row) }}>{cellContent}</span>
            },
        },
        {
            dataField: "machineName",
            text: t("Machine"),
            style: { whiteSpace: 'nowrap' },
            headerStyle: { whiteSpace: 'nowrap' },
            formatter: (cellContent, row, index) => {
                return <span style={checkActiveRow(row)}>{cellContent}</span>
            },
        },
        {
            dataField: "levels",//mức
            text: t("Level"),
            formatter: (cellContent, row, index) => {
                return <span style={checkActiveRow(row)}>{cellContent}</span>
            },
        },
        {
            dataField: "expireDate",//hạn dùng
            text: t("Expiration Date"),
            headerStyle: { whiteSpace: 'nowrap' },
            style: { whiteSpace: 'nowrap' },
            formatter: (cellContent, row, index) => {
                return <span style={checkActiveRow(row)}>{convertDateFormat(row.expireDate, "DD-MM-YYYY HH:mm")}</span>
            },
        },
        {
            dataField: "testCount",
            text: t("Test Count"),
            headerStyle: { whiteSpace: 'nowrap' },
            formatter: (cellContent, row, index) => {
                return <span style={checkActiveRow(row)}>{cellContent}</span>
            },
        },
    ]

    return (
        <div className="">
            <CustomBootstrapTable
                columns={columns}
                data={data}
                paging={paging}
                onSelect={onSelect}
                onSelectAll={onSelectAll}
                onSearch={onSearch}
                onSort={onSort}
                onRefresh={onRefresh}
                filterForm={() => <FilterForm model={model} onChange={onChangeModel} />}
                onReset={onReset}
                onPageChange={onPageChange}
                onSizePerPageChange={onSizePerPageChange}
                onSubmitFilter={onSubmitFilter}
                searchText={model.search}
                loading={loading}
                updatedTime={updatedTime}
                resource={RESOURCE}
                keyField="id"
                showSelectRow={false}
                isEnableExport={false}
                disableToggle={true}
                TableTitle={t("List Lot QC")}
                onRowClick={(e, row, index) => {
                    if (data?.length > 0) {
                        onSelected(row)
                        setResultId(row.id)
                        setData(data.map(x => ({ ...x, selected: x.id == row.id })))
                    }
                }}
                headerClasses={'table-light result-test-color'}
            />
        </div>
    )
}

export default withTranslation(["chartiQCPage", "common"])(TestQCTable)