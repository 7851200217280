import {
    GET_LIST_CHANGE_QC,
    GET_LIST_CHANGE_QC_FAIL,
    GET_LIST_CHANGE_QC_SUCCESS,
    GET_CHANGE_QC_DETAIL,
    GET_CHANGE_QC_DETAIL_FAIL,
    GET_CHANGE_QC_DETAIL_SUCCESS,
    ADD_NEW_CHANGE_QC,
    ADD_NEW_CHANGE_QC_FAIL,
    ADD_NEW_CHANGE_QC_SUCCESS,
    UPDATE_CHANGE_QC,
    UPDATE_CHANGE_QC_FAIL,
    UPDATE_CHANGE_QC_SUCCESS,
    DELETE_CHANGE_QC,
    DELETE_CHANGE_QC_FAIL,
    DELETE_CHANGE_QC_SUCCESS
} from "./actionTypes"
const INIT_STATE = {
    changeQCs: [],
    paging: {},
    loading: false,
    loadingDetail: false,
    updating: false,
    updatedTime: new Date(),
    changeQC: {},
    error: {},
}
const ChangeQCs = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_LIST_CHANGE_QC:
            return {
                ...state,
                error: {},
                loading: true,
            }
        case GET_LIST_CHANGE_QC_SUCCESS:
            const { data, totalElements, ...rest } = action.payload
            return {
                ...state,
                changeQCs: data,
                paging: { ...rest, dataSize: totalElements },
                loading: false,
                updatedTime: new Date(),
            }
        case GET_LIST_CHANGE_QC_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            }

        case GET_CHANGE_QC_DETAIL:
            return {
                ...state,
                changeQC: {},
                error: {},
                loadingDetail: true,
            }
        case GET_CHANGE_QC_DETAIL_SUCCESS:
            const changeQC = action.payload
            return {
                ...state,
                changeQC: changeQC,
                loadingDetail: false,
            }
        case GET_CHANGE_QC_DETAIL_FAIL:
            return {
                ...state,
                error: action.payload,
                loadingDetail: false,
            }
        case ADD_NEW_CHANGE_QC:
            return {
                ...state,
                errorCode: {},
            }
        case ADD_NEW_CHANGE_QC_SUCCESS:
            return {
                ...state,
            }
        case ADD_NEW_CHANGE_QC_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        case UPDATE_CHANGE_QC:
            return {
                ...state,
                updating: true,
                error: {},
            }
        case UPDATE_CHANGE_QC_SUCCESS:
            return {
                ...state,
                updating: false,
            }
        case UPDATE_CHANGE_QC_FAIL:
            return {
                ...state,
                error: action.payload,
                updating: false,
            }

        case DELETE_CHANGE_QC:
            return {
                ...state,
            }
        case DELETE_CHANGE_QC_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        case DELETE_CHANGE_QC_SUCCESS:
            return {
                ...state,
            }
        default:
            return state
        }
    }
export default ChangeQCs