import { LabelAndValue, TitleAndInformation } from "components/Common"
import { ModuleIds } from "constant"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { Col, Row } from "reactstrap"

const RESOURCE = ModuleIds.AccessionNumber

const TitleAndInfo = ({ onEdit, onClone, data, t, ...rest }) => {
    const countNumber = data.totalNumber - data.remainingNumber;
    return (
        <TitleAndInformation
            onEdit={onEdit}
            // onClone={onClone}
            isDisableClone
            resource={RESOURCE}
            {...rest}
        >
            <Row>
                <Col xs="12">
                    <Row>
                        <Col md="auto" className="pr-6">
                            <LabelAndValue label={t("Accession Id")} value={data.id} />
                        </Col>
                        <Col md="auto" className="pr-6">
                            <LabelAndValue
                                label={t("Count")}
                                value={isNaN(countNumber) ? 0 : countNumber}
                            />
                        </Col>
                        <Col md="auto" >
                            <LabelAndValue
                                label={t("Numbers")}
                                value={data.totalNumber}
                                className={"fw-bold"}
                            />
                        </Col>
                        <Col md="auto" className="d-flex justify-content-end ms-auto">
                           <i className="bx bx-shuffle" style={{fontSize : '48px', color: '#adb5bd'}}></i>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </TitleAndInformation>
    )
}

TitleAndInfo.propTypes = {
    onEdit: PropTypes.func,
    onClone: PropTypes.func,
    parameter: PropTypes.object,
    t: PropTypes.any,
}

export default withTranslation(["accessionNumberPage", "common"])(TitleAndInfo)
