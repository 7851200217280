import {
  GET_QC_DATAPOINTS,
  GET_QC_DATAPOINTS_FAIL,
  GET_QC_DATAPOINTS_SUCCESS,
} from "./actionTypes"

export const getQCDatapoints = payload => ({
  type: GET_QC_DATAPOINTS,
  payload: payload,
})

export const getQCDatapointsSuccess = payload => ({
  type: GET_QC_DATAPOINTS_SUCCESS,
  payload: payload,
})

export const getQCDatapointsFail = error => ({
  type: GET_QC_DATAPOINTS_FAIL,
  payload: error,
})