import { spreadSearchQuery } from "helpers/utilities"
import { get, post } from "../../api_helper2"

const BASE_API_URL = `${process.env.REACT_APP_LABORATORY_ENDPOINT}/qc/calculation`

const getLottestQCList = payload => {
    let q = spreadSearchQuery(payload)
    return get(`${BASE_API_URL}?${q}`)
}

const updateLottestQC = req => {
    return post(`${BASE_API_URL}`, req)
}

const exportLottestQCs = (payload) => {
    let q = spreadSearchQuery(payload)
    return post(`${BASE_API_URL}/ExportLottestQC?${q}`, null, {
        responseType: 'arraybuffer',
        headers:
        {
            'Content-Disposition': "attachment; filename=template.xlsx",
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        },
    })
}


export { exportLottestQCs, getLottestQCList, updateLottestQC }

