import React, { useEffect, useState, useRef, useMemo } from "react"
import PropTypes from "prop-types"

import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import filterFactory from "react-bootstrap-table2-filter"
import { Row } from "reactstrap"
import classNames from "classnames"
import { withTranslation } from "react-i18next"
import "./datatables.scss"
import { FilterButton, Now } from ".."
import { compareSortArray } from "helpers/utilities"
import CustomSearchBar from "../CustomTable/SearchBar"

const CustomBootstrapInlineTable = ({
  data,
  columns,
  onSelect,
  onSelectAll,
  isScrollable,
  loading,
  updatedTime,
  t,
  searchText,
  onDragEnd: onDragEndProp,
  isSorted,
  onSort,
  rowClasses,
  isEnableLoadInfo,
  expandRow,
  keyField,
  isTableScrollX,
  rowStyle,
  rowCountCustom,
  showSelectRow,
  defaultSelected,
  isHover = true,
  onSearch,
  filterForm,
  isSearch = false,
  onSubmitFilter,
  onReset,
  onScroll,
  styleBeforeRender,
  customButtonTable,
  style,
  customSize,
  customLeftButtonTable
}) => {
  const [defaultData, setDefaultData] = useState([])
  const [selected, setSelected] = useState([])
  const [defaultSearch, setDefaultSearch] = useState("")
  const [rowCount, setRowCount] = useState(0)
  const [keySearch, setKeySearch] = useState("")
  const sapXep = useRef({})

  useEffect(() => {
    if (defaultSelected && defaultSelected.length > 0) {
      handleOnSelectAll(true, defaultData)
    }
  }, [defaultSelected])

  const handleOnSelect = (row, isSelected) => {
    if (isSelected) {
      setSelected(prev => [...prev, row.id])
    } else {
      setSelected(prev => [...prev.filter(x => x !== row.id)])
    }
    onSelect(row, isSelected)
  }

  const handleOnSelectAll = (isSelected, rows) => {
    const ids = rows.map(r => r.id)
    let results = [...rows]
    if (isSelected) {
      setSelected(ids)
    } else {
      results = []
      setSelected([])
    }

    onSelectAll(results)
  }

  const resetCheckbox = () => {
    setSelected([])
    onSelectAll([])
  }

  useEffect(() => {
    if (!isSorted) resetCheckbox()
  }, [data, isSorted])

  useEffect(() => {
    setDefaultData(JSON.parse(JSON.stringify(data)))
  }, [data])

  useEffect(() => {
    setDefaultSearch(searchText)
  }, [searchText])

  const pageOptions = {
    sizePerPage: isScrollable ? defaultData.length : (customSize || 10),
    totalSize: defaultData.length, // replace later with size(customers),
    custom: true,
  }

  // const defaultSorted = [
  //   {
  //     dataField: "id",
  //     order: "asc",
  //   },
  // ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
    // clickToSelect: true,
    onSelect: handleOnSelect,
    onSelectAll: handleOnSelectAll,
    style: { background: "#f8f9fa" },
    selected, // should be a row keys array
  }

  const handleDataSizeChange = ({ dataSize }) => {
    setRowCount(dataSize)
  }

  const MySearch = props => {
    const { defaultSearch, searchText } = props
    useEffect(() => {
      props.onSearch(defaultSearch)
    }, [defaultSearch])

    return null
  }

  const onSortHandler = (a, b, order, dataField) => {
    if (
      dataField === sapXep?.current.dataField &&
      sapXep.current.order === order
    )
      return null

    const newData = JSON.parse(JSON.stringify(defaultData))
      .sort((a, b) => {
        return compareSortArray(a[dataField], b[dataField], order)
      })
      .map((_item, _idx) => {
        _item.displayOrder = _idx
        return _item
      })

    sapXep.current = { order, dataField }
    return onSort(a, b, order, dataField, newData)
  }

  const onSearchChangeHandler = e => {
    const keySearch = e.target.value
    setKeySearch(keySearch)
  }

  const onSubmitSearchHandler = e => {
    e.preventDefault()
    onSearchHandler(keySearch)
  }

  const onClearSearchHandler = () => {
    setKeySearch("")
    onSearchHandler("")
  }

  const onSearchHandler = searchText => {
    if (onSearch) {
      onSearch(searchText)
      resetCheckbox()
    }
  }

  const onSubmit = (e, values) => {
    if (onSubmitFilter) {
      onSubmitFilter(values)
      resetCheckbox()
    }
  }

  const onResetHandler = () => {
    resetCheckbox()
    onReset && onReset()
  }

  const handleScroll = e => {
    handleEndScroll(e);
  };
  const handleEndScroll = useMemo(
    (e) =>
      _.debounce((e) => onScroll(e), 0),
    []
  );

  return (
    <ToolkitProvider keyField="id" columns={columns} data={defaultData} search>
      {toolkitProps => (
        <React.Fragment>
          <MySearch
            onSearch={toolkitProps.searchProps.onSearch}
            delay={0}
            defaultSearch={defaultSearch}
          />
          {isEnableLoadInfo &&
            <Row className="mb-1 px-2">
              <div className="d-flex flex-wrap flex-row">
                <div className={`align-self-center ${!isSearch ? 'col-sm-4' : ''}`}>
                  <span className="font-size-14">
                    {" "}
                    {t("table:totalRow2", {
                      size: rowCountCustom ? rowCountCustom : rowCount,
                    })}{" "}
                    &bull; {t("Updated")} <Now date={updatedTime} />
                  </span>
                </div>
                {isSearch &&
                  <div className={`ms-auto ${customLeftButtonTable && 'd-flex gap-1'}`}>
                    {customLeftButtonTable && customLeftButtonTable()}
                    <CustomSearchBar
                      onSearch={onSearchChangeHandler}
                      searchText={keySearch}
                      placeholder={t("Search")}
                      onClear={onClearSearchHandler}
                      onSubmit={onSubmitSearchHandler}
                    />
                  </div>
                }
                <div className={`ms-0 ${!isSearch ? 'col-sm-8' : ''}`}>
                  <div className="toolbar button-items text-end">
                    {customButtonTable && customButtonTable()}
                    {filterForm && <FilterButton
                      onReset={onResetHandler}
                      onSubmit={onSubmit}
                    >
                      {filterForm()}
                    </FilterButton>}
                  </div>
                </div>
              </div>
            </Row>}
          <div
            className={classNames(
              { "table-scrollable": isScrollable },
              "table-responsive",
              "px-0",
              isScrollable && `${isTableScrollX ? 'table-scroll-x overflowYhidden' : 'overflowYhidden'}`
            )}
            style={style}
            onScroll={e => {
              onScroll && handleScroll(e)
            }}
          >
            {/* <DraggableComponent> */}
            {keyField && <div className="table-content-before-render" style={styleBeforeRender ? styleBeforeRender : {}}><BootstrapTable
              {...toolkitProps.baseProps}
              id="testTable"
              rowStyle={rowStyle}
              keyField={keyField}
              rowClasses={rowClasses}
              responsive
              bordered={false}
              striped={false}
              columns={columns}
              data={defaultData}
              selectRow={showSelectRow == true ? selectRow : undefined}
              expandRow={expandRow}
              headerClasses={"table-light"}
              filter={filterFactory()}
              onDataSizeChange={handleDataSizeChange}
              pagination={paginationFactory(pageOptions)}
              tabIndexCell
              classes={classNames(
                { "header-fixed": isScrollable },
                "table",
                "align-middle",
                { "table-hover": isHover },
              )}
              // headerWrapperClasses={"thead-light"}
              sort={{
                // No need to configure sortFunc per column
                // dataField: defaultSorted?.dataField,
                // order: defaultSorted?.order,
                sortFunc: onSortHandler,
              }}
            /></div>
            }
            {/* </DraggableComponent> */}
          </div>
        </React.Fragment>
      )}
    </ToolkitProvider>
  )
}

CustomBootstrapInlineTable.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
  onSelect: PropTypes.func,
  onSelectAll: PropTypes.func,
  isScrollable: PropTypes.bool,
  loading: PropTypes.bool,
  t: PropTypes.any,
  updatedTime: PropTypes.any,
  searchText: PropTypes.string,
  onDragEnd: PropTypes.func,
  rowClasses: PropTypes.any,
  expandRow: PropTypes.object,
  customButtonTable: PropTypes.any,
  style: PropTypes.object,
}

CustomBootstrapInlineTable.defaultProps = {
  data: [],
  columns: [],
  onSelect: () => { },
  onSelectAll: () => { },
  onSearch: () => { },
  onRefresh: () => { },
  onReset: () => { },
  loading: false,
  updatedTime: new Date(),
  searchText: "",
  onDragEnd: data => { },
  rowClasses: "",
}

export default withTranslation(["common", "table"])(CustomBootstrapInlineTable)
