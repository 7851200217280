import PropTypes from "prop-types"
import { Row } from "reactstrap"
//i18n
import { AvField } from "availity-reactstrap-validation"
import { CustomAvField, CustomSelect, CustomSelectAsync } from "components/Common"
import { parameterCode } from "constant"
import { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { getInvalidMessageI18n } from "helpers/utilities"

const FilterForm = ({ model, t }) => {
    const [userName, setUserName] = useState('')
    useEffect(() => {
        if (model.userNameValid && model.userNameValid != userName)
            setUserName(model.userNameValid || '')
    }, [model])

    return (
        <>
            <Row>
                <AvField name="userNameValid" type="hidden" value={userName || ""} />
                <div className="col-12 mb-3">
                    <CustomAvField
                        name="runSeq"
                        type="number"
                        value={model.runSeq || 1}
                        label={t("Number Of Run")}
                        errorMessage={getInvalidMessageI18n(t, "Number Of Run")}
                        // onChange={onSidCurrentChangeHandler}
                        min={1}
                        max={20}
                    />
                </div>
                <div className="col-12 mb-3">
                    <CustomSelectAsync
                        name="userValid"
                        placeholder={t("User Valid")}
                        value={model.userValid || ''}
                        valueNameNotSearch={userName || ''}
                        code={parameterCode.USERS}
                        label={t("User Valid")}
                        onChange={(a, b, c, d) => {
                            setUserName(c)
                        }}
                    />
                </div>
            </Row>

        </>
    )
}

FilterForm.propTypes = {
    model: PropTypes.object,
    t: PropTypes.any
}

const mapDispatchToProps = dispatch => ({
})

export default withTranslation(["resultIQCPage", "common"])(connect(
    null,
    mapDispatchToProps
)(FilterForm))